import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import CartModalExt from './CartModalExt';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

const CartModalViewExt = () => {
  const classes = useStyles();

  return (
    <>
      <Container component="main" maxWidth="lg">
        <Paper elevation={5} className={classes.root}>
          <CartModalExt
            closePopup
            fullWidth
            source="licenseWeb"
          />
        </Paper>
      </Container>
    </>
  );
};

const mapStateToProps = ({ home }) => ({
  cart: home.cart,
});

const mapDispatchToProps = {
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CartModalViewExt));
