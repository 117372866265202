/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign, react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField } from '@material-ui/core/';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import deLocale from 'date-fns/locale/es';
import CustomBasicAutoComplete from '../../form/Autocomplete/CustomBasicAutoComplete';
import SetRangeTransaction from './SetRangeTransaction';
import {
  addNewLicenseCustomerAction,
  GetLicenseInformationRangesAction,
  SetLicenseTransactionRangeAction,
} from '../../../actions/license.action';
import { getPackagesAddManualLicenseAction } from '../../../actions/home.action';
import { setMessageAlertAction } from '../../../actions/general.action';
import WarningIcon from '../../../assets/img/iconos/license/WarningIcon.svg';
import QuestionIcon from '../../../assets/img/iconos/license/QuestionIcon.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: 'white',
  },
  title: {
    color: '#3D85C2',
    fontSize: 18,
    fontWeight: 500,
  },
  titlePackage: {
    color: '#515151',
  },
  buttonAddLicense: {
    justifyContent: 'center',
    display: 'inline-flex',
  },
  chipSelected: {
    margin: theme.spacing(0.5),
    backgroundColor: '#E55200',
    color: '#fff',
    '& .MuiChip-clickable': {
      color: '#E55200',
    },
  },
  chipDefault: {
    margin: theme.spacing(0.5),
    backgroundColor: '#fff',
    border: '1px solid #E55200',
    color: '#E55200',
    '& .MuiChip-clickable': {
      color: '#E55200',
    },
  },
  btnAddLicense: {
    marginLeft: 10,
  },
  iconGoBack: {
    color: '#E5521A',
    fontSize: '30px',
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    top: '5px',
    position: 'relative',
  },
  searchTextField: {
    marginLeft: '5px',
    boxShadow: '0px 3px 2px #00000026',
    borderRadius: '10px',
    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
    border: '1px solid #D5D5D5',
    opacity: 1,
    backgroundColor: '#fff',
    outline: 'none',
  },
  dateCustom: {
    '& .MuiIconButton-root': {
      color: '#009fe3',
    },
  },
  errorTextField: {
    borderColor: 'red !important',
  },
}));

const AddNewLicense = (props) => {
  const classes = useStyles();
  const {
    loading, isEditing, customerId, addNewLicenseCustomer, setShowAddLicense, showReturn = true,
    handleCancelAddLicense, handleCancelEditLicense, setMsgAlert, confirmCancelAlert,
    setConfirmCancelAlert, serviceAddManualLicenseCombo, getPackagesAddManualLicense, packages,
    getLicenseInformation, getLicenseInformationRanges, licenseSelected, SetLicenseTransactionRange,
    onCloseModal,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [optionsServices, setOptionsService] = React.useState([]);
  const [chipsPackageOptions, setChipsPackageOptions] = React.useState([]);
  const [transactionRanges, setTransactionRanges] = React.useState([]);
  const [serviceSelected, setServiceSelected] = React.useState(null);
  const [packageSelected, setPackageSelected] = React.useState(null);
  const [packageCodeSelected, setPackageCodeSelected] = React.useState(null);
  const [isValidForm, setIsValidForm] = React.useState(false);
  const [contractNumber, setContractNumber] = React.useState('');
  const [startDate, setStartDate] = React.useState(moment().startOf('day').add(1, 'minute'));
  const [finalDate, setFinalDate] = React.useState(moment(startDate).add(1, 'day').endOf('day').subtract(1, 'second'));
  const [error, setError] = React.useState(false);

  const {
    control, errors, setValue,
  } = useForm();

  const validateForm = () => {
    let isValid = false;
    if (serviceSelected && packageSelected && transactionRanges.length > 0) {
      if (packageCodeSelected === 'BIILIMITADO' || chipsPackageOptions.some((data) => data.code === 'BIILIMITADO' && data.selected)) {
        if (contractNumber !== '') {
          isValid = true;
        }
      } else {
        isValid = true;
      }
    }
    setIsValidForm(isValid);
  };

  useEffect(() => {
    validateForm();
  });

  useEffect(() => {
    if (serviceAddManualLicenseCombo) {
      setOptionsService(serviceAddManualLicenseCombo);
    }
  }, [serviceAddManualLicenseCombo]);

  useEffect(() => {
    if (transactionRanges.length > 0) {
      validateForm();
    }
  }, [transactionRanges]);

  useEffect(() => {
    if (packages?.base?.length > 0) {
      const packagesToAdd = [];
      packages.base.forEach((item) => packagesToAdd.push(
        {
          value: item.packageId,
          text: item.packageName,
          selected: false,
          code: item.packageCode,
        },
      ));
      setChipsPackageOptions(packagesToAdd);
    }
  }, [packages]);

  useEffect(() => {
    if (confirmCancelAlert) {
      switch (confirmCancelAlert) {
        case 'CancelAddLicense':
          setServiceSelected(null);
          setPackageSelected(null);
          setTransactionRanges([]);
          setShowAddLicense(false);
          handleCancelAddLicense();
          break;
        case 'CancelEditLicense':
          setServiceSelected(null);
          setPackageSelected(null);
          setTransactionRanges([]);
          handleCancelEditLicense();
          break;
        case 'Ok':
          break;
        default:
          break;
      }
      setConfirmCancelAlert(null);
    }
  }, [confirmCancelAlert]);

  useEffect(() => {
    if (isEditing) {
      getLicenseInformationRanges(licenseSelected);
    }
  }, []);

  useEffect(() => {
    if (isEditing) {
      if (getLicenseInformation) {
        setServiceSelected({ value: getLicenseInformation.serviceId, text: getLicenseInformation.descriptionService });
        getPackagesAddManualLicense(getLicenseInformation.serviceId);
        setChipsPackageOptions(chipsPackageOptions);
        setPackageSelected(getLicenseInformation.packageId);
        setPackageCodeSelected(getLicenseInformation.packageCode);
        setContractNumber(getLicenseInformation.contractNumber);
        setStartDate(moment(getLicenseInformation.effectiveDate));
        setFinalDate(moment(getLicenseInformation.expirationDate));
      }
    }
  }, [getLicenseInformation]);

  useEffect(() => {
    if (startDate < moment()) {
      setFinalDate(moment().add(1, 'day').endOf('day').subtract(1, 'second'));
    }
    else {
      setFinalDate(moment(startDate).add(1, 'day').endOf('day').subtract(1, 'second'));
    }
  }, [startDate]);

  useEffect(() => {
    if (packageCodeSelected) {
      handleLicenseClick(null, packageCodeSelected);
    }
  }, [packageCodeSelected]);

  if (isEditing) {
    chipsPackageOptions.map((item) => {
      if (getLicenseInformation.packageId === item.value) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });
  }

  const handleCancelClick = () => {
    setMsgAlert({
      category: isEditing
        ? 'cancelEditLicense'
        : 'cancelAddNewLicense',
      typeMsg: 'Confirm',
      tittleMsg: '',
      message: isEditing
        ? '¿Quieres cancelar la edición para esta licencia?'
        : '¿Quieres cancelar la creación de esta nueva licencia?',
      img: isEditing
        ? QuestionIcon
        : WarningIcon,
      showCancelButton: true,
      showClose: false,
      showCloseAlert: false,
      showButtonCancel: true,
      textButtonAcept: 'NO',
      textButtonCancel: 'SI',
      popupStyle: { padding: '10%' },
      contentStyle: { display: 'grid', justifyItems: 'center' },
    });
  };

  const handleChangeOption = (data) => {
    getPackagesAddManualLicense(data.value);
    setServiceSelected(data);
  };

  function closeModal() {
    onCloseModal();
  }

  const handleAddNewLicenseClick = () => {
    let newLicense = null;
    if (packageCodeSelected !== 'BIILIMITADO' || !contractNumber) {
      newLicense = {
        customerId,
        serviceId: serviceSelected.value,
        packageId: packageSelected,
        transactionRanges,
      };
    }
    else {
      newLicense = {
        customerId,
        serviceId: serviceSelected.value,
        packageId: packageSelected,
        transactionRanges,
        contractNumber,
        startDate: startDate.format(),
        finalDate: finalDate.format(),
      };
    }
    if (isEditing) {
      SetLicenseTransactionRange(licenseSelected, transactionRanges, enqueueSnackbar);
      closeModal();
    } else {
      addNewLicenseCustomer(newLicense, enqueueSnackbar);
    }
  };

  const handleLicenseClick = (event, code) => {
    if (!event || !code) {
      return;
    }
    if (code) {
      const packagesitems = [...chipsPackageOptions];
      const updatedPackages = packagesitems.map((item) => {
        if (item.code === code) {
          return {
            ...item,
            selected: true,
          };
        }
        return {
          ...item,
          selected: false,
        };
      });
      setChipsPackageOptions(updatedPackages);
      const selectedPackage = updatedPackages.find((item) => item.code === code);
      if (selectedPackage) {
        setPackageSelected(selectedPackage.value);
        setPackageCodeSelected(selectedPackage.code);
      }
    }
  };

  const handleChangeRangeClick = (data) => {
    if (data.length > 0) {
      validateForm();
    }
  };

  const getLoading = () => (
    <>
      <Grid item xs={1}>
        <CircularProgress size={24} className={classes.buttonProgress} />
      </Grid>
    </>
  );

  return (
    <Grid item lg container spacing={2}>
      {
        !isEditing && (
          <Grid item xs={12}>
            {
              showReturn ? (
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleCancelClick}>
                  <KeyboardArrowLeftIcon className={classes.iconGoBack} />
                </IconButton>
              ) : (
                <>
                </>
              )
            }
            <Typography
              color="primary"
              component="span"
              variant="body1"
              className={classes.title}
            >
              Añadir licencia
            </Typography>
          </Grid>
        )
      }
      <Grid item xs={loading ? 11 : 12}>
        <CustomBasicAutoComplete
          name="service"
          options={optionsServices}
          label="Servicio"
          maxNumberRegistersSelected={0}
          setData={(e) => setValue('service', e)}
          registersSelected={serviceSelected}
          limitTags={1}
          placeholder="Selecciona el servicio"
          control={control}
          handleChangeOption={handleChangeOption}
          error={Object.keys(errors).length > 0}
          className={classes.searchTextField}
        />
      </Grid>
      {loading && getLoading()}
      {
        !loading && serviceSelected && chipsPackageOptions.length > 0 && (
          <>
            <Grid item xs={12}>
              <Typography
                color="primary"
                component="span"
                variant="body1"
                className={classes.titlePackage}
              >
                Cantidad de transacciones anuales:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {
                chipsPackageOptions.length > 0 && (
                  chipsPackageOptions?.map((data) => (
                    <Chip
                      key={data.code}
                      disabled={isEditing}
                      label={data.text}
                      onClick={(e) => handleLicenseClick(e, data.code)}
                      className={`${data.selected === true ? classes.chipSelected : classes.chipDefault}`}
                      clickable
                    />
                  ))
                )
              }
            </Grid>
            <Grid item xs={12}>
              <Typography
                color="primary"
                component="span"
                variant="body1"
              >
                Vigencia de plan anual
              </Typography>
            </Grid>

            {
              (packageCodeSelected === 'BIILIMITADO' || chipsPackageOptions.some((data) => data.code === 'BIILIMITADO' && data.selected)) && (
                <Grid item xs={12} style={{ marginBottom: '16px' }}>
                  <TextField
                    label="Número de contrato"
                    value={contractNumber}
                    onChange={(event) => {
                      setContractNumber(event.target.value);
                    }}
                    fullWidth
                    InputLabelProps={{ style: { marginLeft: '2px' } }}
                    margin="dense"
                    variant="outlined"
                    inputProps={{ maxLength: 100 }}
                    disabled={isEditing}
                    required
                    className={(error) ? classes.errorTextField : ''}
                  />
                  <Box>
                    <Grid container spacing={2} style={{ marginTop: '16px' }}>
                      <Grid item xs={6}>
                        <FormControl>
                          <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className={classes.dateCustom}
                              variant="inline"
                              inputVariant="outlined"
                              value={startDate}
                              format="dd/MM/yyyy"
                              label="Fecha Inicio"
                              size="small"
                              color="primary"
                              placeholder="Fecha Inicio"
                              onChange={(date) => setStartDate(moment(date))}
                              autoOk
                              disabled={isEditing}
                            />
                          </MuiPickersUtilsProvider>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl>
                          <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className={classes.dateCustom}
                              variant="inline"
                              inputVariant="outlined"
                              value={finalDate}
                              format="dd/MM/yyyy"
                              label="Fecha Fin"
                              size="small"
                              color="primary"
                              placeholder="Fecha Fin"
                              onChange={(date) => setFinalDate(moment(date))}
                              shouldDisableDate={(date) => (startDate < moment(date) ? moment(date).isSameOrBefore() : moment(date).isSameOrBefore(startDate)) }
                              autoOk
                              disabled={isEditing}
                            />
                          </MuiPickersUtilsProvider>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )
            }
            {
              packageSelected && (
                <Grid item xs={12}>
                  <SetRangeTransaction
                    setTransactionRanges={setTransactionRanges}
                    transactionRanges={transactionRanges}
                    licenseInformation={getLicenseInformation}
                    handleChangeRangeClick={handleChangeRangeClick}
                    isEditing={isEditing}
                    onCloseModal={onCloseModal}
                  />
                </Grid>
              )
            }
          </>
        )
      }
      <Grid item xs={12} className={classes.buttonAddLicense}>
        <Button
          variant="contained"
          color="primary"
          className={classes.btnAddLicense}
          onClick={() => {
            handleCancelClick();
          }}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.btnAddLicense}
          disabled={!isValidForm}
          onClick={() => {
            if (packageCodeSelected !== 'BIILIMITADO' || !contractNumber) {
              handleAddNewLicenseClick();
              setError(true);
            } else {
              handleAddNewLicenseClick();
              setError(false);
            }
          }}
        >
          {isEditing ? 'Actualizar' : 'Añadir'}
        </Button>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (props) => ({
  addLicenseCustomer: props.license.addLicenseCustomer,
  getLicenseInformation: props.license.getLicenseInformation,
  loading: props.home.loading,
  serviceAddManualLicenseCombo: props.service.serviceAddManualLicenseCombo,
  packages: props.home.packages,
});

const mapDispatchToProps = {
  addNewLicenseCustomer: addNewLicenseCustomerAction,
  setMsgAlert: setMessageAlertAction,
  getLicenseInformationRanges: GetLicenseInformationRangesAction,
  getPackagesAddManualLicense: getPackagesAddManualLicenseAction,
  SetLicenseTransactionRange: SetLicenseTransactionRangeAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewLicense);
