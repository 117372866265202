import {
  SERVICE_FEATURE_LOADING, GET_SERVICE_FEATURES, SET_PARENTS_FEATURES, CLEAR_SERVICE_FEATURES, RESET_PAGINATION_SERVICE_FEATURES,
} from '../types/serviceFeature.type';

const INITIAL_STATE = {
  loading: false,
  serviceFeaturesBatch: [],
  serviceFeaturesBatchRows: null,
  parentFeatures: [],
  serviceFeaturePage: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLEAR_SERVICE_FEATURES:
      return {
        ...state,
        serviceFeaturesBatch: [],
        serviceFeaturesBatchRows: null,
        serviceFeaturePage: 0,
      };
    case GET_SERVICE_FEATURES:
      return {
        ...state,
        serviceFeaturesBatch: action.payload,
        serviceFeaturesBatchRows: action.rownums,
      };
    case SERVICE_FEATURE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_PARENTS_FEATURES:
      return {
        ...state,
        parentFeatures: action.payload,
      };
    case RESET_PAGINATION_SERVICE_FEATURES:
      return {
        ...state,
        serviceFeaturePage: action.payload,
      };
    default:
      return state;
  }
};
