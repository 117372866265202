import Geocode from 'react-geocode';
import { API_KEY_GOOGLE_MAPS } from '../config/config';

// eslint-disable-next-line import/prefer-default-export
export const setGeoCode = (data, setGeoCoordinate) => {
  Geocode.setApiKey(API_KEY_GOOGLE_MAPS);
  Geocode.enableDebug();
  const addressValue = data?.description ? data.description : data;
  if (addressValue !== null) {
    Geocode.fromAddress(addressValue).then(
      (response) => {
        // const { lat, lng } = response.results[0].geometry.location;
        const { location } = response.results[0].geometry;
        if (setGeoCoordinate) {
          setGeoCoordinate(location);
        }
      },
      (err) => {
        console.error('Error cargardo datos geoCode...', err);
        setGeoCoordinate({ lat: null, lng: null });
      },
    );
  }
  return data;
};
