import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  btnContinue: {
    ...theme.home.cart.btnContinue,
  },
  titleMessage: {
    ...theme.home.cart.title,
    fontWeight: 700,
    fontSize: 25,
  },
  containMessage: {
    fontWeight: 400,
    fontSize: '1rem',
    color: '#000000',
    whiteSpace: 'pre-line',
    textAlign: 'center',
  },
  gridContainer: {
    width: '100%',
    margin: 0,
    overflowX: 'hidden',
    background: '#FFFFFF',
    minHeight: '100vh',
  },
}));

const GenericMessage = (props) => {
  const { parameterApp, messageFromRedux } = props;
  const [firstImage, setFirstImage] = useState();
  const [firstMessage, setFirstMessage] = useState('');
  const [secondMessage, setsecondMessage] = useState('');
  const [textInButton, setTextInButton] = useState('');
  const classes = useStyles();

  useEffect(() => {
    if (messageFromRedux !== undefined) {
      setFirstImage(messageFromRedux.firstImage);
      setFirstMessage(messageFromRedux.firstMessage);
      setsecondMessage(messageFromRedux.secondMessage);
      setTextInButton(messageFromRedux.textInButton);
    }
  }, [messageFromRedux]);

  const redirectToLanding = () => {
    window.parent.location.href = parameterApp?.urlsFromBack?.urlLandingPage;
  };
  return (
    <Grid
      container
      spacing={4}
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.gridContainer}
    >
      <Grid item xs={12}>
        <img src={firstImage} alt="Logo generic message" />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.titleMessage}>{firstMessage}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.containMessage}>
          {secondMessage}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={redirectToLanding}
          className={classes.btnContinue}
        >
          {textInButton}
        </Button>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ general, purchaseLicense }) => ({
  parameterApp: general.parameterApp,
  messageFromRedux: purchaseLicense.showMessageAlert,
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GenericMessage)
);
