/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-extraneous-dependencies, react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core/';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/es';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Collapse from '@material-ui/core/Collapse';
import Checkbox from '@material-ui/core/Checkbox';
import { useForm, Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import InputAdornment from '@material-ui/core/InputAdornment';
import clsx from 'clsx';
import {
  GetPackagesAdditionalDiscount, SavePromoCode, GetPackagesId, UpdatePromoCode, promoCodeUserTypeAction,
} from '../../../../actions/packets.action';
import CustomAutocomplete from '../../../../components/form/Autocomplete/CustomAutocomplete';
import { Rules } from '../../../../helpers/RHFRules';
import SwitchiOS from '../../../../components/form/SwitchiOS';
import CustomCheck from '../../../../components/form/CustomCheck';
import CustomInput from '../../../../components/form/CustomInput';
import { isValidTokenAdmin } from '../../../../helpers/authUtils';
import { roundDecimalValue } from '../../../../helpers/generalUtils';
import { getUserDataByIdAction } from '../../../../actions/security.action';
import { getTokenExternalSourceAction } from '../../../../actions/general.action';
import CustomSelect from '../../../../components/form/CustomSelect';

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  cardContent: {
    background: '#0000000D 0% 0% no-repeat padding-box',
    borderRadius: '10px',
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconRadio: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#16B1F3',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#16B1F3',
    },
  },
  expandIcon: {
    '& .MuiSvgIcon-root': {
      fontSize: 33,
    },
  },
  iconSeeMore: {
    color: '#009fe3',
    fontFamily: 'Muli',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '18px',
    height: '18px',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  textFieldCustom: {
    width: '220px',
  },
  dateCustom: {
    width: '270px',
    '& .MuiIconButton-root': {
      color: '#009fe3',
    },
  },
  paperCard: {
    backgroundColor: '#0000000D',
  },
  button: {
    marginTop: 10,
    color: '#fff',
    minWidth: 130,
    borderRadius: 10,
  },
  radioButtonPeriodicity: {
    marginLeft: '15px',
    verticalAlign: 'middle',
    flexDirection: 'column',
  },
  tamIcon: {
    transform: 'scale(0.85)',
  },
}));

const ModalPromo = (props) => {
  const {
    onCloseModal, open, promotionalCodeSelected, setPromoCodeSelected,
    getUserDataById, dataUserCreate, dataUserModify, getTokenExternalSource, promoCodeUserType,
    promoUserType,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [title, setTitle] = useState('Crear código promocional');
  const [activar, setActivar] = React.useState(true);
  const [showRequiredMessage, setShowRequiredMessage] = React.useState(false);
  const [packageSelected, setPackageSelected] = React.useState([]);
  const packagesAdditionDiscount = useSelector(({ packets }) => packets.packagesAdditionalDiscount);
  const packagesIdReducer = useSelector(({ packets }) => packets.packagesId);
  const [packageOptions, setPackageOptions] = React.useState([]);
  const [packageIdCopy, setPackageIdCopy] = React.useState([]);
  const [openViewMore, setOpenViewMore] = React.useState(false);
  const [userCreate, setUserCreate] = React.useState(null);
  const [userModify, setUserModify] = React.useState(null);
  const [errorDate, setErrorDate] = useState(false);
  const [errorEndDate, setErrorEndDate] = useState(false);
  const [periodicityModify, setPeriodicityModify] = useState(false);
  const [isActivated, setActivated] = useState(true);
  const [typeUserId, setTypeUserId] = React.useState(1);
  const [disabledTypeUser, setDisabledTypeUser] = React.useState(false);
  const [periodicityQuantityOne, setPeriodicityQuantity] = useState('0');
  const [promotionCode, setPromotionCode] = React.useState('');
  const [UseQuantity, setUseQuantity] = React.useState(1);
  const [offerTypeCode, setOfferTypeCode] = React.useState('PTJ');
  const [offerValue, setOfferValue] = React.useState('');
  const [effectiveStartDate, setEffectiveStartDate] = React.useState(moment());
  const [ExpirationDate, setExpirationDate] = React.useState(new DateFnsUtils(deLocale).addDays(new Date(), 1));
  const [PeriodicityType, setPeriodicityType] = React.useState('NEVER');
  const [EndPeriodicityDate, setEndPeriodicityDate] = React.useState(moment());
  const [uniqueUse, setUniqueUse] = React.useState(false);
  const [DescriptionPromotionCode, setDescriptionPromotionCode] = React.useState('');
  const [ApplyTotalPurchase, setApplyTotalPurchase] = React.useState(false);
  const [PersonTypeService, setPersonTypeService] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();
  const defaultValues = {
    useQuantity: 1,
    descriptionPromotionCode: '',
    idstate: 0,
    expirationDate: new DateFnsUtils(deLocale).addDays(new Date(), 1),
    applyTotalPurchase: false,
    PromotionalCodePackage: [],
  };
  const [creationDate, setCreationDate] = useState();
  const [updatingDate, setUpdatingDate] = useState();
  const {
    handleSubmit, control, errors, watch,
  } = useForm({ defaultValues });
  const {
    applyTotalPurchase, expirationDate, useQuantity,
  } = watch(['applyTotalPurchase', 'expirationDate', 'useQuantity']);
  useEffect(() => {
    dispatch(GetPackagesAdditionalDiscount());
  }, [dispatch]);
  const fillPackageOptions = () => {
    setPackageOptions([]);
    const codesInfo = [];
    packagesAdditionDiscount.map((packageAdd) => {
      const packageItem = {
        id: packageAdd.id,
        text: packageAdd.packageName,
      };
      codesInfo.push(packageItem);
    });
    setPackageOptions(codesInfo);
  };

  const setPromoUserType = () => {
    let documents = [];
    const promoTypes = [];

    documents = promoUserType.filter((promo) => promo.value === 1 || promo.value === 2 || promo.value === 3);

    if (documents.length > 0) {
      documents.map((doc, index) => (
        promoTypes.push({
          id: index,
          label: doc.typeUserPromo,
          value: doc.value,
        })
      ));
    }
    return promoTypes;
  };

  useEffect(() => {
    const tokenAdmin = localStorage.getItem('tokenAdminIbuho');
    if (tokenAdmin && isValidTokenAdmin(tokenAdmin)) {
      if (promotionalCodeSelected) {
        const userIdCreated = promotionalCodeSelected.createdby !== null ? promotionalCodeSelected.createdby : 0;
        const userIdModified = promotionalCodeSelected.modifiedby !== null ? promotionalCodeSelected.modifiedby : 0;
        getUserDataById(userIdCreated, 'CREATE');
        getUserDataById(userIdModified, 'MODIFY');
      }
    } else {
      getTokenExternalSource();
    }
  }, [promotionalCodeSelected]);

  useEffect(() => {
    if (dataUserCreate) {
      setUserCreate(dataUserCreate);
    } else {
      setUserCreate(null);
    }
  }, [dataUserCreate]);

  useEffect(() => {
    if (dataUserModify) {
      setUserModify(dataUserModify);
    } else {
      setUserModify(null);
    }
  }, [dataUserModify]);

  useEffect(() => {
    fillPackageOptions();
  }, [packagesAdditionDiscount]);

  useEffect(() => {
    const packagesId = [];
    const packageIdSelected = [];
    packagesIdReducer.forEach((packageId) => {
      const packageAlreadySelected = packageOptions.find(
        (packageChild) => packageChild.id === packageId,
      );
      if (packageAlreadySelected != null) {
        packagesId.push(packageAlreadySelected);
        packageIdSelected.push(packageId);
      }
    });
    setPackageSelected(packagesId);
    setPackageIdCopy(packageIdSelected);
  }, [packagesIdReducer]);

  const validateDates = () => {
    const oneDay = 24 * 60 * 60 * 1000;
    const diffDays = roundDecimalValue((ExpirationDate - effectiveStartDate) / oneDay);
    if (diffDays >= 0 && diffDays <= 30) {
      setPeriodicityModify(true);
      setErrorDate(false);
      setEndPeriodicityDate(ExpirationDate);
      return false;
    }
    if (diffDays < 0) {
      setPeriodicityModify(false);
      setErrorDate(true);
      return true;
    }
    if (diffDays > 30) {
      setPeriodicityModify(false);
      setPeriodicityQuantity('0');
      setEndPeriodicityDate(ExpirationDate);
      setPeriodicityType('NEVER');
      setErrorDate(false);
      return false;
    }
  };

  const validateEndDate = () => {
    if (Date.parse(EndPeriodicityDate) < Date.parse(ExpirationDate)) {
      setErrorEndDate(true);
      return true;
    }
    if (Date.parse(ExpirationDate) < Date.parse(effectiveStartDate)) {
      setErrorDate(true);
      return true;
    }
    setErrorEndDate(false);
    setErrorDate(false);
    return false;
  };

  useEffect(() => {
    if (PeriodicityType === 'NEVER' && effectiveStartDate !== '' && ExpirationDate !== '') {
      validateDates();
    }
    if (PeriodicityType !== 'NEVER') {
      validateEndDate();
    }
  }, [effectiveStartDate, ExpirationDate, EndPeriodicityDate]);

  const updatePromotionCode = (data) => {
    setTitle('Editar código promocional');
    const startDateRow = moment(data.effectiveStartDate, 'YYYY-MM-DD HH:mm:ss').toDate();
    const endDateRow = moment(data.expirationDate, 'YYYY-MM-DD HH:mm:ss').toDate();
    const endPeriodicityDateRow = moment(data.endPeriodicityDate, 'YYYY-MM-DD HH:mm:ss').toDate();
    const creationDateToShow = moment(data.createdon, 'YYYY-MM-DD HH:mm:ss').toDate();
    const creationDateString = moment(creationDateToShow).format('DD/MM/yyyy');
    const creationDateHourString = moment(creationDateToShow).format('hh:mm A');
    const cretionDateFinal = `${creationDateString} - ${creationDateHourString}`;
    setCreationDate(cretionDateFinal);
    const updatingDateToShow = moment(data.modifiedon, 'YYYY-MM-DD HH:mm:ss').toDate();
    const updatingDateString = moment(updatingDateToShow).format('DD/MM/yyyy');
    const updatingDateHourString = moment(updatingDateToShow).format('hh:mm A');
    const updatingDateFinal = `${updatingDateString} - ${updatingDateHourString}`;
    setUpdatingDate(updatingDateFinal);
    setPromotionCode(data.promotionCode);
    setDescriptionPromotionCode(data.descriptionPromotionCode);
    setEffectiveStartDate(startDateRow);
    setExpirationDate(endDateRow);
    setEndPeriodicityDate(endPeriodicityDateRow);
    setApplyTotalPurchase(data.applyTotalPurchase);
    setPersonTypeService(data.typeUserId);
    setOfferValue(data.offerValue);
    setPeriodicityType(data.periodicityType.toUpperCase());
    setTypeUserId(data.typeUserId);
    if (data.useQuantity === 1) {
      setUniqueUse(true);
    } else {
      setUseQuantity(data.useQuantity);
    }
    if (data.offerTypeCode === 'PTJ') {
      setOfferTypeCode('PTJ');
    } else {
      setOfferTypeCode('VLR');
    }
    if (data.promotionCode !== '') {
      setPeriodicityQuantity(data.periodicityQuantity);
    }
    if (data.periodicityType === 'MONTHLY') {
      setActivated(false);
    }
    setActivar(data.idstate === 0);
  };
  useEffect(() => {
    if (promotionalCodeSelected && open) {
      if (promotionalCodeSelected.applyProduct) {
        dispatch(GetPackagesId(promotionalCodeSelected.id));
      }
      updatePromotionCode(promotionalCodeSelected);
    }
  }, [promotionalCodeSelected]);

  useEffect(() => {
    promoCodeUserType();
  }, []);

  useEffect(() => {
    if (promotionalCodeSelected) {
      if (promotionalCodeSelected.typeUserId === 2 || promotionalCodeSelected.typeUserId === 3) {
        setDisabledTypeUser(true);
      } else {
        setDisabledTypeUser(false);
      }
    }
  }, [promotionalCodeSelected]);

  function closeModal() {
    setPromotionCode('');
    setUseQuantity(1);
    setDescriptionPromotionCode('');
    setOfferTypeCode('PTJ');
    setOfferValue('');
    setPeriodicityQuantity('0');
    setEffectiveStartDate(new Date());
    setExpirationDate(new Date());
    setPeriodicityType('NEVER');
    setEndPeriodicityDate(new Date());
    setApplyTotalPurchase(false);
    setPersonTypeService('');
    setPackageSelected([]);
    setUniqueUse(false);
    setActivar(true);
    setActivated(true);
    setTitle('Crear código promocional');
    setPromoCodeSelected(null);
    setOpenViewMore(false);
    setDisabledTypeUser(false);
    setShowRequiredMessage(false);
    setTypeUserId(1);
    onCloseModal();
  }

  const StyledRadio = (props) => (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.iconRadio, classes.checkedIcon)} />}
      icon={<span className={classes.iconRadio} />}
      {...props}
    />
  );

  const validateIfChanged = (data) => {
    if (promotionalCodeSelected.descriptionPromotionCode
      !== data.descriptionPromotionCode) return true;
    if (promotionalCodeSelected.idstate
      !== data.idstate) return true;
    if (promotionalCodeSelected.useQuantity
      !== data.useQuantity) return true;
    if (promotionalCodeSelected.applyTotalPurchase
      !== data.applyTotalPurchase) return true;
    if (promotionalCodeSelected.offerTypeCode
      !== data.offerTypeCode) return true;
    if (promotionalCodeSelected.periodicityQuantity
      !== data.periodicityQuantity) return true;
    if (promotionalCodeSelected.offerValue
      !== data.offerValue) return true;
    if (promotionalCodeSelected.periodicityType
      !== data.periodicityType) return true;
    if (moment(promotionalCodeSelected.effectiveStartDate).format('YYYY-MM-DD')
      !== data.effectiveStartDate) return true;
    if (moment(promotionalCodeSelected.expirationDate).format('YYYY-MM-DD')
      !== data.expirationDate) return true;
    if (moment(promotionalCodeSelected.endPeriodicityDate).format('YYYY-MM-DD')
      !== data.endPeriodicityDate) return true;
    if (effectiveStartDate <= ExpirationDate) return true;
    const packageIdSelectedBefore = JSON.stringify(packageIdCopy);
    const packageIdSelectedAfter = JSON.stringify(data.packagesId);
    if (packageIdSelectedBefore
      !== packageIdSelectedAfter) return true;
    return false;
  };

  const onSubmit = (dataSent) => {
    const data = dataSent;
    data.promotionCode = data.promotionCode || promotionCode;
    data.descriptionPromotionCode = data.descriptionPromotionCode || DescriptionPromotionCode;
    data.applyTotalPurchase = data.applyTotalPurchase || ApplyTotalPurchase;
    data.applyProduct = ApplyTotalPurchase === true ? false : !ApplyTotalPurchase;
    data.effectiveStartDate = moment(effectiveStartDate).format('YYYY-MM-DD');
    data.expirationDate = moment(ExpirationDate).format('YYYY-MM-DD');
    data.endPeriodicityDate = moment(EndPeriodicityDate).format('YYYY-MM-DD');
    data.idstate = activar ? 0 : 1;
    data.useQuantity = data.useQuantity === 0 ? 1 : UseQuantity;
    data.useQuantity = data.uniqueUse ? 1 : data.useQuantity;
    data.periodicityType = PeriodicityType;
    data.offerTypeCode = offerTypeCode === 'PTJ' ? 'PTJ' : 'VLR';
    data.offerValue = offerValue;
    data.periodicityQuantity = periodicityQuantityOne;
    data.typeUserId = typeUserId;
    delete data.uniqueUse;
    if ((packageSelected.length > 0 || (data.applyTotalPurchase || ApplyTotalPurchase)) && !errorDate && !errorEndDate) {
      if (promotionalCodeSelected) {
        const packagesIdArray = [];
        packageSelected.forEach((pakcageId) => {
          packagesIdArray.push(pakcageId.id);
        });
        data.packagesId = packagesIdArray;
        validateIfChanged(data);
        if (validateIfChanged(data)) {
          dispatch(UpdatePromoCode(data, enqueueSnackbar));
        }
      } else {
        const promotionalCodePackageArray = [];
        packageSelected.forEach((pakcageId) => {
          promotionalCodePackageArray.push(
            {
              PackageId: pakcageId.id,
            },
          );
        });
        delete data.packagesId;
        data.PromotionalCodePackage = promotionalCodePackageArray;
        dispatch(SavePromoCode(data, enqueueSnackbar));
      }
      closeModal();
    } else {
      setShowRequiredMessage(true);
    }
  };

  const changeSync = (event) => {
    setPackageSelected(event);
    setShowRequiredMessage(false);
  };

  const handleChangeQuantity = (event) => {
    const quantity = event.target.value;
    if (quantity === '') {
      event.target.value = useQuantity.toString();
    }
    if (quantity.length > 4) {
      setUseQuantity(parseInt(quantity.substring(0, 4), 10));
    } else if (quantity.includes('-')) {
      setUseQuantity(0);
    } else {
      setUseQuantity(quantity);
    }
  };
  const changeRepeatType = (e, type) => {
    if (type === 'Repeat') {
      setActivated(false);
      setPeriodicityType('MONTHLY');
      setPeriodicityQuantity('1');
    }
    if (type === 'Never') {
      setActivated(true);
      setPeriodicityQuantity('0');
      setEndPeriodicityDate(expirationDate);
      setPeriodicityType('NEVER');
    }
  };
  const handleChangePeriodicityQuantity = (event) => {
    const periodicityquantity = event.target.value;
    setPeriodicityQuantity(periodicityquantity);
  };

  const setPersonType = (event) => {
    const { value } = event.target;
    setTypeUserId(value);
    setShowRequiredMessage(false);
    if ((event.target.name === 'personTypeService' && event.target.value === 2) || (event.target.name === 'personTypeService' && event.target.value === 3)) {
      setOfferTypeCode('PTJ');
      setOfferValue(100);
      setDisabledTypeUser(true);
    } else {
      setOfferValue('');
      setDisabledTypeUser(false);
    }
    return value;
  };

  const changeDescription = (event) => {
    const description = event.target.value;
    setDescriptionPromotionCode(description);
    setShowRequiredMessage(false);
  };

  const handleSetOfferValue = (event) => {
    setOfferValue(event.target.value);
  };

  const handleChangeUniqueUse = (event) => {
    setUniqueUse(event);
    if (event) {
      setUseQuantity(1);
    }
  };
  return (

    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
    >
      <DialogTitle>
        <Typography color="primary" variant="h6">{title}</Typography>
        <IconButton className={classes.closeButton} onClick={closeModal}>
          <CloseIcon color="primary" className={classes.tamIcon} />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <form
          noValidate
          autoComplete="off"
        >
          <Grid
            container
            justify="space-evenly"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <InputLabel />
              <CustomInput
                label="Código promoción *"
                name={promotionCode !== '' && title === 'Editar código promocional' ? promotionCode : 'promotionCode'}
                defaultValue={promotionCode}
                control={control}
                rule={Rules.required}
                error={errors}
                inputProps={{
                  maxLength: 50,
                  onInput: (e) => { e.target.value = e.target.value.replace(/\s/g, ''); },
                }}
                disabled={promotionalCodeSelected}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Paper variant="outlined">
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={uniqueUse}
                      onChange={(_event, newValue) => handleChangeUniqueUse(newValue)}
                      color="primary"
                      className={{ marginBottom: 10 }}
                    />
                  )}
                  label="Uso único"
                />
              </Paper>
            </Grid>

            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Paper variant="outlined">
                <Box display="flex" justifyContent="flex-start" p={1} alignItems="center">
                  <FormControlLabel
                    control={(
                      <TextField
                        size="small"
                        type="number"
                        style={{ width: '80px', marginLeft: '3px' }}
                        disabled={uniqueUse}
                        defaultValue={UseQuantity}
                        value={uniqueUse ? 1 : UseQuantity}
                        variant="outlined"
                        InputProps={{
                          style: { height: '22px' },
                          inputProps: { min: 1 },
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value < 1 ? 1 : e.target.value;
                        }}
                        onChange={(e) => handleChangeQuantity(e)}
                        color="primary"
                        FormHelperTextProps={{ style: { margin: '0 0 0 5px', color: '#d61103' } }}
                      />
                    )}
                    label="Cantidad uso *"
                    labelPlacement="start"
                  />
                </Box>
              </Paper>
              {uniqueUse ? ''
                : (errors?.useQuantity && <p style={{ margin: '0 0 0 5px', color: '#d61103', fontSize: '0.6964285714285714rem' }}>{errors.useQuantity.message}</p>)}
            </Grid>

            <Grid item xs={12}>
              <InputLabel />
              <CustomInput
                label="Descripción *"
                multiline
                fullWidth
                rows={3}
                name={DescriptionPromotionCode !== '' && title === 'Editar código promocional' ? DescriptionPromotionCode : 'descriptionPromotionCode'}
                defaultValue={DescriptionPromotionCode}
                control={control}
                rule={Rules.letrasTildesNumerosEspacioBlancoCaracteresEspeciales}
                error={errors}
                inputProps={{
                  maxLength: 250,
                  onChange: (e) => changeDescription(e),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={7} lg={7}>
              {
                packageSelected.length === 0 && title === 'Crear código promocional'
                  ? (
                    <>
                      <CustomCheck
                        label="Aplicar promoción al total de la compra"
                        name="applyTotalPurchase"
                        control={control}
                        rule={packageSelected.length === 0 ? Rules.required : { required: false }}
                        error={errors}
                      />
                      {
                        errors.applyTotalPurchase
                        && (
                          <>
                            {!showRequiredMessage && open ? setShowRequiredMessage(true) : ''}
                            <div style={{ color: '#d61103', margin: '0 0 0 5px', fontSize: '0.6964285714285714rem' }}>Campo obligatorio</div>
                          </>
                        )
                      }
                    </>
                  ) : (
                    <>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            checked={ApplyTotalPurchase}
                            onChange={(_event, newValue) => setApplyTotalPurchase(newValue)}
                            color="primary"
                            className={{ marginBottom: 10 }}
                            required
                          />
                        )}
                        label="Aplicar promoción al total de la compra"
                      />
                    </>
                  )
              }

            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={5}>
              <CustomAutocomplete
                name="packagesId"
                options={packageOptions}
                label="Aplicar paquete"
                placeholder="Aplicar paquete"
                className={classes.textFieldCustom}
                disabled={packageSelected.length === 0 && title === 'Crear código promocional' ? applyTotalPurchase : ApplyTotalPurchase}
                registersSelected={packageSelected}
                setData={(e) => changeSync(e)}
                limitTags={2}
                control={control}
                rules={Rules.required}
                error={false}
              />
              {
                showRequiredMessage && !ApplyTotalPurchase && errors.applyTotalPurchase
                && (
                  <>
                    <div style={{ color: '#d61103', margin: '0 0 0 5px', fontSize: '0.6964285714285714rem' }}>Campo obligatorio</div>
                  </>
                )
              }
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={5}>
              <Typography style={{ marginLeft: '10px', marginTop: '12px', fontSize: '0.9285714285714286rem' }} variant="h6">Tipo usuario descuento</Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={8} lg={7}>
              <InputLabel />
              <CustomSelect
                label="Tipo de usuario"
                name="personTypeService"
                defaultValue={PersonTypeService}
                options={setPromoUserType()}
                control={control}
                required
                rule={Rules.required}
                error={errors}
                styleFromHelperText={{ style: { marginRight: 10 } }}
                inputProps={{ onChange: (e) => setPersonType(e) }}
              />
            </Grid>

            <Grid item xs={7}>
              <Paper variant="outlined">
                <Box display="flex" justifyContent="flex-start">
                  <FormControlLabel
                    control={(
                      <RadioGroup row aria-label="discountType" style={{ marginLeft: '15px' }} value={offerTypeCode} onChange={(_event, newValue) => setOfferTypeCode(newValue)}>
                        <FormControlLabel
                          value="VLR"
                          control={<StyledRadio />}
                          label="Valor"
                          disabled={typeUserId === 2 || typeUserId === 3 || disabledTypeUser}
                        />
                        <FormControlLabel
                          value="PTJ"
                          control={<StyledRadio />}
                          label="Porcentaje"
                          disabled={typeUserId === 2 || typeUserId === 3 || disabledTypeUser}
                        />
                      </RadioGroup>
                    )}
                    disabled={typeUserId === 2 || typeUserId === 3 || disabledTypeUser}
                    label="Tipo descuento:"
                    labelPlacement="start"
                  />
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={5}>
              {typeUserId < 2
                ? (
                  <FormControl>
                    <Controller
                      as={(
                        <TextField
                          label={offerTypeCode === 'VLR' ? 'Valor *' : 'Porcentaje *'}
                          placeholder={offerTypeCode === 'VLR' ? 'Valor *' : 'Porcentaje *'}
                          variant="outlined"
                          fullWidth
                          id={offerTypeCode === 'VLR' ? 'value' : 'percentage'}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">{offerTypeCode === 'VLR' ? '$' : '%'}</InputAdornment>,
                          }}
                          size="small"
                          FormHelperTextProps={{ style: { margin: '0 0 0 5px', color: '#d61103' } }}
                          helperText={errors.offerValue ? errors.offerValue.message : ''}
                        />
                      )}
                      name="offerValue"
                      control={control}
                      rules={offerTypeCode === 'PTJ' ? Rules.percentage : Rules.numerico}
                      error={Boolean(errors.offerValue)}
                      inputProps={{
                        maxLength: 18,
                        onChange: (e) => handleSetOfferValue(e),
                      }}
                      defaultValue={offerValue}
                    />
                  </FormControl>
                ) : (
                  <FormControl>
                    <InputLabel />
                    <TextField
                      label={offerTypeCode === 'VLR' ? 'Valor *' : 'Porcentaje *'}
                      placeholder={offerTypeCode === 'VLR' ? 'Valor *' : 'Porcentaje *'}
                      variant="outlined"
                      fullWidth
                      value={100}
                      id={offerTypeCode === 'VLR' ? 'value' : 'percentage'}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{offerTypeCode === 'VLR' ? '$' : '%'}</InputAdornment>,
                      }}
                      size="small"
                      disabled={disabledTypeUser}
                    />
                  </FormControl>
                )}
            </Grid>

            <Grid item xs={6}>
              <FormControl>
                <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className={classes.dateCustom}
                    variant="inline"
                    inputVariant="outlined"
                    value={effectiveStartDate}
                    format="dd/MM/yyyy"
                    label="Inicio vigencia *"
                    size="small"
                    color="primary"
                    placeholder="Inicio vigencia"
                    onChange={(e) => setEffectiveStartDate(e)}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id="expirationDate"
                  className={classes.dateCustom}
                  variant="inline"
                  inputVariant="outlined"
                  format={errors.expirationDate ? 'yyyy/dd/MM' : 'dd/MM/yyyy'}
                  label="Fin vigencia *"
                  onChange={(e) => setExpirationDate(e)}
                  onBlur={validateDates}
                  value={ExpirationDate}
                  error={errorDate}
                  size="small"
                  disablePast
                />
                {
                  errorDate
                  && (
                    <>
                      <div style={{ color: '#d61103', margin: '0 0 0 5px', fontSize: '0.6964285714285714rem' }}>Fin vigencia debe ser mayor a Inicio vigencia</div>
                    </>
                  )
                }
              </MuiPickersUtilsProvider>
            </Grid>
            {periodicityModify
              && (
                <Grid item xs={12}>
                  <Paper variant="outlined">
                    <Box display="flex" justifyContent="flex-start" p={1} alignItems="center">
                      <FormControl>
                        <DialogTitle>
                          <Typography color="primary" variant="h6" style={{ marginLeft: '-20px' }}>Periodicidad</Typography>
                        </DialogTitle>
                        <FormControlLabel
                          control={(
                            <>
                              <RadioGroup row aria-label="periodicityType" className={classes.radioButtonPeriodicity} value={PeriodicityType} onChange={(_event, newValue) => setPeriodicityType(newValue)}>
                                <FormControlLabel
                                  value="NEVER"
                                  control={<StyledRadio />}
                                  label="Nunca"
                                  onChange={(e) => { changeRepeatType(e, 'Never'); }}
                                />
                                <FormControlLabel
                                  value="MONTHLY"
                                  control={<StyledRadio />}
                                  label="Repetir cada: "
                                  onChange={(e) => { changeRepeatType(e, 'Repeat'); }}
                                />
                              </RadioGroup>
                              <TextField
                                size="small"
                                type="number"
                                style={{ width: '65px', marginLeft: '1px' }}
                                disabled={isActivated}
                                variant="outlined"
                                value={periodicityQuantityOne}
                                InputProps={{
                                  style: {
                                    height: '30px',
                                    top: '15px',
                                  },
                                  inputProps: { min: 1, max: 12 },
                                }}
                                onInput={(e) => {
                                  if (e.target.value > 12) {
                                    e.target.value = 12;
                                  }
                                  if (e.target.value < 1) {
                                    e.target.value = 1;
                                  }
                                }}
                                onChange={(e) => handleChangePeriodicityQuantity(e)}
                                color="primary"
                                FormHelperTextProps={{ style: { margin: '0 0 0 5px', color: '#d61103' } }}
                              />
                            </>
                          )}
                        />
                        <Typography style={{ marginLeft: '205px', marginTop: '-28px' }}> Mes(es)</Typography>

                        <Box style={{ display: isActivated ? 'none' : 'block' }}>
                          <Typography style={{ marginLeft: '4px', marginTop: '15px', marginBottom: '15px' }}>Fecha final de repeticion: </Typography>
                          <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className={classes.dateCustom}
                              style={{ marginLeft: '3px', marginTop: '3px', marginBottom: '15px' }}
                              variant="inline"
                              inputVariant="outlined"
                              format="dd/MM/yyyy"
                              color="primary"
                              placeholder="Final repetición"
                              onChange={(e) => setEndPeriodicityDate(e)}
                              onBlur={validateEndDate}
                              value={PeriodicityType === 'NEVER' ? expirationDate : EndPeriodicityDate}
                              error={errorEndDate}
                              size="small"
                              disablePast
                            />
                            {
                              errorEndDate
                              && (
                                <>
                                  <div style={{ color: '#d61103', margin: '0 0 0 5px', fontSize: '0.6964285714285714rem' }}>Fecha final de repetición no puede ser menor a la fecha de expiración</div>
                                </>
                              )
                            }
                          </MuiPickersUtilsProvider>
                        </Box>
                      </FormControl>
                    </Box>
                  </Paper>
                </Grid>
              )}
            <Grid item xs={3}>
              <Paper variant="outlined">
                <Box display="flex" justifyContent="flex-start">
                  <FormControlLabel
                    control={(
                      <SwitchiOS
                        checked={activar}
                        onChange={(e) => setActivar(e.target.checked)}
                        name="active"
                        color="primary"
                      />
                    )}
                    label="Activar"
                    labelPlacement="start"
                  />
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={9} />

            {promotionalCodeSelected && !openViewMore
              && (
                <Grid item xs={12}>
                  <Box display="flex" flexDirection="row-reverse">
                    <Button
                      edge="false"
                      size="small"
                      style={{ float: 'right' }}
                      color="primary"
                      onClick={() => { setOpenViewMore(true); }}
                    >
                      Ver más
                      <ExpandMoreIcon color="primary" />
                    </Button>
                  </Box>
                </Grid>
              )}
            {promotionalCodeSelected && (
              <Grid item xs={12}>
                <p />
                <Collapse in={openViewMore}>
                  <Paper elevation={1} className={classes.paperCard}>
                    <Typography variant="caption">
                      <b>Creado por:</b>
                      {' '}
                      {userCreate ? (userCreate.names) : ''}
                      <br />
                      <b>Fecha y hora de creación:</b>
                      {' '}
                      {creationDate}
                      {' '}
                      <br />
                      <b>Modificado por:</b>
                      {' '}
                      {userModify ? (userModify.names) : ''}
                      {' '}
                      <br />
                      <b>Fecha y hora de modificación:</b>
                      {' '}
                      {promotionalCodeSelected.modifiedby > 0 ? updatingDate : ''}
                    </Typography>
                  </Paper>
                </Collapse>
              </Grid>
            )}
            {openViewMore && (
              <Grid item xs={12}>
                <Box display="flex" flexDirection="row-reverse">
                  <Button
                    edge="false"
                    size="small"
                    style={{ float: 'right' }}
                    color="primary"
                    onClick={() => { setOpenViewMore(false); }}
                  >
                    Ver menos
                    <ExpandLessIcon color="primary" />
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>

      <DialogActions className={classes.dialogActions} disableSpacing>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          className={classes.button}
        >
          {promotionalCodeSelected ? 'Aceptar' : 'Crear'}
        </Button>
      </DialogActions>

    </Dialog>

  );
};

const mapStateToProps = ({ security, packets }) => ({
  dataUserCreate: security.dataUserCreate,
  dataUserModify: security.dataUserModify,
  promoUserType: packets.promoUserType,
});

const mapDispatchToProps = {
  getUserDataById: getUserDataByIdAction,
  getTokenExternalSource: getTokenExternalSourceAction,
  promoCodeUserType: promoCodeUserTypeAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalPromo);
