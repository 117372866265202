import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Countdown from './Countdown';
import packageCrown from '../../assets/img/sliderCard/packageCrown.svg';
import { directPurchaseAction } from '../../actions/home.action';

const useStyles = makeStyles(() => ({
  root: {
    height: '600px',
    padding: '80px 40px 80px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: 'white',
  },
  title: {
    color: '#F7FF00',
    fontWeight: 'bold',
  },
  subtitle: {
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 10,
  },
  description: {
    fontWeight: 'bold',
  },
  buttonOffer: {
    border: '1px solid #E45E10',
    color: '#E45E10',
    fontWeight: 'bold',
    fontSize: '22px',
  },
}));

const PromoCard = (props) => {
  const classes = useStyles();
  const { card, directPurchase, isPublic } = props;

  const {
    packageName, urlImageOffer, offerExpirationTime,
  } = card;

  const getOfertText = (item) => {
    if (item.offerTypeCode === 'PTJ') return `${item.totalOffer}%`;
    if (item.offerTypeCode === 'VLR') {
      return (
        <>
          {
            item.packagePrice > 0 && (
              <s style={{ fontSize: 18, marginRight: 10 }}>{`$${item.packagePrice.toLocaleString('en')}`}</s>
            )
          }
          <span>{` $${item.packagePriceWithDiscount.toLocaleString('en')}`}</span>
        </>
      );
    }
    return '';
  };
  const getValidityText = (item) => {
    if (item.validityTypeId === 'I') return `Vigencia ${item.validityPeriodDescription}`;
    return `Valido por ${item.validityPeriodDescription}`;
  };

  return (
    <Paper className={classes.root} style={{ backgroundImage: `url(${urlImageOffer})` }} elevation={5}>
      <Box>
        <Box display="flex" flexDirection="row-reverse">
          {!isPublic && (
            <img src={packageCrown} alt="Crown" />
          )}
        </Box>
        <Typography className={classes.title} variant="h4">OFERTA</Typography>
        <Typography className={classes.subtitle} variant="h4">{getOfertText(card)}</Typography>
        <Typography className={classes.description} variant="h5">{packageName}</Typography>
        <Typography>{getValidityText(card)}</Typography>
      </Box>
      <Box>
        <Button variant="contained" className={classes.buttonOffer} size="large" onClick={() => directPurchase(card)}>
          ¡COMPRAR YA!
        </Button>
      </Box>
      <Box>
        <Typography>Oferta finaliza en</Typography>
        <Countdown
          titlePosition="bottom"
          endAt={offerExpirationTime.toUTCString()}
          size="small"
          hideYear
          hideMonth
        />
      </Box>
    </Paper>
  );
};

const mapDispatchToProps = {
  directPurchase: directPurchaseAction,
};

export default connect(null, mapDispatchToProps)(PromoCard);
