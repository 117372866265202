// eslint-disable-next-line import/prefer-default-export
export const reportConfig = [
  {
    id: 1,
    description: 'Gestión Servicios',
    code: 'GestionServicio',
    iconUrl: '',
    link: '',
    disable: false,
  },
  {
    id: 2,
    description: 'Soporte por Cliente',
    code: 'SoporteCliente',
    iconUrl: '',
    link: '',
    disable: false,
  },
  {
    id: 3,
    description: 'Códigos Promocionales',
    code: 'CodigosPromocionales',
    iconUrl: '',
    link: '',
    disable: false,
  },
];
