/* eslint-disable react-hooks/exhaustive-deps  */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import {
  getPaymentsPagingAction, reloadBatchAction, searchPaymentsPagingAction,
} from '../../../actions/packets.action';
import { getRejectTypesAction } from '../../../actions/general.action';
import { isValidUsertoConfigurations } from '../../../actions/auth.action';
import GridPaymentsPaging from './GridPaymentsPaging';
import { NUM_REGISTERS_PAGING } from '../../../config/config';
import { PURCHASE_TYPE } from '../../../constants/PurchaseConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 280,
    borderRadius: 16,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  paper: {
    padding: '5px',
    borderRadius: 22,
    marginTop: '20px',
    opacity: '0.87',
    background: 'transparent linear-gradient(180deg, #FFFFFFCC 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box',
    '& .MuiBox-root': {
      marginTop: -1,
      borderTop: '2px solid rgba(193,193,193,0.6)',
    },
  },
  cardContent: {
    padding: 0,
  },
  title: {
    ...theme.backOffice.configurations.title,
    fontWeight: 600,
    fontSize: '26px',
    paddingTop: '15px',
    paddingLeft: '20px',
  },
  subtitle: {
    fontSize: '30px',
    letterSpacing: '0px',
    fontWeight: 'bold',
    lineheight: 1,
  },
  text: {
    fontSize: '16px',
    letterSpacing: '0px',
    lineheight: 1,
  },
  tab: {
    borderBottom: '1px solid #ccc',
    textTransform: 'none',
    fontWeight: '500',
    fontFamily: 'Muli, Arial',
    fontSize: 12,
  },
  accessDeniedContainer: {
    height: 300,
  },
  accessDenied: {
    color: '#272727',
    fontWeight: 500,
    fontSize: '20px',
    paddingTop: '15px',
    paddingLeft: '18px',
  },
}));

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
      <Box p={3}>
        <div>{children}</div>
      </Box>
      )}
    </div>
  );
}

const Payments = (props) => {
  const classes = useStyles();
  const {
    getRejectTypes,
    getPaymentsPaging,
    pendingBatch,
    pendingBatchRows,
    approvedBatch,
    approvedBatchRows,
    rejectedBatch,
    rejectedBatchRows,
    reloadBatch,
    loading,
    searchPaymentsPaging,
    isPaymentSearch,
    appName,
    decodedToken,
  } = props;

  const [value, setValue] = useState(0);
  const [page, setPage] = React.useState(0);
  const [showFunctionality, setShowFunctionality] = useState(false);

  useEffect(() => {
    if (isValidUsertoConfigurations(decodedToken, 'PAYMENTS') && appName === 'license') setShowFunctionality(true);
  }, []);

  useEffect(() => {
    reloadBatch();
    getRejectTypes();
  }, [getPaymentsPaging]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPage(0);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChangePage = (event, newPage, typegrid, rowsPerPage) => {
    const numRows = rowsPerPage;
    let last;
    switch (typegrid) {
      case 'pending':
        last = pendingBatch.length;
        if (!isPaymentSearch && (last < (newPage + 1) * numRows)) {
          getPaymentsPaging({ Last: last, NumRows: NUM_REGISTERS_PAGING, IdState: PURCHASE_TYPE.Pending }, 'pending');
        }
        break;
      case 'approved':
        last = approvedBatch.length;
        if (!isPaymentSearch && (last < (newPage + 1) * numRows)) {
          getPaymentsPaging({ Last: last, NumRows: NUM_REGISTERS_PAGING, IdState: PURCHASE_TYPE.Approved }, 'approved');
        }
        break;
      case 'rejected':
        last = rejectedBatch.length;
        if (!isPaymentSearch && (last < (newPage + 1) * numRows)) {
          getPaymentsPaging({ Last: last, NumRows: NUM_REGISTERS_PAGING, IdState: PURCHASE_TYPE.Rejected }, 'rejected');
        }
        break;
      default:
        break;
    }
    setPage(newPage);
  };

  const handleSearchClick = (text, type) => {
    switch (type) {
      case 'pending':
        searchPaymentsPaging({ IdState: PURCHASE_TYPE.Pending, Search: text }, 'pending');
        break;
      case 'approved':
        searchPaymentsPaging({ IdState: PURCHASE_TYPE.Approved, Search: text }, 'approved');
        break;
      case 'rejected':
        searchPaymentsPaging({ IdState: PURCHASE_TYPE.Rejected, Search: text }, 'rejected');
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Container component="main" maxWidth="lg">
        <Paper elevation={3} className={classes.paper}>
          <Typography color="primary" variant="h4" className={classes.title}>Pagos en efectivo</Typography>
          {showFunctionality
            ? (
              <>
                <Tabs
                  value={value}
                  indicatorColor="secondary"
                  textColor="secondary"
                  onChange={handleChange}
                  aria-label="disabled tabs example"
                  style={{ height: '38px', minHeight: '38px' }}
                >
                  <Tab className={classes.tab} label="Pagos por aprobar" {...a11yProps(0)} />
                  <Tab className={classes.tab} label="Pagos aprobados" {...a11yProps(1)} />
                  <Tab className={classes.tab} label="Pagos rechazados" {...a11yProps(2)} />
                </Tabs>
                {loading && <CircularProgress color="secondary" size={25} />}
                <TabPanel value={value} index={0}>
                  {pendingBatch
                    && (
                    <GridPaymentsPaging
                      rows={pendingBatch}
                      totalRows={pendingBatchRows}
                      type="pending"
                      setPage={setPage}
                      page={page}
                      handleChangePage={handleChangePage}
                      isSearchServer
                      handleSearch={handleSearchClick}
                    />
                    )}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {approvedBatch
                    && (
                    <GridPaymentsPaging
                      rows={approvedBatch}
                      totalRows={approvedBatchRows}
                      type="approved"
                      setPage={setPage}
                      page={page}
                      handleChangePage={handleChangePage}
                      isSearchServer
                      handleSearch={handleSearchClick}
                    />
                    )}
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {rejectedBatch
                    && (
                    <GridPaymentsPaging
                      rows={rejectedBatch}
                      totalRows={rejectedBatchRows}
                      type="rejected"
                      setPage={setPage}
                      page={page}
                      handleChangePage={handleChangePage}
                      isSearchServer
                      handleSearch={handleSearchClick}
                    />
                    )}
                </TabPanel>
              </>
            ) : (
              <Box className={classes.accessDeniedContainer}>
                <Typography color="primary" variant="h4" className={classes.accessDenied}>
                  No tienes los suficientes privilegios para realizar esta acción.
                </Typography>
              </Box>
            )}
        </Paper>
      </Container>
    </>
  );
};

const mapStateToProps = ({ packets, general, auth }) => ({
  payments: packets.payments,
  pendingBatch: packets.pendingBatch,
  pendingBatchRows: packets.pendingBatchRows,
  approvedBatch: packets.approvedBatch,
  approvedBatchRows: packets.approvedBatchRows,
  rejectedBatch: packets.rejectedBatch,
  rejectedBatchRows: packets.rejectedBatchRows,
  isPaymentSearch: packets.isPaymentSearch,
  loading: packets.loading,
  appName: general.appName,
  decodedToken: auth.decodedToken,
});

const mapDispatchToProps = {
  getRejectTypes: getRejectTypesAction,
  getPaymentsPaging: getPaymentsPagingAction,
  reloadBatch: reloadBatchAction,
  searchPaymentsPaging: searchPaymentsPagingAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
