import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid } from '@material-ui/core/';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const useStyles = makeStyles(() => ({
  tableHead: {
    width: '828px',
    height: '40px',
    background: 'var(--unnamed-color-009bdd) 0% 0% no-repeat padding-box',
    borderRadius: '10px',
    opacity: 1,
    color: '#FFF',
    backgroundColor: '#009BDD',
  },
  table: {
    minWidth: 750,
  },
  tableCellHead: {
    color: '#FFF',
    backgroundColor: '#009BDD',
    fontWeight: 'bold',
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: 'white',
    },
  },
  tableCell: {
    textAlign: 'center',
    letterSpacing: '0px',
    color: '#575756',
    opacity: 1,
  },
  tablePagination: {
    font: 'normal normal 600 12px/0.10000000149011612px Muli',
    letterSpacing: '0px',
    opacity: 1,
    color: 'var(--unnamed-color-706f6f)',
    textAlign: 'right',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  container: {
    borderRadius: '10px',
  },
  actionsPaginTable: {
    color: '#009FE3',
  },
  tableSortLabel: {
    color: 'white',
    '&:hover': {
      color: 'white',
    },
  },
  disabledRow: {
    opacity: 0.4,
  },
  columnStyle: {
    fontWeight: 'bold',
  },
}));

const CustomTable = (props) => {
  const classes = useStyles();
  const { data, columns, styleTableContainer } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [countTablePagination, setCountTablePagination] = React.useState();
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('_referencia');

  useEffect(() => {
    setCountTablePagination(data.length);
    setPage(0);
  }, [data]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const orderComparator = comparator(a[0], b[0]);
      if (orderComparator !== 0) return orderComparator;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderByParam) {
    if (b[orderByParam] < a[orderByParam]) {
      return -1;
    }
    if (b[orderByParam] > a[orderByParam]) {
      return 1;
    }
    return 0;
  }

  function getComparator(orderParam, orderByParam) {
    return orderParam === 'desc'
      ? (a, b) => descendingComparator(a, b, orderByParam)
      : (a, b) => -descendingComparator(a, b, orderByParam);
  }

  const handleRequestSort = (event, property) => {
    const propertyCustom = property === 'active' ? 'state' : property;
    const isAsc = orderBy === propertyCustom && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(propertyCustom);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function EnhancedTableHead(props) {
    const {
      classes, order, orderBy, onRequestSort,
    } = props;
    const createSortHandler = (property, isSort) => (event) => {
      if (isSort) {
        onRequestSort(event, property);
      }
    };
    return (
      <TableHead className={classes.tableHead}>
        <TableRow>
          {columns.map((headCell) => (
            <TableCell
              className={classes.tableCellHead}
              key={headCell.id}
              align="center"
              // eslint-disable-next-line no-nested-ternary
              style={headCell.position === 1 ? { borderBottomLeftRadius: '10px', width: headCell.width, paddingLeft: headCell.paddingLeft }
                : (headCell.position === columns.length ? { borderBottomRightRadius: '10px', width: headCell.width, paddingLeft: headCell.paddingLeft }
                  : { width: headCell.width, paddingLeft: headCell.paddingLeft })}
              sortDirection={orderBy === headCell.sortPropertyName ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.sortPropertyName}
                direction={orderBy === headCell.sortPropertyName ? order : 'asc'}
                onClick={createSortHandler(headCell.sortPropertyName, headCell.isSortable)}
                classes={{
                  root: classes.tableSortLabel,
                }}
                hideSortIcon={!headCell.isSortable}
              >
                <Typography classes={{
                  body1: classes.columnStyle,
                }}
                >
                  {headCell.label}
                </Typography>
                {orderBy === headCell.sortPropertyName ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <>
      <TableContainer style={styleTableContainer} className={classes.container}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="small"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={countTablePagination} // {rows.length}
          />
          <TableBody>
            {stableSort(data, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (

                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  className={row.state === 0 ? '' : classes.disabledRow}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        className={classes.tableCell}
                        key={column.id}
                        align={column.align}
                        size="small"
                      >
                        {column.format && typeof value === 'number'
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {
          countTablePagination === 0
            ? (
              <Grid container style={{ justifyContent: 'center' }}>
                <Typography>No existe información correspondiente a los criterios de búsqueda</Typography>
              </Grid>
            )
            : ''
        }
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={countTablePagination}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="Registros por página"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        classes={{
          actions: classes.actionsPaginTable,
          selectIcon: classes.actionsPaginTable,
          caption: classes.tablePagination,
        }}
      />
    </>
  );
};

export default CustomTable;
