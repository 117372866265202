/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getPackagesAction, addToCartAction } from '../../../actions/home.action';
import { UPDATE_CART, HOME_SHOW_CART, SET_PROMO_CODE } from '../../../types/home.type';
import { getLocalStorageEncript } from '../../../helpers/storageUtils';

const RegisterPurchaseExt = (props) => {
  const {
    history, appName, getPackage, cart, packages,
  } = props;

  const dispatch = useDispatch();

  /**
   * Cargamos los paquetes de la aplicacion
   */
  useEffect(() => {
    const packageLocal = JSON.parse(getLocalStorageEncript('packages') || '[]');
    if (packageLocal.length === 0 || packageLocal?.base?.length === 0) {
      getPackage(appName);
    }
  }, [getPackage]);

  /**
   * Cargar cart con el localStorage y llamado de RegisterPurchase
   */
  useEffect(() => {
    const cartLocal = JSON.parse(getLocalStorageEncript('cart') || '[]');
    const promoCodeLocal = JSON.parse(getLocalStorageEncript('promoCode') || null);
    dispatch({ type: UPDATE_CART, payload: cartLocal });
    dispatch({ type: SET_PROMO_CODE, payload: promoCodeLocal });
    if (cartLocal.length > 0) {
      const isPackFree = cartLocal.filter((item) => item.packageType.packageTypeCode === 'BAS' && item.packagePrice === 0);

      if (isPackFree.length > 0) {
        dispatch({ type: HOME_SHOW_CART, payload: false });
      }

    }
  }, []);

  useEffect(() => {
    if (packages && cart.length > 0) {
      const isPackBaseFree = cart.filter((item) => item.packageType.packageTypeCode === 'BAS' && item.packagePrice === 0);
      if (isPackBaseFree.length > 0 && appName === 'firmaya') {
        // Si es paquete gratis y firmaya hace activacion directa
        history.push('/registerPurchaseOrder/activatePlan');
      } else {
        history.push('/registerPurchaseOrder');
      }
    }
  }, [packages, cart]);

  return <></>;
};

const mapStateToProps = ({ home, general }) => ({
  loading: home.loading,
  packages: home.packages,
  appName: general.appName,
  cart: home.cart,
});

const mapDispatchToProps = {
  getPackage: getPackagesAction,
  addToCart: addToCartAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPurchaseExt);
