/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider, useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter, Route, Switch,
} from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import Store from '../store';
import Home from '../views/Home';
import Payments from '../views/BackOffice/Payments';
import Packages from '../views/BackOffice/Packages';
import Licenses from '../views/BackOffice/Licenses';
import Reports from '../views/BackOffice/Reports';
import Configurations from '../views/BackOffice/Configurations';
import RegisterPurchaseOrder from '../views/RegisterPurchaseOrder';
import StatusOrder from '../views/RegisterPurchaseOrder/StatusOrder';
import ActivatePlan from '../views/RegisterPurchaseOrder/ActivatePlan';
import MyPlan from '../views/MyPlan';
import SummaryOrder from '../views/SummaryOrder';
import ManagerExt from '../components/crosscutting/Cart/ManagerExt';
import CartModalExt from '../components/crosscutting/Cart/CartModalExt';
import AddCartExt from '../components/crosscutting/Cart/AddCartExt';
import CartMenuExt from '../components/crosscutting/Cart/CartMenuExt';
import RegisterPurchaseExt from '../components/crosscutting/Cart/RegisterPurchaseExt';
import ReportView from '../components/crosscutting/ReportView';
import { getFullToken, setInfoToken, isAuthenticatedUser } from '../actions/auth.action';
import { invokeInterceptor } from '../actions/UnauthorizedResponseInterceptor.action';
import { SET_APP_NAME } from '../types/general.type';

import MasterPage from '../components/crosscutting/MasterPage/MasterPage.component';
import GenericMessage from '../components/crosscutting/Cart/GenericMessage';

const store = (appName) => {
  const localStore = Store();
  localStore.dispatch({
    type: SET_APP_NAME,
    payload: appName,
  });
  return localStore;
};

const NavRoute = (props) => {
  const {
    exact, path, computedMatch, component: Component,
  } = props;
  const dispatch = useDispatch();
  const { token } = computedMatch.params;
  const appsUserToken = localStorage.getItem('appsUserToken');
  const userInfo = useSelector(({ auth }) => auth.userInfo);
  if (appsUserToken && !userInfo) {
    dispatch(setInfoToken(appsUserToken));
  }
  if (token) {
    dispatch(getFullToken(token, path));
  }
  const appName = useSelector(({ general }) => general.appName);
  dispatch(isAuthenticatedUser(appName));
  dispatch(invokeInterceptor(appName));

  // if (isAccesToRole(userInfo && userInfo.license_role, path, enqueueSnackbar)) {
  return (
    <Route
      exact={exact}
      path={path}
      render={(componentProps) => (
        <>
          <MasterPage component={Component} {...componentProps} myuser={userInfo} />
        </>
      )}
    />
  );
  // }
  // return (
  //   <Redirect to="/" />
  // );
};

const App = ({ appName, theme }) => (
  <ThemeProvider theme={responsiveFontSizes(theme)}>
    <CssBaseline />
    <SnackbarProvider maxSnack={3}>
      <Provider store={store(appName)}>
        <BrowserRouter>
          <Switch>
            <NavRoute exact path="/admin/packages" component={Packages} />
            <NavRoute exact path="/admin/configurations" component={Configurations} />
            <NavRoute exact path="/admin/payments" component={Payments} />
            <NavRoute exact path="/admin/licenses" component={Licenses} />
            <NavRoute exact path="/admin/reports" component={Reports} />
            <NavRoute exact path="/myplan" component={MyPlan} />
            <NavRoute exact path="/registerPurchaseOrder" component={RegisterPurchaseOrder} />
            <NavRoute exact path="/registerPurchaseOrder/statusOrder/:refererenceUuid" component={StatusOrder} />
            <NavRoute exact path="/registerPurchaseOrder/activatePlan" component={ActivatePlan} />
            <NavRoute exact path="/myplan/:token?" component={MyPlan} />
            <NavRoute exact path="/summaryorder/:refererenceUuid" component={SummaryOrder} />
            <NavRoute exact path="/license/addCartItem/:idParam" component={ManagerExt} />
            <Route exact path="/license/viewCartMenu" component={CartMenuExt} />
            <Route exact path="/license/viewCartModal/:idParam?/:quantityParam?/:reloadParam?" component={CartModalExt} />
            <Route exact path="/license/addCart/:encryptedParameter+" component={AddCartExt} />
            <Route exact path="/license/RegisterPurchaseExt" component={RegisterPurchaseExt} />
            <Route exact path="/license/reports/reportView" component={ReportView} />
            <Route exact path="/messageValidationEmail" component={GenericMessage} />
            {/* Rutas más generales al final */}
            <NavRoute exact path="/cart/:packageId" component={Home} />
            <NavRoute exact path="/:token?" component={appName === 'license' ? Configurations : Home} />
          </Switch>
        </BrowserRouter>
      </Provider>
    </SnackbarProvider>
  </ThemeProvider>
);
export default App;
