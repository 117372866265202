import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Paper from '@material-ui/core/Paper';
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

const EditorWysiwyg = (props) => {
  const {
    editorValue, setEditorValue, setSummaryHtml, maxLength,
  } = props;

  const getEditorContent = (editorStateParam) => {
    const contentState = editorStateParam.getCurrentContent();
    const oldContent = editorValue.getCurrentContent();
    if (contentState === oldContent || contentState.getPlainText().length <= maxLength) {
      setSummaryHtml(draftToHtml(convertToRaw(editorStateParam.getCurrentContent())));
      setEditorValue(editorStateParam);
    } else {
      const editorState = EditorState.undo(
        EditorState.push(
          editorValue,
          ContentState.createFromText(oldContent.getPlainText()),
          'delete-character',
        ),
      );
      setEditorValue(editorState);
    }
  };

  return (
    <Paper variant="outlined">
      <Editor
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={getEditorContent}
        editorState={editorValue}
        toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
        }}
      />
    </Paper>
  );
};

export default (EditorWysiwyg);
