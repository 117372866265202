/* eslint-disable react-hooks/exhaustive-deps  */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { useSnackbar } from 'notistack';
import Loading from '../../../../components/crosscutting/Loading';
import CustomTable from '../../../../components/form/Table/CustomTable';
import AlertConfirm from '../../../../components/crosscutting/AlertConfirm';
import { ActivePackage } from '../../../../actions/packets.action';
import SwitchiOS from '../../../../components/form/SwitchiOS';
import coronaIcon from '../../../../assets/img/iconos/ibuhoo/corona.svg';

const useStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: theme.palette.primary.main,
  },
  editIcon: {
    ...theme.backOffice.configurations.editIcon,
  },
}));

const PackageTable = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    setPackageSelected, setStatusModal, enteredFilter, packagesList,
    setIsNew,
  } = props;
  const [packagesRows, setPackagesRows] = React.useState([]);
  const [packagesListCopy, setPackagesListCopy] = React.useState([]);
  const [generalInfo, setGeneralInfo] = useState({
    packageCode: '',
    checked: false,
  });
  const [openAlertConfirm, setOpenAlertConfirm] = useState(false);
  const [activeCode, setActiveCode] = useState(false);
  const [packagesReady, setPackagesReady] = useState(false);

  const loading = useSelector(({ packets }) => packets.loading);
  const { enqueueSnackbar } = useSnackbar();

  function filterInfoTable(enteredFilterParam, codesMapped) {
    const packagesListCustom = codesMapped != null ? codesMapped : packagesListCopy;
    if (packagesListCustom.length > 0) {
      const custom = packagesListCustom.filter(
        (num) => num.packageType
          .toLowerCase()
          .indexOf(enteredFilterParam.toLowerCase()) >= 0
          || num.packageName
            .toLowerCase()
            .indexOf(enteredFilterParam.toLowerCase()) >= 0
          || num.packageCode
            .toLowerCase()
            .indexOf(enteredFilterParam.toLowerCase()) >= 0
          || num.description
            .toLowerCase()
            .indexOf(enteredFilterParam.toLowerCase()) >= 0
          || num.totalValue.props.value
            .indexOf(enteredFilterParam) >= 0
          || num.startDate
            .indexOf(enteredFilterParam) >= 0
          || num.validity
            .toLowerCase()
            .indexOf(enteredFilterParam.toLowerCase()) >= 0
          || enteredFilterParam === '',
      );
      setPackagesRows(custom);
    }
  }

  useEffect(() => {
    filterInfoTable(enteredFilter, null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enteredFilter]);

  function createElementVisibility(isVisiblePublic) {
    return (
      <>
        { !isVisiblePublic
          ? (
            <Tooltip title="Paquete privado" aria-label="Paquete privado" placement="top">
              <img src={coronaIcon} alt="coronaIcon" />
            </Tooltip>
          )
          : ''}
      </>
    );
  }

  const changePackageStatus = (event) => {
    setGeneralInfo({
      packageCode: event.target.value,
      checked: event.target.checked,
    });
    setOpenAlertConfirm(true);
    setActiveCode(event.target.checked);
  };

  const openModalEditPackage = (PackageCode) => {
    const packageEdit = packagesList.find((packageRow) => packageRow.packageCode === PackageCode);
    setPackageSelected(packageEdit);
    setIsNew(false);
    setStatusModal(true);
  };

  function createActiveElement(active, packageCode) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
        <SwitchiOS
          checked={active}
          value={packageCode}
          onChange={(e) => changePackageStatus(e)}
          name="active"
          color="primary"
        />

        <IconButton
          onClick={() => openModalEditPackage(packageCode)}
          aria-label="Editar"
          size="small"
        >
          <Tooltip title="Editar" aria-label="Editar codigo">
            <EditIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>

      </Box>
    );
  }

  function mapPackages(packages) {
    const packagesInfo = [];
    packages.forEach((packageChild) => {
      let offerStartTime = '';
      if (packageChild.offerStartTime != null) {
        offerStartTime = moment(packageChild.offerStartTime, 'YYYY-MM-DD HH:mm:ss').toDate();
        offerStartTime = moment(offerStartTime).format('DD/MM/yyyy');
      }
      let time = '';
      switch (packageChild.validityTypeId) {
        case 'A':
          time = 'Anual';
          break;
        case 'D':
          time = 'Diaria';
          break;
        case 'I':
          time = 'Ilimitada';
          break;
        case 'M':
          time = 'Mensual';
          break;
        default:
          time = 'M';
      }
      const packageRow = {
        publicVisibility: packageChild.publicVisibility,
        visibility: createElementVisibility(packageChild.publicVisibility),
        packageType: packageChild.packageType.packageTypeName,
        packageName: packageChild.packageName,
        description: packageChild.packageDescription,
        total: packageChild.packagePrice,
        totalValue: <NumberFormat value={packageChild.packagePrice !== undefined ? packageChild.packagePrice.toString() : 0} displayType="text" thousandSeparator prefix="$" />,
        startDate: offerStartTime,
        validity: `${packageChild.validityPeriod !== undefined ? packageChild.validityPeriod.toString() : '0'} ${time}`,
        active: createActiveElement(packageChild.idstate === 0, packageChild.packageCode),
        packageCode: packageChild.packageCode,
        state: packageChild.idstate,
        id: packageChild.id,
      };
      packagesInfo.push(packageRow);
    });
    setPackagesRows(packagesInfo);
    setPackagesListCopy(packagesInfo);
    if (enteredFilter !== '') { filterInfoTable(enteredFilter, packagesInfo); }
  }

  useEffect(() => {
    setPackagesRows([]);
    mapPackages(packagesList);
    if (packagesList.length > 0) { setPackagesReady(true); }
  }, [packagesList]);

  const columns = [
    {
      id: 'visibility',
      label: '',
      minWidth: 50,
      position: 1,
      isSortable: true,
      sortPropertyName: 'publicVisibility',
    },
    {
      id: 'packageType',
      label: 'Tipo paquete',
      minWidth: 150,
      position: 2,
      paddingLeft: '40px',
      isSortable: true,
      sortPropertyName: 'packageType',
    },
    {
      id: 'packageName',
      label: 'Nombre paquete',
      minWidth: 100,
      position: 3,
      paddingLeft: '40px',
      isSortable: true,
      sortPropertyName: 'packageName',
    },
    {
      id: 'packageCode',
      label: 'Código paquete',
      minWidth: 100,
      position: 4,
      paddingLeft: '40px',
      isSortable: true,
      sortPropertyName: 'packageCode',
    },
    {
      id: 'description',
      label: 'Descripción',
      minWidth: 200,
      position: 5,
      paddingLeft: '40px',
      isSortable: true,
      sortPropertyName: 'description',
    },
    {
      id: 'totalValue',
      label: 'Valor total',
      minWidth: 100,
      position: 6,
      paddingLeft: '40px',
      isSortable: true,
      sortPropertyName: 'total',
    },
    {
      id: 'startDate',
      label: 'Fecha lanzamiento',
      minWidth: 100,
      position: 7,
      paddingLeft: '40px',
      isSortable: true,
      sortPropertyName: 'startDate',
    },
    {
      id: 'validity',
      label: 'Vigencia',
      minWidth: 100,
      position: 8,
      paddingLeft: '40px',
      isSortable: true,
      sortPropertyName: 'validity',
    },
    {
      id: 'active',
      label: 'Activo',
      minWidth: 100,
      position: 9,
      isSortable: true,
      sortPropertyName: 'active',
    },
  ];

  const confirmChangePackageStatus = () => {
    const packageSelected = packagesList.find((packageChild) => packageChild.packageCode === generalInfo.packageCode);
    const body = {
      id: packageSelected.id,
      idstate: generalInfo.checked ? 0 : 1,
    };
    packageSelected.idstate = body.idstate;
    delete packageSelected.active;
    packageSelected.active = createActiveElement(generalInfo.checked, generalInfo.packageCode);
    mapPackages(packagesList);
    dispatch(ActivePackage(body));
    setGeneralInfo({
      code: '',
      checked: '',
    });
    setOpenAlertConfirm(false);
    enqueueSnackbar(`Se ha ${activeCode ? 'activado' : 'inactivado'} el paquete exitosamente`, {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    });
  };

  if (loading && !packagesReady) return <Loading />;

  return (
    <>
      <CustomTable
        data={packagesRows}
        columns={columns}
        styleTableContainer={{ marginTop: '15px', width: 'auto' }}
      />

      <AlertConfirm
        isOpen={openAlertConfirm}
        tituloModal="Confirmar"
        mensajeModal={`¿Esta seguro que quiere ${activeCode ? 'activar' : 'inactivar'
        } este paquete?`}
        handleAccept={() => confirmChangePackageStatus()}
        onCancel
        onClose={() => setOpenAlertConfirm(false)}
      />
    </>
  );
};

export default PackageTable;
