
/* eslint-disable radix */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid, react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import moment from 'moment';
import { Rules } from '../../../../helpers/RHFRules';
import Loading from '../../../../components/crosscutting/Loading';
import AlertConfirm from '../../../../components/crosscutting/AlertConfirm';

import {
  resetFormRegisterAction,
} from '../../../../actions/purchaseLicense.action';

import ImagePopup from '../../../../assets/img/iconos/license/SuccessfullyCreatedCompany.svg';

import { saveCustomerAction, getCustomerInformationLicenseAction } from '../../../../actions/license.action';

import { getPurchaseOrderParametersAction, getTokenExternalSourceAction, setMessageAlertAction } from '../../../../actions/general.action';
import { calculateCheckDigit } from '../../../../helpers/generalUtils';
import { setGeoCode } from '../../../../helpers/googleMapsUtils';

import CustomInput from '../../../../components/form/CustomInput';
import CustomSelect from '../../../../components/form/CustomSelect';
import CustomAutocompleteSelect from '../../../../components/form/Autocomplete/CustomAutocompleteSelect';
import AutocompleteGoogleMaps from '../../../../components/form/AutocompeteGoogleMaps/CustomAutocompleteGoogleMaps';
import CustomDate from '../../../../components/form/CustomDate';

const useStyles = makeStyles((theme) => ({
  paper: {
    background: 'linear-gradient(#FFFFFFCC, #ffffff)',
    borderRadius: '30px',
    opacity: 1,
    padding: '5px',
    marginTop: '20px',
  },
  paperGroup: {
    backgroundColor: '#fff',
    margin: 20,
    borderRadius: 10,
    border: '1px solid #32ACDE',
    padding: 20,
  },
  title: {
    ...theme.backOffice.configurations.title,
    fontWeight: 600,
    fontSize: '26px',
    paddingTop: '15px',
    paddingLeft: '20px',
  },
  button: {
    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
    border: '1px solid var(--unnamed-color-707070)',
    backgroundColor: '#FFFFFF 0% 0 % no - repeat padding- box',
    borderRadius: '5px',
    opacity: '1',
    font: 'normal normal 600 12px/34px Muli',
  },
  root: {
    width: '100%',
    marginTop: '10%',
    marginBottom: '10%',
    padding: '20px',
    borderRadius: '14px',
    ...theme.registerForm.masterContainer,
  },
  titleForm: {
    color: '#1D71B8',
    textAlign: 'left',
    letterSpacing: '0px',
    opacity: '1',
    fontWeight: 'bold',
  },
  divider: {
    marginBottom: '10px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderColor: theme.palette.primary.main,
    ...theme.registerForm.containerForm,
  },
  rowWrapper: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
  },
  margin: {
    margin: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  contentTextPrimary: {
    color: theme.palette.primary.main,
  },
  contentTextSecondary: {
    color: theme.palette.secondary.main,
    fontSize: 15,
  },
  contentInfoAddress: {
    color: '#77CAED',
  },
  submit: {
    paddingTop: 20,
    textAlign: 'center',
    padding: 10,
  },
  submitLicense: {
    textAlign: 'left',
    padding: 10,
  },
  btnSubmit: {
    marginLeft: 10,
    fontSize: 16,
    width: 200,
    borderRadius: 10,
    fontWeight: 100,
    '&:disabled': {
      opacity: 0.4,
      fontWeight: 100,
      backgroundColor: '#1D71B8',
      color: 'white',
    },
  },
  btnSubmitLicense: {
    borderRadius: '5px',
    border: '1px solid #E45501',
    color: '#E55200',
    '&:disabled': {
    },
  },
  imgLicense: {
    height: '20px',
    width: '30px',
    paddingLeft: '5px',
    marginTop: '2px',
  },
  panelSummary: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    maxHeight: '50px',
    borderRadius: 4,
  },
  expanded: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    borderRadius: 4,
    borderColor: theme.palette.secondary.main,
    '&$expanded': {
      minHeight: '0px',
      maxHeight: '50px',
    },
  },
  infoText: {
    color: theme.palette.secondary.main,
    marginTop: -23,
    fontSize: theme.typography.pxToRem(16),
    position: 'absolute',
  },
  legalTerms: {
    padding: '20px',
  },
  legalTermsPolicy: {
    marginTop: '7px',
    marginLeft: '-40px',
  },
  formErrors: {
    color: theme.palette.error.main,
    fontSize: theme.typography.pxToRem(13),
  },
  buttonProgress: {
    color: theme.palette.error.main,
    top: '10px',
    marginLeft: '10px',
    position: 'relative',
  },
  expansionPanelTitle: {
    color: '#212121',
    fontWeight: 'bold',
    fontSize: 16,
  },
  expansionPanelIcon: {
    color: '#212121',
  },
  btnCashPayment: {
    display: 'none',
  },
  alertActivation: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleActivated: {
    paddingTop: '60px',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  logoActivated: {
    paddingTop: '50px',
    paddingBottom: '30px',
  },
  messageActivated: {
    fontSize: 17,
    textAlign: 'center',
  },
  secondaryMessageAlert: {
    textAlign: 'center',
    color: '#7b7b7b',
    paddingTop: 15,
    paddingBottom: 20,
  },
  typeInput: {
    '& .MuiOutlinedInput-root': {
      position: 'relative',
      borderRadius: '10px',
      boxShadow: '1px 5px 5px #d7d7d7',
    },
  },
  dateCustom: {
    '& .MuiIconButton-root': {
      color: '#009fe3',
    },
  },
}));

const personType = [
  { value: 'J', label: 'Persona Juridica' },
  { value: 'N', label: 'Persona Natural' },
];

const linksToApps = [
  { value: 'biometria', label: 'Biometría' },
  { value: 'confronta', label: 'Confronta' },
  { value: 'otras_apps', label: 'Otras Aplicaciones' },
];

const CreateCustomer = (props) => {

  const classes = useStyles();
  const {
    loading, appName, getPurchaseOrderParameters, documentTypes, statesAndCities,
    history, getTokenExternalSource,
    location, licenseShowMessageAlert,
    resetFormRegister, citiesServGoogleAssistant, saveCustomer,
    getCustomerInformationLicense, customerServiceInfoLicense,
  } = props;

  const {
    handleSubmit, errors, control, setValue, getValues, watch, reset, formState,
  } = useForm({
    mode: 'onChange',
  });

  const watchPersonTypeSer = watch('personTypeService');
  const watchDocTypeSer = watch('documentTypeIdService');
  const watchDocSer = watch('documentNumberService');
  const watchLegalNameSer = watch('legalNameService');
  const watchFirstNameSer = watch('firstNameService');
  const watchLastNameService = watch('lastNameService');
  const watchCellphoneNumberSer = watch('cellphoneNumberService');
  const watchEmailSer = watch('emailService');
  const watchFulladdressSer = watch('fulladdressService');
  const watchDepartmentIdSer = watch('departmentIdService');
  const watchCityIdSer = watch('cityIdService');
  const watchLegalDocTypeSer = watch('legalDocumentTypeIdService');
  const watchDocLeg = watch('legalDocumentNumberService');
  const watchLegalFirstNameSer = watch('legalFirstNameService');
  const watchLegalLastNameSer = watch('legalLastNameService');
  const watchContractStartTime = watch('contractStartTime');
  const watchContractEndTime = watch('contractEndTime');
  const [departaments, setDepartaments] = useState([]);
  const [citiesService, setCitiesService] = useState([]);
  const [personTypeService, setPersonTypeService] = React.useState('N');
  const [linkToAppValue, setLinkToAppValue] = React.useState('');
  const [checkService, setCheckService] = useState(Boolean(Object.keys(customerServiceInfoLicense).lenght));
  const [, setOpenAlertInfo] = useState(false);
  const [messageAlert, setMessageAlert] = useState('');
  const [loadingRues, ] = React.useState(false);
  const [valueDepartamentService, setValueDepartamentService] = React.useState(null);
  const [valueCityService, setValueCityService] = React.useState(null);
  const [geoCodeService, setGeoCodeService] = React.useState({ lat: null, lng: null });
  const [addressMapsService, setAddressMapsService] = React.useState(null);
  const [inputAddressServiceValue, setInputAddressServiceValue] = React.useState('');
  const [optionsAddressService, setOptionsAddressService] = React.useState([]);
  const [disabledSubmit, setDisabledSubmit] = React.useState(false);
  const [customerTimeOut, ] = useState(false);
  const [customerFlag, setCustomerFlag] = useState(false);
  const [intervalTimeFlag, SetIntervalTimeFlag] = useState(false);
  const [contractStartDate, setContractStartDate] = useState(moment().startOf('day').add(1, 'minute'));
  const [contractEndDate, setContractEndDate] = useState(moment(contractStartDate).add(1, 'day').endOf('day').subtract(1, 'second'));

  /**
   * Metodo ir a Url licenses
   */

  const handleGoToLicenses = () => {
    window.location.href = location.pathname;
  };

  useEffect(() => {
    if (statesAndCities) {
      setDepartaments(statesAndCities.itemsStatesList.sort((a, b) => {
        const x = a.text < b.text ? -1 : 1;
        return x;
      }));
      if (customerServiceInfoLicense) {
        setCheckService(true);
      }
    }
  }, [statesAndCities]);

  useEffect(() => {
    const now = moment();
    if (moment(contractStartDate) < moment(now)) {
      setContractEndDate(moment(now).add(1, 'day').endOf('day').subtract(1, 'second'));
    } else {
      setContractEndDate(moment(contractStartDate).add(1, 'day').endOf('day').subtract(1, 'second'));
    }
  }, [contractStartDate]);

  /**
   * UseEfect obtener la informacion del cliente
   */

  useEffect(() => {
    if (customerServiceInfoLicense.result != null) {
      setValue('dvService', checkService ? customerServiceInfoLicense.result.records.dv : '');
      const convertCityString = customerServiceInfoLicense.result.records.cityId.toString();
      const convertCityInt = parseInt(convertCityString.slice(0, 6));
      setValue('firstNameService', checkService ? customerServiceInfoLicense.result.records.firstName : '');
      setValue('lastNameService', checkService ? customerServiceInfoLicense.result.records.lastName : '');
      setValue('legalNameService', checkService ? customerServiceInfoLicense.result.records.legalName : '');
      setValue('cellphoneNumberService', checkService ? customerServiceInfoLicense.result.records.cellphoneNumber : '');
      setValue('emailService', checkService ? customerServiceInfoLicense.result.records.email : '');
      setValue('departmentIdService', checkService ? customerServiceInfoLicense.result.records.departmentId : '');
      if (statesAndCities) {
        setCityCustomerServiceId(convertCityInt, null, customerServiceInfoLicense.result.records.cityId, null, checkService);
      }
      setValue('cityIdService', checkService ? customerServiceInfoLicense.result.records.cityId : '');
      if (checkService) {
        setAddressCustomer(customerServiceInfoLicense.result.records.fullAddress, setOptionsAddressService, setAddressMapsService, 'fulladdressService', setGeoCodeService);
      } else {
        setValue('fulladdressService', '');
        setOptionsAddressService([]);
        setAddressMapsService(null);
      }
      setValue('legalDocumentTypeIdService', checkService ? customerServiceInfoLicense.result.records.legalDocumentTypeId : '');
      setValue('legalDocumentNumberService', checkService ? customerServiceInfoLicense.result.records.legalDocumentNumber : '');
      setValue('legalFirstNameService', checkService ? customerServiceInfoLicense.result.records.legalFirstName : '');
      setValue('legalLastNameService', checkService ? customerServiceInfoLicense.result.records.legalLastName : '');
      setValue('additionalInfoService', checkService ? customerServiceInfoLicense.result.records.addressAdditionalInformation : '');
      setCustomerFlag(true);
    } else {
      setValue('firstNameService', '');
      setValue('lastNameService', '');
      getCheckDigit('dvService', 'documentNumberService', watchDocTypeSer);
      setValue('legalNameService', '');
      setValue('cellphoneNumberService', '');
      setValue('emailService', '');
      setValue('departmentIdService', '');
      setValue('cityIdService', '');
      setValue('fulladdressService', '');
      setOptionsAddressService([]);
      setAddressMapsService(null);
      setValueDepartamentService(null);
      setValueCityService(null);
      setValue('legalDocumentTypeIdService', '');
      setValue('legalDocumentNumberService', '');
      setValue('legalFirstNameService', '');
      setValue('legalLastNameService', '');
      setValue('additionalInfoService', '');
      setCustomerFlag(false);
    }
  }, [customerServiceInfoLicense]);
  /**
   * UseEfect estado de formulario formState
   */

  useEffect(() => {
    const errorCount = Object.getOwnPropertyNames(formState.errors);

    if (errorCount?.length > 0) setDisabledSubmit(true);
    if (watchPersonTypeSer === 'J') {
      if (!watchDocTypeSer || !watchDocSer || !watchLegalNameSer || !watchCellphoneNumberSer
        || !watchEmailSer || !watchFulladdressSer || !watchDepartmentIdSer || !watchCityIdSer
        || !watchLegalDocTypeSer || !watchDocLeg || !watchLegalFirstNameSer || !watchLegalLastNameSer) {
        setDisabledSubmit(true);
      } else if (errorCount?.length === 0) {
        setDisabledSubmit(false);
      }
    } else if (!watchDocTypeSer || !watchDocSer || !watchFirstNameSer || !watchLastNameService
      || !watchCellphoneNumberSer || !watchEmailSer || !watchFulladdressSer || !watchDepartmentIdSer
      || !watchCityIdSer) {
      setDisabledSubmit(true);
    } else if (errorCount.length === 0) {
      setDisabledSubmit(false);
    }
  }, [formState]);

  // ---------------------------------------------
  // method - Remover espacios texto
  // ---------------------------------------------
  const removeSpaces = (nameDoc) => {
    let docNumAux = getValues(nameDoc);
    if (docNumAux) {
      if (docNumAux.match(/\s/g)) {
        docNumAux = docNumAux.replace(/\s/g, '');
      }
      setValue(nameDoc, docNumAux);
    }
  };

  // ---------------------------------------------
  // method - Obtiene digito de verificación
  // ---------------------------------------------
  const getCheckDigit = (dv, nameDoc, typeDoc) => {
    const numero = getValues(nameDoc);
    if (typeDoc === 4 && numero && numero.length > 5) {
      const checkDigit = calculateCheckDigit(numero);
      if (checkDigit || checkDigit === 0) {
        setValue(dv, checkDigit);
      }
    } else {
      setValue(dv, '');
    }
  };

  useEffect(() => {
    removeSpaces('documentNumberService');
  }, [watchDocSer]);

  useEffect(() => {
    removeSpaces('legalDocumentNumberService');
  }, [watchDocLeg]);

  // const cleanFields = (name) => {
  //   setValue(`legalName${name}`, '');
  //   setValue(`firstName${name}`, '');
  //   setValue(`lastName${name}`, '');
  // };

  // ---------------------------------------------
  // method - Obtiene datos de carga de clientes
  // ---------------------------------------------

  const getInformationCustomerLicense = async (customerPersonType, valueDocType, valueDoc, name) => {
    const doc = getValues(valueDoc);
    const docType = getValues(valueDocType);
    const customerId = 0;
    if (!name || name === 'Service') {
      getCheckDigit('dvService', 'documentNumberService', watchDocTypeSer);
    }
    if (!doc) {
      setValue('firstNameService', '');
      setValue('lastNameService', '');
      setValue('dvService', '');
      setValue('legalNameService', '');
      setValue('cellphoneNumberService', '');
      setValue('emailService', '');
      setValue('departmentIdService', '');
      setValue('cityIdService', '');
      setValue('fulladdressService', '');
      setOptionsAddressService([]);
      setAddressMapsService(null);
      setValueDepartamentService(null);
      setValueCityService(null);
      setValue('legalDocumentTypeIdService', '');
      setValue('legalDocumentNumberService', '');
      setValue('legalFirstNameService', '');
      setValue('legalLastNameService', '');
      setValue('additionalInfoService', '');
    }
    await getCustomerInformationLicense(customerId, docType, doc);
  };

  // ---------------------------------------------
  // effect - Obtiene datos de carga inicial de formulario
  // ---------------------------------------------
  useEffect(() => {
    resetFormRegister();
    getTokenExternalSource();
    getPurchaseOrderParameters();
    setValue('contractStartTime', contractStartDate);
    setValue('contractEndTime', contractEndDate);
  }, []);

  // ---------------------------------------------
  // method - Setear direccion
  // ---------------------------------------------
  const setAddressCustomer = (valueAddress, setOptionsMaps, setAddressValue, combo, geoCode) => {
    if (valueAddress) {
      const optionsAddress = [];

      optionsAddress.push({
        description: valueAddress,
        structured_formatting: {
          main_text: valueAddress,
          main_text_matched_substrings: [
            {
              length: 0,
              offset: 0,
            },
          ],
          secondary_text: '',
        },
      });

      setOptionsMaps(optionsAddress);
      if (optionsAddress.length > 0) {
        setAddressValue(optionsAddress[0]);
        setValue(combo, optionsAddress[0]);
        setGeoCode(valueAddress, geoCode);
      }
    }
  };
  // ---------------------------------------------
  // method - Setear ciudad cliente servicio
  // ---------------------------------------------
  const setCityCustomerServiceId = (dptoId, dptoName, cityId, cityName, checkServ) => {
    setValueDepartamentService(null);
    setValueCityService(null);
    if (checkServ) {
      let dptoServ = {};
      if (dptoId > 0) {
        dptoServ = statesAndCities.itemsStatesList.find((d) => d.value === dptoId);
      } else {
        dptoServ = statesAndCities.itemsStatesList.find((d) => d.text === dptoName);
      }

      if (dptoServ) {
        setValueDepartamentService(dptoServ);
        setValue('departmentIdService', dptoServ);
      }

      let newCities = {};
      if (dptoId > 0) {
        newCities = statesAndCities.statesList.find((d) => d.id === dptoId);
      } else {
        newCities = statesAndCities.statesList.find((d) => d.stateName === dptoName);
      }
      if (newCities) {
        setCitiesService(newCities.city);

        let cityBilling = {};
        if (cityId > 0) {
          cityBilling = newCities.city.find((d) => d.id === cityId);
        } else {
          cityBilling = newCities.city.find((d) => d.cityName === cityName);
        }

        if (cityBilling) {
          setValueCityService(cityBilling);
          setValue('departmentIdService', dptoServ.value);
          setValue('cityIdService', cityBilling.id);
        }
      }
    }
  };

  /**
   * Metodo resetear formulario por defecto
   */
  const resetForm = () => {
    setValue('fulladdressService', '');
    setOptionsAddressService([]);
    setAddressMapsService(null);
    setInputAddressServiceValue('');
    setValueDepartamentService(null);
    setValueCityService(null);
    setValue('departmentIdService', null);
    setValue('cityIdService', '');
    reset();
  };

  useEffect(() => {
    SetIntervalTimeFlag(true);
    if (licenseShowMessageAlert) {
      setMessageAlert(licenseShowMessageAlert.message);
      // setIsOnlyMessage(licenseShowMessageAlert.isOnlyMessage);
      // setImageAlert(licenseShowMessageAlert.img);

      setOpenAlertInfo(true);

      const { category } = licenseShowMessageAlert;
      switch (category) {
        case 'license':
          resetForm();
          break;
        default:
          break;
      }
      if (intervalTimeFlag) {
        setInterval(() => { window.location.reload(); }, 3000);
      }
    }
  }, [licenseShowMessageAlert]);

  useEffect(() => {
    if (citiesServGoogleAssistant && citiesServGoogleAssistant.deptoName !== null) {
      setCityCustomerServiceId(0, citiesServGoogleAssistant.deptoName, 0, citiesServGoogleAssistant.cityName, true);
    } else if (addressMapsService && optionsAddressService.length > 3 && !checkService) {
      setValueDepartamentService(null);
      setValueCityService(null);
      setValue('departmentIdService', '');
      setValue('cityIdService', '');
    }
  }, [citiesServGoogleAssistant]);

  // ---------------------------------------------
  // method - setear tipo de persona
  // ---------------------------------------------
  const setPersonType = (event) => {
    const { value } = event.target;

    if (event.target.name === 'personTypeService') {
      setPersonTypeService(value);
      if (value === 'J') {
        setValue('documentTypeIdService', 4);
      }
    }
    if (value === 'N') {
      setValue('legalDocumentTypeIdService', '');
      setValue('legalDocumentNumberService', '');
      setValue('legalFirstNameService', '');
      setValue('legalLastNameService', '');
    }
    return value;
  };

  // ---------------------------------------------
  // method - setear tipo de persona
  // ---------------------------------------------
  const setLinkToApp = (event) => {
    const { value } = event.target;

    setLinkToAppValue(value);
    return value;
  };

  // ---------------------------------------------
  // event envio de datos de formulario
  // ---------------------------------------------
  const onSubmit = (formData) => {
    saveCustomer(formData, appName, history, geoCodeService, ImagePopup);
  };

  // ---------------------------------------------
  // event handler cambio de estado cliente
  // ---------------------------------------------
  const handleChangeStateCustomerServiceId = (idDepartament) => {
    setValueCityService(null);
    const newCities = statesAndCities.statesList.find((d) => d.id === idDepartament);
    if (newCities) {
      setCitiesService(newCities.city.sort((a, b) => {
        const x = a.cityName < b.cityName ? -1 : 1;
        return x;
      }));
    }
    return idDepartament;
  };
  // ---------------------------------------------
  // validación documento
  // ---------------------------------------------
  const validateDocument = (persontype, watchDocType) => {
    if (watchDocType === 6) {
      return Rules.pasaporte;
    } if (watchDocType === 4 && persontype === 'J') {
      return Rules.tipoDocumentoNIT;
    }
    return Rules.otrosDocumentos;
  };

  const validateDoc = (docType, e) => {
    const alfanumerico = /[a-zA-Z0-9]/gi;
    const numerico = /[0-9]/gi;
    if (docType === 6) {
      if (!e.key.match(alfanumerico)) {
        e.preventDefault();
      }
    } else if (!e.key.match(numerico)) {
      e.preventDefault();
    }
  };

  /**
   * event handler ingreso de teclas validas
   * @param {*} e event
   * @param {*} watchDocType tipo de documento
   * @returns valida si la tecla ingresada es valida
   */
  const handleKeyDown = (e, watchDocType) => {
    const { keyCode } = e;
    if (keyCode === 9 || keyCode === 8 || keyCode === 17 || keyCode === 37 || keyCode === 39 || keyCode === 46) return;
    validateDoc(watchDocType, e);
  };

  /**
   * Metodo setear tipos de documento
   * @returns retorna listado de tipos de documentos
   */
  const setDocumentType = () => {
    let documents = [];
    const docTypes = [];

    appName === 'license' ? (personTypeService === 'J')
      ? documents = documentTypes.filter((doc) => doc.value === 4)
      : documents = documentTypes.filter((doc) => doc.value === 1 || doc.value === 2 || doc.value === 4 || doc.value === 6)
      : documents = documentTypes;

    if (documents.length > 0) {
      documents.map((doc, index) => (
        docTypes.push({
          id: index,
          label: doc.text,
          value: doc.value,
        })
      ));
    }
    return docTypes;
  };

  const setDocumentTypeLegalRep = () => {
    let documents = [];
    const docTypes = [];
    documents = documentTypes.filter((doc) => doc.value === 1 || doc.value === 2 || doc.value === 6);

    if (documents.length > 0) {
      documents.map((doc, index) => (
        docTypes.push({
          id: index,
          label: doc.text,
          value: doc.value,
        })
      ));
    }
    return docTypes;
  };

  /**
   * metodo Loading
   * @returns retorna loading circular
   */
  const getLoading = () => (
    <>
      <Grid item xs={1} sm={1}>
        <CircularProgress size={24} className={classes.buttonProgress} />
      </Grid>
    </>
  );

  /**
   * Metodo validar digito de verificación
   * @param {*} referencia digito de verificacion
   * @returns retorna caja de digito de verificació
   */
  const boxCheckDigit = (referencia) => (
    <>
      <Grid item xs={1} sm={1}>
        <Box pt={1} style={{ textAlign: 'center' }}>-</Box>
      </Grid>
      <Grid item xs={2} sm={2}>
        <Controller
          as={(
            <TextField
              size="small"
              label="Dv"
              disabled
              variant="outlined"
              style={{ color: 'red' }}
              className={classes.typeInput}
            />
          )}
          name={referencia}
          control={control}
          defaultValue=""
        />
      </Grid>
    </>
  );

  // ---------------------------------------------
  // Bloqueo tecla enter
  // ---------------------------------------------
  const skipEnterPress = (e) => {
    if (e.which === 13 /* Enter */) {
      e.preventDefault();
    }
  };

  const handleOnchangeDepartament = (data) => {
    setValueDepartamentService(data);
    handleChangeStateCustomerServiceId(data.value);
  };

  const handleOnchangeCity = (data) => {
    setValueCityService(data);
  };

  const handleSetContractDate = (type, date) => {
    setValue(type, date);
    if (type === 'contractStartTime') {
      setContractStartDate(date);
    } else {
      setContractEndDate(date);
    }
  };

  /**
   * validateEndDateContract
   * @param {*} date fecha validacion
   * @returns retorna fechas a desactivar
   */
  const validateEndDateContract = (date) => {
    const now = moment();
    if (moment(contractStartDate) < moment(now)) {
      return moment(date).isSameOrBefore(now);
    }

    return moment(date).isSameOrBefore(contractStartDate);
  };

  if (loading) return <Loading />;
  return (
    <>
      <Container component="main" maxWidth="lg">
        <Paper elevation={3} className={classes.paperGroup}>
          <form
            className={classes.form}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
            onKeyPress={(e) => skipEnterPress(e)}
          >
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Grid item xs={0}>
                <IconButton color="primary" onClick={(e) => handleGoToLicenses(e)} style={{ color: '#E5521A' }}>
                  <ArrowBackIosIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Typography align="left" className={classes.titleForm} variant="h5">
                  Nueva empresa
                </Typography>
              </Grid>
            </Grid>
            {/*  */}
            <Grid container spacing={4} style={{ paddingLeft: '60px', paddingRight: '60px' }}>
              <Grid item xs={12} />
              <Grid item xs={12} md={3}>
                <InputLabel />
                <CustomSelect
                  label="Tipo de persona"
                  name="personTypeService"
                  options={personType}
                  control={control}
                  required
                  rule={Rules.required}
                  error={errors}
                  styleFromHelperText={{ style: { marginRight: 10 } }}
                  inputProps={{ onChange: (e) => setPersonType(e) }}
                  classes={classes.typeInput}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Grid container>
                  <Grid item xs={12} md={loadingRues ? 11 : 12} lg={loadingRues ? 11 : 12}>
                    <InputLabel />
                    <CustomSelect
                      label="Tipo de documento"
                      name="documentTypeIdService"
                      // eslint-disable-next-line no-nested-ternary
                      options={setDocumentType()}
                      control={control}
                      required
                      rule={Rules.required}
                      error={errors}
                      styleFromHelperText={{ style: { marginRight: 10 } }}
                      inputProps={{ onChange: (e) => setPersonType(e) }}
                      classes={classes.typeInput}
                    />
                  </Grid>
                  {loadingRues && getLoading()}
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={watchDocTypeSer === 4 ? 9 : 12} sm={watchDocTypeSer === 4 ? 9 : 12}>
                    <CustomInput
                      label="Número de documento"
                      name="documentNumberService"
                      control={control}
                      rule={validateDocument(personTypeService, watchDocTypeSer)}
                      error={errors}
                      required
                      styleFromHelperText={{ style: { marginRight: 10 } }}
                      onKeyDown={(e) => handleKeyDown(e, watchDocTypeSer)}
                      inputProps={{ onBlur: () => getInformationCustomerLicense(personTypeService, 'documentTypeIdService', 'documentNumberService', 'Service'), maxLength: 15 }}
                      classes={classes.typeInput}
                    />
                  </Grid>
                  {watchDocTypeSer === 4 ? boxCheckDigit('dvService') : ''}
                </Grid>
              </Grid>
              {
                personTypeService === 'J'
                  ? (
                    <Grid item xs={12}>
                      <CustomInput
                        label="Razón Social"
                        name="legalNameService"
                        control={control}
                        rule={Rules.DenySpecialCharactersLegalName}
                        error={errors}
                        required
                        styleFromHelperText={{ style: { marginRight: 10 } }}
                        inputProps={{
                          maxLength: 450,
                        }}
                        classes={classes.typeInput}
                      />
                    </Grid>
                  )
                  : (
                    <>
                      <Grid item xs={12} md={6}>
                        <CustomInput
                          label="Nombres"
                          name="firstNameService"
                          control={control}
                          rule={Rules.letrasTildesMin3}
                          error={errors}
                          required
                          styleFromHelperText={{ style: { marginRight: 10 } }}
                          inputProps={{
                            maxLength: 100,
                          }}
                          classes={classes.typeInput}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <CustomInput
                          label="Apellidos"
                          name="lastNameService"
                          control={control}
                          rule={Rules.letrasTildesMin3}
                          error={errors}
                          required
                          styleFromHelperText={{ style: { marginRight: 10 } }}
                          inputProps={{
                            maxLength: 100,
                          }}
                          classes={classes.typeInput}
                        />
                      </Grid>
                    </>
                  )
              }
              <Grid item xs={12} md={6}>
                <CustomInput
                  label="Teléfono / Celular"
                  name="cellphoneNumberService"
                  control={control}
                  rule={Rules.telCel}
                  error={errors}
                  required
                  styleFromHelperText={{ style: { marginRight: 10 } }}
                  inputProps={{
                    maxLength: 10,
                  }}
                  classes={classes.typeInput}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label="Correo Electrónico"
                  name="emailService"
                  control={control}
                  rule={Rules.email}
                  error={errors}
                  required
                  styleFromHelperText={{ style: { marginRight: 10 } }}
                  inputProps={{
                    maxLength: 100,
                  }}
                  classes={classes.typeInput}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <AutocompleteGoogleMaps
                  name="fulladdressService"
                  options={optionsAddressService}
                  label="Dirección"
                  placeholder="Escriba la dirección"
                  value={addressMapsService}
                  setOptions={setOptionsAddressService}
                  setValueMaps={setAddressMapsService}
                  inputValue={inputAddressServiceValue}
                  setInputValue={setInputAddressServiceValue}
                  setGeoCoordinate={setGeoCodeService}
                  type="Service"
                  showIconLocation
                  control={control}
                  rules={Rules.required}
                  error={errors}
                  className={classes.typeInput}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomAutocompleteSelect
                  name="departmentIdService"
                  options={departaments}
                  label="Departamento"
                  disableClearable
                  value={valueDepartamentService}
                  getOptionLabel={(option) => option.text}
                  required
                  FormHelperTextProps={{ style: { marginRight: 10 } }}
                  handleOnchange={handleOnchangeDepartament}
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  className={classes.typeInput}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomAutocompleteSelect
                  name="cityIdService"
                  options={citiesService}
                  label="Ciudad"
                  disableClearable
                  value={valueCityService}
                  getOptionLabel={(option) => option.cityName}
                  required
                  FormHelperTextProps={{ style: { marginRight: 10 } }}
                  handleOnchange={handleOnchangeCity}
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  className={classes.typeInput}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  label="Información adicional"
                  name="additionalInfoService"
                  control={control}
                  error={errors}
                  inputProps={{
                    maxLength: 100,
                  }}
                  classes={classes.typeInput}
                />
                <Typography
                  style={{ paddingLeft: '5px', fontSize: '12px' }}
                  className={classes.contentInfoAddress}
                >
                  Ejemplo: Interior, apartamento, conjunto residencial, barrio, etc.
                </Typography>
              </Grid>
              {
                personTypeService === 'J'
                  ? (
                    <>
                      <Grid item xs={12}>
                        <h3 className={classes.contentTextPrimary}>Datos representante legal</h3>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CustomSelect
                          label="Tipo de documento Representante Legal"
                          name="legalDocumentTypeIdService"
                          // eslint-disable-next-line no-nested-ternary
                          options={setDocumentTypeLegalRep()}
                          control={control}
                          required
                          rule={Rules.required}
                          error={errors}
                          styleFromHelperText={{ style: { marginRight: 10 } }}
                          classes={classes.typeInput}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CustomInput
                          label="Número de documento Representante Legal"
                          name="legalDocumentNumberService"
                          control={control}
                          rule={validateDocument('J', watchLegalDocTypeSer)}
                          error={errors}
                          required
                          styleFromHelperText={{ style: { marginRight: 10 } }}
                          onKeyDown={(e) => handleKeyDown(e, watchLegalDocTypeSer)}
                          inputProps={{ maxLength: 15 }}
                          classes={classes.typeInput}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CustomInput
                          label="Nombres Representante Legal"
                          name="legalFirstNameService"
                          control={control}
                          rule={Rules.letrasTildesMin3}
                          error={errors}
                          required
                          styleFromHelperText={{ style: { marginRight: 10 } }}
                          inputProps={{
                            maxLength: 100,
                          }}
                          classes={classes.typeInput}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CustomInput
                          label="Apellidos Representante Legal"
                          name="legalLastNameService"
                          control={control}
                          rule={Rules.letrasTildesMin3}
                          error={errors}
                          required
                          styleFromHelperText={{ style: { marginRight: 10 } }}
                          inputProps={{
                            maxLength: 100,
                          }}
                          classes={classes.typeInput}
                        />
                      </Grid>
                      <Grid item xs={12} />
                    </>
                  )
                  : (
                    <>
                    </>
                  )
              }
              <Grid item xs={12} md={6}>
                <InputLabel />
                <CustomSelect
                  label="Aplicación a vincular"
                  name="linkToApp"
                  options={linksToApps}
                  control={control}
                  required
                  rule={Rules.required}
                  error={errors}
                  styleFromHelperText={{ style: { marginRight: 10 } }}
                  inputProps={{ onChange: (e) => setLinkToApp(e) }}
                  classes={classes.typeInput}
                />
              </Grid>
              {
                linkToAppValue === 'biometria'
                  ? (
                    <>
                      <Grid item xs={12}>
                        <h3 className={classes.contentTextPrimary}>Vigencia del contrato</h3>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <CustomDate
                          id="contractStartTime"
                          name="contractStartTime"
                          variant="inline"
                          inputVariant="outlined"
                          classNameKeyboardDate={`${classes.typeInput} ${classes.dateCustom}`}
                          formatInputValue="DD/MM/yyyy"
                          label="Fecha inicio"
                          dateSelected={contractStartDate}
                          onChangeHandler={(date) => handleSetContractDate('contractStartTime', moment(date))}
                          size="small"
                          color="primary"
                          rule={Rules.required}
                          placeholder="Fecha inicio"
                          control={control}
                          error={errors.contractStartTime}
                          formatKeyboardDate="dd/MM/yyyy"
                          defaultValue={contractStartDate}
                        />
                        {
                          ((Object.keys(errors).length > 0 && watchContractStartTime === null)
                          || errors?.contractStartTime)
                            && (
                              <>
                                <div
                                  style={{ color: '#d61103', margin: '0 0 0 5px', fontSize: '0.6964285714285714rem' }}
                                >
                                  { watchContractStartTime === null ? 'Campo obligatorio' : errors?.contractStartTime.message }
                                </div>
                              </>
                            )
                        }
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <CustomDate
                          id="contractEndTime"
                          name="contractEndTime"
                          variant="inline"
                          inputVariant="outlined"
                          classNameKeyboardDate={`${classes.typeInput} ${classes.dateCustom}`}
                          formatInputValue="DD/MM/yyyy"
                          label="Fecha fin"
                          dateSelected={contractEndDate}
                          onChangeHandler={(date) => handleSetContractDate('contractEndTime', moment(date))}
                          size="small"
                          color="primary"
                          rule={Rules.required}
                          placeholder="Fecha fin"
                          control={control}
                          error={errors.contractEndTime}
                          formatKeyboardDate="dd/MM/yyyy"
                          shouldDisableDate={(date) => validateEndDateContract(date)}
                          defaultValue={contractEndDate}
                        />
                        {
                          ((Object.keys(errors).length > 0 && watchContractEndTime === null)
                          || errors?.contractEndTime)
                            && (
                              <>
                                <div
                                  style={{ color: '#d61103', margin: '0 0 0 5px', fontSize: '0.6964285714285714rem' }}
                                >
                                  { watchContractEndTime === null ? 'Campo obligatorio' : errors?.contractEndTime.message }
                                </div>
                              </>
                            )
                        }
                      </Grid>
                      <Grid item xs={12} />
                    </>
                  )
                  : (
                    <>
                    </>
                  )
              }
            </Grid>
            <Box className={classes.submit}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="medium"
                className={classes.btnSubmit}
                disabled={disabledSubmit}
                setCustomerTimeOut={false}
              >
                {
                  !customerFlag ? 'Crear' : 'Actualizar'
                }
              </Button>
            </Box>
          </form>
        </Paper>

        {/* alerta informativa o error */}
        {
          licenseShowMessageAlert
          && (
            !customerTimeOut
            && (
              <AlertConfirm
                setOpenAlertInfo={setOpenAlertInfo}
                messageAlert={messageAlert}
              />
            )
          )
        }
      </Container>
    </>
  );
};

const mapStateToProps = (props) => ({
  userInfo: props.auth.userInfo,
  loading: props.license.loading,
  billingInfo: props.purchaseLicense.customerBillingInfo,
  customerServiceInfoLicense: props.license.customerServiceInfoLicense,
  licenseShowMessageAlert: props.general.showMessageAlert,
  documentTypes: props.general.documentTypes,
  statesAndCities: props.general.statesAndCities,
  applicationInfo: props.general.applicationInfo,
  appName: props.general.appName,
  citiesServGoogleAssistant: props.google.citiesServGoogleAssistant,
});

const mapDispatchToProps = {
  getPurchaseOrderParameters: getPurchaseOrderParametersAction,
  getCustomerInformationLicense: getCustomerInformationLicenseAction,
  getTokenExternalSource: getTokenExternalSourceAction,
  customersetMsgAlert: setMessageAlertAction,
  resetFormRegister: resetFormRegisterAction,
  saveCustomer: saveCustomerAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCustomer);
