/* eslint-disable react-hooks/exhaustive-deps  */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core/';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Popover from '@material-ui/core/Popover';
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SortIcon from '@material-ui/icons/Sort';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { setFiltersPageAction } from '../../../actions/general.action';
import CustomBasicAutoComplete from '../../form/Autocomplete/CustomBasicAutoComplete';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: 'white',
  },
  title: {
    color: '#F7FF00',
    fontWeight: 'bold',
  },
  titleOptionsFilter: {
    color: '#3580C0',
  },
  subtitle: {
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 10,
  },
  description: {
    fontWeight: 'bold',
  },
  checkOptionFilter: {
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#019BDD',
    },
  },
  buttonActionFilter: {
    border: '1px solid #565656',
    backgroundColor: '#FFFFFF',
    fontSize: '12px',
    '&:hover': {
      backgroundColor: '#E55200',
      color: '#FFFFFF',
      border: '1px solid #E55200',
    },
  },
  buttonApplyFilter: {
    border: '1px solid #565656',
    backgroundColor: '#1D71B8',
    fontSize: '12px',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#0083f2',
      color: '#FFFFFF',
      border: '1px solid #1D71B8',
    },
  },
  sortList: {
    height: '25px',
    '&:hover': {
      backgroundColor: '#009BDD',
      color: '#FFFFFF',
    },
  },
  containerFilters: {
    padding: '15px',
  },
  titleFilter: {
    fontSize: '24px',
    color: '#1D71B8',
  },
  searchTextField: {
    marginLeft: '5px',
    boxShadow: '0px 3px 2px #00000026',
    borderRadius: '10px',
    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
    border: '1px solid #D5D5D5',
    opacity: 1,
    backgroundColor: '#fff',
  },
}));

const Filter = (props) => {
  const classes = useStyles();
  const {
    setPlacement, placement, handleApplyFilter, optionsSearch, optionSearchSelected, handleSortBy,
    handleChangeOption, maxNumberRegistersSelected, limitTags, setOptionsFilter, optionsFilter,
    showExportButton, titleExportButton, handleViewReport,
  } = props;

  const {
    control, errors, setValue,
  } = useForm();

  // const {
  //   search
  // } = watch(['search']);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElSort, setAnchorElSort] = React.useState(null);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [openPopperSort, setOpenPopperSort] = React.useState(false);

  useEffect(() => {
    setValue('search', []);
  }, []);

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpenPopper((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleClickSort = (newPlacement) => (event) => {
    setAnchorElSort(event.currentTarget);
    setOpenPopperSort((prev) => placement !== newPlacement || !prev);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenPopper(false);
  };

  const handleCloseSort = () => {
    setAnchorElSort(null);
    setOpenPopperSort(false);
  };

  function CkeckFilter(propsCheck) {
    const { filter } = propsCheck;
    const [checked, setChecked] = React.useState(false);

    const handleChangeChecked = () => {
      setChecked(!checked);
      Object.values(optionsFilter).forEach((item) => {
        if (item.id === filter.id) {
          // eslint-disable-next-line no-param-reassign
          item.filtered = !filter.filtered;
        }
      });
      setOptionsFilter(optionsFilter);
    };

    return (
      <FormControlLabel
        control={(
          <Checkbox
            checked={filter.filtered}
            className={classes.checkOptionFilter}
            onChange={handleChangeChecked}
            name={filter.label}
            disabled={filter.disabled}
          />
        )}
        label={filter.label}
      />
    );
  }

  return (
    <Grid item xs container spacing={2}>
      <Grid item xs={12} sm={12} md={1} lg={1}>
        <Typography
          color="primary"
          component="span"
          variant="body1"
          className={classes.titleFilter}
        >
          Bolsas
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <CustomBasicAutoComplete
          name="search"
          options={optionsSearch}
          label="Empresa"
          maxNumberRegistersSelected={maxNumberRegistersSelected}
          setData={(e) => setValue('search', e)}
          registersSelected={optionSearchSelected}
          limitTags={limitTags}
          placeholder="Empresa"
          control={control}
          handleChangeOption={handleChangeOption}
          error={Object.keys(errors).length > 0}
          className={classes.searchTextField}
        />
      </Grid>
      <Grid item xs={3} sm={3} md={2} lg={1}>
        <Popover
          open={openPopper}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          PaperProps={{
            style: { width: '200px' },
          }}
        >
          <Grid item xs container className={classes.containerFilters}>
            <Grid item xs={12}>
              <Typography className={classes.titleOptionsFilter}>Productos o servicios</Typography>
            </Grid>
            {optionsFilter?.map((filter, index) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Grid key={index} item xs={12}>
                  <CkeckFilter
                    key={filter.id}
                    filter={filter}
                  />
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Button
                variant="contained"
                className={classes.buttonApplyFilter}
                size="medium"
                onClick={() => handleApplyFilter()}
                fullWidth
              >
                Aplicar filtro
              </Button>
            </Grid>
          </Grid>
        </Popover>
        <Button
          variant="contained"
          className={classes.buttonActionFilter}
          size="medium"
          onClick={handleClick('bottom')}
          startIcon={<FilterListIcon />}
          endIcon={<ArrowDropDownIcon />}
          style={{ width: '100px' }}
        >
          Filtrar
        </Button>
      </Grid>
      <Grid item xs={3} sm={3} md={2} lg={1}>
        <Popover
          open={openPopperSort}
          anchorEl={anchorElSort}
          onClose={handleCloseSort}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          PaperProps={{
            style: { width: '200px' },
          }}
        >
          <List component="nav">
            <ListItem button className={classes.sortList}>
              <ListItemText
                primary="A - Z"
                onClick={() => handleSortBy('a-z')}
              />
            </ListItem>
            <ListItem button className={classes.sortList}>
              <ListItemText
                primary="Z - A"
                onClick={() => handleSortBy('z-a')}
              />
            </ListItem>
            <ListItem button className={classes.sortList}>
              <ListItemText
                primary="Fecha más reciente"
                onClick={() => handleSortBy('desc')}
              />
            </ListItem>
            <ListItem button className={classes.sortList}>
              <ListItemText
                primary="Fecha más antigua"
                onClick={() => handleSortBy('asc')}
              />
            </ListItem>
          </List>
        </Popover>
        <Button
          variant="contained"
          className={classes.buttonActionFilter}
          size="medium"
          onClick={handleClickSort('bottom')}
          startIcon={<SortIcon />}
          endIcon={<ArrowDropDownIcon />}
          style={{ width: '110px', marginLeft: 10 }}
        >
          Ordenar
        </Button>
      </Grid>
      <Grid item xs={3} sm={3} md={2} lg={1}>
        {
          showExportButton && (
            <Button
              variant="contained"
              className={classes.buttonActionFilter}
              size="medium"
              onClick={() => handleViewReport('GeneralConsumption')}
              startIcon={<OpenInNewIcon />}
              style={{ width: '180px', marginLeft: 30 }}
            >
              {titleExportButton || 'Exportar'}
            </Button>
          )
        }
      </Grid>
    </Grid>
  );
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = {
  setFiltersPage: setFiltersPageAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
