import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import PackageTable from './PackageTable';
import ModalPackage from './ModalPackage';
import { getPackagesAction } from '../../../../actions/packets.action';
import newButton from '../../../../assets/img/iconos/ibuhoo/newButton.svg';
import searchIcon from '../../../../assets/img/iconos/ibuhoo/searchIcon.svg';

const useStyles = makeStyles({
  paper: {
    padding: '5px',
    marginTop: '20px',
    opacity: '0.95',
    borderRadius: 10,
  },
  boxTop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  searchTextField: {
    boxShadow: '0px 3px 2px #00000026',
    width: '504px',
    borderRadius: '10px',
    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
    border: '1px solid #D5D5D5',
    opacity: 1,
    height: '33px',
  },
  title: {
    fontWeight: 600,
    paddingLeft: '15px',
  },
  mat_icon: {
    height: 32,
    width: 32,
  },
  searchIcon: {
    paddingLeft: '10px',
  },
});

const CreatePackage = (props) => {
  const {
    packages, getPackages,
  } = props;

  const classes = useStyles();
  const [statusModal, setStatusModal] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [packageSelected, setPackageSelected] = useState(null);
  const [enteredFilter, setEnteredFilter] = useState('');

  useEffect(() => {
    getPackages();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Box className={classes.boxTop}>
        <TextField
          className={classes.searchTextField}
          value={enteredFilter}
          autoFocus
          onChange={(e) => setEnteredFilter(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={searchIcon} alt="searchIcon" className={classes.searchIcon} />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
          placeholder="Buscar"
        />
        <Tooltip title="Crear paquete" aria-label="Nuevo paquete">
          <IconButton
            onClick={() => { setIsNew(true); setStatusModal(true); }}
            style={{ fontSize: '20px', float: 'right' }}
            edge="end"
            size="small"
          >
            <img src={newButton} alt="newButton" />
          </IconButton>
        </Tooltip>
      </Box>
      <PackageTable
        setPackageSelected={setPackageSelected}
        setStatusModal={setStatusModal}
        enteredFilter={enteredFilter}
        packageSelected={packageSelected}
        packagesList={packages}
        setIsNew={setIsNew}
      />

      <ModalPackage
        isOpen={statusModal}
        onClose={() => setStatusModal(false)}
        item={packageSelected}
        setPackageSelected={setPackageSelected}
        isNew={isNew}
        packages={packages}
      />
    </>
  );
};

const mapStateToProps = ({ packets }) => ({
  packages: packets.packages,
  packagesRows: packets.packagesRows,
});

const mapDispatchToProps = {
  getPackages: getPackagesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePackage);
