/* eslint-disable no-nested-ternary */
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ReportIcon from '@material-ui/icons/Report';

const useStyles = makeStyles((theme) => ({
  tableHead: {
    width: '828px',
    height: '40px',
    opacity: 1,
  },
  table: {
    minWidth: 750,
    width: '90%',
    margin: 'auto',
  },
  tableLicense: {
    minWidth: 750,
    width: '100%',
    margin: 'auto',
  },
  tableCellHead: {
    fontWeight: 'bold',
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: 'white',
    },
    '&:first-child': {
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
    },
    '&:last-child': {
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
    },
    ...theme.miplan.tablePlanTable,
  },
  tableCell: {
    height: '40px',
    letterSpacing: '0px',
    fontSize: 13,
    '&:last-child': {
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
    },
    '&:first-child': {
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
    },
    ...theme.miplan.tableCellPlanTable,
  },
  container: {
  },
  containerLicense: {
    width: '98%',
  },
  tableSortLabel: {
    color: 'white',
    '&:hover': {
      color: 'white',
    },
  },
  columnStyleParent: {
    fontWeight: 700,
    fontSize: 14,
  },
  spanPlanTable: {
    ...theme.miplan.spanPlanTable,
  },
}));

const WarningIcon = withStyles((theme) => ({
  root: {
    ...theme.miplan.reportIcon,
  },
}))(ReportIcon);

const PlanTable = (props) => {
  const classes = useStyles();
  const {
    columns, data, appName,
  } = props;

  function EnhancedTableHead() {
    return (
      <TableHead className={classes.tableHead}>
        <TableRow>
          {columns.map((headCell) => (
            <TableCell
              className={classes.tableCellHead}
              key={headCell.id}
            >
              <TableSortLabel
                hideSortIcon={!headCell.isSortable}
              >
                <Typography classes={{
                  body1: classes.columnStyleParent,
                }}
                >
                  {headCell.label}
                </Typography>
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  function Row(props) {
    const { row } = props;
    return (
      <>
        {appName !== 'license' && (
          <br />
        )}
        <TableRow
          hover
          role="checkbox"
          tabIndex={-1}
          key={row.id}
          className={classes.rowCell}
        >
          {columns.map((column) => {
            const value = row[column.id];
            return (
              <TableCell
                className={classes.tableCell}
                key={column.id}
                size="small"
              >
                {column.id === 'expirationDate' ? row.isNearToExpire === true ? (
                  <>
                    <>
                      {value}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <WarningIcon style={{ marginBottom: '-7px' }} />
                      &nbsp;&nbsp;
                      <span className={classes.spanPlanTable}>Proximo a vencer</span>
                    </>
                  </>
                ) : value : value}
              </TableCell>
            );
          })}
        </TableRow>
      </>
    );
  }

  return (
    <>
      <TableContainer className={appName === 'license' ? classes.containerLicense : classes.container}>
        <Table
          stickyHeader
          className={appName === 'license' ? classes.tableLicense : classes.table}
          aria-labelledby="tableTitle"
          size="small"
          aria-label="customized table"
          style={{ tableLayout: 'fixed' }}
        >
          <EnhancedTableHead />
          <TableBody>
            {data.map((row) => (
              <Row key={row.packageCode} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
    </>
  );
};

export default PlanTable;
