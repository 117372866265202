import axios from 'axios';
import {
  LOADING,
  GET_PACKAGES,
  UPDATE_CART,
  STATUS_MODALCART,
  STATUS_MODALCART_EXT,
  SET_PROMO_CODE,
  GET_MY_PLANS,
  STATUS_MODALPACKAGES,
  GET_PACKAGESBYFEATURE,
  GET_PRICE_BY_LICENSE_TYPE,
  GET_QUANTITY_LICENSE,
  HOME_SHOW_CART,
  CLEAR_ALL_CART,
  GET_DETAIL_EMPLOYEE,
  SET_DETAIL_EMPLOYEE,
  GET_PACKAGE_BY_ENCRYPTED_PARAMETER,
} from '../types/home.type';
import { API_LICENCIAMIENTO } from '../config/config';
import { defaultHeaders } from '../helpers/generalUtils';
import { CleanCart } from '../helpers/cartUtils';

import {
  getPriceLicenseByQuantityAction,
} from './localStorage.action';
import { getLocalStorageEncript } from '../helpers/storageUtils';
import expiredLogoFirmaya from '../assets/img/iconos/firmaya/ExpiredPlan.svg';
import { setMessageAlertAction } from './purchaseLicense.action';

export const addToCartAction = (data, enqueueSnackbar, showCart) => (
  dispatch,
  getState,
) => {
  const {
    home: { cart },
    general: { appName },
  } = getState();
  dispatch({ type: HOME_SHOW_CART, payload: true });

  if (
    data.packagePrice === 0
    && cart.filter((item) => item.packagePrice === 0).length > 0
  ) {
    enqueueSnackbar('Sólo puedes escoger un paquete gratis.', {
      variant: 'warning',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    });
  } else {
    let cartAux = [];
    let isValid = true;
    const packFree = cart.filter((item) => item.packageType.packageTypeCode === 'BAS' && item.packagePrice === 0);
    const packBase = cart.filter((item) => item.packageType.packageTypeCode === 'BAS' && item.packageId !== data.packageId);

    // Paquete Free no puede adicionar complementarios ni combo
    if (packFree.length > 0 && data.packageType.packageTypeCode !== 'BAS') {
      isValid = false;
      enqueueSnackbar('¡El plan gratuito no puede adquirir paquetes adicionales!', {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });

      if (showCart) { dispatch({ type: HOME_SHOW_CART, payload: showCart }); }
    }

    // Solo adicionar un paquete base;
    if (packBase.length >= 1 && data.packageType.packageTypeCode === 'BAS' && (appName !== 'colnomina' && appName !== 'bemyself')) {
      cartAux = cart.filter((item) => item.packageType.packageTypeCode !== 'BAS');
    } else {
      cartAux = cart;
    }
    // Paquete Free no puede agregar paquetes adicionales
    if (data.packageType.packageTypeCode === 'BAS' && data.packagePrice === 0) {
      cartAux = [];
    }
    if (isValid) {
      cartAux.push(data);
      const cartUpdated = Object.assign([], cartAux);
      if (cartUpdated.length > 0) {
        dispatch({ type: CLEAR_ALL_CART, payload: false });
      }
      dispatch({ type: UPDATE_CART, payload: cartUpdated });
    }
  }
};

export const setStatusCart = (data) => (dispatch) => {
  const storeLanding = getLocalStorageEncript('storeLanding');
  if (storeLanding) {
    dispatch({ type: STATUS_MODALCART_EXT, payload: data });
  } else {
    dispatch({ type: STATUS_MODALCART, payload: data });
  }
};

export const directPurchaseAction = (data, enqueueSnackbar) => (dispatch) => {
  dispatch(addToCartAction(data, enqueueSnackbar));
  dispatch(setStatusCart(true));
};

export const getPackagesAction = (appName, packageId, sendToken = true) => async (dispatch) => {
  try {
    let config = null;
    if (sendToken) config = defaultHeaders();
    dispatch({ type: LOADING, payload: true });
    const response = await axios.get(
      `${API_LICENCIAMIENTO}/license/api/package/GetPackagesByServiceName/${appName}`, config,
    );
    if (response.status === 200) {
      dispatch({ type: GET_PACKAGES, payload: response.data.result.records });
      dispatch({ type: LOADING, payload: false });
      if (packageId) {
        const packageFlat = Object.values(response.data.result.records).flat(1);
        const uniquePackages = [...new Set(packageFlat)];
        const packageToAdd = uniquePackages.find(
          (pkg) => pkg.packageId === Number(packageId),
        );
        dispatch(directPurchaseAction(packageToAdd));
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteOfCartAction = (packageId, allItems) => (
  dispatch,
  getState,
) => {
  const {
    home: { cart },
  } = getState();
  let cartUpdated;
  if (allItems) { cartUpdated = cart.filter((item) => item.packageId !== packageId); } else {
    cart.splice(
      cart.findIndex((item) => item.packageId === packageId),
      1,
    );
    cartUpdated = Object.assign([], cart);
  }
  if (cartUpdated.length === 0) {
    dispatch({ type: CLEAR_ALL_CART, payload: true });
  }
  dispatch({ type: UPDATE_CART, payload: cartUpdated });
};

export const applyPromotionCodeAction = (
  data,
  setLoading,
  enqueueSnackbar,
  showAlert = true,
) => async (dispatch, getState) => {
  try {
    const {
      home: { promoCode, cart },
      auth: { userInfo },
    } = getState();

    if (cart.filter((item) => item.packagePrice === 0).length > 0
    ) {
      enqueueSnackbar('No se puede aplicar el código promocional al paquete seleccionado.', {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
      return;
    }

    if (promoCode && promoCode.promotionCode === data.PromotionCode) return;
    if (setLoading) setLoading(true);

    const cartClean = CleanCart(cart);
    const itemsCarts = [];
    let customerRegistered = null;
    cartClean.forEach((item) => itemsCarts.push({
      packageId: item.id,
      packageCode: '',
      packageName: item.name,
      packagePrice: item.price,
      unitPrice: item.price,
      quantity: item.number,
      discount: 0,
      discountDescription: '',
      taxIva: item.taxiva,
      subtotal: 0,
      quantityEmployees: item.quantityEmployees,
    }));

    if (userInfo) {
      customerRegistered = {
        documentType: userInfo.customer_type,
        documentNumber: userInfo.customer_value,
      };
    }

    const validPromoCode = promoCode ? promoCode.validPromoCodes : [];
    const code = data.PromotionCode;
    const promotionCodeRequest = {
      promoCode: code,
      ValidPromoCodes: validPromoCode,
      PackagesInCart: itemsCarts,
      customerRegistered,
    };
    const config = defaultHeaders();
    const response = await axios.post(
      `${API_LICENCIAMIENTO}/license/api/PurchaseOrderLicense/ValidatePromotionCode`,
      promotionCodeRequest, config,
    );
    if (response.status === 200) {
      if (showAlert) {
        if (enqueueSnackbar) {
          enqueueSnackbar('El código promocional ha sido aplicado a su compra.', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
          });
        }
      }
      dispatch({ type: SET_PROMO_CODE, payload: response.data.result.records });
      if (setLoading) setLoading(false);
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 400) {
        dispatch({ type: LOADING, payload: false });
        if (enqueueSnackbar) {
          enqueueSnackbar('No se puede aplicar el código promocional al paquete seleccionado.', {
            variant: 'warning',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
          });
        }
        if (setLoading) setLoading(false);
      }
    }
  }
};

export const getPackageBaseCustomerAction = (
  data,
  setLoading,
  onClose,
  enqueueSnackbar,
  history,
  urlRedirect,
) => async (dispatch, getState) => {
  try {
    setLoading(true);
    const {
      home: { cart },
    } = getState();
    let isValid = true;
    const config = defaultHeaders();
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/license/GetPackageBaseCustomer`, data, config);

    if (response.data.result.records.length === 0) {
      const cartBase = cart.filter((item) => item.packageType.packageTypeCode === 'BAS');
      if (cartBase.length === 0) {
        dispatch({ type: LOADING, payload: false });
        enqueueSnackbar('Para continuar la compra, debes primero seleccionar un paquete base.', {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
        isValid = false;
      } else {
        isValid = true;
      }
    } else {
      const packages = response.data.result.records;
      if (packages.length > 0) {
        const cartComplementary = cart.filter((item) => item.packageType.packageTypeCode === 'CMB'
          || item.packageType.packageTypeCode === 'CMP');
        const cartBase = cart.filter((item) => item.packageType.packageTypeCode === 'BAS');
        const baseFree = packages.filter((p) => p.package.packageTypeId === 1
          && p.package.packagePrice === 0);
        const basePaid = packages.filter((p) => p.package.packageTypeId === 1
          && p.package.packagePrice > 0);
        if (baseFree.length > 0 > 0
          && basePaid.length === 0
          && cartBase.length === 0
          && cartComplementary.length > 0) {
          dispatch({ type: LOADING, payload: false });
          enqueueSnackbar('No es posible adquirir este paquete con un paquete gratis, debes primero seleccionar un paquete base', {
            variant: 'warning',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
          });
          isValid = false;
        }
      }
    }
    onClose();
    if (isValid) {
      if (urlRedirect) {
        window.parent.location.href = urlRedirect;
      } else {
        history.push('/registerPurchaseOrder');
      }
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    enqueueSnackbar('Error de servidor', {
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    });
    setLoading(false);
  }
};

export const getPackagesAddManualLicenseAction = (serviceId) => async (dispatch, getState) => {
  try {
    const { service: { services } } = getState();
    if (services) {
      const service = services.find((x) => x.id === serviceId);
      dispatch(
        getPackagesAction(service.serviceName.toLowerCase(), 0, false),
      );
    }
  } catch (error) {
    console.error(error);
  }
};

export const setStatusModalPackage = (data) => (dispatch) => {
  dispatch({ type: STATUS_MODALPACKAGES, payload: data });
};

export const getPackagesByFeatureAction = (
  appName,
  serviceFeature,
  enqueueSnackbar,
) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const config = defaultHeaders();
    const response = await axios.get(
      `${API_LICENCIAMIENTO}/license/api/package/GetPackagesByServiceNameAndFeatureCode/${appName}/${serviceFeature}`, config,
    );
    if (response.status === 200) {
      dispatch({ type: GET_PACKAGESBYFEATURE, payload: response.data.result.records });
      dispatch({ type: LOADING, payload: false });
    }
  } catch (error) {
    const { response } = error;
    if (response.data.statusCode === 422) {
      enqueueSnackbar(response.data.statusMessage, {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    } else {
      enqueueSnackbar('No es posible obtener la informacion', {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
    dispatch(setStatusModalPackage(false));
    dispatch({ type: LOADING, payload: false });
  }
};

export const directModalPackageAction = (appName, serviceFeature) => (dispatch) => {
  dispatch(getPackagesByFeatureAction(appName, serviceFeature));
  dispatch(setStatusModalPackage(true));
};

export const getMyPlanAction = (data, enqueueSnackbar, serviceFeature) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const config = defaultHeaders();
    const response = await axios.post(
      `${API_LICENCIAMIENTO}/license/api/License/GetDetailLicenseCustomerMyPlan`,
      data, config,
    );
    if (response.status === 200) {
      dispatch({ type: GET_MY_PLANS, payload: response.data.result.records });
      dispatch({ type: LOADING, payload: false });
      if (serviceFeature !== undefined) {
        dispatch(directModalPackageAction(data.ServiceName, serviceFeature));
      }
    }
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error.response) {
      if (error.response.status === 422) {
        dispatch({ type: GET_MY_PLANS, payload: error.response.data });
      } else {
        enqueueSnackbar('Error de servidor', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    }
  }
};

export const getPriceByLicenseTypeAction = (type) => async (dispatch) => {
  try {
    const config = defaultHeaders();
    Object.assign(config.headers, {
      pType: type || '',
    });
    const response = await axios.get(
      `${API_LICENCIAMIENTO}/license/api/license/GetPriceByLicenseType/`, config,
    );
    if (response.status === 200) {
      const quantity = response.data.result.map((item) => {
        return {
          value: item.quantity.toString(),
          label: item.quantity.toString(),
          type: item.type,
        };
      });
      dispatch({ type: GET_QUANTITY_LICENSE, payload: quantity });
      dispatch({ type: GET_PRICE_BY_LICENSE_TYPE, payload: response.data.result });
    }
  } catch (error) {
    console.error(error);
  }
};

export const UpdateQuantityCartAction = (data) => (
  dispatch,
  getState,
) => {
  const {
    home: { cart },
  } = getState();

  const cartAux = Object.assign([], cart);
  cartAux.splice(
    cartAux.findIndex((item) => item.packageId === data.packageId),
    1,
  );

  cartAux.push(data);
  const cartUpdated = Object.assign([], cartAux);
  dispatch({ type: UPDATE_CART, payload: cartUpdated });
};

export const UpdateQuantityPriceCartAction = (data, type) => (
  dispatch,
  getState,
) => {
  const {
    home: { cart },
  } = getState();

  const price = getPriceLicenseByQuantityAction(type, data);

  const cartAux = Object.assign([], cart);
  cartAux.splice(
    cartAux.findIndex((item) => item.packageId === data.packageId),
    1,
  );
  cartAux.packagePrice = price;
  cartAux.packageUnitPrice = price;
  cartAux.packagePriceWithDiscount = price;

  cartAux.push(data);
  const cartUpdated = Object.assign([], cartAux);
  dispatch({ type: UPDATE_CART, payload: cartUpdated });
};

export const setShowCartAction = (visible) => (dispatch) => {
  dispatch({ type: HOME_SHOW_CART, payload: visible });
};

export const getDetailEmployeeAction = (data) => async (dispatch) => {
  try {
    const array = [];
    const config = defaultHeaders();
    const response = await axios.post(
      `${API_LICENCIAMIENTO}/license/api/License/GetDetailLicenseEmployeeMyPlan`,
      data, config,
    );
    if (response.status === 200) {
      response.data.result.records.map((item, index) => array.push({
        key: index,
        value: `${item.nameMonth.toString()}/${item.year.toString()}`,
        label: `${item.nameMonth.toString()}/${item.year.toString()}`,
        month: item.month,
        year: item.year,
      }));
      dispatch({ type: GET_DETAIL_EMPLOYEE, payload: array });
      dispatch({ type: LOADING, payload: false });
    }
  } catch (error) {
    console.error(error);
  }
};

export const setDetailEmployeeAction = (data) => async (dispatch) => {
  try {
    const config = defaultHeaders();
    const response = await axios.post(
      `${API_LICENCIAMIENTO}/license/api/License/SetDetailLicenseEmployeeMyPlan`,
      data, config,
    );
    if (response.status === 200) {
      dispatch({ type: SET_DETAIL_EMPLOYEE, payload: response.data.result.records });
      dispatch({ type: LOADING, payload: false });
    }
  } catch (error) {
    console.error(error);
  }
};

export const getInfoPackageByEncryptedParameterAction = (data) => async (dispatch) => {
  try {
    const config = defaultHeaders();
    dispatch({ type: LOADING, payload: true });
    const dataEncrypted = {
      package: data,
    };
    const response = await axios.post(
      `${API_LICENCIAMIENTO}/license/api/Package/DecryptPackage`,
      dataEncrypted, config,
    );
    if (response.status === 200) {
      dispatch({ type: GET_PACKAGE_BY_ENCRYPTED_PARAMETER, payload: response.data.result.records, isErrorGetPackage: false });
      dispatch({ type: LOADING, payload: false });
    }
  } catch (error) {
    console.error(error.response);
    dispatch({ type: GET_PACKAGE_BY_ENCRYPTED_PARAMETER, payload: {}, isErrorGetPackage: true });
    dispatch({ type: LOADING, payload: false });
    dispatch(
      setMessageAlertAction({
        firstImage: expiredLogoFirmaya,
        firstMessage: '¡Ocurrió un inconveniente!',
        secondMessage: 'No hemos podido procesar la solicitud, por favor inténtalo más tarde.',
        textInButton: 'Entendido',
      }),
    );
  }
};
