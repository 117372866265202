export const PURCHASE_TYPE = {
  Pending: 5,
  Approved: 6,
  Rejected: 7,
};

export const DISCOUNT_TYPE = {
  Value: 'VLR',
  Percentage: 'PTJ',
};
