export const LICENSE_LOADING = 'LICENSE_LOADING';
export const GET_LICENSES_CUSTOMER = 'GET_LICENSES_CUSTOMER';
export const GET_DETAIL_LICENSES_CUSTOMER = 'GET_DETAIL_LICENSES_CUSTOMER';
export const SET_CHIPS_FILTERS_LICENSES = 'SET_CHIPS_FILTERS_LICENSES';
export const CHANGE_CHIPS_FILTERS_LICENSES = 'CHANGE_CHIPS_FILTERS_LICENSES';
export const CLEAR_LICENSES_CUSTOMER = 'CLEAR_LICENSES_CUSTOMER';
export const ADD_LICENSE_CUSTOMER = 'ADD_LICENSE_CUSTOMER';
export const LICENSE_SHOW_MESSAGE = 'LICENSE_SHOW_MESSAGE';
export const GET_CUSTOMER_INFORMATION_LICENSE = 'GET_CUSTOMER_INFORMATION_LICENSE';
export const GET_LICENSE_INFORMATION = 'GET_LICENSE_INFORMATION';
