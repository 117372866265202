import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.switchBase.main,
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.switchBase.checked,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.switchBase.selectTrack,
        borderColor: theme.switchBase.selectTrack,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.switchBase.main}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.switchBase.track,
  },
  checked: {},
}))(Switch);

const SwitchiOS = ({
  check, handleChange, lblOff, lblOn, ...other
}) => (
  <FormGroup>
    <Typography component="div">
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>{lblOff}</Grid>
        <Grid item>
          <AntSwitch onChange={handleChange} name="checkedC" {...other} />
        </Grid>
        <Grid item>{lblOn}</Grid>
      </Grid>
    </Typography>
  </FormGroup>
);
export default SwitchiOS;
