import React from 'react';
import { Controller } from 'react-hook-form';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const CustomCheck = (props) => {
  const {
    label, name, control, rule, handleOnChangeCkecked, error,
    className, classesFormControlLabel, size, defaultValue, disabled,
  } = props;

  return (
    <FormControl fullWidth>
      <FormControlLabel
        control={(
          <Controller
            render={(props) => (handleOnChangeCkecked !== undefined
              ? (
                <Checkbox
                  color="primary"
                  className={className}
                  disabled={disabled}
                  onChange={(e) => {
                    handleOnChangeCkecked(props.onChange(e.target.checked));
                  }}
                  checked={props.value}
                  size={size}
                />
              )
              : (
                <Checkbox
                  color="primary"
                  className={className}
                  disabled={disabled}
                  onChange={(e) => {
                    props.onChange(e.target.checked);
                  }}
                  checked={props.value}
                  size={size}
                />
              ))}
            name={name}
            control={control}
            rules={rule}
            error={Boolean(error[name])}
            defaultValue={defaultValue}
          />
            )}
        key={name}
        label={label}
        classes={classesFormControlLabel}
      />
    </FormControl>
  );
};

export default CustomCheck;
