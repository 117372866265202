/* eslint-disable no-param-reassign, array-callback-return, react-hooks/exhaustive-deps, no-unused-vars  */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core/';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Chip from '@material-ui/core/Chip';
import AddCircleOutlineTwoToneIcon from '@material-ui/icons/AddCircleOutlineTwoTone';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CustomTableCollapse from '../../../components/form/Table/CustomTableCollapse';
import { isValidTokenAdmin } from '../../../helpers/authUtils';
import {
  getDetailLicensesCustomerAction,
  clearLicensesCustomerAction,
  setFiltersLicenseAction,
} from '../../../actions/license.action';
import { getTokenExternalSourceAction } from '../../../actions/general.action';
import { getCustomerLicensedAction } from '../../../actions/packets.action';
import { getServicesAction } from '../../../actions/service.action';
import Filter from '../../../components/application/Filter';
import AddNewLicense from '../../../components/application/AddNewLicense';

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  cardContent: {
    background: '#0000000D 0% 0% no-repeat padding-box',
    borderRadius: '10px',
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconRadio: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#16B1F3',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#16B1F3',
    },
  },
  expandIcon: {
    '& .MuiSvgIcon-root': {
      fontSize: 33,
    },
  },
  iconSeeMore: {
    color: '#009fe3',
    fontFamily: 'Muli',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '18px',
    height: '18px',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  textFieldCustom: {
    width: '220px',
  },
  dateCustom: {
    width: '270px',
    '& .MuiIconButton-root': {
      color: '#009fe3',
    },
  },
  paperCard: {
    backgroundColor: '#0000000D',
  },
  button: {
    marginTop: 10,
    color: '#fff',
    minWidth: 130,
    borderRadius: 10,
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: '#FCEAE0',
    color: '#E55200',
    '& .MuiChip-deleteIcon': {
      color: '#E55200',
    },
  },
  gridTable: {
    margin: 10,
  },
  tableContainer: {
    borderRadius: '10px',
  },
  tableroot: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  table: {
    margin: 10,
  },
  tableHead: {
    width: '828px',
    height: '40px',
    background: 'var(--unnamed-color-009bdd) 0% 0% no-repeat padding-box',
    borderRadius: '10px',
    opacity: 1,
    color: '#FFF',
    backgroundColor: '#009BDD',
  },
  tableCell: {
    textAlign: 'center',
    letterSpacing: '0px',
    color: '#575756',
    opacity: 1,
    '&:last-child': {
      borderBottomRightRadius: '10px',
    },
    '&:first-child': {
      borderBottomLeftRadius: '10px',
    },
  },
  balancePlans: {
    flexDirection: 'row-reverse',
  },
  groupContainer: {
    border: '2px solid #9DD8F2',
    marginTop: '10px',
    marginBottom: '10px',
    borderRadius: 12,
  },
  gridFeature: {
    maxHeight: '150px',
    marginBottom: '5px',
  },
  cardFeatureBalance: {
    borderRadius: 12,
    backgroundColor: '#F6F6F6',
    margin: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '35px',
    boxShadow: '2px 2px 2px -2px rgba(75,75,75,0.34)',
    minHeight: '150px',
    maxHeight: '150px',
  },
  boxFeatures: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'flex-end',
  },
  titleFeature: {
    color: '#E97839',
    fontSize: '13px',
    fontWeight: 600,
  },
  UseFeature: {
    fontSize: '12px',
  },
  txFeature: {
    fontSize: '12px',
    fontWeight: 600,
    color: '#575756',
  },
  customerName: {
    color: '#4A8DC5',
    fontWeight: 700,
  },
  customerInfo: {
    color: '#565656',
    fontSize: '12px',
  },
  serviceName: {
    color: '#E55200',
    fontSize: '13px',
    fontWeight: 700,
  },
  badgeStatus: {
    top: '-3px',
  },
  iconActiveLicense: {
    color: '#009838',
    fontSize: 15,
  },
  statusActiveLicense: {
    paddingLeft: '45px',
    color: '#009838',
    fontSize: 13,
    top: '9px',
  },
  iconInactiveLicense: {
    color: '#D91637',
    fontSize: 15,
  },
  statusInactiveLicense: {
    paddingLeft: '50px',
    color: '#D91637',
    fontSize: 13,
    top: '9px',
  },
  viewReportFeature: {
    color: '#009BDD',
  },
  addLicenseContainer: {
    backgroundColor: '#F7F7F7',
    padding: 15,
    borderRadius: 15,
    border: '1px solid #dcd9d9',
  },
  btnAddNewLicense: {
    border: '1px solid #E55200',
    backgroundColor: '#fff',
    color: '#E55200',
    marginBottom: '15px',
  },
  rowCell: {
    height: '50px',
  },
  disabledRow: {
    opacity: 0.4,
    height: 20,
  },
  tamIcon: {
    transform: 'scale(0.85)',
  },
}));

const ModalValidLicenses = (props) => {
  const {
    onCloseModal, open, licenseSelected, detailLicenses,
    getDetailLicensesCustomer, getTokenExternalSource, clearLicensesCustomer,
    chipsFiltersOptions, setFiltersLicense, getCustomerLicensed, customerLicensedCombo,
    servicesCombo, getServices, setConfirmCancelAlert, confirmCancelAlert,
  } = props;

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [customerSelected, setCustomerSelected] = useState({});
  const [optionsFilter, setOptionsFilter] = useState([]);
  const [placement, setPlacement] = useState();
  const [showAddLicense, setShowAddLicense] = useState(false);

  const modifyChipFilterPage = () => {
    setFiltersLicense('Service', null, null, optionsFilter);
  };

  const modifyCustomerChipFilterPage = (customer) => {
    setFiltersLicense('Customer', null, customer, null);
  };

  const handleViewGeneralReport = (id) => {
    // To DO ver reporte general de consumo Kibana
    console.log(id);
  };

  const handleViewDetailFeature = (feature) => {
    if (feature) {
      const DocumentNumber = detailLicenses[0].customerInfo.documentNumber;
      const DocumentType = detailLicenses[0].customerInfo.documentType;
      const { claimCreate, claimDelete } = feature;
      window.open(`/license/reports/reportView?reportType=KibanaConsumptionReport&documentType=${DocumentType}&documentNumber=${DocumentNumber}&claimCreate=${claimCreate}&claimDelete=${claimDelete}`, '_blank');
    }
  };

  const getLicensesByFilter = (sortType) => {
    const filters = [];
    const customer = chipsFiltersOptions.find((c) => c.type === 'Customer');
    if (customer) {
      filters.push({
        type: 'Customer',
        value: customer.id,
      });
    }

    Object.values(optionsFilter).forEach((filter) => {
      if (filter.filtered === true) {
        filters.push({
          type: 'Service',
          value: filter.id,
        });
      }
    });

    const licenseRequest = {
      purchaseOrderLicenseId: licenseSelected,
      filters,
      sortType,
    };
    getDetailLicensesCustomer(licenseRequest, false, enqueueSnackbar);
  };

  const setFilterService = () => {
    const servicesOptionFilter = [];
    servicesCombo.map((item) => {
      const detailFeatureRow = {
        id: item.value,
        label: item.label,
        filtered: false,
        disabled: false,
      };
      servicesOptionFilter.push(detailFeatureRow);
    });
    setOptionsFilter(servicesOptionFilter);
  };

  function createIconViewGeneralReport(id) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <IconButton
          edge="end"
          aria-label="Editar"
          size="small"
          onClick={() => handleViewGeneralReport(id)}
          disabled
          style={{ paddingLeft: 10, display: 'none' }}
        >
          <Tooltip title="Reporte general" aria-label="add" placement="top">
            <AssignmentIcon fontSize="small" className={classes.viewReportFeature} />
          </Tooltip>
        </IconButton>
      </Box>
    );
  }

  function createIconViewReportElement(feature) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <IconButton
          edge="end"
          aria-label="Editar"
          size="small"
          onClick={() => handleViewDetailFeature(feature)}
          disabled={feature.featureCategoryType !== 'T'}
          style={{ paddingLeft: 10 }}
        >
          <Tooltip title="Ver detalle de consumo" aria-label="add" placement="top">
            <VisibilityIcon fontSize="small" className={classes.viewReportFeature} />
          </Tooltip>
        </IconButton>
      </Box>
    );
  }

  function mapLicenses(licenses) {
    const licensesInfo = [];
    licenses.map((item) => {
      item.serviceLicense[0].licensePlan.map((license) => {
        const datailPlanLicense = [];
        license.balanceFeaturesLicense.map((feature) => {
          const detailFeatureRow = {
            id: feature.id,
            feature: feature.featureName,
            totalPlan: feature.totalPlan,
            totalUsed: feature.totalUsed,
            totalAvailable: feature.totalAvailable,
            lastConsumptionDate: moment().format('DD/MM/yyyy'),

          };
          datailPlanLicense.push(detailFeatureRow);
        });
        const licenseRow = {
          packageName: license.packageName,
          effectiveDate: format(new Date(license.effectiveDate), 'dd-MM-yyyy'),
          expirationDate: format(new Date(license.expirationDate), 'dd-MM-yyyy'),
          purchaseDate: format(new Date(license.purchaseDate), 'dd-MM-yyyy hh:mm:ss aaaa'),
          id: license.purchaseOrderLicenseId,
          rowsTableCollapse: datailPlanLicense,
        };
        licensesInfo.push(licenseRow);
      });
    });
  }

  function mapLicensesService(licenseService, customer) {
    const licensesInfo = [];
    licenseService.licensePlan.map((license) => {
      const datailPlanLicense = [];
      license.balanceFeaturesLicense.map((feature) => {
        const detailFeatureRow = {
          id: feature.id,
          feature: feature.featureName,
          totalPlan: feature.featureCategoryType === 'F'
            ? '-'
            : feature.totalPlan,
          totalUsed: feature.featureCategoryType === 'F'
            ? '-'
            : feature.totalUsed,
          totalAvailable: feature.featureCategoryType === 'F'
            ? '-'
            : feature.totalAvailable,
          lastConsumptionDate: format(new Date(feature.lastUseDate), 'dd/MM/yyyy'),
          viewDetailReport: createIconViewReportElement(feature),
        };
        datailPlanLicense.push(detailFeatureRow);
      });
      const licenseRow = {
        packageName: license.packageName,
        effectiveDate: format(new Date(license.effectiveDate), 'dd/MM/yyyy hh:mm:ss aaaa'),
        expirationDate: format(new Date(license.expirationDate), 'dd/MM/yyyy hh:mm:ss aaaa'),
        purchaseDate: format(new Date(license.purchaseDate), 'dd/MM/yyyy hh:mm:ss aaaa'),
        id: license.purchaseOrderLicenseId,
        viewGeneralReport: createIconViewGeneralReport(license.purchaseOrderLicenseId),
        rowsTableCollapse: datailPlanLicense,
      };
      licensesInfo.push(licenseRow);
    });
    return licensesInfo;
  }

  useEffect(() => {
    getServices();
    getCustomerLicensed(null, null, '', true, enqueueSnackbar);
  }, []);

  useEffect(() => {
    const tokenAdmin = localStorage.getItem('tokenAdminIbuho');
    if (tokenAdmin && isValidTokenAdmin(tokenAdmin)) {
      if (licenseSelected && detailLicenses.length === 0 && servicesCombo.length > 0) {
        const filters = [];
        const licenseRequest = {
          purchaseOrderLicenseId: licenseSelected,
          filters,
        };
        getDetailLicensesCustomer(licenseRequest, true, enqueueSnackbar);
      }
    } else {
      getTokenExternalSource();
    }
  }, [licenseSelected, servicesCombo]);

  useEffect(() => {
    if (servicesCombo) {
      setFilterService();
    }
  }, [servicesCombo]);

  useEffect(() => {
    //let customer = null;
    if (detailLicenses.length > 0) {
      mapLicenses(detailLicenses);
      detailLicenses.forEach((item) => {
        //customer = customerSelected?.id ? null : item.customerInfo;
      });
    }
  }, [detailLicenses]);

  useEffect(() => {
    if (chipsFiltersOptions.length > 0 && optionsFilter.length > 0) {
      const newOptionsFilter = [];
      Object.values(optionsFilter).forEach((item) => {
        const chip = chipsFiltersOptions.find((x) => x.key === item.id && x.type === 'Service');
        if (chip) {
          item.filtered = true;
          if (chip.default) item.disabled = true;
        }
        newOptionsFilter.push(item);
      });
      setOptionsFilter(newOptionsFilter);
    }
  }, [chipsFiltersOptions, servicesCombo]);

  function closeModal() {
    onCloseModal();
    clearLicensesCustomer();
  }

  const handleDelete = (chipToDelete) => () => {
    if (chipToDelete.default === false) {
      const removeOptionsFilter = [];
      Object.values(optionsFilter).forEach((item) => {
        if (chipToDelete.key === item.id && chipToDelete.type === 'Service') {
          item.filtered = false;
        }
        removeOptionsFilter.push(item);
      });
      setOptionsFilter(removeOptionsFilter);
      setFiltersLicense('Service', null, null, optionsFilter);
      getLicensesByFilter('asc');
    }
  };

  const handleChangePage = (event, newPage, rowsPerPage) => {
    setPage(newPage);
  };

  const handleApplyFilter = () => {
    modifyChipFilterPage();
    getLicensesByFilter('asc');
  };

  const handleSortBy = (sort) => {
    getLicensesByFilter(sort);
  };

  const handleChangeOption = (data) => {
    setCustomerSelected(data);
    modifyCustomerChipFilterPage(data);

    const filters = [];
    if (data.id) {
      filters.push({
        type: 'Customer',
        value: data.id,
      });
    }

    Object.values(optionsFilter).forEach((filter) => {
      if (filter.filtered === true) {
        filters.push({
          type: 'Service',
          value: filter.id,
        });
      }
    });

    const licenseRequest = {
      purchaseOrderLicenseId: licenseSelected,
      filters,
    };
    getDetailLicensesCustomer(licenseRequest, false, enqueueSnackbar);
  };

  const handleAddNewLicense = () => {
    setShowAddLicense(true);
  };

  const handleCancelAddLicense = () => {
    setConfirmCancelAlert('CancelAddLicense');
  };

  const handleViewReport = () => {
    if (detailLicenses && detailLicenses.length > 0) {
      const { documentType, documentNumber } = detailLicenses[0].customerInfo;
      window.open(`/license/reports/reportView?reportType=GeneralConsumptionReport&documentType=${documentType}&documentNumber=${documentNumber}`, '_blank');
    }
  };

  const statusLicense = (isActive) => (
    <>
      &nbsp;
      {
        isActive ? (
          <Badge
            overlap="rectangular"
            badgeContent="Activa"
            classes={{ root: classes.badgeStatus, badge: classes.statusActiveLicense }}
          >
            <CheckCircleIcon className={classes.iconActiveLicense} />
          </Badge>
        ) : (
          <Badge
            overlap="rectangular"
            badgeContent="Inactiva"
            classes={{ root: classes.badgeStatus, badge: classes.statusInactiveLicense }}
          >
            <CancelIcon className={classes.iconInactiveLicense} />
          </Badge>
        )
      }
    </>
  );

  const columns = [
    {
      id: 'packageName',
      label: 'Paquete',
      paddingLeft: '40px',
      isSortable: false,
      sortPropertyName: 'packageName',
      position: 1,
      align: 'center',
      alignRow: 'left',
    },
    {
      id: 'effectiveDate',
      label: 'Fecha de inicio',
      paddingLeft: '40px',
      isSortable: false,
      sortPropertyName: 'effectiveDate',
      position: 2,
      align: 'center',
      alignRow: 'center',
    },
    {
      id: 'expirationDate',
      label: 'Fecha fin',
      paddingLeft: '40px',
      isSortable: false,
      sortPropertyName: 'expirationDate',
      position: 3,
      align: 'center',
      alignRow: 'center',
    },
    {
      id: 'purchaseDate',
      label: 'Fecha de compra',
      paddingLeft: '40px',
      isSortable: false,
      sortPropertyName: 'purchaseDate',
      position: 4,
      align: 'center',
      alignRow: 'center',
    },
    {
      id: 'viewGeneralReport',
      label: '',
      paddingLeft: '40px',
      isSortable: false,
      sortPropertyName: 'viewGeneralReport',
      position: 5,
      align: 'center',
      alignRow: 'center',
    },
    {
      id: 'itemCollapse',
      label: '',
      paddingLeft: '40px',
      isSortable: false,
      sortPropertyName: 'itemCollapse',
      position: 6,
      align: 'center',
      alignRow: 'center',
      className: 'classes.collapseFeatures',
    },
  ];

  const columnsCollapse = [
    {
      id: 'feature',
      label: 'Característica',
      position: 1,
      align: 'center',
      alignRow: 'left',
    },
    {
      id: 'totalPlan',
      label: 'Compradas',
      position: 2,
      align: 'center',
      alignRow: 'center',
    },
    {
      id: 'totalUsed',
      label: 'Consumidas',
      position: 3,
      align: 'center',
      alignRow: 'center',
    },
    {
      id: 'totalAvailable',
      label: 'Disponibles',
      position: 4,
      align: 'center',
      alignRow: 'center',
    },
    {
      id: 'lastConsumptionDate',
      label: 'Ultimo consumo',
      position: 5,
      align: 'center',
      alignRow: 'center',
    },
    {
      id: 'viewDetailReport',
      label: '',
      position: 6,
      align: 'center',
      alignRow: 'center',
    },
  ];

  function PlanFeatureCard(propsPlans) {
    const {
      balance, classes, //onRequestSort,
    } = propsPlans;

    // const createSortHandler = (property, isSort) => (event) => {
    //   if (isSort) {
    //     onRequestSort(event, property);
    //   }
    // };

    const notifyAlertUseLicense = (percentageUsed) => (
      // eslint-disable-next-line no-nested-ternary
      percentageUsed === 100 ? (
        <Tooltip title="Sin disponibilidad" placement="top">
          <WarningRoundedIcon style={{ marginLeft: '12px', color: '#D91637' }} fontSize="small" />
        </Tooltip>
      ) : (
        percentageUsed >= 85 && percentageUsed < 100 ? (
          <Tooltip title="Baja disponibilidad" placement="top">
            <WarningRoundedIcon style={{ marginLeft: '12px', color: '#E99C00' }} fontSize="small" />
          </Tooltip>
        ) : ('')
      )
    );

    return (
      <Grid item xs={6} sm={12} md={2} lg={2} className={classes.gridFeature}>
        <Card className={classes.cardFeatureBalance} variant="outlined">
          <CardContent>
            <Grid container spacing={0}>
              <Grid item xs={10} sm={10}>
                <Typography className={classes.titleFeature} color="textSecondary" gutterBottom>
                  {balance.featureName}
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2}>
                {notifyAlertUseLicense(balance.percentageUsed)}
              </Grid>
            </Grid>
            <Typography className={classes.UseFeature} color="textSecondary">
              Total:
              <span className={classes.txFeature}>{` ${balance.totalPlan}`}</span>
            </Typography>
            <Typography className={classes.UseFeature} color="textSecondary">
              Disponibles:
              <span className={classes.txFeature}>{` ${balance.totalAvailable}`}</span>
            </Typography>
            <Typography className={classes.UseFeature} color="textSecondary">
              Ultimo consumo:
              <span className={classes.txFeature}>{` ${format(new Date(balance.lastUseDate), 'dd/MM/yyyy')}`}</span>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        disableEnforceFocus
      >
        <DialogTitle style={{ backgroundColor: '#F1F1F1' }}>
          {
            servicesCombo.length > 0 && (
              <Filter
                setPlacement={setPlacement}
                placement={placement}
                setOptionsFilter={setOptionsFilter}
                optionsFilter={optionsFilter}
                optionsSearch={customerLicensedCombo}
                handleChangeOption={handleChangeOption}
                optionSearchSelected={customerSelected}
                maxNumberRegistersSelected={0}
                limitTags={1}
                handleApplyFilter={handleApplyFilter}
                handleSortBy={handleSortBy}
                showExportButton
                titleExportButton="Detalle de consumo"
                handleViewReport={handleViewReport}
              />
            )
          }
          <IconButton className={classes.closeButton} onClick={closeModal}>
            <CloseIcon color="primary" className={classes.tamIcon} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div>
            {
              chipsFiltersOptions.length > 0 && (
                chipsFiltersOptions?.map((data) => {
                  return (
                    <Chip
                      key={data.key}
                      label={data.value}
                      onDelete={handleDelete(data)}
                      className={classes.chip}
                    />
                  );
                })
              )
            }
            {
              detailLicenses.map((item) => (
                item.serviceLicense.map((service, index) => (
                  <Grid
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    container
                    justify="space-evenly"
                    alignItems="stretch"
                    spacing={2}
                    className={classes.groupContainer}
                  >
                    <Grid item xs container spacing={2}>
                      <Grid item xs={12} sm={12} md={2} lg={3}>
                        <Grid item xs>
                          <Typography gutterBottom variant="subtitle1" className={classes.customerName}>
                            {item.customerInfo?.customerName}
                          </Typography>
                          <Typography variant="body2" className={classes.customerInfo}>
                            {item.customerInfo?.documentNumber}
                          </Typography>
                          {
                            item.customerInfo?.legalRepresentativeName !== '' && (
                              <Typography variant="body2" className={classes.customerInfo}>
                                {`Rep. Legal: ${item.customerInfo?.legalRepresentativeName}`}
                              </Typography>
                            )
                          }
                          <Typography variant="body2" className={classes.serviceName}>
                            {service.serviceName}
                          </Typography>
                          <Typography variant="body2" className={classes.customerInfo}>
                            {`Vigencia: ${format(new Date(service.licenseBaseStartDate), 'dd-MM-yyyy')} 
                            / ${format(new Date(service.licenseBaseExpirationDate), 'dd-MM-yyyy')}`}
                            {statusLicense(service.licenseBaseIsActive)}
                          </Typography>
                          <Typography variant="body2" className={classes.customerInfo}>
                            {`Ultimo Consumo: ${format(new Date(service.lastConsumptionLicense), 'dd/MM/yyyy hh:mm:ss aaaa ')}`}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={0} item xs={12} sm={12} md={10} lg={9} className={classes.balancePlans}>
                        {service.balanceTotalPlans.filter((x) => x.featureCategoryType === 'T').map((balance, i) => (
                          <PlanFeatureCard
                            // eslint-disable-next-line react/no-array-index-key
                            key={i}
                            classes={classes}
                            balance={balance}
                          />
                        ))}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '15px' }}>
                      <Button
                        type="submit"
                        variant="contained"
                        className={classes.btnAddNewLicense}
                        endIcon={<AddCircleOutlineTwoToneIcon />}
                        size="small"
                        onClick={() => handleAddNewLicense()}
                      >
                        Añadir licencia
                      </Button>
                      {
                        showAddLicense === true && (
                          <Grid container spacing={0} item xs={12} className={classes.addLicenseContainer}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <AddNewLicense
                                customerId={detailLicenses[0]?.customerInfo.customerId}
                                setShowAddLicense={setShowAddLicense}
                                handleCancelAddLicense={handleCancelAddLicense}
                                confirmCancelAlert={confirmCancelAlert}
                                setConfirmCancelAlert={setConfirmCancelAlert}
                                showReturn={false}
                              />
                            </Grid>
                          </Grid>
                        )
                      }
                    </Grid>
                    <Grid item xs={12} className={classes.gridTable}>
                      {
                        item.serviceLicense.length > 0 && (
                          <CustomTableCollapse
                            data={mapLicensesService(service, item.customerInfo)}
                            columns={columns}
                            columnsCollapse={columnsCollapse}
                            showPagination={false}
                            rowsPerPageOptions={[5, 10, 15]}
                            handleChangePage={handleChangePage}
                            setPage={setPage}
                            page={page}
                            classTable={{
                              rows: classes.rowCell,
                              disabledRows: classes.disabledRow,
                            }}
                          />
                        )
                      }
                    </Grid>
                  </Grid>
                ))
              ))
            }
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions} disableSpacing />
      </Dialog>
    </>
  );
};

const mapStateToProps = (props) => ({
  detailLicenses: props.license.detailLicenses,
  chipsFiltersOptions: props.license.chipsFiltersOptions,
  customerLicensedCombo: props.packets.customerLicensedCombo,
  servicesCombo: props.service.servicesCombo,
});

const mapDispatchToProps = {
  getDetailLicensesCustomer: getDetailLicensesCustomerAction,
  clearLicensesCustomer: clearLicensesCustomerAction,
  setFiltersLicense: setFiltersLicenseAction,
  getTokenExternalSource: getTokenExternalSourceAction,
  getCustomerLicensed: getCustomerLicensedAction,
  getServices: getServicesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalValidLicenses);
