import axios from 'axios';
import {
  FEATURE_TYPE_LOADING, GET_FEATURE_TYPES_LIST,
} from '../types/featureType.type';
import { API_LICENCIAMIENTO } from '../config/config';
import { defaultHeaders } from '../helpers/generalUtils';

export const getFeatureTypeAction = () => async (dispatch) => {
  try {
    dispatch({ type: FEATURE_TYPE_LOADING, payload: true });
    const config = defaultHeaders();
    const response = await axios.get(`${API_LICENCIAMIENTO}/license/api/featureType/GetAllFeatureType`, config);
    if (response.status === 200) {
      dispatch({ type: GET_FEATURE_TYPES_LIST, payload: response.data.result.featureTypeList });
      dispatch({ type: FEATURE_TYPE_LOADING, payload: false });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: FEATURE_TYPE_LOADING, payload: false });
  }
};

export const registerFeatureTypeAction = (data, enqueueSnackbar) => async (dispatch) => {
  try {
    dispatch({ type: FEATURE_TYPE_LOADING, payload: true });
    const config = defaultHeaders();
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/featureType/RegisterFeatureType`, data, config);
    if (response.status === 200) {
      dispatch({ type: FEATURE_TYPE_LOADING, payload: false });
      await dispatch(getFeatureTypeAction());
      enqueueSnackbar('La característica se ha guardado exitosamente.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 422) {
        enqueueSnackbar(error.response.data.statusMessage, {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar('Error de servidor', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    }
    console.error(`Error grabando tipo de característica de servicio: ${error.message}`);
    dispatch({ type: FEATURE_TYPE_LOADING, payload: false });
  }
};

export const changeStateFeatureTypeAction = (data, enqueueSnackbar) => async (dispatch) => {
  try {
    dispatch({ type: FEATURE_TYPE_LOADING, payload: true });
    const config = defaultHeaders();
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/featureType/ChangeStateFeatureType`, data, config);
    if (response.status === 200) {
      dispatch({ type: FEATURE_TYPE_LOADING, payload: false });
      await dispatch(getFeatureTypeAction());
      const message = data.Status
        ? 'Se ha activado la característica exitosamente.'
        : 'Se ha inactivado la característica exitosamente.';
      enqueueSnackbar(message, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 422) {
        enqueueSnackbar(error.response.data.statusMessage, {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar('Error de servidor', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    }
    dispatch({ type: FEATURE_TYPE_LOADING, payload: false });
    console.error(`Error modificando tipo de característica de servicio: ${error.message}`);
  }
};
