/* eslint-disable max-len */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import AddCircleOutlineTwoToneIcon from '@material-ui/icons/AddCircleOutlineTwoTone';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import TableModalExt from '../Cart/TableModal/TableModalExt';
import CardModalExt from '../Cart/Responsive/CardModalExt';
import SliderCard from '../../application/SliderCard';
import DetailPackage from '../../application/Package/DetailPackage';
import { Rules } from '../../../helpers/RHFRules';
import { CleanCart } from '../../../helpers/cartUtils';
import { roundDecimalValue } from '../../../helpers/generalUtils';
import { getParmeterConfigAction } from '../../../actions/general.action';

import {
  setStatusCart,
  addToCartAction,
  deleteOfCartAction,
  applyPromotionCodeAction,
  getPackageBaseCustomerAction,
} from '../../../actions/home.action';

import { getQuantityByTypeAction } from '../../../actions/localStorage.action';

const getSubtotal = (cart) => {
  const total = cart.reduce(
    (previousValue, currentValue) => (
      (((currentValue.base * currentValue.number) - currentValue.discount) + currentValue.taxiva) + previousValue
    ), 0,
  );
  if (total) {
    if (cart.filter((x) => x.discount > 0).length > 0) {
      return `$${roundDecimalValue(total).toLocaleString('en')}`;
    }
    return `$${roundDecimalValue(total).toLocaleString('en')}`;
  }
  return `$${roundDecimalValue(total).toLocaleString('en')}`;
};

const getTotalByProduct = (row) => {
  const total = ((row.base * row.number) - row.discount) + row.taxiva;
  if (total) {
    if (row.discount > 0) {
      return `$${roundDecimalValue(total).toLocaleString('en')}`;
    }
    return `$${roundDecimalValue(total).toLocaleString('en')}`;
  }
  return `$${roundDecimalValue(total).toLocaleString('en')}`;
};

const useStyles = makeStyles((theme) => ({
  root: {
  },
  titleModalCart: {
    ...theme.home.cart.title,
    fontWeight: 700,
    fontSize: 25,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  tableCotainer: {
    marginBottom: 20,
    borderRadius: 5,
  },
  table: {
    minWidth: 300,
  },
  tableHead: {
    ...theme.home.cart.tableHead,
    heigth: 35,
  },
  dialogTitle: {
    ...theme.home.cart.dialogTitle,
  },
  dialogContent: {
    marginBottom: 20,
    ...theme.home.cart.dialogContentCardModal,
  },
  promoForm: {
    ...theme.home.cart.promoForm,
    display: 'flex',
    width: 'fit-content',
    alignItems: 'flex-start',
    borderRadius: 5,
    padding: 15,
    marginBottom: 20,
  },
  promoFormResponsive: {
    ...theme.home.cart.promoForm,
    display: 'flex',
    alignItems: 'flex-start',
    borderRadius: 5,
    padding: 15,
    marginBottom: 20,
  },
  divider: {
    marginBottom: 20,
  },
  totalWrapper: {
    float: 'right',
  },
  totalWrapperResponsive: {
  },
  infoDescountText: {
    ...theme.home.cart.discountText,
    fontSize: 14,
  },
  totalText: {
    ...theme.home.cart.totalText,
    fontSize: 20,
    textAlign: 'right',
  },
  taxInclude: {
    fontSize: 14,
    color: '#B4B4B4',
    textAlign: 'right',
    marginBottom: 15,
  },
  btnPromotional: {
    ...theme.home.cart.btnPromotional,
  },
  btnPromotionalResponsive: {
    ...theme.home.cart.btnPromotionalResponsive,
  },
  tableCell: {
    ...theme.home.cart.tableCell,
    fontWeight: 900,
  },
  rowCell: {
    fontWeight: 700,
  },
  textPromotional: {
    ...theme.home.cart.textPromotional,
    marginRight: 10,
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      ...theme.home.cart.colorTextPromotional,
    },
    '& .MuiInputBase-input': {
      ...theme.home.cart.colorTextPromotional,
    },
    '& .MuiInputBase-input:focus': {
      ...theme.home.cart.colorTextPromotional,
    },
  },
  btnContinue: {
    ...theme.home.cart.btnContinue,
  },
  btnContinueResponsive: {
    fontSize: '20px',
  },
  btnRemoveFromCart: {
    ...theme.home.cart.btnRemoveFromCart,
    padding: '0',
  },
  dvTitlePopup: {
    width: '100%',
  },
  btnAddProducts: {
    ...theme.home.cart.btnAddPackages,
    '&:hover': {
      ...theme.home.cart.hoverAddProducts,
    },
  },
  tamIcon: {
    transform: 'scale(0.85)',
  },
}));

const CartModal = (props) => {
  const {
    isOpen, onClose, cart, addToCart, deleteOfCart, applyPromotionCode,
    promoCode, history, userInfo, appName, packageBase, getPackageBaseCustomer, getQuantityByType,
    parameterApp, getParmeterConfig,
  } = props;

  const [isShownId, setIsShownId] = useState(0);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const EMPLOYEE_TYPE = 'EMPLOYEE';
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [featuresPackage, setFeaturesPackage] = useState([]);
  const [quantityLicenseLocalEmployee] = useState(getQuantityByType(EMPLOYEE_TYPE));
  const [isDisabled, setIsDisabled] = useState(true);

  const classes = useStyles();
  const cartClean = CleanCart(cart, promoCode);

  useEffect(() => {
    if (appName && appName !== '') {
      getParmeterConfig(appName, 'ParameterApps');
    }
  }, []);

  const {
    handleSubmit, control, errors, reset,
  } = useForm();

  const handleClickPurchase = () => {
    if (!userInfo) {
      const countBasePackage = packageBase?.filter((a) => cartClean?.some((b) => a.packageId === b.id)).length;

      if (countBasePackage === 0) {
        enqueueSnackbar('Para continuar la compra, debes primero seleccionar un paquete base.', {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
        return;
      }
      onClose();
      history.push('/registerPurchaseOrder');
    } else {
      const customerRequest = {
        DocumentType: userInfo.customer_type,
        DocumentNumber: userInfo.customer_value,
        ServiceName: appName,
      };

      getPackageBaseCustomer(customerRequest, setLoading, onClose, enqueueSnackbar, history);
    }
  };

  const disabledFree = (row) => row.price === 0;

  function deleteItem(value, row) {
    const { id, number } = row;
    const cartItem = cart.find((item) => item.packageId === id);
    if (value > number) addToCart(cartItem, enqueueSnackbar);
    else deleteOfCart(cartItem.packageId, false);
  }

  function showInfoItem(row) {
    const { id } = row;
    const cartItem = cart.find((item) => item.packageId === id);
    setFeaturesPackage(cartItem.itemsPackageFeatures);
  }

  const handleQuantity = (e, row, type, val, flag) => {
    // const { value } = e.target;
    let value = 0;
    if (flag === 1 || flag === 2) {
      value = val;
    } else {
      value = e.target.value;
    }
    deleteItem(value, row);
    if (promoCode) {
      const data = {
        PromotionCode: promoCode.promoCode,
      };
      applyPromotionCode(data, setLoading, enqueueSnackbar, false);
    }
  };

  const handleDelete = (e, row) => {
    deleteItem(0, row);
    if (promoCode && cart.length >= 1) {
      const data = {
        PromotionCode: promoCode.promoCode,
      };
      applyPromotionCode(data, setLoading, enqueueSnackbar, false);
    }
  };

  const handleApplyOfert = (data) => {
    applyPromotionCode(data, setLoading, enqueueSnackbar);
    reset();
  };

  const handleShowElement = (id) => {
    setIsShownId(id);
  };

  const handleRedirectToHome = () => {
    onClose();
    history.push('/');
  };

  const handlePopoverOpen = (e, row) => {
    setAnchorEl(e.currentTarget);
    showInfoItem(row);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const unique = cartClean.length === 1;

  const open = Boolean(anchorEl);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={isOpen}
      >
        <DialogTitle className={classes.dialogTitle}>
          <Grid item xs={12}>
            <Typography className={classes.titleModalCart}>{unique ? cart[0].packageName : 'Carrito de compras'}</Typography>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<AddCircleOutlineTwoToneIcon />}
              className={classes.btnAddProducts}
              onClick={() => {
                handleRedirectToHome();
              }}
            >
              Agregar productos
            </Button>
          </Grid>
          <IconButton aria-label="close" color="primary" className={classes.closeButton} onClick={onClose}>
            <CloseIcon className={classes.tamIcon} />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container>
            {unique && !isXs
              && (
                <Grid item xs={4}>
                  <SliderCard
                    card={cart[0]}
                    showActions={false}
                    showHeader={false}
                    isbgColor
                    colorBgSlider={theme.home.cart.slider.backgroundColor}
                  />
                </Grid>
              )}
            <Grid item xs={(unique && !isXs) ? 8 : 12}>
              {
                isXs
                  ? (
                    <CardModalExt
                      cart={cart}
                      cartClean={cartClean}
                      open={open}
                      handleShowElement={handleShowElement}
                      handlePopoverOpen={handlePopoverOpen}
                      handlePopoverClose={handlePopoverClose}
                      handleQuantity={handleQuantity}
                      quantityLicenseLocalEmployee={quantityLicenseLocalEmployee}
                      disabledFree={disabledFree}
                      roundDecimalValue={roundDecimalValue}
                      getTotalByProduct={getTotalByProduct}
                      isShownId={isShownId}
                      handleDelete={handleDelete}
                    />
                  ) : (
                    <TableModalExt
                      appName={appName}
                      cart={cart}
                      cartClean={cartClean}
                      open={open}
                      handleShowElement={handleShowElement}
                      handlePopoverOpen={handlePopoverOpen}
                      handlePopoverClose={handlePopoverClose}
                      handleQuantity={handleQuantity}
                      quantityLicenseLocalEmployee={quantityLicenseLocalEmployee}
                      disabledFree={disabledFree}
                      roundDecimalValue={roundDecimalValue}
                      getTotalByProduct={getTotalByProduct}
                      isShownId={isShownId}
                      handleDelete={handleDelete}
                    />
                  )
              }
              {
                parameterApp && parameterApp.promotionCodeApply && (
                  <>
                    <form
                      noValidate
                      autoComplete="off"
                      onSubmit={handleSubmit(handleApplyOfert)}
                      className={isXs ? classes.promoFormResponsive : classes.promoForm}
                    >
                      <FormControl>
                        <Controller
                          as={(
                            <TextField
                              label="Código promocional"
                              size="small"
                              variant="outlined"
                              inputProps={{
                                maxLength: 20,
                                onChange: (e) => {
                                  if (e.target.value !== '') {
                                    setIsDisabled(false);
                                  } else {
                                    setIsDisabled(true);
                                  }
                                },
                              }}
                              className={classes.textPromotional}
                              FormHelperTextProps={{ style: { marginRight: 0 } }}
                              helperText={errors.PromotionCode ? errors.PromotionCode.message : null}
                            />
                          )}
                          name="PromotionCode"
                          control={control}
                          rules={Rules.required}
                          defaultValue=""
                        />
                      </FormControl>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        style={{ marginTop: 2 }}
                        disabled={isDisabled}
                        className={!isXs ? classes.btnPromotional : classes.btnPromotionalResponsive}
                      >
                        {loading ? 'Cargando' : 'Aplicar'}
                        {loading && <CircularProgress color="secondary" size={25} />}
                      </Button>
                    </form>
                    <Grid item xs={12} md={8}>
                      <Typography className={classes.infoDescountText}>
                        <span>*El valor del descuento aplica antes de IVA.</span>
                      </Typography>
                    </Grid>
                  </>
                )
              }
              <Divider className={classes.divider} variant="fullWidth" />
              <Grid container>
                <Grid item xs={12} md={8} />
                <Grid item xs={12} md={4}>
                  <Box className={!isXs ? classes.totalWrapper : classes.totalWrapperResponsive}>
                    <Typography className={classes.totalText}>
                      <>
                        <span>Total: </span>
                        <span style={{ fontWeight: 800 }}>
                          {loading ? 'Procesando...' : `${getSubtotal(cartClean)}`}
                          {loading && <CircularProgress color="secondary" size={25} />}
                        </span>
                      </>
                    </Typography>
                    <Typography className={classes.taxInclude}>Impuesto incluido</Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={handleClickPurchase}
                      className={!isXs ? classes.btnContinue : classes.btnContinueResponsive}
                    >
                      Continuar
                    </Button>
                  </Box>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {/* Popover Detalles de Paquete */}
      {parameterApp && parameterApp.planFeaturesview && (
        <DetailPackage
          featuresPackage={featuresPackage}
          anchorEl={anchorEl}
        />
      )}
    </>
  );
};

const mapStateToProps = (props) => ({
  isOpen: props.home.isCartOpen,
  cart: props.home.cart,
  promoCode: props.home.promoCode,
  parameterApp: props.general.parameterApp,
});

const mapDispatchToProps = {
  onClose: () => setStatusCart(false),
  addToCart: addToCartAction,
  deleteOfCart: deleteOfCartAction,
  applyPromotionCode: applyPromotionCodeAction,
  getPackageBaseCustomer: getPackageBaseCustomerAction,
  getQuantityByType: getQuantityByTypeAction,
  getParmeterConfig: getParmeterConfigAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CartModal));
