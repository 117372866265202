/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { ReactComponent as Ckeckimage } from '../../../../assets/img/iconos/ibuhoo/ckeckimage.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 280,
    borderRadius: 12,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '35px',
    boxShadow: '2px 2px 2px -2px rgba(75,75,75,0.34)',
  },
  cardContent: {
    padding: 0,
  },
  cardHeader: {
    backgroundRepeat: 'no-repeat',
    height: '140px',
    padding: '10px 20px 0',
    color: 'white',
    fill: '#FFFFF',
  },
  title: {
    fontSize: '16px',
    letterSpacing: '0px',
    lineHeight: 1,
  },
  subtitle: {
    fontSize: '22px',
    letterSpacing: '0px',
    fontWeight: 'bold',
    lineheight: 1,
  },
  text: {
    fontSize: '19px',
    letterSpacing: '0px',
    fontWeight: 'bold',
    lineheight: 1,
  },
  cardList: {
    padding: 0,
    fontSize: 10,
    letterSpacing: '0px',
    lineheight: 1,
  },
  btnDirectPurchase: {
    fontWeight: 900,
    '& .MuiButton-label': {
      fontWeight: 900,
      fontSize: '14px',
    },
  },
  addToCartButton: {
    width: 37,
    minWidth: 0,
  },
  iconBox: {
    ...theme.home.cart.checkIconBox,
  },
  listItemPopover: {
    ...theme.home.cart.listItemPopover,
  },
}));

const DetailItemCart = (props) => {
  const {
    card, showList = true,
  } = props;

  const { itemsPackageFeatures: listItems } = card;
  const classes = useStyles();

  return (
    <div>
      <CardContent className={classes.cardContent}>
        {showList
          && (
            <Box className={classes.cardList}>
              <List dense disablePadding className={classes.listItemPopover}>
                {listItems.map((item) => (
                  <ListItem
                    key={item.itemPackageId}
                    dense
                    disableGutters
                  >
                    <ListItemIcon style={{ minWidth: 0, marginRight: '10px' }}>
                      <Ckeckimage className={classes.iconBox} />
                    </ListItemIcon>
                    {
                      item.quantity === 0 ? (
                        <ListItemText primary={`${item.serviceFeature.serviceFeatureDescription}`} />) : (
                          <ListItemText primary={`${item.serviceFeature.isunlimited === true ? `${item.serviceFeature.serviceFeatureDescription} (Ilimitadas)` : `${item.quantity} ${item.serviceFeature.serviceFeatureDescription}`}`} />)
                    }
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
      </CardContent>
    </div>
  );
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailItemCart);
