import {
  GET_CITIESBILLING_ASSISTANT_GOOGLE,
  GET_CITIESSERVICE_ASSISTANT_GOOGLE,
} from '../types/google.type';
import {
  removeAccentsTildes,
} from '../helpers/generalUtils';

// eslint-disable-next-line import/prefer-default-export
export const getCitiesGoogleAssistantAction = (data, type) => async (dispatch) => {
  try {
    let citiesAssistant = {
      cityName: null,
      deptoName: null,
    };
    if (type === 'Billing') {
      const arrCitiesBilling = data.structured_formatting.secondary_text.split(', ');
      if (arrCitiesBilling.length === 2) {
        citiesAssistant = {
          cityName: removeAccentsTildes(arrCitiesBilling[0]).toUpperCase() === 'BOGOTA'
            ? 'BOGOTA, D.C.'// removeAccents(arrCitiesBilling[0])
            : removeAccentsTildes(data.structured_formatting.main_text).toUpperCase(),
          deptoName: removeAccentsTildes(arrCitiesBilling[0]).toUpperCase(),
        };
      } else if (arrCitiesBilling.length > 2) {
        citiesAssistant = {
          cityName: removeAccentsTildes(arrCitiesBilling[0]).toUpperCase(),
          deptoName: removeAccentsTildes(arrCitiesBilling[1]).toUpperCase(),
        };
      }
      dispatch({
        type: GET_CITIESBILLING_ASSISTANT_GOOGLE,
        payload: citiesAssistant,
      });
    }
    if (type === 'Service') {
      const arrCitiesService = data.structured_formatting.secondary_text.split(', ');
      if (arrCitiesService.length === 2) {
        citiesAssistant = {
          cityName: removeAccentsTildes(arrCitiesService[0]).toUpperCase() === 'BOGOTA'
            ? 'BOGOTA, D.C.'// removeAccents(arrCitiesBilling[0])
            : removeAccentsTildes(data.structured_formatting.main_text).toUpperCase(),
          deptoName: removeAccentsTildes(arrCitiesService[0]).toUpperCase(),
        };
      } else if (arrCitiesService.length > 2) {
        citiesAssistant = {
          cityName: removeAccentsTildes(arrCitiesService[0]).toUpperCase(),
          deptoName: removeAccentsTildes(arrCitiesService[1]).toUpperCase(),
        };
      }
      dispatch({
        type: GET_CITIESSERVICE_ASSISTANT_GOOGLE,
        payload: citiesAssistant,
      });
    }
  } catch (error) {
    console.error(error);
  }
};

export const clearGoogleAssistantAction = (type) => async (dispatch) => {
  try {
    const assistant = {
      cityName: null,
      deptoName: null,
    };
    if (type === 'Billing') {
      dispatch({ type: GET_CITIESBILLING_ASSISTANT_GOOGLE, payload: assistant });
    }
    if (type === 'Service') {
      dispatch({ type: GET_CITIESSERVICE_ASSISTANT_GOOGLE, payload: assistant });
    }
  } catch (error) {
    console.error(error);
  }
};