/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  getCustomerLinkedPackageAction,
  getPromoCodesLinkedPackageAction,
  getChannelsLinkedPackageAction,
  getFeaturesDetailPackageAction,
} from '../../../actions/packets.action';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 280,
    borderRadius: 12,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '35px',
    boxShadow: '2px 2px 2px -2px rgba(75,75,75,0.34)',
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    boxShadow: '2px 2px 2px -2px rgba(75,75,75,0.34)',
    borderRadius: 10,
  },
  card: {
    ...theme.home.cart.detailPackage,
    width: '200px',
  },
  cardContent: {
    padding: '15px 15px 0',
  },
  titleModal: {
    ...theme.home.cart.title,
    fontWeight: 700,
    fontSize: 12,
  },
  textPackage: {
    fontSize: 10,
  },
}));

const InfoPackage = (props) => {
  const {
    loadingInfoPackage, infoPackage, anchorEl, getCustomerLinkedPackage, getPromoCodesLinkedPackage,
    customerLinkedPackage, promoCodeLinkedPackage, channelLinkedPackage, getChannelsLinkedPackage,
    getFeaturesDetailPackage, featuresLinkedPackage,
  } = props;

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (infoPackage.type === 'customerPackage') {
      getCustomerLinkedPackage(infoPackage.packageId, enqueueSnackbar);
    } else if (infoPackage.type === 'promoCode') {
      getPromoCodesLinkedPackage(infoPackage.packageId, enqueueSnackbar);
    } else if (infoPackage.type === 'channelPackage') {
      getChannelsLinkedPackage(infoPackage.packageId, enqueueSnackbar);
    } else if (infoPackage.type === 'infoPackage') {
      getFeaturesDetailPackage(infoPackage.packageId, enqueueSnackbar);
    }
  }, [infoPackage]);

  const setInfoPackage = (type) => {
    switch (type) {
      case 'customerPackage':
        return (
          <List disablePadding>
            { customerLinkedPackage.length > 0 ? (customerLinkedPackage.map((item) => (
              <ListItem
                key={item.value}
                divider
                dense
                disableGutters
              >
                <ListItemText
                  primaryTypographyProps={{ style: { fontSize: 10 } }}
                  primary={`${item.text}`}
                />
              </ListItem>
            ))) : (
              <Typography className={classes.textPackage}>No se encontró resultados</Typography>
            )}
          </List>
        );
      case 'infoPackage':
        return (
          <List disablePadding>
            { featuresLinkedPackage.length > 0 ? (featuresLinkedPackage.map((item) => (
              <ListItem
                key={item.value}
                divider
                dense
                disableGutters
              >
                <ListItemText
                  primaryTypographyProps={{ style: { fontSize: 10 } }}
                  primary={`${item.text}`}
                />
              </ListItem>
            ))) : (
              <Typography className={classes.textPackage}>No se encontró resultados</Typography>
            )}
          </List>
        );
      case 'promoCode':
        return (
          <List disablePadding>
            { promoCodeLinkedPackage.length > 0 ? (promoCodeLinkedPackage.map((item) => (
              <ListItem
                key={item.promoCodePackageId}
                divider
                dense
                disableGutters
              >
                <ListItemText
                  primaryTypographyProps={{ style: { fontSize: 10 } }}
                  primary={`${item.promotionalCode} ${item.discount}`}
                />
              </ListItem>
            ))) : (
              <Typography className={classes.textPackage}>No se encontró resultados</Typography>
            )}
          </List>
        );
      case 'channelPackage':
        return (
          <List disablePadding>
            { channelLinkedPackage.length > 0 ? (channelLinkedPackage.map((item) => (
              <ListItem
                key={item.value}
                divider
                dense
                disableGutters
              >
                <ListItemText
                  primaryTypographyProps={{ style: { fontSize: 10 } }}
                  primary={`${item.text}`}
                />
              </ListItem>
            ))) : (
              <Typography className={classes.textPackage}>No se encontró resultados</Typography>
            )}
          </List>
        );
      default:
        return (
          <>
          </>
        );
    }
  };

  return (
    <Popover
      id="mouse-over-popover"
      className={classes.popover}
      classes={{
        paper: classes.paper,
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      disableRestoreFocus
    >
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.titleModal}>{infoPackage.title}</Typography>
          {loadingInfoPackage ? (
            <>
              <CircularProgress size={24} className={classes.buttonProgress} />
              <Typography className={classes.textPackage}>Procesando....</Typography>
            </>
          ) : (
            setInfoPackage(infoPackage.type)
          )}
        </CardContent>
      </Card>
    </Popover>
  );
};

const mapStateToProps = (props) => ({
  loadingInfoPackage: props.packets.loadingInfoPackage,
  customerLinkedPackage: props.packets.customerLinkedPackage,
  promoCodeLinkedPackage: props.packets.promoCodeLinkedPackage,
  channelLinkedPackage: props.packets.channelLinkedPackage,
  featuresLinkedPackage: props.packets.featuresLinkedPackage,
});

const mapDispatchToProps = {
  getCustomerLinkedPackage: getCustomerLinkedPackageAction,
  getPromoCodesLinkedPackage: getPromoCodesLinkedPackageAction,
  getChannelsLinkedPackage: getChannelsLinkedPackageAction,
  getFeaturesDetailPackage: getFeaturesDetailPackageAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoPackage);
