/* eslint-disable no-nested-ternary, react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import { Grid } from '@material-ui/core/';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useStyles = makeStyles(() => ({
  tableHead: {
    width: '828px',
    height: '40px',
    background: 'var(--unnamed-color-009bdd) 0% 0% no-repeat padding-box',
    borderRadius: '10px',
    opacity: 1,
    color: '#FFF',
    backgroundColor: '#009BDD',
  },
  table: {
    minWidth: 750,
  },
  tableCellHead: {
    color: '#FFF',
    backgroundColor: '#009BDD',
    fontWeight: 'bold',
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: 'white',
    },
  },
  tableCell: {
    letterSpacing: '0px',
    color: '#575756',
    fontSize: 13,
  },
  tablePagination: {
    font: 'normal normal 600 12px/0.10000000149011612px Muli',
    letterSpacing: '0px',
    opacity: 1,
    color: 'var(--unnamed-color-706f6f)',
    textAlign: 'right',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  container: {
    borderRadius: '10px',
  },
  actionsPaginTable: {
    color: '#009FE3',
  },
  tableSortLabel: {
    color: 'white',
    '&:hover': {
      color: 'white',
    },
  },
  disabledRow: {
    // opacity: 0.4,
  },
  columnStyleParent: {
    fontWeight: 500,
    fontSize: 14,
  },
  columnStyleCollapse: {
    fontWeight: 500,
    fontSize: 12,
    color: '#4188C3',
  },
  tableHeadCollapse: {
    width: '828px',
    height: '40px',
    background: 'var(--unnamed-color-DDEAF5) 0% 0% no-repeat padding-box',
    borderRadius: '10px',
    opacity: 1,
    color: '#FFF',
    backgroundColor: '#DDEAF5',
  },
  arrowCollapse: {
    color: '#009BDD',
    fontSize: '30px',
  },
  collapseFeatures: {
    color: '#E55200',
    fontSize: '30px',
  },
  collapseDefault: {
    color: '#fff',
    fontSize: '30px',
  },
  boxIconCollapse: {
    width: '100%',
  },
}));

const CustomTableCollapse = (props) => {
  const classes = useStyles();
  const {
    data, columns, columnsCollapse, showPagination, hideHeader, classTable,
    rowsPerPageOptions, countRecordsList, handleChangePage, setPage, page,
  } = props;

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [countTablePagination, setCountTablePagination] = React.useState();
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('_referencia');

  useEffect(() => {
    setCountTablePagination(countRecordsList || data.length);
  }, [data]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const orderComparator = comparator(a[0], b[0]);
      if (orderComparator !== 0) return orderComparator;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderByParam) {
    if (b[orderByParam] < a[orderByParam]) {
      return -1;
    }
    if (b[orderByParam] > a[orderByParam]) {
      return 1;
    }
    return 0;
  }

  function getComparator(orderParam, orderByParam) {
    return orderParam === 'desc'
      ? (a, b) => descendingComparator(a, b, orderByParam)
      : (a, b) => -descendingComparator(a, b, orderByParam);
  }

  const handleRequestSort = (event, property) => {
    const propertyCustom = property === 'active' ? 'state' : property;
    const isAsc = orderBy === propertyCustom && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(propertyCustom);
  };

  const handleChangePageTable = (event, newPage) => {
    setPage(newPage);
    handleChangePage(event, newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function EnhancedTableHead(props) {
    const {
      classes, order, orderBy, onRequestSort,
    } = props;
    const createSortHandler = (property, isSort) => (event) => {
      if (isSort) {
        onRequestSort(event, property);
      }
    };
    return (
      <TableHead className={classes.tableHead}>
        <TableRow>
          {columns.map((headCell) => (
            <TableCell
              className={classes.tableCellHead}
              key={headCell.id}
              align={headCell.align}
              // eslint-disable-next-line no-nested-ternary
              style={headCell.position === 1 ? { borderBottomLeftRadius: '10px', width: headCell.width, paddingLeft: headCell.paddingLeft }
                : (headCell.position === columns.length ? { borderBottomRightRadius: '10px', width: headCell.width, paddingLeft: headCell.paddingLeft }
                  : { width: headCell.width, paddingLeft: headCell.paddingLeft })}
              sortDirection={orderBy === headCell.sortPropertyName ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.sortPropertyName}
                direction={orderBy === headCell.sortPropertyName ? order : 'asc'}
                onClick={createSortHandler(headCell.sortPropertyName, headCell.isSortable)}
                classes={{
                  root: classes.tableSortLabel,
                }}
                hideSortIcon={!headCell.isSortable}
              >
                <Typography classes={{
                  body1: classes.columnStyleParent,
                }}
                >
                  {headCell.label}
                </Typography>
                {orderBy === headCell.sortPropertyName ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  function EnhancedTableColapseHead(props) {
    const {
      classes,
    } = props;
    return (
      <TableHead className={classes.tableHeadCollapse}>
        <TableRow>
          {columnsCollapse.map((headCell) => (
            <TableCell
              key={headCell.id}
              align="center"
            >
              <Typography classes={{
                body1: classes.columnStyleCollapse,
              }}
              >
                {headCell.label}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  function Row(props) {
    const { row } = props;
    const [openRow, setOpenRow] = React.useState(false);
    const [showCollapse, setShowCollapse] = React.useState(false);

    const handleShowCollapse = (id) => {
      if (id === 0 && openRow) return;
      setShowCollapse(id);
    };

    return (
      <>
        <TableRow
          hover
          role="checkbox"
          tabIndex={-1}
          key={row.id}
          className={classTable
            ? classTable.rows
            : row.state === 0
              ? classes.rowCell
              : classes.disabledRow}
          onMouseEnter={() => handleShowCollapse(row.id)}
          onMouseLeave={() => handleShowCollapse(0)}
        >
          {columns.map((column) => {
            const value = row[column.id];
            return (
              <TableCell
                className={classes.tableCell}
                key={column.id}
                align={column.alignRow}
                size="small"
              >
                {
                  // eslint-disable-next-line no-nested-ternary
                  column.id !== 'itemCollapse' ? (
                    column.format && typeof value === 'number'
                      ? column.format(value)
                      : value
                  ) : (
                    showCollapse || column.showIconCollapse ? (
                      <Box className={classes.boxIconCollapse}>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => setOpenRow(!openRow)}
                        >
                          {openRow
                            ? <KeyboardArrowUpIcon className={`${column.className ? classes.collapseFeatures : classes.arrowCollapse}`} />
                            : <KeyboardArrowDownIcon className={`${column.className ? classes.collapseFeatures : classes.arrowCollapse}`} />}
                        </IconButton>
                      </Box>
                    ) : (
                      <Box className={classes.boxIconCollapse}>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                        >
                          <KeyboardArrowDownIcon className={classes.collapseDefault} />
                        </IconButton>
                      </Box>
                    )
                  )
                }
              </TableCell>
            );
          })}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={openRow} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small" aria-label="table-collapse">
                  {
                    !hideHeader && (
                      <EnhancedTableColapseHead
                        classes={classes}
                        numSelected={selected.length}
                      />
                    )
                  }
                  <TableBody>
                    {row.rowsTableCollapse.map((rowCollapse) => (
                      <RowCollapse key={rowCollapse.id} row={rowCollapse} />
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  function RowCollapse(props) {
    const { row } = props;

    return (
      <>
        <TableRow
          hover
          role="checkbox"
          tabIndex={-1}
          key={row.id}
          className={row.state === 0 ? '' : classes.disabledRow}
        >
          {columnsCollapse.map((column) => {
            const value = row[column.id];
            return (
              <TableCell
                className={classes.tableCell}
                key={column.id}
                align={column.alignRow}
                size="small"
              >
                {
                  column.format && typeof value === 'number'
                    ? column.format(value)
                    : value
                }
              </TableCell>
            );
          })}
        </TableRow>
      </>
    );
  }

  return (
    <>
      <TableContainer className={classes.container}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="small"
          aria-label="enhanced table"
        >
          {
            !hideHeader && (
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={countTablePagination}
              />
            )
          }
          <TableBody>
            {stableSort(data, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <Row key={row.id} row={row} />
              ))}
          </TableBody>
        </Table>
        {
          countTablePagination === 0
            ? (
              <Grid container style={{ justifyContent: 'center' }}>
                <Typography>No existe información correspondiente a los criterios de búsqueda</Typography>
              </Grid>
            )
            : ''
        }
      </TableContainer>
      {showPagination && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={countTablePagination || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={(e, newpage) => { handleChangePageTable(e, newpage, rowsPerPage); }}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Registros por página"
          labelDisplayedRows={({ from, to, count }) => `${rowsPerPageOptions.length === 1 ? 'Registros' : ''} ${from}-${to} de ${count}`}
          classes={{
            actions: classes.actionsPaginTable,
            selectIcon: classes.actionsPaginTable,
            caption: classes.tablePagination,
          }}
        />
      )}
    </>
  );
};

export default CustomTableCollapse;
