/* --------------------------------------------- */
/* --- Obtener nombre aplicacion -- */
/* --------------------------------------------- */
// eslint-disable-next-line import/prefer-default-export
export const setMessage = (data, userInfo) => {
  const message = {
    category: null,
    title: null,
    message: null,
    submessage: null,
    acceptText: null,
    showCloseAlert: null,
    showCancelButton: null,
    customMessage: null,
    minDateToRenewLicense: null,
  };
  if (data) {
    message.message = data.message
      ? data.message
      : 'Se ha presentado un error al procesar la compra, por favor vuelve a intentarlo.';
    switch (data.code) {
      case 'ER15':
        message.category = 'errorRegisterPayment';
        message.title = '';
        break;
      case 'ER16':
        message.category = 'buyOnlyOneLicense';
        message.title = '¡Tenemos un problema!';
        message.message = '';
        message.customMessage = data.message;
        message.minDateToRenewLicense = data.minDateToRenewLicense;
        if (userInfo) {
          message.acceptText = 'Ir a Mi Plan';
          message.submessage = 'Si necesitas adquirir un adicional para tu paquete, lo puedes hacer ingresando a Mi Plan.';
        } else {
          message.acceptText = 'Ingresar';
          message.showCancelButton = true;
          message.showCloseAlert = false;
          message.submessage = 'Si necesitas adquirir algún adicional para este paquete, lo puedes hacer en la opción "Mi Plan" dentro de la aplicación.';
        }
        break;
      case 'ER17':
        message.category = 'licensePendingActivation';
        message.title = '¡Algo salió mal!';
        message.acceptText = 'Entendido';
        message.submessage = 'Si tienes alguna duda escribenos a soporte.colfactura@colfactura.com';
        break;
      default:
        message.category = 'errorRegisterPayment';
        message.title = '¡Lo sentimos!';
        break;
    }
  }
  return message;
};
