import axios from 'axios';
import { API_LICENCIAMIENTO } from '../config/config';
import {
  SUMMARY_LOADING, GET_SUMMARY_ORDER, UPLOAD_PAYMENT_SUPPORT, RESET_UPLOAD_PAYMENT_SUPPORT, UPLOAD_RUT, RESET_UPLOAD_RUT,
} from '../types/summaryOrder.type';
import { defaultHeaders } from '../helpers/generalUtils';
import { SET_REFERENCEUUID_PURCHASE_ORDER, PURCHASE_SHOW_MESSAGE } from '../types/purchaseLicense.type';

export const getSummaryOrderAction = (referenceUuid, appName) => async (dispatch) => {
  try {
    dispatch({ type: SUMMARY_LOADING, payload: true });
    const config = defaultHeaders();
    Object.assign(config.headers, {
      pReferenceUuid: referenceUuid,
      pAppName: appName,
    });
    const response = await axios.get(`${API_LICENCIAMIENTO}/license/api/purchaseorderlicense/getsummaryorder`, config);
    dispatch({
      type: GET_SUMMARY_ORDER,
      payload: response.data.result.summaryOrder,
    });
    dispatch({ type: SUMMARY_LOADING, payload: false });
  } catch (error) {
    dispatch({ type: SUMMARY_LOADING, payload: false });
    console.error(error);
  }
};

export const uploadPaymentSupportAction = (referenceUuid, file) => async (dispatch) => {
  try {
    dispatch({ type: SUMMARY_LOADING, payload: true });
    const config = defaultHeaders();
    const UploadSupportRequest = {
      referenceUuid,
      fileName: file.file_name,
      contentFileBase64: file.uploaded_file,
      supportType: file.supportType_file,
    };
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/purchaseorderlicense/uploadPaymentSupport`, UploadSupportRequest, config);
    if (file.supportType_file === 'PAYMENT_SUPPORT') {
      dispatch({
        type: UPLOAD_PAYMENT_SUPPORT,
        payload: response.data.result,
      });
    } else if (file.supportType_file === 'RUT') {
      dispatch({
        type: UPLOAD_RUT,
        payload: response.data.result,
      });
    }
    // Limpiar objeto referenceUuidPurchaseOrder
    dispatch({
      type: SET_REFERENCEUUID_PURCHASE_ORDER,
      payload: undefined,
    });
    dispatch({
      type: PURCHASE_SHOW_MESSAGE,
      payload: undefined,
    });

    dispatch({ type: SUMMARY_LOADING, payload: false });
  } catch (error) {
    dispatch({ type: SUMMARY_LOADING, payload: false });
    dispatch({
      type: UPLOAD_PAYMENT_SUPPORT,
      payload: false,
    });
    console.error(error);
  }
};

export const resetUploadPaymentSupportAction = () => async (dispatch) => {
  try {
    dispatch({ type: RESET_UPLOAD_PAYMENT_SUPPORT, payload: undefined });
    dispatch({ type: RESET_UPLOAD_RUT, payload: undefined });
  } catch (error) {
    console.error(error);
  }
};
