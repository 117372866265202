/* eslint-disable max-len */
import {
  LOADING, GET_PAYMENTS, UPDATE_PENDING, UPDATE_APPROVED, UPDATE_REJECTED, GET_CHANNEL, GET_CHANNEL_COMBO, GET_CUSTOMER_COMBO, GET_CUSTOMER_LICENSED_COMBO,
  GET_PACKAGES, GET_PACKAGES_TYPE, GET_PACKAGES_TYPE_COMBO, GET_PROMO_CODES, SET_PROMO_CODE_SELECTED, GET_PACKAGES_ADDITIONAL_DISCOUNT, GET_PACKAGES_ID,
  PACKET_SHOW_MESSAGE, PAYMENT_IS_SEARCH, GET_SUPPORT_PAYMENT_FILE, GET_RUT_FILE, UPDATE_PACKAGE_FEATURES, SELECT_PACKAGE_FEATURES, SEARCH_PACKAGE_FEATURES,
  LOADING_INFO_PACKAGE, CUSTOMER_LINKED_PACKAGE, PROMOCODE_LINKED_PACKAGE, CHANNEL_LINKED_PACKAGE, FEATURES_LINKED_PACKAGE, SEARCH_PACKAGE, PROMOCODE_USER_TYPE,
} from '../types/packets.type';

const INITIAL_STATE = {
  loading: false,
  payments: {
    pending: [],
  },
  packages: [],
  packagesRows: null,
  packagesType: [],
  channel: [],
  channelCombo: [],
  customerCombo: [],
  customerLicensedCombo: [],
  packageTypeCombo: [],
  promoCodes: [],
  pendingBatch: null,
  pendingBatchRows: null,
  approvedBatch: null,
  approvedBatchRows: null,
  rejectedBatch: null,
  rejectedBatchRows: null,
  promoCodeSelected: null,
  packagesAdditionalDiscount: [],
  packagesId: [],
  messageAlertModal: undefined,
  isPaymentSearch: false,
  paymentSupporFile: undefined,
  rutFile: undefined,
  packagesFeatures: [],
  packagesSearch: [],
  loadingInfoPackage: false,
  customerLinkedPackage: [],
  promoCodeLinkedPackage: [],
  channelLinkedPackage: [],
  featuresLinkedPackage: [],
  promoUserType: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PAYMENTS:
      return {
        ...state,
        payments: {
          approved: action.payload.purchaseAproved,
          rejected: action.payload.purchaseRejected,
          pending: action.payload.purchasePending,
          pendingRows: action.payload.purchasePendingRows,
          approvedRows: action.payload.purchaseAprovedRows,
          rejectedRows: action.payload.purchaseRejectedRows,
        },
      };
    case GET_PACKAGES:
      return {
        ...state,
        packages: action.payload,
        packagesRows: action.rownums,
      };
    case GET_PACKAGES_TYPE:
      return {
        ...state,
        packagesType: action.payload,
      };
    case GET_PACKAGES_TYPE_COMBO:
      return {
        ...state,
        packageTypeCombo: action.payload,
      };
    case GET_CHANNEL:
      return {
        ...state,
        channel: action.payload,
      };
    case GET_CHANNEL_COMBO:
      return {
        ...state,
        channelCombo: action.payload,
      };
    case GET_CUSTOMER_COMBO:
      return {
        ...state,
        customerCombo: action.payload,
      };
    case GET_CUSTOMER_LICENSED_COMBO:
      return {
        ...state,
        customerLicensedCombo: action.payload,
      };
    case GET_PROMO_CODES:
      return {
        ...state,
        promoCodes: action.payload,
      };
    case GET_PACKAGES_ADDITIONAL_DISCOUNT:
      return {
        ...state,
        packagesAdditionalDiscount: action.payload,
      };
    case GET_PACKAGES_ID:
      return {
        ...state,
        packagesId: action.payload,
      };
    case UPDATE_PENDING:
      return {
        ...state,
        pendingBatch: action.payload,
        pendingBatchRows: action.rownums,
      };
    case UPDATE_APPROVED:
      return {
        ...state,
        approvedBatch: action.payload,
        approvedBatchRows: action.rownums,
      };
    case UPDATE_REJECTED:
      return {
        ...state,
        rejectedBatch: action.payload,
        rejectedBatchRows: action.rownums,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_PROMO_CODE_SELECTED:
      return {
        ...state,
        promoCodeSelected: action.payload,
      };
    case PACKET_SHOW_MESSAGE:
      return {
        ...state,
        messageAlertModal: action.payload,
      };
    case PAYMENT_IS_SEARCH:
      return {
        ...state,
        isPaymentSearch: action.payload,
      };
    case GET_SUPPORT_PAYMENT_FILE:
      return {
        ...state,
        paymentSupporFile: action.payload,
      };
    case GET_RUT_FILE:
      return {
        ...state,
        rutFile: action.payload,
      };
    case UPDATE_PACKAGE_FEATURES:
      return {
        ...state,
        packagesFeatures: action.payload,
        packagesSearch: action.copypayload,
      };
    case SELECT_PACKAGE_FEATURES:
      return {
        ...state,
        packagesFeatures: action.payload,
      };
    case SEARCH_PACKAGE_FEATURES:
      return {
        ...state,
        packagesFeatures: action.payload,
      };
    case SEARCH_PACKAGE:
      return {
        ...state,
        packagesFeatures: action.payload,
      };
    case CUSTOMER_LINKED_PACKAGE:
      return {
        ...state,
        customerLinkedPackage: action.payload,
      };
    case PROMOCODE_LINKED_PACKAGE:
      return {
        ...state,
        promoCodeLinkedPackage: action.payload,
      };
    case CHANNEL_LINKED_PACKAGE:
      return {
        ...state,
        channelLinkedPackage: action.payload,
      };
    case FEATURES_LINKED_PACKAGE:
      return {
        ...state,
        featuresLinkedPackage: action.payload,
      };
    case LOADING_INFO_PACKAGE:
      return {
        ...state,
        loadingInfoPackage: action.payload,
      };
    case PROMOCODE_USER_TYPE:
      return {
        ...state,
        promoUserType: action.payload,
      };
    default:
      return state;
  }
};
