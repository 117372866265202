/* eslint-disable jsx-a11y/anchor-is-valid, react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import LicenseGroup from './LicenseGroup';
import ExpiredPlan from './ExpiredPlan';
import { getMyPlanAction } from '../../actions/home.action';
import Loading from '../../components/crosscutting/Loading';
import PackageModal from '../../components/application/PackageModal';
import { isValidUsertoConfigurations } from '../../actions/auth.action';
import {clearLocalStorageStore} from '../../helpers/storageUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 280,
    borderRadius: 16,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  paper: {
    padding: '5px',
    marginTop: '20px',
    paddingBottom: '20px',
    opacity: '0.95',
    borderRadius: '20px',
  },
  appPaper: {
    padding: '5px',
    marginTop: '20px',
    paddingBottom: '20px',
    opacity: '0.95',
    borderRadius: '20px',
    boxShadow: 'none',
  },
  cardContent: {
    padding: 0,
  },
  title: {
    marginLeft: 20,
    paddingTop: '15px',
    color: '#003582',
    fontWeight: 700,
  },
}));

const MyPlan = (props) => {
  const classes = useStyles();
  const {
    userInfo, myplan, loading, appName, getMyPlan, parameters,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const decodedTokenJwt = useSelector(({ auth }) => auth.decodedToken);
  localStorage.removeItem('storeLanding');
  clearLocalStorageStore();

  useEffect(() => {
    if (!isValidUsertoConfigurations(decodedTokenJwt, 'CONFIGURATION') && userInfo) {
      const CustomerRequest = {
        DocumentType: userInfo.customer_type,
        DocumentNumber: userInfo.customer_value,
        ServiceName: appName === 'license' ? '' : appName,
      };
      getMyPlan(CustomerRequest, enqueueSnackbar, parameters?.service_feature_code);
      localStorage.setItem('redirectPage', 'myPlan');
    }
  }, [userInfo]);

  if (loading) return <Loading />;

  return (
    <>
      <Container component="main" maxWidth="lg">
        <Paper elevation={3} className={appName === 'license' ? classes.paper : classes.appPaper}>
          {
           appName === 'license' && (
           <Typography color="primary" variant="h4" className={classes.title}>Mi plan</Typography>
           )
          }
          {myplan.length && myplan.map((itemPlan, indexl) => (
            <LicenseGroup key={indexl} data={itemPlan} appName={appName} />
          ))}
          {myplan.statusCode && myplan.statusCode === 422 && (
            <ExpiredPlan appName={appName} />
          )}
        </Paper>
      </Container>
      <PackageModal serviceName={appName} />
    </>
  );
};

const mapStateToProps = ({ home, general, auth }) => ({
  userInfo: auth.userInfo,
  loading: home.loading,
  myplan: home.myplan,
  appName: general.appName,
  parameters: auth.parameters,
});

const mapDispatchToProps = {
  getMyPlan: getMyPlanAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPlan);
