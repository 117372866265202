/* eslint-disable import/prefer-default-export */
import { createTheme, lighten } from '@material-ui/core/styles';
import background from '../assets/img/background/firmaEmail.png';
import logo from '../assets/img/logo/firmaEmail.svg';
import MuliFont from '../assets/font/muli_font/Muli-Light.ttf';

const muli = {
  fontFamily: 'Muli-Light',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Muli-Light'),
    local('Muli-Light'),
    url(${MuliFont}) format('ttf')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export default createTheme({
  app: {
    favicon: 'gse.ico',
    title: 'Firma email',
  },
  palette: {
    primary: {
      main: '#009B38',
    },
    secondary: {
      main: '#E55200',
    },
    error: {
      main: '#d61103',
    },
  },
  typography: {
    fontFamily: 'Muli, Arial',
    fontSize: 13,
  },
  panelSummary: {
    color: 'white',
  },
  resendEmailLink: {},
  verifyEmailLink: {},
  checkOptionFilter: {},
  checkOptionHover: {},
  switchBase: {
    main: '#9e9e9e',
    track: '#fff',
    checked: '#fff',
    selectTrack: '#009B38',
  },
  logo,
  styleLogo: {
    zIndex: 1,
  },
  colorHeader: '#EDEDED',
  header: {
    usserLogged: {
      color: '#BDBDBD',
    },
  },
  home: {
    paquetes: {
      backgroundColor: '#F6F6F6',
    },
    combos: {
      backgroundColor: '#ECF0DD',
      border: '1px solid #4DA623',
    },
    agregados: {
      backgroundColor: '#F8F8F8',
      border: '1px solid #B2B2B2',
    },
    backgroundSliderCard: {
      color: 'rgb(255, 255, 255, 0.1)',
    },
    bgColorHead: {
      bases:
        'green01,green02,green03,green01,green02,green03,green01,green02,green03,green01,green02,green03',
      combos: 'green',
      arrayComplementary:
        '1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4',
      complement: 'green',
      complementaryMyplan: 'green',
    },
    sliderComboText: {},
    sliderComplementText: {},
    sliderComplementaryButtonColor: {
      color: 'primary',
    },
    sliderComplementaryButtonTextColor: {
      color: 'primary',
    },
    sliderBackGroundColor: {},
    sliderArrow: {},
    shoppingCart: {
      color: '#FFFFFF',
    },
    cart: {
      dialogTitle: {},
      dialogContent: {},
      title: {
        color: '#009B38',
      },
      tableHead: {
        backgroundColor: '#54BA35',
      },
      cardList: {},
      checkIconBox: {
        width: 15,
        height: 15,
        stroke: '#2cc63e',
      },
      listItem: {},
      discountText: {
        color: '#30B442',
      },
      infoDescountTextResponsive: {
        color: '#009BDD',
      },
      totalText: {
        color: '#00732B',
      },
      promoForm: {
        backgroundColor: '#F1F1F1',
        color: '#B2B2B2',
        borderRadius: 5,
      },
      promoFormNoResponsive: {
        backgroundColor: '#F1F1F1',
        color: '#B2B2B2',
        borderRadius: 5,
      },
      slider: {},
      quantityPackage: {},
      quantityPackageResponsive: {},
      dividerMiddle: {},
      listDividerBottom: {},
      btnPromotional: {
        backgroundColor: '#95C11F',
      },
      btnPromotionalResponsive: {
        backgroundColor: '#FFF',
        color: '#E55200',
        border: '1px solid',
      },
      btnCancelResponsive: {
        backgroundColor: '#FFF',
        color: '#E55200',
        border: '1px solid',
      },
      tableCell: {
        color: '#fff',
      },
      colorItem: {},
      listItemPopover: {},
      dividerPopover: {},
      textPromotional: {
        backgroundColor: '#fff',
      },
      colorTextPromotional: {},
      colorNumberQuantity: {},
      btnContinue: {
        backgroundColor: '#E55200',
        color: '#fff',
        fontSize: 14,
      },
      btnContinueResponsive: {},
      btnAddPackages: {
        backgroundColor: '#fff',
        color: '#009B38 !important',
        border: '2px solid #009B38 !important',
        float: 'right',
        '&:hover': {
          backgroundColor: '#B1D8A9',
        },
      },
      summaryCart: {
        backgroundColor: '#787878',
      },
      tableCellNoItems: {},
      subtotalText: {
        color: '#4BB672',
      },
      btnGoToCart: {
        backgroundColor: '#54BA35',
      },
      btnRemoveFromCart: {
        color: '#9D9D9D',
      },
      detailPackage: {
        backgroundColor: '#F4F4F4',
      },
      iconShoppingCart: {
        iconShoppingCart: {
          fill: '#CCCCCC',
          '& .b, .c': {
            stroke: '#CCCCCC',
          },
        },
      },
      hoverAddProducts: {
        backgroundColor: '#636363',
      },
      titleDetail: {
        color: '#E55200',
      },
      seeMore: {
        color: '#E55200',
      },
      seeLess: {
        color: '#E55200',
      },
      span: {
        color: '#4F4F4F',
      },
      spanResposive: {},
      borderColor: {
        background: '#FFFFFF2E',
        border: '1px solid #D6D6D6',
      },
      customBadge: {},
      customBadgeItems: 'white',
      helpIconColor: {},
      helpIconColorLicense: {
        color: '#FFFFFF',
      },
      backgroundDialogResponsive: {},
      titleHeader: {},
      deleteIconFromCart: {},
      quantityActionCart: {
        color: '#004C1C',
      },
      typographyCard: {},
      moreLessButton: {
        color: '#2CC63E',
      },
      spacingContent: {},
      itemListStyles: {},
      itemListTextStyle: {
      },
    },
  },
  miplan: {
    bgColorCards: {},
    appBgColorCards: {
      boxShadow: 'none',
    },
    rootCard: {},
    title: {
      color: '#009B38',
    },
    subtitle: {
      color: '#6D6E72',
    },
    cardHeader: {
      color: '#fff',
      backgroundColor: '#8C8C8C',
    },
    featureName: {},
    featureValue: {
      color: '#009B38',
    },
    buttonCard: {
      backgroundColor: '#009B38',
      color: '#fff',
    },
    hoverButtonCard: {
      color: '#fff',
      backgroundColor: '#009B38',
    },
    iconCard: {
      color: '#009B38',
    },
    btnDirectPlan: {
      backgroundColor: '#EA4519',
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#f57c00',
      },
    },
    circularColor: {
      color: '#FFFFFF',
    },
    tablePlanTable: {
      color: '#FFFFFF',
      backgroundColor: '#009838',
      fontWeight: 700,
    },
    tableCellPlanTable: {
      color: '#000000',
      backgroundColor: '#FFFFFF',
      fontSize: 14,
      fontWeight: 500,
    },
    purchasedPlans: {
      color: '#3C3C3B',
    },
    plansTypography: {
      color: '#4F4F4F',
    },
    spanPlanTable: {
      color: '#F5AE19',
    },
    reportIcon: {
      color: '#F5AE19',
    },
  },
  registerForm: {
    masterContainer: {},
    containerForm: {
      expansionPanel: {
        color: '#FFFFFF',
      },
    },
    activatePlan: {
      buttonActivate: {
        backgroundColor: '#009838',
        color: '#fff',
      },
      policyAndTerms: {
        color: '#009B38',
      },
    },
    btnCancel: {},
    btnResponsive: {},
    goHomeLink: {},
    titleForm: {},
    contentTextSecondary: {
      main: '#E55200',
    },
    typeInput: {
      position: 'relative',
      borderRadius: '4px',
    },
    colorLabelFocus: {},
    colorLabel: '',
    colorInputError: '',
    checkDigit: {
      main: '#E55200',
    },
    typeInputValidationCode: {},
    colorLabelValidationCode: '',
    colorBorderNormalValidationCode: {},
  },
  statusLicense: {
    containerMessage: {
      backgroundColor: 'white',
    },
    titleStatusLicense: {
      color: '#F4D612',
    },
    summaryOrderPapper: {
      backgroundColor: '#FFFCF8',
    },
    buttonAction: {
      backgroundColor: '#009838',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#005C3C',
      },
    },
    appoveLicense: {},
    detailStatus: {
    },
  },
  alertPopup: {
    iconClose: {
      color: '#009838',
    },
    titleAlert: {
      color: '#E2C105',
    },
    buttonCancel: {
      backgroundColor: '#009838',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#009838',
      },
    },
    buttonAccept: {
      backgroundColor: '#009838',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#009838',
      },
    },
    secondaryMessageText: {
      color: '#c3c3c3',
    },
    mensajeModalMain: {},
  },
  summaryOrder: {
    loadFiles: {
      backgroundColor: '#F2F5F3',
      color: '#59BC38',
    },
    totalPurchase: {
      color: '#54BA38',
    },
    tableCellSummaryOrder: {},
    purchaseSummary: {},
    attachReceiptButtons: {
      color: '#009B38',
      borderColor: '#009B38',
    },
    textSummaryOrder: {
      '&.MuiTypography-h4': {
        marginLeft: 20,
        fontWeight: 'bold',
      },
    },
    purchaseEmpty: {
      '&.MuiTypography-body1': {
        marginBottom: 10,
        fontWeight: 500,
      },
    },
    formLabelColor: {
      color: 'var(--unnamed-color-575756)',
    },
    linearProgress: {
      backgroundColor: lighten('#FFFFFF', 0.5),
    },
    progressBarBackgroundColor: '#009BDD',
    dividerStyles: {},
  },
  backOffice: {
    configurations: {
      title: {
        color: '#003582',
      },
      editIcon: {
        color: '#009BDD',
      },
    },
  },
  helpToolTip: {
    root: {
      backgroundColor: '#4BB672',
      color: '#121212',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      label: {
        fontWeight: 'bold',
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          background: `url(${background}) no-repeat center center fixed`,
          WebkitBackgroundSize: 'cover',
          MozBackgroundSize: 'cover',
          OBackgroundSize: 'cover',
          backgroundSize: 'cover',
        },
        '@font-face': [muli],
      },
    },
  },
});
