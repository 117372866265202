/* eslint-disable import/prefer-default-export */
export const menuBackOfficeBuilt = [
  {
    id: 1,
    description: 'Configuración',
    code: 'CONFIGURATION',
    icon: 'Configuration',
    link: 'configurations',
    idstate: 0,
    disable: false,
    accessRole: [
      {
        role: 'SUPERADMIN',
        authorize: [
          'LICENSE_FULL_ACCESS',
        ],
      },
      {
        role: 'LICENSECOMERCIAL',
        authorize: [
          'LICENSE_PROMOCODE_CREATE',
          'LICENSE_PROMOCODE_MODIFY',
          'LICENSE_PROMOCODE_READ',
          'LICENSE_PROMOCODE_DELETE',
        ],
      },
      {
        role: 'LICENSEPROMOCODE',
        authorize: [
          'LICENSE_PROMOCODE_CREATE',
          'LICENSE_PROMOCODE_MODIFY',
          'LICENSE_PROMOCODE_READ',
          'LICENSE_PROMOCODE_DELETE',
        ],
      },
    ],
  },
  {
    id: 2,
    description: 'Paquetes',
    code: 'PACKAGES',
    icon: 'Packages',
    link: 'packages',
    idstate: 0,
    disable: false,
    accessRole: [
      {
        role: 'SUPERADMIN',
        authorize: [
          'LICENSE_FULL_ACCESS',
        ],
      },
    ],
  },
  {
    id: 3,
    description: 'Órdenes de compra',
    code: 'PURCHASE_ORDERS',
    icon: 'Orders',
    link: 'registerPurchaseOrder',
    idstate: 0,
    disable: true,
    accessRole: [
      {
        role: 'SUPERADMIN',
        authorize: [
          'LICENSE_FULL_ACCESS',
        ],
      },
    ],
  },
  {
    id: 4,
    description: 'Licencias y consumos',
    code: 'LICENSES',
    icon: 'License',
    link: 'licenses',
    idstate: 0,
    disable: false,
    accessRole: [
      {
        role: 'SUPERADMIN',
        authorize: [
          'LICENSE_FULL_ACCESS',
        ],
      },
      {
        role: 'LICENSECOMERCIAL',
        authorize: [
          'LICENSE_PROMOCODE_CREATE',
          'LICENSE_PROMOCODE_MODIFY',
          'LICENSE_PROMOCODE_READ',
          'LICENSE_PROMOCODE_DELETE',
        ],
      },
      {
        role: 'LICFINANCIERO',
        authorize: [
          'LICENSE_FINANCIERO',
        ],
      },
    ],
  },
  {
    id: 5,
    description: 'Informes',
    code: 'REPORTS',
    icon: 'Reports',
    link: 'reports',
    idstate: 0,
    disable: false,
    accessRole: [
      {
        role: 'SUPERADMIN',
        authorize: [
          'LICENSE_FULL_ACCESS',
        ],
      },
    ],
  },
  {
    id: 6,
    description: 'Pagos',
    code: 'PAYMENTS',
    icon: 'Payments',
    link: 'payments',
    idstate: 0,
    disable: false,
    accessRole: [
      {
        role: 'SUPERADMIN',
        authorize: [
          'ReadPayments',
          'ApprovePayments',
          'ModifyPayments',
        ],
      },
      {
        role: 'LICFINANCIERO',
        authorize: [
          'ReadPayments',
          'ApprovePayments',
          'ModifyPayments',
          'LICENSE_FINANCIERO',
        ],
      },
      {
        role: 'LICFINANCIEROCONSULTA',
        authorize: [
          'ReadPayments',
          'LICENSE_FINANCIERO_CONSULTA',
        ],
      },
    ],
  },
];
