/* eslint-disable import/prefer-default-export */
import { createTheme, lighten } from '@material-ui/core/styles';
import logo from '../assets/img/logo/guarda.png';
import MuliFont from '../assets/font/muli_font/Muli-Light.ttf';

const muli = {
  fontFamily: 'Muli-Light',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Muli-Light'),
    local('Muli-Light'),
    url(${MuliFont}) format('ttf')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export default createTheme({
  app: {
    favicon: 'superguardaIco.ico',
    title: 'Guarda',
  },
  palette: {
    primary: {
      main: '#011E3D',
    },
    secondary: {
      main: '#F8FAFC',
    },
    error: {
      main: '#FF316F',
    },
  },
  typography: {
    fontFamily: 'Muli, Arial',
    fontSize: 13,
  },
  panelSummary: {
    color: '#335474',
  },
  resendEmailLink: {
    color: '#2499EF',
  },
  verifyEmailLink: {
    backgroundColor: '#2499EF',
    color: '#FFFFFF',
  },
  checkOptionFilter: {
    color: '#2499EF',
  },
  switchBase: {
    main: '#A7BEDA',
    track: '#CCDDF0',
    checked: '#2499EF',
    selectTrack: '#C6E7FF',
  },
  checkOptionHover: {
    color: '#2499EF',
  },
  logo,
  styleLogo: {
    zIndex: 1,
  },
  colorHeader: '#F1F1F1',
  header: {
    usserLogged: {
      color: '#044A95',
    },
  },
  home: {
    paquetes: {
      backgroundColor: '#044A95',
    },
    combos: {
      backgroundColor: '#FFEFD8',
      border: '1px solid #ED8D3D',
    },
    agregados: {
      backgroundColor: '#F6F6F6',
      border: '1px solid #B2B2B2',
    },
    backgroundSliderCard: {
      color: 'rgb(255, 255, 255, 0.1)',
    },
    bgColorHead: {
      bases:
        'orange,orange01,orange02,orange,orange01,orange02,orange,orange01,orange02,orange,orange01,orange02',
      combos: 'orange04',
      arrayComplementary:
        '1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4',
      complement: 'red',
      complementaryMyplan: 'orange01',
    },
    sliderComboText: {},
    sliderComplementText: {},
    sliderComplementaryButtonColor: {
      color: '#EDEDED',
    },
    sliderComplementaryButtonTextColor: {
      color: '#F44236',
    },
    sliderBackGroundColor: {},
    sliderArrow: {},
    shoppingCart: {
      color: '#4F4F4F',
    },
    cart: {
      dialogTitle: {},
      dialogContent: {},
      title: {
        color: '#011E3D',
      },
      tableHead: {
        backgroundColor: '#F8FAFC',
      },
      cardList: {},
      checkIconBox: {
        width: 15,
        height: 15,
        stroke: '#2cc63e',
      },
      listItem: {},
      discountText: {
        color: '#738499',
      },
      infoDescountTextResponsive: {
        color: '#738499',
      },
      totalText: {
        color: '#011E3D',
      },
      promoForm: {
        backgroundColor: '#FFFFFF',
        color: '#D3E6F3',
        border: '1px solid #FFFFFF',
        borderRadius: 8,
      },
      promoFormNoResponsive: {
        backgroundColor: '#FFFFFF',
        color: '#D3E6F3',
        border: '1px solid #D3E6F3',
        borderRadius: 8,
      },
      slider: {},
      quantityPackage: {},
      quantityPackageResponsive: {},
      dividerMiddle: {},
      listDividerBottom: {},
      btnPromotional: {
        backgroundColor: '#2499EF',
        color: '#FFFFFF',
        '&:hover': {
          backgroundColor: '#00152A',
        },
      },
      btnPromotionalResponsive: {
        backgroundColor: '#FFF',
        border: '1px solid',
      },
      btnCancelResponsive: {
        backgroundColor: '#FFF',
        color: '#335474',
        border: '1px solid',
      },
      tableCell: {
        color: '#335474',
      },
      colorItem: {},
      listItemPopover: {},
      dividerPopover: {},
      textPromotional: {
        backgroundColor: '#fff',
      },
      colorTextPromotional: {},
      colorNumberQuantity: {},
      btnContinue: {
        backgroundColor: '#2499EF',
        color: '#FFFFFF',
        fontSize: 14,
      },
      btnContinueResponsive: {
        backgroundColor: '#2499EF',
        color: '#FFFFFF',
      },
      btnAddPackages: {
        backgroundColor: '#fff',
        color: '#011E3D !important',
        border: '2px solid #011E3D !important',
        float: 'right',
        '&:hover': {
          backgroundColor: '#FEECD4',
        },
      },
      summaryCart: {
        backgroundColor: '#FFFFFF',
      },
      tableCellNoItems: {},
      subtotalText: {
        color: '#011E3D',
      },
      btnGoToCart: {
        backgroundColor: '#2499EF',
      },
      btnRemoveFromCart: {
        color: '#9D9D9D',
      },
      detailPackage: {
        backgroundColor: '#F4F4F4',
      },
      iconShoppingCart: {
        iconShoppingCart: {
          fill: '#CCCCCC',
          '& .b, .c': {
            stroke: '#CCCCCC',
          },
        },
      },
      hoverAddProducts: {
        backgroundColor: '#F1F1F1',
      },
      titleDetail: {
        color: '#011E3D',
      },
      seeMore: {
        color: '#2499EF',
      },
      seeLess: {
        color: '#2499EF',
      },
      span: {
        color: '#011E3D',
      },
      spanResposive: {},
      borderColor: {
        background: '#FFFFFF',
        border: '1px solid #FFFFFF',
      },
      customBadge: {
        backgroundColor: '#2CC63E',
      },
      customBadgeItems: 'white',
      helpIconColor: {
        color: '#738499',
      },
      helpIconColorLicense: {
        color: '#738499',
      },
      backgroundDialogResponsive: {
        backgroundColor: '#F5F8FA',
      },
      titleHeader: {
        color: '#011E3D',
      },
      deleteIconFromCart: {
        color: '#738499',
      },
      quantityActionCart: {
        color: '#329FEF',
      },
      typographyCard: {
        color: '#011E3D',
      },
      moreLessButton: {
        color: '#011E3D',
      },
      spacingContent: {},
      itemListStyles: {},
      itemListTextStyle: {
      },
    },
  },
  miplan: {
    bgColorCards: {},
    appBgColorCards: {
      boxShadow: 'none',
    },
    rootCard: {},
    title: {
      color: '#E55200',
    },
    subtitle: {
      color: '#6D6E72',
    },
    cardHeader: {
      color: '#E55200',
    },
    featureName: {},
    featureValue: {
      color: '#E55200',
    },
    buttonCard: {
      color: '#fff',
      backgroundColor: '#E45400',
    },
    hoverButtonCard: {
      color: '#fff',
      backgroundColor: '#F5AE19',
    },
    iconCard: {
      color: '#E55200',
    },
  },
  registerForm: {
    masterContainer: {},
    containerForm: {
      titleForm: {
        color: '#FFFFFF',
      },
    },
    activatePlan: {
      buttonActivate: {
        backgroundColor: '#E75200',
        color: '#fff',
      },
      policyAndTerms: {
        color: '#2499EF',
      },
    },
    btnCancel: {
      backgroundColor: '#2499EF',
      color: '#FFFFFF',
    },
    btnResponsive: {
      backgroundColor: '#2499EF',
      color: '#FFFFFF',
    },
    goHomeLink: {
      color: '#2499EF',
      fontSize: 18,
      fontFamily: 'Muli',
    },
    titleForm: {
      color: '#011E3D',
    },
    contentTextSecondary: {
      main: '#738499',
    },
    typeInput: {
      position: 'relative',
      borderRadius: '7px',
    },
    colorLabelFocus: {},
    colorLabel: '',
    colorInputError: '',
    checkDigit: {
      main: '#738499',
    },
    controlLabel: {
      color: '#011E3D',
    },
    colorBorderError: {},
    colorBorderNormal: {},
    dataTreatment: {},
    controlLabelModify: {},
    colorCkeckNormal: {},
    btnNoResponsiveCancel: {},
    handleBackMenuButton: {},
    typeInputValidationCode: {
      color: '#738499',
    },
    colorLabelValidationCode: '',
    colorBorderNormalValidationCode: {
      color: '#E9EFF6',
    },
  },
  statusLicense: {
    containerMessage: {
      backgroundColor: 'white',
    },
    titleStatusLicense: {
      color: '#2499EF',
    },
    summaryOrderPapper: {
      backgroundColor: '#FFFCF8',
    },
    buttonAction: {
      backgroundColor: '#2499EF',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#011E3D',
      },
    },
    appoveLicense: {},
    detailStatus: {
    },
  },
  alertPopup: {
    iconClose: {
      color: '#738499',
    },
    titleAlert: {
      color: '#011E3D',
    },
    buttonCancel: {
      backgroundColor: '#2499EF',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#011E3D',
      },
    },
    buttonAccept: {
      backgroundColor: '#2499EF',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#2499EF',
      },
    },
    secondaryMessageText: {
      color: '#c3c3c3',
    },
    mensajeModalMain: {},
  },
  summaryOrder: {
    loadFiles: {
      backgroundColor: '#F5F3F2',
      color: '#011E3D',
    },
    totalPurchase: {
      color: '#011E3D',
    },
    tableCellSummaryOrder: {
      color: '#011E3D',
      fontFamily: 'Mulish',
    },
    purchaseSummary: {
      color: '#647283',
      fontFamily: 'Mulish',
    },
    attachReceiptButtons: {
      color: '#011E3D',
      borderColor: '#011E3D',
    },
    textSummaryOrder: {
      '&.MuiTypography-h4': {
        marginLeft: 20,
        fontWeight: 'bold',
        color: '#011E3D',
      },
    },
    purchaseEmpty: {
      '&.MuiTypography-body1': {
        marginBottom: 10,
        fontWeight: 500,
      },
    },
    formLabelColor: {
      color: 'var(--unnamed-color-575756)',
    },
    linearProgress: {
      backgroundColor: lighten('#FFFFFF', 0.5),
    },
    progressBarBackgroundColor: '#009BDD',
    dividerStyles: {},
  },
  backOffice: {
    configurations: {
      title: {
        color: '#003582',
      },
      editIcon: {
        color: '#009BDD',
      },
    },
  },
  helpToolTip: {
    root: {
      backgroundColor: '#335474',
      color: '#FFFFFF',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      label: {
        fontWeight: 'bold',
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#F5F8FA',
          WebkitBackgroundSize: 'cover',
          MozBackgroundSize: 'cover',
          OBackgroundSize: 'cover',
          backgroundSize: 'cover',
        },
        '@font-face': [muli],
      },
    },
  },
});
