import { SET_AUTH_STATUS, SET_USER_INFO, SET_PARAMETERS_INFO, SET_DECODED_TOKEN, } from '../types/auth.type';

const INITIAL_STATE = {
  isAuth: undefined,
  userInfo: undefined,
  parameters: null,
  decodedToken: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_AUTH_STATUS:
      return {
        ...state,
        isAuth: action.payload,
      };
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    case SET_PARAMETERS_INFO:
      return {
        ...state,
        parameters: action.payload,
      };
    case SET_DECODED_TOKEN:
      return {
        ...state,
        decodedToken: action.payload,
      };  
    default:
      return state;
  }
};
