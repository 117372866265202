/* eslint-disable max-len */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import DetailItemCart from './DetailItemCart';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#FFFF',
  },
  Detail: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  titleModalCart: {
    ...theme.home.cart.title,
    fontWeight: 700,
    fontSize: 25,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  tableItemsCart: {
    minHeight: '420px',
    maxHeight: '420px',
    overflow: 'auto',
    '@media (max-width: 1080px)': {
      minHeight: '280px',
      maxHeight: '420px',
    },
  },
  tableCotainer: {
    marginBottom: 20,
    borderRadius: 10,
    paddingTop: 5,
    ...theme.home.cart.borderColor,
  },
  table: {
    minWidth: 300,
  },
  tableHead: {
    ...theme.home.cart.tableHead,
    heigth: 35,
  },
  dialogContent: {
    marginBottom: 1,
  },
  promoForm: {
    ...theme.home.cart.promoForm,
    width: 'fit-content',
    display: 'flex',
    alignItems: 'flex-start',
    borderRadius: 5,
    padding: 15,
    marginTop: 15,
    marginBottom: 20,
  },
  divider: {
    marginBottom: 20,
  },
  dividerMiddle: {
    ...theme.home.cart.dividerMiddle,
  },
  listDividerBottom: {
    ...theme.home.cart.listDividerBottom,
  },
  totalWrapper: {
    float: 'right',
  },
  totalText: {
    ...theme.home.cart.totalText,
    fontSize: 20,
  },
  taxInclude: {
    fontSize: 14,
    color: '#B4B4B4',
    textAlign: 'right',
    marginBottom: 15,
  },
  btnPromotional: {
    ...theme.home.cart.btnPromotional,
  },
  span: {
    ...theme.home.cart.span,
    fontWeight: 200,
    padding: 8,
  },
  spanResponsive: {
    ...theme.home.cart.spanResposive,
  },
  rowCell: {
    fontWeight: 700,
    cursor: 'default',
  },
  tableCellItems: {
    padding: 8,
  },
  textPromotional: {
    ...theme.home.cart.textPromotional,
    marginRight: 10,
  },
  btnContinue: {
    ...theme.home.cart.btnContinue,
  },
  btnRemoveFromCart: {
    ...theme.home.cart.btnRemoveFromCart,
  },
  deleteIconFromCart: {
    ...theme.home.cart.deleteIconFromCart,
  },
  dvTitlePopup: {
    width: '100%',
  },
  btnAddProducts: {
    ...theme.home.cart.btnAddPackages,
    float: 'right',
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
    '&:hover': {
      ...theme.home.cart.hoverAddProducts,
    },
  },
  infoDescountText: {
    ...theme.home.cart.discountText,
    fontSize: 12,
  },
  observationText: {
    color: '#fff',
    fontSize: 14,
  },
  linkContact: {
    color: '#16B1F3',
    fontSize: 14,
  },
  buttonCard: {
    ...theme.home.cart.moreLessButton,
  },
  cardHeader: {
    ...theme.miplan.cardHeader,
    fontSize: 25,
    padding: 0,
    minHeight: 70,
    '& .MuiTypography-displayBlock': {
      fontWeight: 600,
    },
  },
  noItems: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 24,
    '& .MuiTypography-body1': {
      ...theme.home.cart.noItems,
    },
  },
  iconShoppingCart: {
    ...theme.home.cart.iconShoppingCart,
  },
  titleDetail: {
    fontWeight: 'bold',
    ...theme.home.cart.titleDetail,
  },
  CardHeader: {
    fontWeight: 'bold',
  },
  seeMore: {
    ...theme.home.cart.seeMore,
  },
  seeLess: {
    ...theme.home.cart.seeLess,
  },
  titleHeader: {
    fontWeight: 'bold',
    fontSize: '19px',
    ...theme.home.cart.titleHeader,
  },
  quantityActionCart: {
    ...theme.home.cart.quantityActionCart,
    transform: 'scale(1.5)',
    height: '18px',
    width: '18px',
  },
  quantityPackageResponsive: {
    ...theme.home.cart.quantityPackageResponsive,
  },
  typographyCard: {
    ...theme.home.cart.typographyCard,
  },
}));
const CardModalExt = (props) => {
  const classes = useStyles();

  const {
    cart, cartClean, handleQuantity, quantityLicenseLocalEmployee,
    disabledFree, roundDecimalValue, getTotalByProduct,
    handleDelete, EMPLOYEE_TYPE,
  } = props;

  function ShowDetail(props) {
    const { id } = props;
    const [openRow, setOpenRow] = useState(false);

    const rangesList = cart.find((item) => item.packageId === id);

    return (
      <>
        <Collapse in={openRow} timeout="auto" unmountOnExit>
          <CardContent className={classes.Detail}>
            <Typography className={classes.titleDetail} paragraph>Detalle</Typography>
            <DetailItemCart
              card={rangesList}
            />
          </CardContent>
        </Collapse>
        <CardActions disableSpacing style={{ justifyContent: 'right' }}>
          <Button variant="text" className={classes.buttonCard} onClick={() => setOpenRow(!openRow)}>
            {
              !openRow
                ? (
                  <>
                    <Typography className={classes.seeMore}>
                      Ver más
                    </Typography>
                    <ExpandMoreIcon className={classes.seeMore} />
                  </>
                ) : (
                  <>
                    <Typography className={classes.seeLess}>
                      Ver menos
                    </Typography>
                    <ExpandLessIcon className={classes.seeLess} />
                  </>
                )
            }
          </Button>
        </CardActions>
      </>
    );
  }

  return (
    <>
      {
        cart.length ? (
          cartClean.map((row) => (
            <Card className={classes.tableCotainer} key={row.id}>
              <CardHeader
                classes={{ title: classes.titleHeader }}
                title={row.name}
                action={(
                  <Tooltip title="Eliminar">
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={(e) => handleDelete(e, row)}
                      className={classes.btnRemoveFromCart}
                    >
                      <DeleteIcon
                        color="secondary"
                        className={classes.deleteIconFromCart}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              />
              <Divider variant="middle" className={classes.dividerMiddle} />
              <CardContent>
                <Box>
                  <List dense disablePadding className={classes.listDividerBottom}>
                    <ListItem
                      divider
                      dense
                      disableGutters
                    >
                      <ListItemText
                        primary={(
                          <>
                            <Typography
                              component="span"
                              variant="body2"
                              color="textPrimary"
                              style={{
                                display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '16px',
                              }}
                              className={classes.typographyCard}
                            >
                              <span>Cantidad </span>
                              {row.isEmployee ? (
                                <FormControl>
                                  <TextField
                                    label="#empleados"
                                    size="small"
                                    variant="outlined"
                                    select
                                    onChange={(e) => { handleQuantity(e, row, EMPLOYEE_TYPE); }}
                                    name="quantity"
                                    defaultValue={row.number}
                                    style={{ textAlign: 'center', width: '100px' }}
                                  >
                                    <MenuItem value="">
                                      <em>Seleccione una opción</em>
                                    </MenuItem>
                                    {quantityLicenseLocalEmployee && quantityLicenseLocalEmployee.map((option) => (
                                      <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </FormControl>
                              )
                                : (
                                  <FormControl>
                                    <TextField
                                      style={{ alignItems: 'flex-end' }}
                                      variant="outlined"
                                      size="small"
                                      type="number"
                                      disabled={disabledFree(row)}
                                      name="quantity"
                                      className={classes.quantityPackageResponsive}
                                      autoComplete="off"
                                      value={row.number}
                                      InputProps={{
                                        min: '1',
                                        max: '999',
                                        step: '1',
                                        style: {
                                          backgroundColor: '#FFFFFF2E', width: '100px', height: '26px', padding: 0,
                                        },
                                        startAdornment: (
                                          <div>
                                            <RemoveIcon id="remove" onClick={(e) => { handleQuantity(e, row, null, row.number, 1); }} className={classes.quantityActionCart} fontSize="medium" style={{ width: '14px', marginTop: '6px', marginLeft: '8px' }} />
                                          </div>
                                        ),
                                        endAdornment: (
                                          <div>
                                            <AddIcon id="add" onClick={(e) => { handleQuantity(e, row, null, row.number + 1, 2); }} className={classes.quantityActionCart} style={{ width: '14px', marginTop: '6px', marginRight: '8px' }} />
                                          </div>
                                        ),
                                      }}
                                      // eslint-disable-next-line react/jsx-no-duplicate-props
                                      inputProps={{
                                        style: { textAlign: 'center' },
                                      }}
                                    />
                                  </FormControl>
                                )}
                            </Typography>
                            <Typography
                              component="span"
                              variant="body2"
                              color="textPrimary"
                              style={{
                                display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '16px',
                              }}
                              className={classes.typographyCard}
                            >
                              <span>Valor unitario </span>
                              <span className={classes.span}>{`$${row.base.toLocaleString('en')}`}</span>
                            </Typography>
                            <Typography
                              component="span"
                              variant="body2"
                              color="textPrimary"
                              style={{
                                display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '16px',
                              }}
                              className={classes.typographyCard}
                            >
                              <span>Descuento </span>
                              <span className={classes.span}>{`$${roundDecimalValue(row.discount, 2).toLocaleString('en')}`}</span>
                            </Typography>
                            <Typography
                              component="span"
                              variant="body2"
                              color="textPrimary"
                              style={{
                                display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '16px',
                              }}
                              className={classes.typographyCard}
                            >
                              <span>IVA </span>
                              <span className={classes.span}>{`${row.ivaValue}%`}</span>
                            </Typography>
                            <Typography
                              component="span"
                              variant="body2"
                              color="textPrimary"
                              style={{
                                display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '16px',
                              }}
                            >
                              <span style={{ fontWeight: 'bold' }} className={classes.spanResponsive}>Subtotal</span>
                              <span className={classes.span} style={{ fontWeight: 900 }}>{getTotalByProduct(row)}</span>
                            </Typography>
                          </>
                        )}
                      />
                    </ListItem>
                  </List>
                </Box>
              </CardContent>
              <ShowDetail
                id={row.id}
              />
            </Card>
          ))
        ) : (
          <CardContent className={classes.noItems} key={0}>
            <Typography style={{ marginBottom: 10, fontWeight: 1000 }}>
              ¡Tu carrito está vacío!
            </Typography>
            <AddShoppingCartIcon className={classes.iconShoppingCart} />
          </CardContent>
        )
      }
    </>
  );
};

const mapStateToProps = () => ({

});

const mapDispatchToProps = {
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CardModalExt));
