import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import PromoTable from './PromoTable';
import ModalPromo from './ModalPromo';
import newButton from '../../../../assets/img/iconos/ibuhoo/newButton.svg';
import searchIcon from '../../../../assets/img/iconos/ibuhoo/searchIcon.svg';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  searchTextField: {
    boxShadow: '0px 3px 2px #00000026',
    width: '504px',
    borderRadius: '10px',
    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
    border: '1px solid #D5D5D5',
    opacity: 1,
    height: '33px',
  },
}));

const PromoCode = () => {
  const classes = useStyles();
  const [openModalNewPromo, setOpenModalNewPromo] = useState(false);
  const [promoCodeSelected, setPromoCodeSelected] = useState(null);
  const [enteredFilter, setEnteredFilter] = useState('');

  return (
    <>
      <div className={classes.root}>
        <div>
          <TextField
            className={classes.searchTextField}
            value={enteredFilter}
            onChange={(e) => setEnteredFilter(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={searchIcon} alt="searchIcon" style={{ paddingLeft: '10px' }} />
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
            placeholder="Buscar"
          />
          <Tooltip title="Crear código de promoción" aria-label="Nuevo código">
            <IconButton
              onClick={() => setOpenModalNewPromo(true)}
              style={{ fontSize: '20px', float: 'right' }}
              edge="end"
              size="small"
            >
              <img src={newButton} alt="newButton" />
            </IconButton>
          </Tooltip>
        </div>
        <PromoTable
          setPromoCodeSelected={setPromoCodeSelected}
          setOpenModalNewPromo={setOpenModalNewPromo}
          enteredFilter={enteredFilter}
          promoCodeSelected={promoCodeSelected}
        />
      </div>
      <ModalPromo
        onCloseModal={() => setOpenModalNewPromo(false)}
        open={openModalNewPromo}
        promotionalCodeSelected={promoCodeSelected}
        setPromoCodeSelected={setPromoCodeSelected}
      />
    </>
  );
};

export default PromoCode;
