/* eslint-disable react-hooks/exhaustive-deps  */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { isValidUsertoConfigurations } from '../../../actions/auth.action';
import { getTokenExternalSourceAction, clearMessageAlertAction } from '../../../actions/general.action';
import {
  getLicenseUsageCustomerAction,
  getClearLicensesCustomerAction,
} from '../../../actions/license.action';
import { getServicesAction } from '../../../actions/service.action';
import searchIcon from '../../../assets/img/iconos/ibuhoo/searchIcon.svg';
import LicensesTableList from './LicensesTableList';
import ModalValidLicenses from './ModalValidLicenses';
import Loading from '../../../components/crosscutting/Loading';
import newButton from '../../../assets/img/iconos/ibuhoo/newButton.svg';
import reportToManagement from '../../../assets/img/iconos/license/ReportToManagement.svg';
import CreateCustomer from './Customer/CreateCustomer';
import AddNewLicense from '../../../components/application/AddNewLicense';
import ModalAddNewLicense from '../../../components/application/AddNewLicense/ModalAddNewLicense';
import AlertConfirm from '../../../components/crosscutting/AlertConfirm';

const useStyles = makeStyles((theme) => ({
  paper: {
    background: 'linear-gradient(#FFFFFFCC, #ffffff)',
    borderRadius: '30px',
    opacity: 1,
    padding: '5px',
    marginTop: '20px',
  },
  searchTextField: {
    marginLeft: '5px',
    boxShadow: '0px 3px 2px #00000026',
    width: '100%',
    borderRadius: '10px',
    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
    border: '1px solid #D5D5D5',
    opacity: 1,
    height: '33px',
  },
  paperGroup: {
    backgroundColor: '#fff',
    margin: 20,
    borderRadius: 10,
    border: '1px solid #32ACDE',
    padding: 20,
  },
  title: {
    ...theme.backOffice.configurations.title,
    fontWeight: 600,
    fontSize: '26px',
    paddingTop: '15px',
    paddingLeft: '20px',
  },
  cardsLicenses: {
    paddingTop: 20,
  },
  button: {
    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
    backgroundColor: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #707070',
    borderRadius: '5px',
    font: 'normal normal Muli',
    opacity: 1,
    letterSpacing: '0px',
    display: 'inline-block',
    '&:hover': {
      background: '#E55200',
      color: '#FFFFFF',
    },
  },
  imageReport: {
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  addNewCustomer: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  contentAddNewLicense: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    marginTop: 20,
  },
  sectionAddNewLicense: {
    borderRadius: '10px',
    backgroundColor: '#fff',
    border: '1px solid #32ACDE',
    padding: '10px',
    marginBottom: '20px',
  },
  recordNotFound: {
    justifyContent: 'left',
    color: '#00A8E7',
    minHeight: 100,
  },
  accessDeniedContainer: {
    height: 300,
  },
  accessDenied: {
    color: '#272727',
    fontWeight: 500,
    fontSize: '20px',
    paddingTop: '15px',
    paddingLeft: '18px',
  },
}));

const Licenses = (props) => {
  const classes = useStyles();
  const {
    loading, getTokenExternalSource, appName, decodedToken, getServices, clearMessageAlert,
    licenseCustomer, getLicenseUsageCustomer, getClearLicensesCustomer, licenseCustomerRowsNum,
    nextSkipLicense, showMessageAlert, addLicenseCustomer,
    location,
  } = props;

  const [showFunctionality, setShowFunctionality] = useState(false);
  const [enteredFilter, setEnteredFilter] = useState('');
  const [openModalLicenses, setOpenModalLicenses] = useState(false);
  const [openModalAddLicense, setOpenModalAddLicense] = useState(false);
  const [createCustomer, setCreateCustomer] = useState(false);
  const [licenseSelected, setLicenseSelected] = useState(null);
  const [page, setPage] = useState(0);
  const [showListCustomers, setShowListCustomers] = useState(true);
  const [showAddLicense, setShowAddLicense] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [openAlertInfo, setOpenAlertInfo] = useState(false);
  const [confirmCancelAlert, setConfirmCancelAlert] = useState(null);

  useEffect(() => {
    if (isValidUsertoConfigurations(decodedToken, 'LICENSES') && appName === 'license') {
      setShowFunctionality(true);
      getServices();
      getLicenseUsageCustomer(0, 10, '', null);
    }
    const appsAdminToken = localStorage.getItem('tokenAdminIbuho');
    if (!appsAdminToken) getTokenExternalSource();
  }, []);

  useEffect(() => {
    if (showMessageAlert) {
      setOpenAlertInfo(true);
    }
  }, [showMessageAlert]);

  useEffect(() => {
    if (addLicenseCustomer) {
      setShowAddLicense(false);
      setShowListCustomers(true);
      setCreateCustomer(false);
      setCustomerId(null);
      if (addLicenseCustomer === true) {
        setInterval(() => { window.location.reload(); }, 3000);
      }
    }
  }, [addLicenseCustomer]);

  const handleOpenModalViewLicense = (id) => {
    setLicenseSelected(id);
    setOpenModalLicenses(true);
  };

  const handleOpenModalAddLicense = (id) => {
    setLicenseSelected(id);
    setOpenModalAddLicense(true);
  };

  const handleChangePage = (event, newPage, rowsPerPage) => {
    const numRows = rowsPerPage;
    const last = nextSkipLicense > 0
      ? nextSkipLicense
      : licenseCustomer?.length;
    if (last < (newPage + 1) * numRows) {
      if (nextSkipLicense < licenseCustomerRowsNum) {
        getLicenseUsageCustomer(last, 10, enteredFilter, null);
      }
    }
    setPage(newPage);
  };

  const handleSearch = () => {
    getClearLicensesCustomer();
    getLicenseUsageCustomer(0, 10, enteredFilter, null);
    setPage(0);
  };

  const handleAddNewLicense = (id) => {
    setShowAddLicense(id > 0);
    setShowListCustomers(false);
    setCustomerId(id);
  };

  const handleCancelAddLicense = () => {
    setShowAddLicense(false);
    setShowListCustomers(true);
    setCustomerId(null);
  };

  const handleClickCancelAlert = () => {
    if (showMessageAlert) {
      switch (showMessageAlert.category) {
        case 'createNewLicense':
          setOpenAlertInfo(false);
          break;
        case 'cancelAddNewLicense':
          setOpenAlertInfo(false);
          setConfirmCancelAlert('CancelAddLicense');
          break;
        case 'cancelEditLicense':
          setOpenAlertInfo(false);
          setConfirmCancelAlert('CancelEditLicense');
          setOpenModalAddLicense(false);
          break;
        default:
          setOpenAlertInfo(false);
          break;
      }
    }
    clearMessageAlert();
  };

  const handleClickOkAlert = () => {
    if (showMessageAlert) {
      switch (showMessageAlert.category) {
        case 'createNewLicense':
          setOpenAlertInfo(false);
          break;
        case 'cancelAddNewLicense':
          setOpenAlertInfo(false);
          break;
        case 'cancelEditLicense':
          setOpenAlertInfo(false);
          break;
        default:
          break;
      }
    }
    setConfirmCancelAlert('Ok');
    clearMessageAlert();
  };

  const handleShowCreateCustomer = () => {
    setCreateCustomer(true);
    setShowListCustomers(false);
    setShowListCustomers(false);
    setShowAddLicense(false);
  };

  if (loading) return <Loading />;

  return (
    <>
      <Container component="main" maxWidth="lg">
        <Paper elevation={3} className={classes.paper}>
          <Typography color="primary" variant="h4" className={classes.title}>Licencias y consumos</Typography>
          {
            showFunctionality
              ? (
                <>
                  {
                    showListCustomers && (
                      <Paper elevation={3} className={classes.paperGroup}>
                        <Box>
                          <Grid container spacing={2}>
                            <Grid item xs={12} lg={8}>
                              <TextField
                                className={classes.searchTextField}
                                value={enteredFilter}
                                onChange={(e) => setEnteredFilter(e.target.value)}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <img src={searchIcon} alt="searchIcon" style={{ paddingLeft: '10px' }} />
                                    </InputAdornment>
                                  ),
                                  disableUnderline: true,
                                  onBlur: () => handleSearch(),
                                }}
                                placeholder="Buscar"
                              />
                            </Grid>
                            <Grid item xs={10} lg={3}>
                              <Button
                                className={classes.button}
                                disabled
                                style={{ display: 'none' }}
                              >
                                <img src={reportToManagement} alt="icono-reporte" />
                                Reporte a gerencia
                              </Button>
                            </Grid>
                            <Grid item xs={2} lg={1} className={classes.addNewCustomer}>
                              <Tooltip title="Crear empresa" aria-label="Crear nueva empresa">
                                <IconButton
                                  onClick={() => { handleShowCreateCustomer(); }}
                                  edge="end"
                                  size="small"
                                >
                                  <img src={newButton} alt="newButton" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Box>
                        <Grid container className={classes.cardsLicenses}>
                          {licenseCustomer?.length > 0 ? (
                            <LicensesTableList
                              customerServicelicense={licenseCustomer}
                              countRecordsList={licenseCustomerRowsNum}
                              handleOpenModalViewLicense={handleOpenModalViewLicense}
                              handleOpenModalAddLicense={handleOpenModalAddLicense}
                              handleChangePage={handleChangePage}
                              setPage={setPage}
                              page={page}
                              handleAddNewLicense={handleAddNewLicense}
                            />
                          ) : (
                            <Grid container className={classes.recordNotFound}>
                              <Typography>No existe información correspondiente a los criterios de búsqueda</Typography>
                            </Grid>
                          )}
                        </Grid>
                      </Paper>
                    )
                  }
                  {
                    showAddLicense && (
                      <Box className={classes.contentAddNewLicense}>
                        <Grid container item xs={6} spacing={2} className={classes.sectionAddNewLicense}>
                          <Grid item xs={12}>
                            <AddNewLicense
                              customerId={customerId}
                              setShowAddLicense={setShowAddLicense}
                              handleCancelAddLicense={handleCancelAddLicense}
                              confirmCancelAlert={confirmCancelAlert}
                              setConfirmCancelAlert={setConfirmCancelAlert}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    )
                  }
                  {
                    createCustomer && (
                      <CreateCustomer
                        location={location}
                      />
                    )
                  }
                </>
              ) : (
                <Box className={classes.accessDeniedContainer}>
                  <Typography color="primary" variant="h4" className={classes.accessDenied}>
                    No tienes los suficientes privilegios para realizar esta acción.
                  </Typography>
                </Box>
              )
          }
        </Paper>
        {
          openModalLicenses && (
            <ModalValidLicenses
              onCloseModal={() => setOpenModalLicenses(false)}
              open={openModalLicenses}
              licenseSelected={licenseSelected}
              setLicenseSelected={setLicenseSelected}
              setConfirmCancelAlert={setConfirmCancelAlert}
              confirmCancelAlert={confirmCancelAlert}
            />
          )
        }
        {
          openModalAddLicense && (
            <ModalAddNewLicense
              onCloseModal={() => setOpenModalAddLicense(false)}
              open={openModalAddLicense}
              licenseSelected={licenseSelected}
            />
          )
        }
        {
          showMessageAlert && (
            <AlertConfirm
              isOpen={openAlertInfo}
              mensajeModal={showMessageAlert.message}
              isOnlyMessage={showMessageAlert.isOnlyMessage}
              showClose={showMessageAlert.showClose}
              showCloseAlert={showMessageAlert.showCloseAlert}
              onCancel={showMessageAlert.showButtonCancel}
              onClose={handleClickCancelAlert}
              handleAccept={handleClickOkAlert}
              setOpenAlertInfo={setOpenAlertInfo}
              textButtonAcept={showMessageAlert.textButtonAcept}
              textButtonCancel={showMessageAlert.textButtonCancel}
              messageAlert={showMessageAlert}
            />
          )
        }
      </Container>
    </>
  );
};

const mapStateToProps = (props) => ({
  appName: props.general.appName,
  showMessageAlert: props.general.showMessageAlert,
  decodedToken: props.auth.decodedToken,
  loading: props.license.loading,
  licenseCustomer: props.license.licenseCustomer,
  licenseCustomerRowsNum: props.license.licenseCustomerRowsNum,
  nextSkipLicense: props.license.nextSkipLicense,
  addLicenseCustomer: props.license.addLicenseCustomer,
});

const mapDispatchToProps = {
  getTokenExternalSource: getTokenExternalSourceAction,
  getLicenseUsageCustomer: getLicenseUsageCustomerAction,
  getClearLicensesCustomer: getClearLicensesCustomerAction,
  getServices: getServicesAction,
  clearMessageAlert: clearMessageAlertAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Licenses);
