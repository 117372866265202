/* eslint-disable linebreak-style */
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { makeStyles } from '@material-ui/core/styles';
import { Controller } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(() => ({
  iconCustom: {
    '& .MuiIconButton-root': {
      color: '#009fe3',
    },
  },
  formControlCustom: {
    width: '100%',
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" color="primary" />;

const CustomAutocomplete = (props) => {
  const {
    options, label, placeholder, className, disabled, registersSelected, defaultValue,
    setData, control, rule, name, error, limitTags, maxNumberRegistersSelected,
    hideCheck,
  } = props;
  const [isOptionSelected, setOptionSelected] = React.useState([]);
  const handleChangeOptionSelected = (event) => {
    setOptionSelected(event);
  };

  const classes = useStyles();

  return (

    <FormControl className={classes.formControlCustom}>
      <Controller
        render={(propsComponente) => (
          <Autocomplete
            autoSelect
            freeSolo
            multiple
            size="small"
            options={options}
            noOptionsText="No hay registros"
            disableClearable
            className={!disabled && classes.iconCustom}
            disabled={disabled}
            limitTags={limitTags}
            required
            filterSelectedOptions
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.text)}
            value={registersSelected}
            renderOption={(option, { selected }) => (
              <>
                {hideCheck && (
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                )}
                {option.text}
              </>
            )}
            onChange={(_, data) => {
              if (maxNumberRegistersSelected && maxNumberRegistersSelected > 0 && data && data.length > 0) {
                if (data.length <= (maxNumberRegistersSelected + 1)) {
                  const customData = [];
                  data.forEach((element) => {
                    const isSelected = (registersSelected || []).includes(element);
                    if (isSelected && isOptionSelected.length === 0) {
                      customData.push(element);
                    }
                  });
                  propsComponente.onChange(customData);
                  setData(customData);
                  handleChangeOptionSelected(data);
                }
              } else {
                propsComponente.onChange(data);
                setData(data);
                handleChangeOptionSelected(data);
              }
            }}
            renderInput={(params) => (
              <TextField
                className={className}
                {...params}
                variant="outlined"
                label={label}
                error={error}
                placeholder={placeholder}
                FormHelperTextProps={{ style: { margin: '0 0 0 5px', color: '#d61103' } }}
                helperText={error ? 'Campo obligatorio' : ''}
              />
            )}
            onBlur={() => {
              const updatedData = (registersSelected || []).filter(
                (item) => registersSelected.some((selectedItem) => selectedItem === item),
              );
              propsComponente.onChange(updatedData);
              setData(updatedData);
            }}
          />
        )}
        name={name}
        control={control}
        rules={rule}
        defaultValue={defaultValue || ''}
      />
    </FormControl>
  );
};

export default CustomAutocomplete;
