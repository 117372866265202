import aesjs from 'aes-js';
import {
  ENCRYPTION_KEY, ENCRYPTION_IV, LOCAL_STORAGE_ENCRYPT,
} from '../config/config';

function encriptString(textString) {
  try {
    const aesCtr = new aesjs.ModeOfOperation.cbc(ENCRYPTION_KEY, ENCRYPTION_IV);
    const requestTextBytesUtf8 = aesjs.utils.utf8.toBytes(textString);
    const requestPaddedData = aesjs.padding.pkcs7.pad(requestTextBytesUtf8);
    const encryptedBytes = aesCtr.encrypt(requestPaddedData);
    const encryptedTextBase64 = Buffer.from(encryptedBytes).toString('base64');
    return encryptedTextBase64;
  } catch (err) {
    console.error(err);
    return textString;
  }
}

/**
 * limpia el querystring
 *
 * @method
 * @param {paramsChar} paramsChar
 */
function clearQueryVariable(paramsChar) {
  try {
    // preservar nuevas líneas, etc. - JSON válido
    let paramsCharLocal = paramsChar
      .replace(/\\n/g, '\\n')
      .replace(/\\'/g, "\\'")
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, '\\&')
      .replace(/\\r/g, '\\r')
      .replace(/\\t/g, '\\t')
      .replace(/\\b/g, '\\b')
      .replace(/\\f/g, '\\f')
      .replace(/ñ/g, '\\u00F1')
      .replace(/[áÁ]/g, '\\u00E1')
      .replace(/[éÉ]/g, '\\u00E9')
      .replace(/[íÍ]/g, '\\u00ED')
      .replace(/[óÓ]/g, '\\u00F3')
      .replace(/[úÚ]/g, '\\u00FA');
    // eliminar caracteres JSON no imprimibles y otros no válidos
    paramsCharLocal = paramsCharLocal.replace(/[\000-\031\200-\377]+/g, '');
    return paramsCharLocal;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error limpiando parametros:', err);
    return paramsChar;
  }
}

function decryptString(encryptedTextBase64) {
  try {
    const paramsChar = decodeURIComponent(encryptedTextBase64);
    const aesCbc = new aesjs.ModeOfOperation.cbc(ENCRYPTION_KEY, ENCRYPTION_IV);
    const encryptedtext = aesjs.utils.hex.toBytes(
      new Buffer(paramsChar || '', 'base64').toString('hex'),
    );
    const decryptedBytes = aesCbc.decrypt(encryptedtext);
    const decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);

    const cleanParamsObj = clearQueryVariable(decryptedText);

    return cleanParamsObj;
  } catch (err) {
    console.error(err);
  }
}

/**
 * Adiciona al localStorage encriptando la cadena
 * @param {*} value valor a encriptar
 * @param {*} key llave encripcion
 */
export const addLocalStorageEncript = (value, key) => {
  try {
    if (LOCAL_STORAGE_ENCRYPT) {
      localStorage.setItem(key, encriptString(value));
    } else {
      localStorage.setItem(key, value);
    }
  } catch (err) {
    console.error(err);
  }
};

/**
 *
 * @param {*} key llave de desencripción
 * @returns retorna valor desecnriptado
 */
export const getLocalStorageEncript = (key) => {
  try {
    const value = localStorage.getItem(key);
    if (value !== undefined && value !== null) {
      if (LOCAL_STORAGE_ENCRYPT) {
        return decryptString(value);
      }
      return value;
    }
  } catch (err) {
    console.error(err);
  }
};

/**
 * Limpia de localStorage keys tienda
 */
export const clearLocalStorageStore = () => {
  localStorage.removeItem('cartAdded');
  localStorage.removeItem('pricesLicense');
  localStorage.removeItem('quantityLicense');
  localStorage.removeItem('packages');
  localStorage.removeItem('cart');
  localStorage.removeItem('promoCode');
};
