/* eslint-disable linebreak-style */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { Controller } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(() => ({
  iconCustom: {
    '& .MuiIconButton-root': {
      color: '#009fe3',
    },
  },
  formControlCustom: {
    width: '100%',
  },
}));

const CustomAutocompleteSelect = (props) => {
  const {
    options, label, disableClearable, value, FormHelperTextProps, disabled, className,
    required, control, rule, name, error, handleOnchange, getOptionLabel, defaultValue,
  } = props;

  const classes = useStyles();

  return (

    <FormControl className={classes.formControlCustom}>
      <Controller
        render={(propsComponent) => (
          <Autocomplete
            {...propsComponent}
            options={options}
            noOptionsText="No hay registros"
            size="small"
            disableClearable={disableClearable}
            disabled={disabled}
            required={required}
            fullWidth
            getOptionLabel={getOptionLabel}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                variant="outlined"
                required={required}
                error={Boolean(error[name])}
                style={{ marginRight: 10, color: 'red' }}
                FormHelperTextProps={FormHelperTextProps}
                helperText={error[name] ? error[name].message : null}
                className={className}
              />
            )}
            value={value}
            onChange={(_, data) => {
              propsComponent.onChange(data);
              if (data) handleOnchange(data);
            }}
          />
        )}
        name={name}
        control={control}
        rules={rule}
        error={Boolean(error[name])}
        defaultValue={defaultValue || ''}
      />
    </FormControl>
  );
};

export default CustomAutocompleteSelect;
