/* eslint-disable import/prefer-default-export */
export const packageSliderConfig = [
  {
    packageTypeId: '1',
    packageType: 'base',
    color: [
      {
        id: '1',
        bgColor: '#0093D2',
        actionColor: '#4DB4E0',
        searchColor: '#20789E',
      },
      {
        id: '2',
        bgColor: '#00B9DF',
        actionColor: '#4DCEE9',
        searchColor: '#7FD2FC',
      },
      {
        id: '3',
        bgColor: '#47BEFB',
        actionColor: '#4DB4E0',
        searchColor: '#4B92B7',
      },
    ],
  },
  {
    packageTypeId: '2',
    packageType: 'combo',
    color: [
      {
        id: '1',
        bgColor: '#F44236',
        actionColor: '#F77B73',
        searchColor: '#B24840',
      },
      {
        id: '2',
        bgColor: '#F46F66',
        actionColor: '#F79A94',
        searchColor: '#B1625C',
      },
    ],
  },
  {
    packageTypeId: '3',
    packageType: 'complementary',
    color: [
      {
        id: '1',
        bgColor: '#41B764',
        actionColor: '#7ACD93',
        searchColor: '#478E5C',
      },
      {
        id: '2',
        bgColor: '#269995',
        actionColor: '#68B8B5',
        searchColor: '#377C79',
      },
    ],
  },
];
