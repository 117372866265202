import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { LeftMenu } from '@dg-bucaramanga/react-components-dg-pre';
import jwtdecode from 'jwt-decode';
import Header from '../Header';
import { menuBackOfficeBuilt } from '../../../helpers/menuConfig';

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    marginRight: 10,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    top: '64px',
    width: drawerWidth,
    background: 'none',
    borderRight: 'none',
  },
  content: {
    flexGrow: 1,
    padding: '0px',
  },
}));

// eslint-disable-next-line react/prop-types
const MasterPage = ({ component: Component, container, ...props }) => {
  const classes = useStyles();
  const { location } = props;
  const [menuReducer, setMenuReducer] = React.useState();
  const appName = useSelector(({ general }) => general.appName);
  const history = useHistory();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [homePage, setHomePage] = useState('');
  const [token, setToken] = useState({
    result: {
      token: '',
    },
    error: {},
  });

  const getToken = (currentToken) => {
    let jwt;
    if (currentToken) {
      jwt = currentToken;
    } else {
      jwt = localStorage.getItem('appsUserToken');
    }

    if (jwt) {
      const jwtToken = jwt.replace('Bearer ', '');
      setToken((oldState) => ({
        ...oldState,
        result: {
          ...oldState.result,
          token: jwtToken,
        },
      }));
      const user = jwtdecode(jwtToken);
      if (user.LICENSE_ROLE === 'LICFINANCIERO' || user.LICENSE_ROLE === 'LICFINANCIEROCONSULTA') {
        setHomePage('/admin/payments');
      } else {
        setHomePage('/admin/configurations');
      }
      return true;
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    const menuReducerBuilt = menuBackOfficeBuilt;
    setMenuReducer(menuReducerBuilt);
    getToken();
  }, []);

  const drawMenu = () => (
    <>
      <div>
        {token.result.token && !!menuReducer && menuReducer.length > 0 ? (
          <>
            <Hidden xsDown>
              <LeftMenu
                open
                token={token.result.token}
                appName="license"
                drawerProps={{
                  variant: 'permanent',
                  open: true,
                  classes: {
                    paper: classes.drawer,
                  },
                }}
                version=""
                functions={[]}
              />
            </Hidden>
            <Hidden smUp>
              <LeftMenu
                open
                token={token.result.token}
                appName="license"
                drawerProps={{
                  variant: 'temporary',
                  anchor: theme.direction === 'rtl' ? 'right' : 'left',
                  open: mobileOpen,
                  onClose: handleDrawerToggle,
                  ModalProps: {
                    keepMounted: true,
                  },
                  classes: {
                    paper: classes.drawer,
                  },
                }}
                version=""
                functions={[]}
              />
            </Hidden>
          </>
        ) : null}
      </div>
    </>
  );

  return (
    <>
      {appName === 'license' ? (
        <div className={classes.root}>
          <CssBaseline />
          <Header />
          {
            location.pathname.toLowerCase() !== '/myplan'
              ? (
                <nav
                  className={`${classes.drawer} 'custom-drawer'`}
                  aria-label="mailbox folders"
                >

                  <Hidden xsDown implementation="css">
                    <Drawer
                      classes={{
                        paper: classes.drawerPaper,
                      }}
                      variant="permanent"
                      open
                    >
                      {drawMenu()}
                    </Drawer>
                  </Hidden>
                </nav>
              )
              : ''
          }

          <main className={classes.content}>
            <div className={classes.toolbar} />
            <br />
            <br />
            <br />
            {location.pathname === '/' ? (
              history.push(homePage)
            ) : (
            // eslint-disable-next-line react/jsx-props-no-spreading
              <Component {...props} />
            )}
          </main>
        </div>
      ) : (
        <>
          {location.pathname.includes('/admin/') ? (
            history.push('/')
          ) : (
            <>
              <Header />
              <Component {...props} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default MasterPage;
