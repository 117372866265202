import axios from 'axios';
import {
  SECURITY_LOADING, SECURITY_GET_USERDATA_BY_ID, SECURITY_GET_USERCREATE_BY_ID, SECURITY_GET_USERMODIFY_BY_ID,
} from '../types/security.type';
import { API_SEGURIDAD } from '../config/config';

// eslint-disable-next-line import/prefer-default-export
export const getUserDataByIdAction = (userId, type) => async (dispatch) => {
  try {
    dispatch({ type: SECURITY_LOADING, payload: true });
    dispatch({ type: SECURITY_GET_USERCREATE_BY_ID, payload: undefined });
    dispatch({ type: SECURITY_GET_USERMODIFY_BY_ID, payload: undefined });
    const defaultHeaders = () => ({
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${localStorage.getItem('tokenAdminIbuho')}`,
        iduser: userId,
      },
    });
    const config = defaultHeaders();
    const response = await axios.get(`${API_SEGURIDAD}/api/user/GetUserByNameId`, config);
    if (response.status === 200) {
      dispatch({ type: SECURITY_GET_USERDATA_BY_ID, payload: response.data.result });
      if (type === 'CREATE') dispatch({ type: SECURITY_GET_USERCREATE_BY_ID, payload: response.data.result });
      if (type === 'MODIFY') dispatch({ type: SECURITY_GET_USERMODIFY_BY_ID, payload: response.data.result });
      dispatch({ type: SECURITY_LOADING, payload: false });
    }
  } catch (error) {
    console.error(error);
  }
};
