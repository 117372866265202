import {
  LOADING, GET_CITIESBILLING_ASSISTANT_GOOGLE, GET_CITIESSERVICE_ASSISTANT_GOOGLE,
} from '../types/google.type';

const INITIAL_STATE = {
  loading: false,
  citiesBillGoogleAssistant: undefined,
  citiesServGoogleAssistant: undefined,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_CITIESBILLING_ASSISTANT_GOOGLE:
      return {
        ...state,
        citiesBillGoogleAssistant: action.payload,
      };
    case GET_CITIESSERVICE_ASSISTANT_GOOGLE:
      return {
        ...state,
        citiesServGoogleAssistant: action.payload,
      };
    default:
      return state;
  }
};
