/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-array-index-key */
/* eslint-disable radix */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Checkbox,
  Grid,
  Card,
  CardActions,
  CardContent,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  MenuItem,
  TextField,
  Typography,
  Tooltip,
} from '@material-ui/core/';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import packageCrown from '../../../assets/img/sliderCard/packageCrown.svg';
import searchIcon from '../../../assets/img/iconos/ibuhoo/searchIcon.svg';
import LogoColfactura from '../../../assets/img/logo/colfactura.png';
import LogoColnomina from '../../../assets/img/logo/colnomina.png';
import LogoFirmaya from '../../../assets/img/logo/firmaYa.svg';
import LogoSuperGuarda from '../../../assets/img/logo/firmaYa.svg';
import LogoFirmamail from '../../../assets/img/logo/firmaEmail.svg';
import LogoGSE from '../../../assets/img/logo/gse.svg';
import DescriptionPackageIcon from '../../../assets/img/iconos/license/DescriptionPackageIcon.svg';
import ChannelIcon from '../../../assets/img/iconos/license/ChannelIcon.svg';
import CompanyIcon from '../../../assets/img/iconos/license/CompanyIcon.svg';
import PromoCodeIcon from '../../../assets/img/iconos/license/PromoCodeIcon.svg';
import Loading from '../../crosscutting/Loading';
import InfoPackage from './InfoPackage';
import { packageSliderConfig } from './packageSliderConfig';
import {
  addFeatureToPackageAction,
  selectFeatureToPackageAction,
  searchFeatureToPackageAction,
  getCustomerLinkedPackageAction,
} from '../../../actions/packets.action';

const useStyles = makeStyles(() => ({
  root: {
    margin: 10,
    minHeight: '721px',
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '35px',
    boxShadow: '2px 2px 2px -2px rgba(75,75,75,0.34)',
  },
  card: {
    maxWidth: '50%',
  },
  cardContent: {
    padding: 0,
  },
  cardHeader: {
    backgroundRepeat: 'no-repeat',
    fill: '#FFFFF',
  },
  title: {
    fontSize: '16px',
    letterSpacing: '0px',
    lineHeight: 1,
  },
  infoPackage: {
    padding: 10,
  },
  titlePackageName: {
    color: '#fff',
    fontSize: 20,
  },
  titlePackagePriceWithDiscount: {
    color: '#fff',
    fontSize: 15,
  },
  titlePackagePrice: {
    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: '22px',
    letterSpacing: '0px',
    fontWeight: 'bold',
    lineheight: 1,
  },
  text: {
    fontSize: '19px',
    letterSpacing: '0px',
    fontWeight: 'bold',
    lineheight: 1,
  },
  cardList: {
    fontSize: 10,
    letterSpacing: '0px',
    lineheight: 1,
  },
  btnDirectPurchase: {
    fontWeight: 900,
    '& .MuiButton-label': {
      fontWeight: 900,
      fontSize: '14px',
    },
  },
  addToCartButton: {
    width: 37,
    minWidth: 0,
  },
  iconBox: {
    width: 15,
    height: 15,
  },
  containerFeaturesList: {
    minHeight: '500px',
    maxHeight: '522px',
    height: '468px',
    overflowY: 'scroll',
    backgroundColor: '#fff',
    '&::-webkit-scrollbar': {
      width: '0.4em',
      backgroundColor: '#fff',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#C1C1C1',
      outline: '1px solid slategrey',
    },
  },
  containerFeaturesListWithoutDiscount: {
    minHeight: '500px',
    maxHeight: '522px',
    overflowY: 'scroll',
    backgroundColor: '#fff',
    '&::-webkit-scrollbar': {
      width: '0.4em',
      backgroundColor: '#fff',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#C1C1C1',
      outline: '1px solid slategrey',
    },
  },
  serviceList: {
    backgroundColor: '#fff',
    paddingTop: '0px',
  },
  logoService: {
    width: '100px',
  },
  verticalDivider: {
    position: 'relative',
    left: '-15px',
  },
  itemService: {
    backgroundColor: '#313131',
    color: '#fff',
    textAlign: 'center',
    height: 50,
    justifyContent: 'center',
    cursor: 'default',
    '&:hover': {
      backgroundColor: '#313131',
    },
  },
  actionFilter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1D71B8',
    height: 45,
    textAlign: 'center',
  },
  searchIcon: {
    paddingLeft: '10px',
  },
  actionIcons: {
    color: '#ccc',
    height: 28,
    width: 28,
    margin: 2,
  },
  checkCircleIcon: {
    color: '#73B728',
  },
  searchTextField: {
    marginLeft: '5px',
    boxShadow: '0px 3px 2px #00000026',
    borderRadius: '10px',
    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
    border: '1px solid #D5D5D5',
    opacity: 1,
    backgroundColor: '#fff',
    width: '95%',
  },
}));

const PackageSliderCard = (props) => {
  const {
    itemPackage, colorBgIndex, packageTypeId, loading, addFeatureToPackage,
    selectFeatureToPackage, searchFeatureToPackage, setSaveSettings, totalPackages,
  } = props;

  const classes = useStyles();
  const [enteredFilter, setEnteredFilter] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [infoPackage, setInfoPackage] = useState({
    type: '',
  });

  const setColorBG = () => {
    const sliderConfig = packageSliderConfig.filter((x) => x.packageTypeId === packageTypeId);
    const color = sliderConfig[0].color.filter((c) => c.id === colorBgIndex);
    return color[0];
  };
  const colorSlider = setColorBG();

  const searchFilterFeature = (packageId) => {
    searchFeatureToPackage(packageId, enteredFilter);
  };

  const getLogoService = (serviceName) => {
    switch (serviceName.toLowerCase()) {
      case 'biometria':
        return LogoGSE;
      case 'colfactura':
        return LogoColfactura;
      case 'colnomina':
        return LogoColnomina;
      case 'firmaya':
        return LogoFirmaya;
      case 'firmamail':
        return LogoFirmamail;
      case 'superguarda':
        return LogoSuperGuarda;
      case 'gse':
        return LogoGSE;
      default:
        return LogoGSE;
    }
  };

  const getColorBaseApp = (serviceName) => {
    switch (serviceName.toLowerCase()) {
      case 'biometria':
        return '#24A9E2';
      case 'colfactura':
        return '#1DBDEE';
      case 'colnomina':
        return '#1DBDEE';
      case 'firmaya':
        return '#E7651D';
      case 'firmamail':
        return '#1DA34E';
      case 'superguarda':
        return '#E7651D';
      case 'gse':
        return '#24A9E2';
      default:
        return '#24A9E2';
    }
  };

  const titleInfoPackage = (type) => {
    switch (type) {
      case 'customerPackage':
        return 'Empresas';
      case 'infoPackage':
        return 'Descripción del paquete';
      case 'promoCode':
        return 'Código promocional';
      case 'channelPackage':
        return 'Canales';
      default:
        break;
    }
  };

  const handlePopoverOpen = (e, type, packageId) => {
    setAnchorEl(e.currentTarget);
    const info = {
      type,
      title: titleInfoPackage(type),
      packageId,
    };
    setInfoPackage(info);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  function FeatureService(propsFeature) {
    const {
      packageId, feature, serviceId, serviceName, index,
    } = propsFeature;

    const handleSelectFeature = (data) => () => {
      setSaveSettings(true);
      selectFeatureToPackage(data, packageId, serviceId);
    };

    function handleChangeQuantity(e, data, type) {
      let quantity = parseInt(e.target.value);
      if (!type) {
        if (isNaN(parseInt(e.target.value))) {
          quantity = parseInt(data.quantity);
        } else if (quantity < 1) {
          quantity = 1;
        }
      } else if (type === 'add') {
        quantity = parseInt(data.quantity) + 1;
      } else if (type === 'remove') {
        if (parseInt(data.quantity) === 1) {
          quantity = 1;
        } else {
          quantity = parseInt(data.quantity) - 1;
        }
      }
      if (quantity > 9999999) {
        quantity = parseInt(data.quantity);
      }
      setSaveSettings(true);
      addFeatureToPackage(data, packageId, serviceId, quantity);
    }

    const setStorgeType = (e, data) => {
      const quantityTX = parseInt(data.quantity);
      setSaveSettings(true);
      addFeatureToPackage(data, packageId, serviceId, quantityTX, e.target.value);
    };

    const setColorItem = (i) => {
      if (i % 2 === 0) {
        return '#F7F7F7';
      }
      return '#FFFFFF';
    };

    const labelId = `checkbox-list-label-${feature.featureId}`;
    const storageTypeName = `storageType-${feature.featureId}`;
    return (
      <ListItem
        key={feature.featureId}
        role={undefined}
        dense
        button
        style={{ backgroundColor: setColorItem(index) }}
      >
        <Divider
          orientation="vertical"
          flexItem
          className={classes.verticalDivider}
          style={{ backgroundColor: getColorBaseApp(serviceName), border: `1px solid ${getColorBaseApp(serviceName)}` }}
        />
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={feature.selected}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': labelId }}
            icon={<RadioButtonUncheckedOutlinedIcon />}
            checkedIcon={<CheckCircleOutlineOutlinedIcon className={classes.checkCircleIcon} />}
            onClick={handleSelectFeature(feature)}
          />
        </ListItemIcon>
        <ListItemText
          id={labelId}
          style={{ marginLeft: '-30px' }}
          primary={`${feature.featureName}`}
        />
        <ListItemSecondaryAction>
          <Box>
            <TextField
              style={{ alignItems: 'flex-end' }}
              variant="outlined"
              size="small"
              type="text"
              name="quantity"
              autoComplete="off"
              value={feature.quantity}
              disabled={!feature.selected}
              InputProps={{
                min: 1,
                max: 9999,
                step: 1,
                style: {
                  backgroundColor: '#FFFFFF2E', width: '100px', height: '30px', padding: 0,
                },
                startAdornment: (
                  <Box>
                    <IconButton
                      color="primary"
                      className={classes.closeButton}
                      disabled={!feature.selected}
                      onClick={(e) => { handleChangeQuantity(e, feature, 'remove'); }}
                      style={{
                        width: '10px',
                      }}
                    >
                      <RemoveIcon
                        id="remove"
                        className={classes.titleDetail}
                        style={{
                          color: feature.selected
                            ? '#009BDD'
                            : '#BDBDBD',
                          width: '15px',
                          cursor: 'pointer',
                        }}
                      />
                    </IconButton>
                  </Box>
                ),
                endAdornment: (
                  <Box>
                    <IconButton
                      color="primary"
                      className={classes.closeButton}
                      disabled={!feature.selected}
                      onClick={(e) => { handleChangeQuantity(e, feature, 'add'); }}
                      style={{
                        width: '10px',
                      }}
                    >
                      <AddIcon
                        id="add"
                        className={classes.titleDetail}
                        style={{
                          color: feature.selected
                            ? '#009BDD'
                            : '#BDBDBD',
                          width: '15px',
                          cursor: 'pointer',
                        }}
                        disabled
                      />
                    </IconButton>
                  </Box>
                ),
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{
                style: { textAlign: 'center' },
              }}
              onChange={(e) => handleChangeQuantity(e, feature)}
            />
            {feature.featureTypeName === 'Almacenamiento' && feature.selected === true && (
              <TextField
                select
                id={storageTypeName}
                variant="outlined"
                className={classes.storageTypeField}
                label=""
                size="small"
                value={feature.storageType}
                onChange={(e) => setStorgeType(e, feature)}
                disabled={!feature.selected}
                InputProps={{
                  style: {
                    backgroundColor: '#FFFFFF2E', width: '70px', height: '30px', padding: 0,
                  },
                }}
                SelectProps={{
                  classes: {
                    icon: classes.iconSelect,
                  },
                }}
              >
                <MenuItem>
                  <em>Seleccione</em>
                </MenuItem>
                <MenuItem value="MB">MB</MenuItem>
                <MenuItem value="GB">GB</MenuItem>
              </TextField>
            )}
          </Box>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }

  if (loading) return <Loading />;

  return (
    <Card
      className={`${classes.root} ${totalPackages === 1 ? classes.card : ''}`}
      style={{ backgroundColor: `${colorSlider.bgColor}` }}
      variant="outlined"
      key={itemPackage.packageId}
    >
      <CardContent className={classes.cardContent}>
        <Box className={classes.cardHeader}>
          <Grid container item xs={12}>
            <Grid item xs={11} className={classes.infoPackage}>
              <Typography
                className={classes.titlePackageName}
              >
                {itemPackage.packageName}
              </Typography>
              {
                itemPackage.packageDiscount && itemPackage.packageDiscount !== '' && (
                  <Typography
                    className={classes.titlePackagePriceWithDiscount}
                  >
                      {`$${itemPackage.packagePrice.toLocaleString('en')} (-${itemPackage.packageDiscount})`}
                  </Typography>
                )
              }
              <Typography
                className={classes.titlePackagePrice}
              >
                {`$${itemPackage.packageWithDiscount ? itemPackage.packageWithDiscount.toLocaleString('en') : itemPackage.packagePrice.toLocaleString('en')}`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={1}
              style={{ padding: 5, textAlign: '-webkit-right' }}
            >
              {!itemPackage.publicVisibility && (
                <Tooltip title="Paquete privado">
                  <img src={packageCrown} alt="Crown" />
                </Tooltip>
              )}
            </Grid>
          </Grid>
          <Box
            style={{ backgroundColor: `${colorSlider.actionColor}`, height: 28 }}
          >
            <Tooltip title="Empresa" placement="top">
              <IconButton
                aria-label="close"
                color="primary"
                className={classes.actionIcons}
                onClick={(e) => handlePopoverOpen(e, 'customerPackage', itemPackage.packageId)}
                onMouseLeave={handlePopoverClose}
              >
                <img src={CompanyIcon} alt="company" className={classes.searchIcon} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Descripción del paquete" placement="top">
              <IconButton
                aria-label="close"
                color="primary"
                className={classes.actionIcons}
                onClick={(e) => handlePopoverOpen(e, 'infoPackage', itemPackage.packageId)}
                onMouseLeave={handlePopoverClose}
              >
                <img src={DescriptionPackageIcon} alt="description" className={classes.searchIcon} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Código de promoción" placement="top">
              <IconButton
                aria-label="close"
                color="primary"
                className={classes.actionIcons}
                onClick={(e) => handlePopoverOpen(e, 'promoCode', itemPackage.packageId)}
                onMouseLeave={handlePopoverClose}
              >
                <img src={PromoCodeIcon} alt="promoCode" className={classes.searchIcon} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Canales" placement="top">
              <IconButton
                aria-label="close"
                color="primary"
                className={classes.actionIcons}
                onClick={(e) => handlePopoverOpen(e, 'channelPackage', itemPackage.packageId)}
                onMouseLeave={handlePopoverClose}
              >
                <img src={ChannelIcon} alt="channel" className={classes.searchIcon} />
              </IconButton>
            </Tooltip>
          </Box>
          <Box
            className={classes.actionFilter}
            style={{ backgroundColor: `${colorSlider.searchColor}` }}
          >
            <TextField
              className={classes.searchTextField}
              value={enteredFilter}
              onChange={(e) => setEnteredFilter(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  searchFilterFeature(itemPackage.packageId);
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} alt="searchIcon" className={classes.searchIcon} />
                  </InputAdornment>
                ),
                disableUnderline: true,
                onBlur: () => searchFilterFeature(itemPackage.packageId),
              }}
              placeholder="Buscar caracteristica"
            />
          </Box>
        </Box>
        <Box className={itemPackage.packageDiscount && itemPackage.packageDiscount !== '' ? classes.containerFeaturesList : classes.containerFeaturesListWithoutDiscount}>
          <List
            className={classes.serviceList}
          >
            {itemPackage.serviceList.map((item, index) => (
              <Box key={index}>
                <ListItem
                  role={undefined}
                  dense
                  button
                  className={classes.itemService}
                >
                  <img src={getLogoService(item.serviceName)} className={classes.logoService} alt="logo" />
                </ListItem>
                {item.features.length > 0 ? (item.features.map((feature, featureIndex) => (
                  <FeatureService
                    key={feature.featureId}
                    packageId={itemPackage.packageId}
                    feature={feature}
                    serviceId={item.serviceId}
                    serviceName={item.serviceName}
                    index={featureIndex}
                  />
                ))) : (
                  <ListItem
                    role={undefined}
                    dense
                    button
                  >
                    <ListItemText primary="No existe característica" />
                  </ListItem>
                )}
              </Box>
            ))}
          </List>
        </Box>
      </CardContent>
      <CardActions />
      {/* Popover Detalles de Paquete */}
      <InfoPackage infoPackage={infoPackage} anchorEl={anchorEl} />
    </Card>
  );
};

const mapStateToProps = (props) => ({
  packages: props.packets.packages,
  packagesRows: props.packets.packagesRows,
  packagesFeatures: props.packets.packagesFeatures,
  loading: props.packets.loading,
});

const mapDispatchToProps = {
  addFeatureToPackage: addFeatureToPackageAction,
  selectFeatureToPackage: selectFeatureToPackageAction,
  searchFeatureToPackage: searchFeatureToPackageAction,
  getCustomerLinkedPackage: getCustomerLinkedPackageAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PackageSliderCard);
