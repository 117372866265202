/* eslint-disable jsx-a11y/anchor-is-valid, react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import SearchIcon from '@material-ui/icons/Search';
import MenuItem from '@material-ui/core/MenuItem';
import AlertConfirm from '../../../components/crosscutting/AlertConfirm';
import {
  rejectPaymentAction,
  approvePaymentAction,
  searchPaymentsPagingAction,
  setMessageAlertModalAction,
  getPaymentSupportAction,
} from '../../../actions/packets.action';
import { authorizeActionsRole } from '../../../actions/auth.action';
import { PURCHASE_TYPE } from '../../../constants/PurchaseConstants';
import IconoFiltro from '../../../assets/img/iconos/ibuhoo/Icono_filtro.svg';
import IconDownloadGrid from '../../../assets/img/iconos/ibuhoo/icon_download_grid.png';
import IconCheckGrid from '../../../assets/img/iconos/ibuhoo/icon_check_grid.png';
import IconDeleteGrid from '../../../assets/img/iconos/ibuhoo/icon_delete_grid.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    '& .MuiTablePagination-root': {
      justifyContent: 'center',
      display: 'flex',
    },
  },
  table: {
    minWidth: 750,
    fontFamily: 'Muli, Arial',
  },
  tableHead: {
    backgroundColor: '#009BDD',
  },
  tableCellHead: {
    color: '#FFF',
  },
  tableCell: {
    fontFamily: 'Muli, Arial',
    fontSize: 12,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  boxFilter: {
    paddingBottom: theme.spacing(2.5),
    '& .MuiTextField-root input': {
      paddingBottom: 12,
      height: 4,
    },
  },
  btnFilter: {
    marginLeft: theme.spacing(2),
    border: '1px solid #C1C1C1',
    height: 25,
    fontSize: 11,
    fontFamily: 'Muli, Arial',
    backgroundColor: '#FFF',
  },
  inputSearch: {
    backgroundColor: '#FFF',
    width: 368,
    fontSize: '3em',
  },
  menuItemReject: {
    color: '#6D6E71',
    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#009BDD !important',
    },
  },
  error: {
    color: theme.palette.error.main,
    fontSize: 12,
    paddingBottom: 5,
  },
}));

const headCells = [
  {
    id: 'id', numeric: false, disablePadding: false, label: 'Referencia',
  },
  {
    id: 'purchaseDate', numeric: false, disablePadding: false, label: 'Fecha soporte',
  },
  {
    id: 'legalName', numeric: false, disablePadding: false, label: 'Cliente',
  },
  {
    id: 'documentTypeCode', numeric: false, disablePadding: true, label: 'Tipo documento',
  },
  {
    id: 'documentNumber', numeric: false, disablePadding: false, label: 'Documento',
  },
  {
    id: 'totalPurchase', numeric: true, disablePadding: false, label: 'Valor a pagar',
  },
  {
    id: 'btn', numeric: false, disablePadding: false, label: '',
  },
  {
    id: 'btn', numeric: false, disablePadding: false, label: '',
  },
  {
    id: 'btn', numeric: false, disablePadding: false, label: '',
  },
  {
    id: 'btn', numeric: false, disablePadding: false, label: '',
  },
];

function EnhancedTableHead(props) {
  const {
    classes, order, orderBy, onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell, item) => (
          <TableCell
            className={classes.tableCellHead}
            // eslint-disable-next-line react/no-array-index-key
            key={item}
            align={headCell.numeric ? 'right' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.id !== 'btn'
              ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              )
              : headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'documentNumber' || orderBy === 'legalName') {
    if (b.customer[orderBy] < a.customer[orderBy]) {
      return -1;
    }
    if (b.customer[orderBy] > a.customer[orderBy]) {
      return 1;
    }
  }

  if (orderBy === 'documentTypeCode') {
    if (b.customer.documentTypeLicense[orderBy] < a.customer.documentTypeLicense[orderBy]) {
      return -1;
    }
    if (b.customer.documentTypeLicense[orderBy] > a.customer.documentTypeLicense[orderBy]) {
      return 1;
    }
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function FormatDate(pdate) {
  return new Intl.DateTimeFormat('en-GB').format(new Date(pdate));
}

const GridPaymentsPaging = (props) => {
  const classes = useStyles();
  const {
    rows,
    totalRows,
    type,
    handleChangePage,
    setPage,
    page,
    rejectTypes,
    rejectPayment,
    approvePayment,
    searchPaymentsPaging,
    isSearchServer,
    appName,
    messageAlertModal,
    setMessageAlertModal,
    getPaymentSupport,
    paymentSupporFile,
    rutFile,
  } = props;
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('_referencia');
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = React.useState();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openAlertAprobe, setOpenAlertAprobe] = useState(false);
  const [openAlertReject, setOpenAlertReject] = useState(false);
  const [openAlertInfo, setOpenAlertInfo] = useState(false);
  const [item, setItem] = useState();
  const [valueRejectType, setValueRejectType] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [filterData, setFilterData] = useState(rows);
  const [errorMessage, setErrorMessage] = useState('');
  const [disabledApprovePayment, setDisabledApprovePayment] = useState(false);
  const [downloadSupport, setDownloadSupp] = useState(false);
  const [downloadRUT, setDownloadRUT] = useState(false);
  const decodedTokenJwt = useSelector(({ auth }) => auth.decodedToken);

  useEffect(() => {
    const authorizeRole = authorizeActionsRole(decodedTokenJwt, 'PAYMENTS');
    if (authorizeRole) {
      const approvePayments = (authorizeRole.authorize.some((x) => x === 'ApprovePayments')
                                && authorizeRole.authorize.some((x) => x === 'ModifyPayments'));
      setDisabledApprovePayment(!approvePayments);
    }
  }, []);

  useEffect(() => {
    let downloadDocument;
    const currentDate = moment().toDate();
    if (paymentSupporFile !== undefined && downloadSupport === true) {
      const supportFileName = String(paymentSupporFile.fileName);
      const extension = String('.pdf');
      if (supportFileName.lastIndexOf('.pdf') !== -1) {
        downloadDocument = document.createElement('a');
        const supportBase64 = String(paymentSupporFile.base64);
        downloadDocument.href = String('data:document/pdf;base64,') + supportBase64;
        downloadDocument.download = format(new Date(currentDate), 'yyyyMMdd_hhmm') + extension;
        downloadDocument.click();
      } else {
        const file = String(paymentSupporFile.pathFile);
        const win = window.open(file);
        if (win != null) {
          win.focus();
        }
      }
    }
  }, [paymentSupporFile]);

  /**
   * Convert base64 to .pdf or .png file
   */
  useEffect(() => {
    let downloadDocument;
    const currentDate = moment().toDate();
    if (rutFile !== undefined && downloadRUT === true) {
      const rutFileName = String(rutFile.fileName);
      const extension = String('.pdf');
      if (rutFileName.lastIndexOf('.pdf') !== -1) {
        downloadDocument = document.createElement('a');
        const rutBase64 = String(rutFile.base64);
        downloadDocument.href = String('data:image/pdf;base64,') + rutBase64;
        downloadDocument.download = format(new Date(currentDate), 'yyyyMMdd_hhmm') + extension;
        downloadDocument.click();
      } else {
        const file = String(rutFile.pathFile);
        const win = window.open(file);
        if (win != null) {
          win.focus();
        }
      }
    }
  }, [rutFile]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (_referencia) => selected.indexOf(_referencia) !== -1;

  const handleRejectClick = (event) => {
    setItem(event);
    setOpenAlertReject(true);
  };

  const handleApproveClick = (event) => {
    setItem(event);
    setOpenAlertAprobe(true);
  };

  const handleRejectPay = () => {
    if (valueRejectType) {
      const data = { id: item.id, idState: 7, rejectTypeId: valueRejectType };
      rejectPayment(data, appName, enqueueSnackbar);
      setOpenAlertReject(false);
    } else {
      setErrorMessage('Debes seleccionar un motivo de rechazo');
    }
  };

  const handleCloseRejectPayment = () => {
    setValueRejectType(null);
    setOpenAlertReject(false);
    setErrorMessage('');
  };

  const handleApprovePay = () => {
    const data = { id: item.id, idState: 6 };
    approvePayment(data, appName, enqueueSnackbar);
    setOpenAlertAprobe(false);
  };

  const handleRejectType = (event) => {
    setErrorMessage('');
    setValueRejectType(event.target.id);
  };

  const handleViewDetailClick = (id) => {
    setValueRejectType(id);
    setOpenAlertInfo(true);
  };

  const handleViewSupport = (support) => {
    if (support !== 0) {
      const refererenceUuid = support.referenceUuid;
      getPaymentSupport(refererenceUuid, 'PAYMENT_SUPPORT');
      setDownloadSupp(true);
    }
  };
  const handleViewRUT = (rut) => {
    if (rut !== 0) {
      const refererenceUuid = rut.referenceUuid;
      getPaymentSupport(refererenceUuid, 'RUT');
      setDownloadRUT(true);
    }
  };
  function rejectDescription() {
    if (valueRejectType === null) return '-';
    const rejectDesc = rejectTypes.find(
      (h) => h.value === valueRejectType,
    ).text;
    return ` ${rejectDesc}`;
  }
  const handleSearchClick = (event) => {
    const { name } = event.target;
    if (isSearchServer && name !== 'searchText') {
      switch (type) {
        case 'pending':
          searchPaymentsPaging({ IdState: PURCHASE_TYPE.Pending, Search: search }, 'pending');
          break;
        case 'approved':
          searchPaymentsPaging({ IdState: PURCHASE_TYPE.Approved, Search: search }, 'approved');
          break;
        case 'rejected':
          searchPaymentsPaging({ IdState: PURCHASE_TYPE.Rejected, Search: search }, 'rejected');
          break;
        default:
          break;
      }
    } else {
      if (event.target.value !== undefined) {
        const result = rows.filter((data) => `${data.paymentReference} ${data.customer.legalName} ${FormatDate(data.purchaseDate)} ${data.customer.documentNumber} ${data.customer.documentTypeLicense?.documentTypeCode} ${data.totalPurchase}`
          .toLowerCase().includes(event.target.value.toLowerCase()));
        setFilterData(result);
        setPage(0);
        return;
      }
      const result = rows.filter((data) => `${data.paymentReference} ${data.customer.legalName} ${FormatDate(data.purchaseDate)} ${data.customer.documentNumber} ${data.customer.documentTypeLicense?.documentTypeCode} ${data.totalPurchase}`
        .toLowerCase().includes(search.toLowerCase()));
      setFilterData(result);
    }
    setPage(0);
  };

  useEffect(() => {
    if (rows.length > 0) {
      setFilterData(rows);
    }
  }, [rows]);

  return (
    <div className={classes.root}>
      <div className={classes.boxFilter}>
        <TextField
          name="searchText"
          size="small"
          variant="outlined"
          className={classes.inputSearch}
          onChange={(e) => { setSearch(e.target.value); handleSearchClick(e); }}
          onKeyDown={(e) => {
            if (e.key === ' ') {
              e.preventDefault();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ color: '#C6C6C6' }}>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button name="searchButton" variant="outlined" aria-label="Filter list" onClick={(e) => { handleSearchClick(e); }} className={classes.btnFilter}>
          <img name="searchButton" src={IconoFiltro} alt="Icono filtro" style={{ paddingRight: '4px' }} />
          Filtrar
        </Button>
      </div>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={totalRows}// {rows.length}
            />
            <TableBody>
              {Object.keys(filterData).length > 0 && stableSort(filterData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell align="center" component="th" id={labelId} scope="row" padding="none" style={{ color: '#1982B1' }} className={classes.tableCell}>
                        {row.paymentReference}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>{FormatDate(row.purchaseDate)}</TableCell>
                      <TableCell align="center" className={classes.tableCell}>{row.customer.legalName}</TableCell>
                      <TableCell align="center" className={classes.tableCell}>{row.customer.documentTypeLicense?.documentTypeCode}</TableCell>
                      <TableCell align="center" className={classes.tableCell}>{row.customer.documentNumber}</TableCell>
                      <TableCell align="center" className={classes.tableCell}>{`$${row.totalPurchase.toLocaleString('en')}`}</TableCell>
                      <TableCell align="center" style={{ cursor: 'pointer' }}>
                        <Tooltip title="Ver comprobante de pago">
                          <IconButton
                            edge="end"
                            aria-label="view"
                            color="secondary"
                            size="small"
                            onClick={() => { handleViewSupport(row); }}
                          >
                            <img src={IconDownloadGrid} alt="Descargar" color={row.paymentSupport.length ? 'error' : 'primary'} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center" style={{ cursor: 'pointer' }}>
                        <Tooltip title="Ver RUT">
                          <IconButton
                            edge="end"
                            aria-label="view"
                            color="secondary"
                            size="small"
                            onClick={() => { handleViewRUT(row); }}
                          >
                            <img src={IconDownloadGrid} alt="Descargar" color={row.paymentSupport.length ? 'error' : 'primary'} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      {type === 'pending' && (
                        <>
                          <TableCell align="right">
                            <Tooltip title="Aprobar pago">
                              <IconButton
                                edge="end"
                                aria-label="Aprobar"
                                size="small"
                                onClick={() => { handleApproveClick(row); }}
                                disabled={disabledApprovePayment}
                              >
                                <img src={IconCheckGrid} alt="Aprobar" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <TableCell align="left">
                            <Tooltip title="Rechazar pago">
                              <IconButton
                                edge="end"
                                aria-label="rechazar"
                                size="small"
                                onClick={() => { handleRejectClick(row); }}
                                disabled={disabledApprovePayment}
                              >
                                <img src={IconDeleteGrid} alt="Rechazar" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </>
                      )}
                      {type === 'rejected' && (
                      <TableCell align="center">
                        <Link href="#" onClick={() => { handleViewDetailClick(row.rejectTypeId); }}>
                          Ver detalle
                        </Link>
                      </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              {
                filterData.length === 0 && rows.length === 0
                && <div>No existe información correspondiente</div>
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          labelRowsPerPage=""
          component="div"
          count={rows.length !== filterData.length ? filterData.length : totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={(e, newpage) => { handleChangePage(e, newpage, type, rowsPerPage); }}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {openAlertInfo
         && (
         <AlertConfirm
           isOpen={openAlertInfo}
           tituloModal="Motivo del rechazo"
           mensajeModal={<center>{rejectDescription()}</center>}
           handleAccept={() => setOpenAlertInfo(false)}
           onClose={() => setOpenAlertInfo(false)}
         />
         )}
      {openAlertAprobe
        && (
          <AlertConfirm
            isOpen={openAlertAprobe}
            tituloModal="Aprobar la compra"
            mensajeModal="¿Está segúro que desea aprobar la compra?"
            handleAccept={handleApprovePay}
            onClose={() => setOpenAlertAprobe(false)}
          />
        )}
      {openAlertReject
        && (
          <AlertConfirm
            isOpen={openAlertReject}
            tituloModal="Rechazar compra"
            mensajeModal="¿Está segúro que desea rechazar el pago?"
            textButtonAcept="Rechazar"
            handleAccept={handleRejectPay}
            onClose={() => handleCloseRejectPayment()}
          >
            <TextField
              select
              label="Motivo de rechazo"
              size="small"
              variant="outlined"
              required
              fullWidth
              style={{
                marginRight: 10, marginBottom: 10, color: 'red', boxShadow: '0px 3px 2px #00000033', borderRadius: '10px',
              }}
            >
              <MenuItem onClick={handleRejectType}>Seleccione</MenuItem>
              {rejectTypes.map(
                (td) => (
                  <MenuItem
                    id={td.value}
                    key={td.value}
                    value={td.value}
                    onClick={handleRejectType}
                    className={classes.menuItemReject}
                  >
                    {td.text}
                  </MenuItem>
                ),
              )}
            </TextField>
            <Typography className={classes.error}>
              { errorMessage }
            </Typography>
          </AlertConfirm>
        )}
      {messageAlertModal && messageAlertModal.showMessageAlertModal
        && (
          <AlertConfirm
            isOpen={messageAlertModal.showMessageAlertModal}
            tituloModal={messageAlertModal.tittleMsg}
            mensajeModal={messageAlertModal.message}
            handleAccept={handleApprovePay}
            onClose={() => setMessageAlertModal({ showMessageAlertModal: false })}
            isOnlyMessage
          />
        )}
    </div>
  );
};

const mapStateToProps = ({ general, packets }) => ({
  rejectTypes: general.rejectTypes,
  appName: general.appName,
  messageAlertModal: packets.messageAlertModal,
  paymentSupporFile: packets.paymentSupporFile,
  rutFile: packets.rutFile,
});

const mapDispatchToProps = {
  rejectPayment: rejectPaymentAction,
  approvePayment: approvePaymentAction,
  searchPaymentsPaging: searchPaymentsPagingAction,
  setMessageAlertModal: setMessageAlertModalAction,
  getPaymentSupport: getPaymentSupportAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(GridPaymentsPaging);
