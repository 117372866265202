/* eslint-disable import/no-dynamic-require, react-hooks/exhaustive-deps */
/* eslint-disable global-require */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import InputLabel from '@material-ui/core/InputLabel';
import { useSnackbar } from 'notistack';
import {
  setStatusModalPackage,
  getPackagesByFeatureAction,
  getDetailEmployeeAction,
  setDetailEmployeeAction,
} from '../../actions/home.action';
import CustomSelect from '../../components/form/CustomSelect';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 200,
    borderRadius: 16,
    paddingBottom: 10,
    margin: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.5)',
    ...theme.miplan.rootCard,
  },
  paper: {
    padding: '5px',
    marginTop: '20px',
    opacity: '0.95',
  },
  cardHeader: {
    ...theme.miplan.cardHeader,
    fontSize: 25,
    padding: 0,
    minHeight: 70,
    '& .MuiTypography-displayBlock': {
      fontWeight: 600,
    },
  },
  iconCard: {
    textAlign: 'center',
    borderRadius: 5,
    width: 30,
    height: 30,
    boxShadow: '0px 4px 7px #0000001F',
    ...theme.miplan.iconCard,
  },
  featureName: {
    ...theme.miplan.featureName,
  },
  featureValue: {
    ...theme.miplan.featureValue,
  },
  buttonCard: {
    ...theme.miplan.buttonCard,
    '&:hover': {
      ...theme.miplan.hoverButtonCard,
    },
  },
  iconBox: {
    width: 30,
    height: 30,
    boxShadow: '0px 4px 7px #0000001F',
    borderRadius: 5,
  },
}));

const LicenseCard = (props) => {
  const classes = useStyles();
  const {
    feature, serviceName, setIsPackageOpen, getPackagesByFeature, userInfo, appName, detailEmployee, getDetailEmployee, setEmployeeAction, setDetailEmployee,
  } = props;

  const {
    errors, control, setValue,
  } = useForm({
    mode: 'onChange',
  });
  const { enqueueSnackbar } = useSnackbar();

  const [loadingRues, setLoadingRues] = useState(false);

  const handleClickBuyMore = (featureCode) => {
    // history.push(`/cart/${pId}`);
    setIsPackageOpen(true);
    getPackagesByFeature(serviceName.toLowerCase(), featureCode, enqueueSnackbar);
  };

  const setDetailCustomerEmployee = (event) => {
    setLoadingRues(true);
    const { value } = event.target;
    detailEmployee.forEach((element) => {
      if (element.value === value) {
        const CustomerRequest = {
          DocumentType: userInfo.customer_type,
          DocumentNumber: userInfo.customer_value,
          ServiceName: appName === 'license' ? '' : appName,
          Month: element.month,
          Year: element.year,
        };
        setEmployeeAction(CustomerRequest).then(() => {
          setLoadingRues(false);
        });
        setValue('detailLicenseEmployee', element.value);
      }
    });
  };

  useEffect(() => {
    if (feature.featureCode === 'COLNOMINA_EMPLEADO') {
      const CustomerRequest = {
        DocumentType: userInfo.customer_type,
        DocumentNumber: userInfo.customer_value,
        ServiceName: appName === 'license' ? '' : appName,
      };
      getDetailEmployee(CustomerRequest);
    }
  }, []);

  useEffect(() => {
    if (detailEmployee.length > 0) {
      detailEmployee.forEach((element) => {
        if (element.key === 0) {
          const CustomerRequest = {
            DocumentType: userInfo.customer_type,
            DocumentNumber: userInfo.customer_value,
            ServiceName: appName === 'license' ? '' : appName,
            Month: element.month,
            Year: element.year,
          };
          setEmployeeAction(CustomerRequest);
          setValue('detailLicenseEmployee', element.value);
        }
      });
    }
  }, [detailEmployee]);

  const getLoading = () => (
    <>
      <Grid item xs={1} sm={1}>
        <CircularProgress size={24} className={classes.buttonProgress} />
      </Grid>
    </>
  );

  const getIconImg = (pLogoType) => {
    let folder;

    switch (serviceName.toLowerCase()) {
      case 'license':
      case 'firmaya':
        folder = serviceName.toLowerCase();
        break;
      case 'colfactura':
      case 'colnomina':
      case 'firmamail':
        folder = serviceName.toLowerCase();
        break;
      case 'firmaya plus.':
        folder = 'firmaya';
        break;
      default:
        return require('../../assets/img/iconos/colfactura/icono factura.svg');
    }
    try {
      switch (pLogoType.toLowerCase()) {
        case 'facturas':
          return require(`../../assets/img/iconos/${folder}/icono factura.svg`);
        case 'usuarios': case 'usuario':
          return require(`../../assets/img/iconos/${folder}/Icono usuarios.svg`);
        case 'sucursales':
          return require(`../../assets/img/iconos/${folder}/Icono sucursales.svg`);
        case 'plantillas':
          return require(`../../assets/img/iconos/${folder}/Icono plantillas.svg`);
        case 'informes':
          return require(`../../assets/img/iconos/${folder}/Icono informes.svg`);
        case 'empresa': case 'empresas':
          return require(`../../assets/img/iconos/${folder}/Icono empresa.svg`);
        case 'almacenamiento':
          return require(`../../assets/img/iconos/${folder}/Icono almacenamiento.svg`);
        case 'otros':
          return require(`../../assets/img/iconos/${folder}/icono factura.svg`);
        case 'firmas digitales':
          return require(`../../assets/img/iconos/${folder}/Icono firma digital.svg`);
        case 'estampas': case 'tsa':
          return require(`../../assets/img/iconos/${folder}/Icono estampas.svg`);
        case 'firmas manuscritas':
          return require(`../../assets/img/iconos/${folder}/Firma manuscrita.svg`);
        case 'firma ltv':
          return require(`../../assets/img/iconos/${folder}/Firma ltv.svg`);
        case 'bolsas':
          return require(`../../assets/img/iconos/${folder}/Icono bolsas.svg`);
        case 'marca de agua':
          return require(`../../assets/img/iconos/${folder}/Icono firma digital.svg`);
        case 'firma centralizada':
          return require(`../../assets/img/iconos/${folder}/Icono firma digital.svg`);
        case 'firma otp': case 'token': case 'firma qr':
          return require(`../../assets/img/iconos/${folder}/Icono estampas.svg`);
        case 'firma digital centralizada':
          return require(`../../assets/img/iconos/${folder}/Icono firma centralizada.svg`);
        case 'empleados': case 'empleado':
          return require(`../../assets/img/iconos/${folder}/Icono empleados.svg`);
        case 'documentos': case 'documento':
          return require(`../../assets/img/iconos/${folder}/Icono documento.svg`);
        default:
          return require('../../assets/img/iconos/colfactura/icono factura.svg');
      }
    } catch (error) {
      return require('../../assets/img/iconos/colfactura/icono factura.svg');
    }
  };

  return (
    <>
      <Card className={classes.root} variant="outlined">
        <CardHeader align="center" titleTypographyProps={{ variant: 'h6' }} title={feature.featureAlias} className={classes.cardHeader} />
        <div style={{ textAlign: 'center' }}>
          <img src={getIconImg(feature.logoType)} title={feature.featureAlias} alt="Logo" className={classes.iconBox} />
          <Divider variant="middle" />
        </div>
        <CardContent>
          {
            feature.featureCode === 'COLNOMINA_EMPLEADO' ? (
              <>
                <Grid container>
                  <Grid item xs={12} md={loadingRues ? 11 : 12} lg={loadingRues ? 11 : 12}>
                    <InputLabel />
                    <CustomSelect
                      name="detailLicenseEmployee"
                      options={detailEmployee}
                      control={control}
                      error={errors}
                      styleFromHelperText={{ style: { marginRight: 10 } }}
                      inputProps={{ onChange: (e) => setDetailCustomerEmployee(e) }}
                    />
                  </Grid>
                  {loadingRues && getLoading()}
                </Grid>
              </>
            ) : (
              <>
              </>
            )
          }
          <Box>
            <List dense disablePadding>
              <ListItem
                divider
                dense
                disableGutters
              >
                <ListItemText
                  // style={{ textAlign: 'center' }}
                  style={feature.featureCode === 'COLNOMINA_EMPLEADO' ? { textAlign: 'center' } : { textAlign: 'center', height: '78px', marginTop: '20px' }}
                  primary={(
                    <>
                      <Typography
                        component="p"
                        variant="body2"
                        color="textPrimary"
                      >
                        <span className={classes.featureName}>Total: </span>
                        <span className={classes.featureValue}>{ feature.featureCode === 'COLNOMINA_EMPLEADO' ? (!loadingRues && setDetailEmployee.quantityTx) : feature.quantityTxLabel }</span>
                      </Typography>
                      <Typography
                        component="p"
                        variant="body2"
                        color="textPrimary"
                      >
                        <span className={classes.featureName}>Disponible: </span>
                        <span className={classes.featureValue}>{ feature.featureCode === 'COLNOMINA_EMPLEADO' ? (!loadingRues && setDetailEmployee.quantityBalance) : feature.amountBalanceTxLabel }</span>
                      </Typography>
                      <Typography
                        component="p"
                        variant="body2"
                        color="textPrimary"
                      >
                        <span className={classes.featureName}>Usado: </span>
                        <span className={classes.featureValue}>{ feature.featureCode === 'COLNOMINA_EMPLEADO' ? (!loadingRues && setDetailEmployee.quantityUse) : feature.quantityAddLabel}</span>
                      </Typography>
                    </>
                  )}
                />
              </ListItem>
            </List>
          </Box>
        </CardContent>
        <CardActions disableSpacing style={{ justifyContent: 'center' }}>
          <Button variant="contained" onClick={() => { handleClickBuyMore(feature.featureCode); }} className={classes.buttonCard}>
            Comprar más
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

const mapStateToProps = ({ home, auth, general }) => ({
  isOpen: home.isPackageOpen,
  detailEmployee: home.detailEmployee,
  userInfo: auth.userInfo,
  appName: general.appName,
  setDetailEmployee: home.setDetailEmployee,
});

const mapDispatchToProps = {
  getPackagesByFeature: getPackagesByFeatureAction,
  setIsPackageOpen: setStatusModalPackage,
  getDetailEmployee: getDetailEmployeeAction,
  setEmployeeAction: setDetailEmployeeAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LicenseCard);
