import axios from 'axios';
import {
  SERVICE_LOADING, GET_SERVICES, GET_SERVICES_COMBO, GET_SERVICES_MANUAL_LICENSE_COMBO,
} from '../types/service.type';
import { API_LICENCIAMIENTO } from '../config/config';
import { defaultHeaders } from '../helpers/generalUtils';

export const getServicesAction = () => async (dispatch) => {
  try {
    const serviceCombo = [];
    const serviceAddManualLicenseCombo = [];
    dispatch({ type: SERVICE_LOADING, payload: true });
    const config = defaultHeaders();
    const response = await axios.get(`${API_LICENCIAMIENTO}/license/api/service/GetAllServices`, config);
    if (response.status === 200) {
      response.data.result.records
        .filter((x) => x.idstate === 0)
        .forEach((item) => serviceCombo.push(
          {
            value: item.id,
            label: item.descriptionService,
            filtered: false,
            disabled: false,
          },
        ));

      response.data.result.records
        .filter((x) => x.idstate === 0 && x.addManualLicense === true)
        .forEach((item) => serviceAddManualLicenseCombo.push(
          {
            value: item.id,
            text: item.descriptionService,
          },
        ));

      dispatch({ type: GET_SERVICES, payload: response.data.result.records });
      dispatch({ type: GET_SERVICES_COMBO, payload: serviceCombo });
      dispatch({ type: GET_SERVICES_MANUAL_LICENSE_COMBO, payload: serviceAddManualLicenseCombo });
      dispatch({ type: SERVICE_LOADING, payload: false });
    }
  } catch (error) {
    console.error(error);
  }
};


export const registerServiceAction = (data, enqueueSnackbar) => async (dispatch) => {
  try {
    dispatch({ type: SERVICE_LOADING, payload: true });
    const config = defaultHeaders();
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/service/RegisterService`, data, config);
    if (response.status === 200) {
      dispatch({ type: SERVICE_LOADING, payload: false });
      await dispatch(getServicesAction());
      enqueueSnackbar('El producto o servicio se ha guardado exitosamente.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 422) {
        enqueueSnackbar(error.response.data.statusMessage, {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar('Error de servidor', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    }
    console.error(`Error grabando servicio: ${error.message}`);
  }
};

export const changeStateServiceAction = (data, enqueueSnackbar) => async (dispatch) => {
  try {
    dispatch({ type: SERVICE_LOADING, payload: true });
    const config = defaultHeaders();
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/service/ChangeStateService`, data, config);
    if (response.status === 200) {
      dispatch({ type: SERVICE_LOADING, payload: false });
      await dispatch(getServicesAction());
      const message = data.Status
        ? 'Se ha activado el producto o servicio exitosamente.'
        : 'Se ha inactivado el producto o servicio exitosamente.';
      enqueueSnackbar(message, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
      // dispatch(
      //   setMessageAlertAction({
      //     category: 'registerCashPurchase',
      //     typeMsg: 'Success',
      //     tittleMsg: '¡Perfecto!',
      //     message,
      //   }),
      // );

    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 422) {
        enqueueSnackbar(error.response.data.statusMessage, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar('Error de servidor', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    }
    dispatch({ type: SERVICE_LOADING, payload: false });
    console.error(`Error modificando servicio: ${error.message}`);
  }
};
