import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core/';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import Loading from '../../../../components/crosscutting/Loading';
import newButton from '../../../../assets/img/iconos/ibuhoo/newButton.svg';
import SwitchiOS from '../../../../components/form/SwitchiOS';
import ModalServiceFeature from './ModalServiceFeature';
import AlertConfirm from '../../../../components/crosscutting/AlertConfirm';
import { changeStateServiceFeatureAction } from '../../../../actions/serviceFeature.action';
import searchIcon from '../../../../assets/img/iconos/ibuhoo/searchIcon.svg';

const useStyles = makeStyles((theme) => ({

  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  tableCotainer: {
    borderRadius: '10px',
  },
  table: {
    width: 'auto',
  },
  tableHead: {
    width: '828px',
    height: '40px',
    background: 'var(--unnamed-color-009bdd) 0% 0% no-repeat padding-box',
    borderRadius: '10px',
    opacity: 1,
    color: '#FFF',
    backgroundColor: '#009BDD',
  },
  tableCellHead: {
    color: '#FFF',
    backgroundColor: '#009BDD',
    fontWeight: 'bold',
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: 'white',
    },
  },
  tablePagination: {
    font: 'normal normal 600 12px/0.10000000149011612px Muli',
    letterSpacing: '0px',
    opacity: 1,
    color: 'var(--unnamed-color-706f6f)',
    textAlign: 'right',
  },
  tableCell: {
    textAlign: 'center',
    letterSpacing: '0px',
    color: '#575756',
    opacity: 1,
  },
  tableCellSmaller: {
    textAlign: 'center',
    letterSpacing: '0px',
    color: '#575756',
    opacity: 1,
    fontSize: 'smaller',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  textFieldSearch: {
    boxShadow: '0px 3px 2px #00000026',
    width: '504px',
    borderRadius: '10px',
    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
    border: '1px solid #D5D5D5',
    opacity: 1,
    height: '33px',
  },
  columnStyle: {
    fontWeight: 'bold',
  },
  rowOver: {
    hover: {
      backgroundColor: '#009BDD',
    },
  },
  mat_icon: {
    height: 32,
    width: 32,
  },
  editIcon: {
    ...theme.backOffice.configurations.editIcon,
  },
  tableSortLabel: {
    color: 'white',
    '&:hover': {
      color: 'white',
    },
  },
  disabledRow: {
    opacity: 0.4,
  },
}));

const useStyles2 = makeStyles({
  caption: {
    color: 'green',
    padding: 8,
    border: '1px dashed grey',
    fontSize: '0.875rem',
  },
  toolbar: {
    '& > p:nth-of-type(2)': {
      fontSize: '1.25rem',
      color: 'red',
      fontWeight: 600,
    },
  },
  actions: {
    color: '#009FE3',
  },
  selectIcon: {
    color: '#009FE3',
  },
});

const headCells = [
  {
    id: 'serviceName', numeric: false, paddingLeft: '40px', label: 'Nombre Servicio', width: '210px',
  },
  {
    id: 'serviceFeatureCode', numeric: false, paddingLeft: '35px', label: 'Código Característica', width: '230px',
  },
  {
    id: 'featureDescription', numeric: false, paddingLeft: '40px', label: 'Descripción', width: '300px',
  },
  {
    id: 'featureTypeName', numeric: false, paddingLeft: '40px', label: 'Tipo Caracteristica', width: '210px',
  },
  {
    id: 'featureRelationName', numeric: false, paddingLeft: '40px', label: 'Relación Característica', width: '245px',
  },
  {
    id: 'idstate', numeric: false, paddingLeft: '', label: 'Activo', width: '10px',
  },
];

function EnhancedTableHead(props) {
  const {
    classes, order, orderBy, onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className={classes.tableCellHead}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'center'}
            // eslint-disable-next-line no-nested-ternary
            style={headCell.id === 'serviceName' ? { borderBottomLeftRadius: '10px', paddingLeft: headCell.paddingLeft }
              : (headCell.id === 'idstate' ? { borderBottomRightRadius: '10px', paddingLeft: headCell.paddingLeft }
                : { paddingLeft: headCell.paddingLeft })}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              classes={{
                root: classes.tableSortLabel,
              }}
            >
              <Typography classes={{
                body1: classes.columnStyle,
              }}
              >
                {headCell.label}
              </Typography>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function descendingComparator(a, b, orderByParam) {
  if (b[orderByParam] < a[orderByParam]) {
    return -1;
  }
  if (b[orderByParam] > a[orderByParam]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderByParam) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderByParam)
    : (a, b) => -descendingComparator(a, b, orderByParam);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const GridServiceFeature = (props) => {
  const classes = useStyles();
  const classes2 = useStyles2();
  const {
    rows,
    totalRows,
    type,
    handleChangePage,
    changeStateServiceFeature,
    setPage,
    page,
    loading,
  } = props;
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('_id');
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openEditAddModal, setOpenEditAddModal] = useState(false);
  const [item, setItem] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [filterData, setFilterData] = useState(rows);
  const [isNew, setIsNew] = useState(false);
  const [openAlertConfirmState, setOpenAlertConfirmState] = useState(false);

  useEffect(() => {
    setFilterData(rows);
  }, [rows]);

  // useEffect(() => {
  //   setPage(0);
  // }, [filterData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchOnChange = (event) => {
    setPage(0);
    const result = rows.filter((data) => `${data.serviceName} ${data.serviceFeatureCode} ${data.featureDescription}`
      .toLowerCase().includes(event.target.value.toLowerCase()));
    setFilterData(result);
  };

  const handleSwitchClick = (event, r) => {
    setOpenAlertConfirmState(true);
    setItem(r);
  };

  const handleEditClick = (event) => {
    setItem(event);
    setIsNew(false);
    setOpenEditAddModal(true);
  };

  const handleChangeStatus = () => {
    changeStateServiceFeature({ Id: item.id, Status: item.idstate === 1 }, enqueueSnackbar);
    setOpenAlertConfirmState(false);
    setPage(0);
  };

  const handleCancel = () => {
    setOpenAlertConfirmState(false);
  };

  if (loading) return <Loading />;

  return (
    <div className={classes.root}>
      <div>
        <TextField
          className={classes.textFieldSearch}
          onChange={handleSearchOnChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={searchIcon} alt="searchIcon" style={{ paddingLeft: '10px' }} />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
          placeholder="Buscar"
        />
        <Tooltip title="Crear característica de servicio" aria-label="Nueva característica de servicio">
          <IconButton
            edge="end"
            size="small"
            style={{ float: 'right' }}
            onClick={(e) => { setIsNew(true); setOpenEditAddModal(true); }}
          >
            <img src={newButton} alt="newButton" />
          </IconButton>
        </Tooltip>
      </div>
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableCotainer}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={totalRows}
            />
            <TableBody>
              {Object.keys(filterData).length > 0 && stableSort(filterData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                    className={row.idstate === 0 ? '' : classes.disabledRow}
                  >
                    <TableCell className={classes.tableCell}>
                      {row.serviceName}
                    </TableCell>
                    <TableCell className={classes.tableCellSmaller}>{row.serviceFeatureCode}</TableCell>
                    <TableCell className={classes.tableCellSmaller}>{row.featureDescription}</TableCell>
                    <TableCell className={classes.tableCell}>{row.featureTypeName}</TableCell>
                    <TableCell className={classes.tableCellSmaller}>{row.featureRelationName}</TableCell>
                    <TableCell align="center">
                      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <SwitchiOS
                          onChange={(e) => { handleSwitchClick(e, row); }}
                          checked={row.idstate === 0}
                          id={row.id}
                        />
                        <IconButton
                          edge="end"
                          aria-label="Editar"
                          size="small"
                          onClick={() => { handleEditClick(row); }}
                        >
                          <Tooltip title="Editar" aria-label="add">
                            <EditIcon className={classes.editIcon} />
                          </Tooltip>
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {
            filterData.length === 0 && rows.length > 0
            && (
            <Grid container style={{ justifyContent: 'center' }}>
              <Typography>No existe información correspondiente a los criterios de búsqueda</Typography>
            </Grid>
            )
          }
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          labelRowsPerPage="Registros por página"
          component="div"
          count={rows.length !== filterData.length ? filterData.length : totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={(e, newpage) => { handleChangePage(e, newpage, type, rowsPerPage); }}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          classes={{
            actions: classes2.actions,
            selectIcon: classes2.selectIcon,
            caption: classes.tablePagination,
          }}
        />
      </Paper>
      {openEditAddModal
        && (
        <ModalServiceFeature
          isOpen={openEditAddModal}
          onClose={() => setOpenEditAddModal(false)}
          item={item}
          isNew={isNew}
        />
        )}
      {openAlertConfirmState
        && (
          <AlertConfirm
            isOpen={openAlertConfirmState}
            tituloModal="Confirmar"
            mensajeModal={item.idstate === 1
              ? '¿Esta seguro que quiere activar esta característica del servicio?'
              : '¿Esta seguro que quiere inactivar esta característica del servicio?'}
            handleAccept={handleChangeStatus}
            onClose={handleCancel}
            onCancel
          />
        )}
    </div>
  );
};

const mapStateToProps = ({ serviceFeature }) => ({
  loading: serviceFeature.loading,
});

const mapDispatchToProps = {
  changeStateServiceFeature: changeStateServiceFeatureAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(GridServiceFeature);
