/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { useSnackbar } from 'notistack';
import AlertConfirm from '../../../../components/crosscutting/AlertConfirm';
import { ActivePromoCode, GetPromoCodes } from '../../../../actions/packets.action';
import CustomTable from '../../../../components/form/Table/CustomTable';
import Loading from '../../../../components/crosscutting/Loading';
import SwitchiOS from '../../../../components/form/SwitchiOS';

const useStyles = makeStyles((theme) => ({
  editIcon: {
    ...theme.backOffice.configurations.editIcon,
  },
}));

const PromoTable = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { setPromoCodeSelected, setOpenModalNewPromo, enteredFilter } = props;
  const [codesRows, setCodesRows] = React.useState([]);
  const [codesListCopy, setCodesListCopy] = React.useState([]);
  const [generalInfo, setGeneralInfo] = useState({
    code: '',
    checked: false,
  });
  const [openAlertConfirm, setOpenAlertConfirm] = useState(false);
  const [activeCode, setActiveCode] = useState(false);
  const [promCodesReady, setPromCodesReady] = useState(false);
  const promoCodes = useSelector(({ packets }) => packets.promoCodes);
  const loading = useSelector(({ packets }) => packets.loading);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(GetPromoCodes()).then(() => {
      setPromCodesReady(true);
    });
  }, [dispatch]);

  function filterInfoTable(enteredFilterParam, codesMapped) {
    const codesList = codesMapped != null ? codesMapped : codesListCopy;
    setCodesRows(codesList.filter(
      (num) => num.code
        .toLowerCase()
        .indexOf(enteredFilterParam.toLowerCase()) >= 0
        || num.quantity
          .toLowerCase()
          .indexOf(enteredFilterParam.toLowerCase()) >= 0
        || num.description
          .toLowerCase()
          .indexOf(enteredFilterParam.toLowerCase()) >= 0
        || num.discount
          .indexOf(enteredFilterParam) >= 0
        || num.startDate
          .indexOf(enteredFilterParam) >= 0
        || num.endDate
          .indexOf(enteredFilterParam) >= 0
        || enteredFilterParam === '',
    ));
  }

  useEffect(() => {
    filterInfoTable(enteredFilter, null);
  }, [enteredFilter]);

  const handleChangeCheckBox = (event) => {
    setGeneralInfo({
      code: event.target.value,
      checked: event.target.checked,
    });
    setOpenAlertConfirm(true);
    setActiveCode(event.target.checked);
  };

  const openModalEditPromo = (codeSelected) => {
    const codeEdit = promoCodes.find((codeRow) => codeRow.promotionCode === codeSelected);
    setPromoCodeSelected(codeEdit);
    setOpenModalNewPromo(true);
  };

  function createActiveElement(active, code) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <SwitchiOS
          checked={active}
          onChange={(e) => handleChangeCheckBox(e)}
          value={code}
          name="active"
          color="primary"
        />
        <IconButton
          edge="end"
          aria-label="Editar"
          size="small"
          onClick={() => openModalEditPromo(code)}
          style={{ paddingLeft: 10 }}
        >
          <Tooltip title="Editar" aria-label="add" placement="top">
            <EditIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
      </Box>
    );
  }

  function mapCodes(promoCodesParam) {
    const codesInfo = [];
    promoCodesParam.forEach((promoCode) => {
      const offertValueRow = promoCode.offerValue.toString();
      const codeRow = {
        code: promoCode.promotionCode,
        description: promoCode.descriptionPromotionCode,
        quantity: promoCode.useQuantity > 1 ? promoCode.useQuantity.toString() : 'Uso único',
        discount: promoCode.offerTypeCode === 'PTJ' ? offertValueRow.concat('%') : '$'.concat(offertValueRow),
        startDate: promoCode.effectiveStartDate.split('T')[0].replaceAll('-', '/'),
        endDate: promoCode.expirationDate.split('T')[0].replaceAll('-', '/'),
        active: createActiveElement(promoCode.idstate === 0, promoCode.promotionCode),
        state: promoCode.idstate,
        id: promoCode.id,
      };
      codesInfo.push(codeRow);
    });
    setCodesRows(codesInfo);
    setCodesListCopy(codesInfo);
    if (enteredFilter !== '') filterInfoTable(enteredFilter, codesInfo);
  }

  useEffect(() => {
    setCodesRows([]);
    mapCodes(promoCodes);
  }, [promoCodes]);

  const columns = [
    {
      id: 'code',
      label: 'Código promocional',
      paddingLeft: '40px',
      width: 150,
      position: 1,
      isSortable: true,
      sortPropertyName: 'code',
    },
    {
      id: 'quantity',
      label: 'Cantidad de uso',
      paddingLeft: '40px',
      width: 150,
      position: 2,
      isSortable: true,
      sortPropertyName: 'quantity',
    },
    {
      id: 'description',
      label: 'Descripción',
      paddingLeft: '40px',
      width: 250,
      position: 3,
      isSortable: true,
      sortPropertyName: 'description',
    },
    {
      id: 'discount',
      label: 'Descuento',
      paddingLeft: '40px',
      width: 10,
      position: 4,
      isSortable: true,
      sortPropertyName: 'discount',
    },
    {
      id: 'startDate',
      label: 'Inicio vigencia',
      paddingLeft: '40px',
      width: 165,
      position: 5,
      isSortable: true,
      sortPropertyName: 'startDate',
    },
    {
      id: 'endDate',
      label: 'Fin vigencia',
      paddingLeft: '40px',
      width: 165,
      position: 6,
      isSortable: true,
      sortPropertyName: 'endDate',
    },
    {
      id: 'active',
      paddingLeft: '',
      label: 'Activo',
      width: 10,
      position: 7,
      isSortable: true,
      sortPropertyName: 'active',
    },
  ];

  const handleConfirmChecked = () => {
    const codeSelected = promoCodes
      .find((promoCode) => promoCode.promotionCode === generalInfo.code);
    const body = {
      id: codeSelected.id,
      idstate: generalInfo.checked ? 0 : 1,
    };
    codeSelected.idstate = body.idstate;
    mapCodes(promoCodes);
    dispatch(ActivePromoCode(body));
    setGeneralInfo({
      code: '',
      checked: '',
    });
    setOpenAlertConfirm(false);
    enqueueSnackbar(`Se ha ${activeCode ? 'activado' : 'inactivado'} el código promocional exitosamente`, {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    });
  };

  if (loading && !promCodesReady) return <Loading />;

  return (
    <>
      <CustomTable
        data={codesRows}
        columns={columns}
        styleTableContainer={{ marginTop: '15px', width: 'auto' }}
      />

      <AlertConfirm
        isOpen={openAlertConfirm}
        tituloModal="Confirmar"
        mensajeModal={`¿Esta seguro que quiere ${activeCode ? 'activar' : 'inactivar'
        } este código promocional?`}
        handleAccept={() => handleConfirmChecked()}
        onCancel
        onClose={() => setOpenAlertConfirm(false)}
      />
    </>
  );
};

export default PromoTable;
