import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import {
  SuiteBar,
  UserMenu,
} from '@dg-bucaramanga/react-components-dg-pre';
import CartMenu from './CartMenu';
import CartModal from './CartModal';
import CartModalViewExt from '../Cart/CartModalViewExt';
import { setStatusCart } from '../../../actions/home.action';
import { setLogOutAction } from '../../../actions/auth.action';
import { setValueBackOfficeTab } from '../../../actions/general.action';
import {
  URL_WEB_COLFACTURA,
  URL_WEB_FIRMAYA,
  URL_WEB_FIRMAMAIL,
  URL_WEB_SUPERGUARDA,
  URL_WEB_IBUHOO,
} from '../../../config/config';
import { getLocalStorageEncript } from '../../../helpers/storageUtils';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    margin: 0,
    padding: 0,
    backgroundColor: theme.colorHeader,
  },
  content: {
    paddingRight: 10,
    paddingLeft: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tab: {
    borderBottom: '1px solid #ccc',
    textTransform: 'none',
  },
  logo: {
    maxWidth: 100,
  },
  divider: {
    margin: 5,
  },
  name: {
    color: theme.header.usserLogged.color,
    textTransform: 'capitalize',
    marginLeft: 5,
    marginRight: 5,
    fontWeight: 400,
  },
  customBadge: {
    ...theme.home.cart.customBadge,
  },
  badgeItems: {
    color: theme.home.cart.customBadgeItems,
    '& .MuiBadge-badge': {
      color: theme.home.cart.customBadgeItems,
      border: '2px solid white',
      boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.5)',
    },
  },
  shoppingCart: {
    ...theme.home.shoppingCart,
  },
  suiteBar: {
    marginRight: 10,
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
    paddingLeft: '10px',
    paddingRight: '10px',
    background: '#F1F1F1 0% 0% no-repeat padding-box',
  },
  styleLogo: {
    marginTop: 5,
  },
}));

const Header = (props) => {
  const {
    cart,
    items,
    setIsCartOpen,
    userInfo,
    appName,
    packages,
    showCart,
    setLogOut,
    history,
  } = props;

  const theme = useTheme();
  const classes = useStyles();
  const [cartMenu, setCart] = useState(null);
  const [storeLanding, setStoreLanding] = useState(false);
  const [token, setToken] = useState({
    result: {
      token: '',
    },
    error: {},
  });
  const { base } = packages;

  const handleClickLogOut = () => {
    setLogOut(appName, history);
  };

  const handleClickGoToMyPlan = () => {
    history.push('/myplan');
  };

  // eslint-disable-next-line consistent-return
  const getToken = (currentToken) => {
    let jwt;
    if (currentToken) {
      jwt = currentToken;
    } else {
      jwt = localStorage.getItem('appsUserToken');
    }

    if (jwt) {
      const jwtToken = jwt.replace('Bearer ', '');
      setToken((oldState) => ({
        ...oldState,
        result: {
          ...oldState.result,
          token: jwtToken,
        },
      }));
      return true;
    }
  };

  useEffect(() => {
    getToken();
    const landing = getLocalStorageEncript('storeLanding');
    if (landing) {
      setStoreLanding(true);
    }
  }, []);

  const redirectExternalUrl = () => {
    switch (appName) {
      case 'colfactura':
        return URL_WEB_COLFACTURA;
      case 'firmaya':
        return URL_WEB_FIRMAYA;
      case 'firmamail':
        return URL_WEB_FIRMAMAIL;
      case 'superguarda':
        return URL_WEB_SUPERGUARDA;
      default:
        return URL_WEB_IBUHOO;
    }
  };

  const userInfoComponent = {
    user: {
      email: '',
      name: '',
      role: [''],
      profilePicture: '',
      closeSession: () => {
        // llamar funcion cerrar sesion
        handleClickLogOut();
      },
    },
  };

  return (
    <>
      <AppBar position={appName === 'license' ? 'fixed' : 'static'} style={{ zIndex: 1211 }}>
        <Toolbar className={classes.toolbar}>
          <Grid container>
            <Grid item xs={false} sm={1} />
            <Grid item xs={12} sm={10} className={classes.content}>
              <a href={redirectExternalUrl()}>
                <img
                  src={theme.logo}
                  alt="logo"
                  className={classes.styleLogo}
                  style={{ width: '170px' }}
                />
              </a>
              <Box display="flex" alignItems="center">
                {
                  appName !== 'license' && showCart === true && (
                  <IconButton onClick={(e) => setCart(e.currentTarget)}>
                    <Badge
                      overlap="rectangular"
                      color="secondary"
                      badgeContent={items}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      classes={{ badge: classes.customBadge }}
                      className={classes.badgeItems}
                    >
                      <ShoppingCartIcon className={classes.shoppingCart} />
                    </Badge>
                  </IconButton>
                  )
                }
                {userInfo && (
                  <>
                    {
                      appName !== 'license' && (
                        <Box className={classes.suiteBar}>
                          <SuiteBar
                            appName={appName}
                            token={token.result.token}
                            scrollWidth={20}
                          />
                        </Box>
                      )
                    }
                    <Divider
                      orientation="vertical"
                      flexItem
                      className={classes.divider}
                    />
                    <Hidden smDown>
                      {userInfo && (
                        <Typography
                          className={classes.name}
                        >
                          {`${userInfo.name} ${userInfo.surname}`}
                        </Typography>
                      )}
                    </Hidden>
                  </>
                )}
                <CartMenu
                  cart={cart}
                  element={cartMenu}
                  setElement={setCart}
                  openCartModal={() => setIsCartOpen(true)}
                  history={history}
                  appName={appName}
                />
                {userInfo && (
                  <UserMenu
                    key="useMenu"
                    appName="license"
                    userInfo={userInfoComponent}
                    setTokens={setToken}
                    tokens={token}
                    functions={[{ name: 'handleClickGoToMyPlan', function: handleClickGoToMyPlan }]}
                    viewLoader
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={false} sm={1} />
          </Grid>
        </Toolbar>
      </AppBar>

      <CartModal userInfo={userInfo} appName={appName} packageBase={base} />
      {
        storeLanding && (
          <CartModalViewExt />
        )
      }
    </>
  );
};

const mapStateToProps = ({ home, auth, general }) => ({
  userInfo: auth.userInfo,
  cart: home.cart,
  items: home.cart.length,
  packages: home.packages,
  showCart: home.showCart,
  appName: general.appName,
});

const mapDispatchToProps = {
  setIsCartOpen: setStatusCart,
  setLogOut: setLogOutAction,
  setValueBOTab: setValueBackOfficeTab,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
