/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import axios from 'axios';
import {
  LOADING, GET_PAYMENTS, UPDATE_PENDING, UPDATE_APPROVED, UPDATE_REJECTED, GET_CHANNEL, GET_CHANNEL_COMBO, GET_CUSTOMER_COMBO, GET_CUSTOMER_LICENSED_COMBO,
  GET_PROMO_CODES, GET_PACKAGES, GET_PACKAGES_TYPE, GET_PACKAGES_TYPE_COMBO, SET_PROMO_CODE_SELECTED, GET_PACKAGES_ADDITIONAL_DISCOUNT, GET_PACKAGES_ID,
  PACKET_SHOW_MESSAGE, PAYMENT_IS_SEARCH, GET_SUPPORT_PAYMENT_FILE, GET_RUT_FILE, UPDATE_PACKAGE_FEATURES, SELECT_PACKAGE_FEATURES, SEARCH_PACKAGE_FEATURES,
  LOADING_INFO_PACKAGE, CUSTOMER_LINKED_PACKAGE, PROMOCODE_LINKED_PACKAGE, CHANNEL_LINKED_PACKAGE, FEATURES_LINKED_PACKAGE, SEARCH_PACKAGE, PROMOCODE_USER_TYPE,
} from '../types/packets.type';
import { API_LICENCIAMIENTO, NUM_REGISTERS_PAGING, DEFAULTRESPONSE } from '../config/config';
import { defaultHeaders } from '../helpers/generalUtils';
import { PURCHASE_TYPE } from '../constants/PurchaseConstants';

export const setMessageAlertModalAction = (data) => async (dispatch) => {
  try {
    dispatch({
      type: PACKET_SHOW_MESSAGE,
      payload: data,
    });
  } catch (error) {
    console.error(error);
  }
};

export const getPaymentsAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const config = defaultHeaders();
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/PurchaseOrder/GetPurchaseCash`, config);
    if (response.status === 200) {
      dispatch({ type: GET_PAYMENTS, payload: response.data.result.records });
      dispatch({ type: LOADING, payload: false });
    }
  } catch (error) {
    console.error(error);
  }
};

export const getPaymentsPagingAction = (data, type) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const config = defaultHeaders();
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/PurchaseOrder/GetPurchaseCashByBatch`, data, config);
    if (response.status === 200) {
      switch (type) {
        case 'pending':
          const { packets: { pendingBatch } } = getState();
          if (pendingBatch) Array.prototype.push.apply(pendingBatch, response.data.result.records.purchase);
          const arrUpdated = Object.assign([], pendingBatch === null ? response.data.result.records.purchase : pendingBatch);
          dispatch({ type: UPDATE_PENDING, payload: arrUpdated, rownums: response.data.result.records.purchaseRows });
          break;
        case 'approved':
          const { packets: { approvedBatch } } = getState();
          if (approvedBatch) Array.prototype.push.apply(approvedBatch, response.data.result.records.purchase);
          const arrUpdatedAp = Object.assign([], approvedBatch === null ? response.data.result.records.purchase : approvedBatch);
          dispatch({ type: UPDATE_APPROVED, payload: arrUpdatedAp, rownums: response.data.result.records.purchaseRows });
          break;
        case 'rejected':
          const { packets: { rejectedBatch } } = getState();
          if (rejectedBatch) Array.prototype.push.apply(rejectedBatch, response.data.result.records.purchase);
          const arrUpdatedRe = Object.assign([], rejectedBatch === null ? response.data.result.records.purchase : rejectedBatch);
          dispatch({ type: UPDATE_REJECTED, payload: arrUpdatedRe, rownums: response.data.result.records.purchaseRows });
          break;
        default:
          break;
      }
      dispatch({ type: LOADING, payload: false });
    }
  } catch (error) {
    console.error(error);
  }
};

export const reloadBatchAction = () => (dispatch) => {
  try {
    dispatch({ type: UPDATE_PENDING, payload: null, rownums: 0 });
    dispatch({ type: UPDATE_APPROVED, payload: null, rownums: 0 });
    dispatch({ type: UPDATE_REJECTED, payload: null, rownums: 0 });
    dispatch(getPaymentsPagingAction({ Last: 0, NumRows: NUM_REGISTERS_PAGING, IdState: PURCHASE_TYPE.Pending }, 'pending'));
    dispatch(getPaymentsPagingAction({ Last: 0, NumRows: NUM_REGISTERS_PAGING, IdState: PURCHASE_TYPE.Approved }, 'approved'));
    dispatch(getPaymentsPagingAction({ Last: 0, NumRows: NUM_REGISTERS_PAGING, IdState: PURCHASE_TYPE.Rejected }, 'rejected'));
  } catch (error) {
    console.error(`Error aprobando pago: ${error.message}`);
  }
};

export const approvePaymentAction = (data, appName, enqueueSnackbar) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const config = defaultHeaders();
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/PurchaseOrder/ChangeStatePurchase/${appName}`, data, config);
    if (response.status === 200) {
      dispatch(reloadBatchAction());
      dispatch({ type: LOADING, payload: false });
      // enqueueSnackbar('Se ha aprobado el pago satisfactoriamente.', {
      //   variant: 'success',
      //   anchorOrigin: {
      //     vertical: 'top',
      //     horizontal: 'right',
      //   },
      // });

      dispatch(
        setMessageAlertModalAction({
          showMessageAlertModal: true,
          typeMsg: 'Success',
          tittleMsg: '¡Perfecto!',
          message: 'Se ha aprobado el pago satisfactoriamente',
        }),
      );
      // dispatch({ type: PACKET_SHOW_MESSAGE, payload: true });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 422) {
        enqueueSnackbar(error.response.data.statusMessage, {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar('Error de servidor', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    }
    console.error(`Error aprobando pago: ${error.message}`);
  }
};

export const rejectPaymentAction = (data, appName, enqueueSnackbar) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const config = defaultHeaders();
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/PurchaseOrder/ChangeStatePurchase/${appName}`, data, config);
    if (response.status === 200) {
      dispatch(reloadBatchAction());
      dispatch({ type: LOADING, payload: false });
      // enqueueSnackbar('El pago ha sido rechazado satisfactoriamente.', {
      //   variant: 'success',
      //   anchorOrigin: {
      //     vertical: 'top',
      //     horizontal: 'right',
      //   },
      // });
      dispatch(
        setMessageAlertModalAction({
          showMessageAlertModal: true,
          typeMsg: 'Success',
          tittleMsg: '¡Perfecto!',
          message: 'El pago ha sido rechazado satisfactoriamente.',
        }),
      );
      // dispatch({ type: PACKET_SHOW_MESSAGE, payload: true });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 422) {
        enqueueSnackbar(error.response.data.statusMessage, {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar('Error de servidor', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    }
    console.error(`Error aprobando pago: ${error.message}`);
  }
};

export const getPackagesAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const config = defaultHeaders();
    const response = await axios.get(`${API_LICENCIAMIENTO}/license/api/Package/GetPackages`, config);
    if (response.status === 200) {
      dispatch({ type: GET_PACKAGES, payload: response.data.result.records });
      dispatch({ type: LOADING, payload: false });
    }
  } catch (error) {
    console.error(`Error obteniendo la lista de paquetes: ${error.message}`);
  }
};

// eslint-disable-next-line consistent-return
export const getCountPackagesByTypeAction = (packageTypeId, relevance) => async () => {
  try {
    const config = defaultHeaders();
    const response = await axios.get(`${API_LICENCIAMIENTO}/license/api/Package/GetCountPackagesByType/${packageTypeId}/${relevance}`, config);
    if (response.status === 200) {
      return response.data.result.count;
    }
    return 0;
  } catch (error) {
    console.error(`Error obteniendo la cantidad de paquetes por packageTypeId: ${error.message}`);
  }
};

export const getPackagesTypeAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const packageTypeCombo = [];
    const config = defaultHeaders();
    const response = await axios.get(`${API_LICENCIAMIENTO}/license/api/Package/GetPackagesType`, config);
    if (response.status === 200) {
      response.data.result.records.forEach((item) => packageTypeCombo.push(
        {
          value: item.packageTypeId,
          label: item.packageTypeName,
        },
      ));

      dispatch({ type: GET_PACKAGES_TYPE, payload: response.data.result.records });
      dispatch({ type: GET_PACKAGES_TYPE_COMBO, payload: packageTypeCombo });
      dispatch({ type: LOADING, payload: false });
    }
  } catch (error) {
    console.error(`Error obteniendo la lista de tipo de paquetes: ${error.message}`);
  }
};

export const getChannelAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const channelCombo = [];
    const config = defaultHeaders();
    const response = await axios.get(`${API_LICENCIAMIENTO}/license/api/Package/GetAllChannel`, config);
    if (response.status === 200) {
      response.data.result.records.forEach((item) => channelCombo.push(
        {
          id: item.channelId,
          text: item.channelType,
        },
      ));

      dispatch({ type: GET_CHANNEL, payload: response.data.result.records });
      dispatch({ type: GET_CHANNEL_COMBO, payload: channelCombo });
      dispatch({ type: LOADING, payload: false });
    }
  } catch (error) {
    console.error(`Error obteniendo la lista de canales: ${error.message}`);
  }
};

export const getCustomerAction = (startRecord, takeRecord, dataFilter, addDocNumber) => async (dispatch) => {
  try {
    const customerCombo = [];
    const config = defaultHeaders();
    Object.assign(config.headers, {
      startRegister: startRecord,
      registerNumber: takeRecord,
      filter: dataFilter || '',
    });
    const response = await axios.get(`${API_LICENCIAMIENTO}/license/api/Customer/GetCustomersPaged`,
      config);
    if (response.status === 200) {
      response.data.result.records.forEach((item) => {
        if (addDocNumber) {
          customerCombo.push(
            {
              id: item.id,
              text: item.legalName ? `${item.documentNumber} - ${item.legalName}` : `${item.documentNumber} - ${item.firstName} ${item.lastName}`,
            },
          );
        } else {
          customerCombo.push(
            {
              id: item.id,
              text: item.legalName ? item.legalName : `${item.firstName} ${item.lastName}`,
            },
          );
        }
      });
      dispatch({ type: GET_CUSTOMER_COMBO, payload: customerCombo });
    }
  } catch (error) {
    console.error(`Error obteniendo la lista de clientes: ${error.message}`);
  }
};

export const getCustomerLicensedAction = (
  startRecord,
  takeRecord,
  dataFilter,
  addDocNumber,
  enqueueSnackbar,
) => async (dispatch) => {
  try {
    const customerCombo = [];
    const config = defaultHeaders();
    Object.assign(config.headers, {
      startRegister: startRecord,
      registerNumber: takeRecord,
      filter: dataFilter || '',
    });
    const response = await axios.get(`${API_LICENCIAMIENTO}/license/api/Customer/GetCustomersLicensed`,
      config);
    if (response.status === 200) {
      response.data.result.records.forEach((item) => {
        if (addDocNumber) {
          customerCombo.push(
            {
              id: item.id,
              text: `${item.documentNumber} - ${item.name}`,
            },
          );
        } else {
          customerCombo.push(
            {
              id: item.id,
              text: item.name,
            },
          );
        }
      });
      dispatch({ type: GET_CUSTOMER_LICENSED_COMBO, payload: customerCombo });
    }
  } catch (error) {
    const { response } = error;
    enqueueSnackbar(response.status === 404
      ? 'No se encontraron licencias'
      : 'La solicitud no ha podido ser procesada', {
      variant: response.status === 404
        ? 'warning'
        : 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    });
  }
};

export const saveNewPackageAction = (data, enqueueSnackbar) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const config = defaultHeaders();
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/Package/SavePackage`, data, config);
    if (response.status === 200) {
      dispatch({ type: LOADING, payload: false });
      await dispatch(getPackagesAction());
      enqueueSnackbar('El paquete se ha guardado exitosamente', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
  } catch (error) {
    console.error(`Error guardando el paquete: ${error.message}`);
    const errorMessage = `El paquete ${data.PackageCode} no fue guardado`;
    if (error.response) {
      if (error.response.status === 422) {
        enqueueSnackbar(errorMessage, {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar('Error de servidor', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    }
  }
};

export const updatePackageAction = (data, enqueueSnackbar) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const config = defaultHeaders();
    const response = await axios.put(`${API_LICENCIAMIENTO}/license/api/Package/UpdatePackage`, data, config);
    if (response.status === 200) {
      dispatch({ type: LOADING, payload: false });
      await dispatch(getPackagesAction());
      enqueueSnackbar('El paquete se ha editado exitosamente', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
  } catch (error) {
    console.error(`Error obteniendo la lista de paquetes: ${error.message}`);
    const errorMessage = `El paquete ${data.packageCode} no fue editado`;
    if (error.response) {
      if (error.response.status === 422) {
        enqueueSnackbar(errorMessage, {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar('Error de servidor', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    }
  }
};

export const changeStatePackageAction = (data, enqueueSnackbar) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const config = defaultHeaders();
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/Package/ActivePackage`, data, config);
    if (response.status === 200) {
      dispatch({ type: LOADING, payload: false });
      const message = data.Status
        ? 'Se ha activado el paquete exitosamente.'
        : 'Se ha inactivado el paquete exitosamente.';
      enqueueSnackbar(message, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 422) {
        enqueueSnackbar(error.response.data.statusMessage, {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar('Error de servidor', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    }
    dispatch({ type: LOADING, payload: false });
    console.error(`Error modificando el paquete: ${error.message}`);
  }
};

export const GetPromoCodes = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const config = defaultHeaders();
    const response = await axios.get(`${API_LICENCIAMIENTO}/license/api/PromotionalCode/GetPromotionalCodes`, config);
    if (response.status === 200) {
      dispatch({ type: GET_PROMO_CODES, payload: response.data.result.records });
      dispatch({ type: LOADING, payload: false });
    }
  } catch (error) {
    console.error(`Error obteniendo la lista de códigos promocionales: ${error.message}`);
  }
};

export const GetPackagesAdditionalDiscount = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const config = defaultHeaders();
    const response = await axios.get(`${API_LICENCIAMIENTO}/license/api/Package/GetPackagesAdditionalDiscount`, config);
    if (response.status === 200) {
      dispatch({ type: GET_PACKAGES_ADDITIONAL_DISCOUNT, payload: response.data.result.records });
      dispatch({ type: LOADING, payload: false });
    }
  } catch (error) {
    console.error(`Error obteniendo la lista de paquetes con descuentos adicionales: ${error.message}`);
  }
};

export const GetPackagesId = (promoCodeId) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const config = defaultHeaders();
    Object.assign(config.headers, {
      promotionalCodeId: promoCodeId,
    });
    const response = await axios.get(`${API_LICENCIAMIENTO}/license/api/PromotionalCode/GetPackagesId`, config);
    if (response.status === 200) {
      dispatch({ type: GET_PACKAGES_ID, payload: response.data.result.records });
      dispatch({ type: LOADING, payload: false });
    }
  } catch (error) {
    console.error(`Error obteniendo la lista de paquetes asociados a un codigo promocional : ${error.message}`);
  }
};

export const SavePromoCode = (data, enqueueSnackbar) => async (dispatch) => {
  try {
    const config = defaultHeaders();
    await axios.post(`${API_LICENCIAMIENTO}/license/api/PromotionalCode/SaveNewPromoCode`, data, config).then(() => {
      dispatch(GetPromoCodes());
      enqueueSnackbar('El código promocional se ha guardado exitosamente', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    });
  } catch (error) {
    console.error(`Error guardando el código promocional: ${error.message}`);
    const errorMessage = `El código ${data.promotionCode} no fue guardado`;
    enqueueSnackbar(errorMessage, {
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    });
  }
};

export const UpdatePromoCode = (data, enqueueSnackbar) => async (dispatch) => {
  try {
    const config = defaultHeaders();
    await axios.put(`${API_LICENCIAMIENTO}/license/api/PromotionalCode/UpdatePromoCode`, data, config).then(() => {
      dispatch(GetPromoCodes());
      enqueueSnackbar('El código promocional se ha editado exitosamente', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    });
  } catch (error) {
    console.error(`Error actualizando el código promocional: ${error.message}`);
    const errorMessage = `El código ${data.promotionCode} no fue editado`;
    enqueueSnackbar(errorMessage, {
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    });
  }
};

export const ActivePromoCode = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const config = defaultHeaders();
    const response = await axios.put(`${API_LICENCIAMIENTO}/license/api/PromotionalCode/ActivePromoCode`, data, config);
    if (response.status === 201) {
      dispatch({ type: LOADING, payload: false });
    }
  } catch (error) {
    console.error(`Error activando el código promocional: ${error.message}`);
  }
};

export const ActivePackage = (data) => async () => {
  try {
    const config = defaultHeaders();
    await axios.put(`${API_LICENCIAMIENTO}/license/api/Package/ActivePackage`, data, config);
  } catch (error) {
    console.error(`Error activando el paquete: ${error.message}`);
  }
};

export const setPromotionalCodeSelected = (promotionalCodeSelected) => (dispatch) => {
  dispatch({
    type: SET_PROMO_CODE_SELECTED,
    payload: promotionalCodeSelected,
  });
};

export const searchPaymentsPagingAction = (data, type) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const config = defaultHeaders();
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/PurchaseOrder/SearchPurchaseCash`, data, config);
    if (response.status === 200) {
      const dataResultSearch = Object.assign([], response.data.result.records.purchase);
      switch (type) {
        case 'pending':
          dispatch({ type: UPDATE_PENDING, payload: dataResultSearch, rownums: response.data.result.records.purchaseRows });
          break;
        case 'approved':
          dispatch({ type: UPDATE_APPROVED, payload: dataResultSearch, rownums: response.data.result.records.purchaseRows });
          break;
        case 'rejected':
          dispatch({ type: UPDATE_REJECTED, payload: dataResultSearch, rownums: response.data.result.records.purchaseRows });
          break;
        default:
          break;
      }
      dispatch({ type: PAYMENT_IS_SEARCH, payload: true });
      dispatch({ type: LOADING, payload: false });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: LOADING, payload: false });
  }
};

export const getPaymentSupportAction = (referenceUuid, supportType) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const config = defaultHeaders();
    Object.assign(config.headers, {
      ReferenceUuid: referenceUuid,
      SupportType: supportType,
    });
    const response = await axios.get(`${API_LICENCIAMIENTO}/license/api/purchaseorderlicense/getpaymentsupport`, config);
    if (supportType === 'PAYMENT_SUPPORT') {
      dispatch({
        type: GET_SUPPORT_PAYMENT_FILE,
        payload: response.data.result.resultUploadSupport,
      });
    } else if (supportType === 'RUT') {
      dispatch({
        type: GET_RUT_FILE,
        payload: response.data.result.resultUploadSupport,
      });
    }
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    const { response } = error;
    dispatch(
      setMessageAlertModalAction({
        showMessageAlertModal: true,
        typeMsg: 'Error',
        tittleMsg: '¡Lo sentimos!',
        message: response?.data?.statusMessage
          ? response.data.statusMessage
          : DEFAULTRESPONSE.errorDownloadFile,
      }),
    );
    dispatch({ type: LOADING, payload: false });
    console.error(error);
  }
};

export const getFeatureToPackageAction = (data) => async (
  dispatch,
) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const config = defaultHeaders();
    
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/package/GetPackagesConfiguration`, data, config);
    
    const packages = response.data.result.records;
    localStorage.setItem('packageConfiguration', JSON.stringify(packages));

    if (response.status === 200){
      dispatch({ type: UPDATE_PACKAGE_FEATURES, payload: packages, copypayload: packages });
    }
    dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.error(`Error al guardar el cliente: ${error.message}`);
    }
};

export const addFeatureToPackageAction = (data, packageId, serviceId, quantityTX, storageType) => async (
  dispatch,
  getState,
) => {
  const {
    packets: { packagesFeatures },
  } = getState();

  dispatch({ type: LOADING, payload: true });

  const packsUpdated = packagesFeatures;
  const itemPackage = packsUpdated.find((x) => x.packageId === packageId);
  const { featureId } = data;
  itemPackage.serviceList.forEach((element) => {
    if (element.serviceId === serviceId) {
      element.features.forEach((feature) => {
        if (feature.featureId === featureId) {
          itemPackage.updated = true;
          feature.quantity = quantityTX;
          if (storageType) {
            feature.storageType = storageType;
          }
        }
      });
    }
  });

  localStorage.setItem('packageConfiguration', JSON.stringify(packsUpdated));

  dispatch({ type: UPDATE_PACKAGE_FEATURES, payload: packsUpdated });
  dispatch({ type: LOADING, payload: false });
};

export const selectFeatureToPackageAction = (data, packageId, serviceId) => async (
  dispatch,
  getState,
) => {
  const {
    packets: { packagesFeatures },
  } = getState();
  dispatch({ type: LOADING, payload: true });

  const packsUpdated = packagesFeatures;
  const itemPackage = packsUpdated.find((x) => x.packageId === packageId);
  const { featureId } = data;
  itemPackage.serviceList.forEach((element) => {
    if (element.serviceId === serviceId) {
      element.features.forEach((feature) => {
        if (feature.featureId === featureId) {
          feature.selected = !feature.selected;
          if (feature.selected === true) {
            feature.quantity = 1;
            itemPackage.updated = true;
            if (feature.categoryType === 'Almacenamiento') {
              feature.storageType = 'MB';
            }
          } else {
            itemPackage.updated = true;
            feature.quantity = 0;
            feature.storageType = '';
          }
        }
      });
    }
  });

  localStorage.setItem('packageConfiguration', JSON.stringify(packsUpdated));
  dispatch({ type: SELECT_PACKAGE_FEATURES, payload: packsUpdated });
  dispatch({ type: LOADING, payload: false });
};

export const searchFeatureToPackageAction = (packageId, filter) => (
  dispatch,
) => {
  dispatch({ type: LOADING, payload: true });

  const packsUpdated = JSON.parse(localStorage.getItem('packageConfiguration'));
  packsUpdated.forEach((element) => {
    if (element.packageId === packageId) {
      element.serviceList.forEach((service) => {
        service.features = service.features.filter((x) => x.featureName.toLowerCase().includes(filter.toLowerCase()));
      });
    }
  });

  dispatch({ type: SEARCH_PACKAGE_FEATURES, payload: packsUpdated });
  dispatch({ type: LOADING, payload: false });
};

export const searchPackageAction = (filter) => (
  dispatch,
) => {
  dispatch({ type: LOADING, payload: true });

  const packsUpdatedJson = JSON.parse(localStorage.getItem('packageConfiguration'));
  let packsUpdated = [];
  
  packsUpdated = packsUpdatedJson.filter((x) => x.packageName.toLowerCase().includes(filter.toLowerCase()));
  
  dispatch({ type: SEARCH_PACKAGE, payload: packsUpdated });
  dispatch({ type: LOADING, payload: false });
};

/**
 * Obtiene clientes vinculados a paquete
 * @param {*} packageId id de paquete
 * @returns retorna listado de clientes vinculados a paquete
 */
export const getCustomerLinkedPackageAction = (packageId, enqueueSnackbar) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_INFO_PACKAGE, payload: true });
    const config = defaultHeaders();
    const response = await axios.get(`${API_LICENCIAMIENTO}/license/api/package/GetCustomerLinkedPackage/${packageId}`, config);
    if (response.status === 200) {
      dispatch({ type: LOADING_INFO_PACKAGE, payload: false });
      dispatch({ type: CUSTOMER_LINKED_PACKAGE, payload: response.data.result.records });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 500) {
        enqueueSnackbar('Se ha presentado un error al procesar la solicitud', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    }
    dispatch({ type: LOADING_INFO_PACKAGE, payload: false });
    console.error(`Error consultando info de paquete: ${error.message}`);
  }
};

export const getPromoCodesLinkedPackageAction = (packageId, enqueueSnackbar) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_INFO_PACKAGE, payload: true });
    const config = defaultHeaders();
    const response = await axios.get(`${API_LICENCIAMIENTO}/license/api/package/GetPromoCodeLinkedPackage/${packageId}`, config);
    if (response.status === 200) {
      dispatch({ type: LOADING_INFO_PACKAGE, payload: false });
      dispatch({ type: PROMOCODE_LINKED_PACKAGE, payload: response.data.result.records });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 500) {
        enqueueSnackbar('Se ha presentado un error al procesar la solicitud', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    }
    dispatch({ type: LOADING_INFO_PACKAGE, payload: false });
    console.error(`Error consultando info de paquete: ${error.message}`);
  }
};

export const getChannelsLinkedPackageAction = (packageId, enqueueSnackbar) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_INFO_PACKAGE, payload: true });
    const config = defaultHeaders();
    const response = await axios.get(`${API_LICENCIAMIENTO}/license/api/package/GetChannelsLinkedPackage/${packageId}`, config);
    if (response.status === 200) {
      dispatch({ type: LOADING_INFO_PACKAGE, payload: false });
      dispatch({ type: CHANNEL_LINKED_PACKAGE, payload: response.data.result.records });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 500) {
        enqueueSnackbar('Se ha presentado un error al procesar la solicitud', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    }
    dispatch({ type: LOADING_INFO_PACKAGE, payload: false });
    console.error(`Error consultando info de paquete: ${error.message}`);
  }
};

export const getFeaturesDetailPackageAction = (packageId, enqueueSnackbar) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_INFO_PACKAGE, payload: true });
    const config = defaultHeaders();
    const response = await axios.get(`${API_LICENCIAMIENTO}/license/api/package/GetFeaturesPackage/${packageId}`, config);
    if (response.status === 200) {
      dispatch({ type: LOADING_INFO_PACKAGE, payload: false });
      dispatch({ type: FEATURES_LINKED_PACKAGE, payload: response.data.result.records });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 500) {
        enqueueSnackbar('Se ha presentado un error al procesar la solicitud', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    }
    dispatch({ type: LOADING_INFO_PACKAGE, payload: false });
    console.error(`Error consultando info de paquete: ${error.message}`);
  }
};

export const updateFeaturesToPackageAction = (data, enqueueSnackbar) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    
    const config = defaultHeaders();
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/package/SetFeaturesDependingPackage`, data, config);

    if (response.status === 200) {
      enqueueSnackbar('Las caracteristicas se han actualizado exitosamente', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
  }
};

export const promoCodeUserTypeAction = () => async (dispatch) => {
  try {
    const promocodeType = [];
    dispatch({ type: LOADING, payload: true });
    const config = defaultHeaders();
    const response = await axios.get(`${API_LICENCIAMIENTO}/license/api/PromotionalCode/GetTypeUserPromotional`, config);

    response.data.result.records.forEach((item) => promocodeType.push(
      {
        value: item.id,
        typeUserPromo: item.typeUser,
      },
    ));

    dispatch({ type: PROMOCODE_USER_TYPE, payload: promocodeType  })
    
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.error(`Error obteniendo la lista de usuarios en códigos promocionales: ${error.message}`);
  }
};


