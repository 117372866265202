/* eslint-disable no-shadow, react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { isValidUsertoConfigurations, authorizeActionsRole } from '../../../actions/auth.action';
import GridServices from './Services/GridServices';
import GridFeatureType from './FeatureType/GridFeatureType';
import GridServiceFeature from './ServiceFeatures/GridServiceFeature';
import { getServicesAction } from '../../../actions/service.action';
import { getServiceFeaturesAction } from '../../../actions/serviceFeature.action';
import { getParentsServiceFeaturesAction } from '../../../actions/serviceFeature.action';
import { getFeatureTypeAction } from '../../../actions/featureType.action';
import { getServiceTypesAction, getFeatureTypesAction, getTokenExternalSourceAction } from '../../../actions/general.action';
import { NUM_REGISTERS_PAGING } from '../../../config/config';
import PromoCode from './PromoCode';
import Loading from '../../../components/crosscutting/Loading';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '5px',
    marginTop: '20px',
    opacity: '0.95',
    borderRadius: 20,
    background: 'linear-gradient(#FFFFFFCC, #ffffff)',
  },
  tabMd: {
    borderBottom: '1px solid #ccc',
    textTransform: 'none',
    fontWeight: 600,
  },
  tabLg: {
    borderBottom: '1px solid #ccc',
    textTransform: 'none',
    fontWeight: 600,
    width: '100%',
  },
  paperTab: {
    backgroundColor: '#fff',
    margin: 20,
    borderRadius: 10,
    border: '1px solid #32ACDE',
  },
  title: {
    ...theme.backOffice.configurations.title,
    fontWeight: 600,
    fontSize: '26px',
    paddingTop: '15px',
    paddingLeft: '20px',
  },
  accessDeniedContainer: {
    height: 300,
  },
  accessDenied: {
    color: '#272727',
    fontWeight: 500,
    fontSize: '20px',
    paddingTop: '15px',
    paddingLeft: '18px',
  },
}));

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const Configuration = (props) => {
  const classes = useStyles();
  const {
    getServices, services,
    getServiceTypes, serviceFeaturesBatch, serviceFeaturesBatchRows, getServiceFeatures,
    getFeatureTypes, getFeatureTypeList, featureTypesList, serviceFeaturePage,
    getTokenExternalSource, getOnlyParentFeactures
  } = props;

  const [value, setValue] = useState(0);
  const [page, setPage] = React.useState(0);
  const [showFunctionality, setShowFunctionality] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const appName = useSelector(({ general }) => general.appName);
  const decodedTokenJwt = useSelector(({ auth }) => auth.decodedToken);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    getServices();
    getServiceTypes();
    if (isValidUsertoConfigurations(decodedTokenJwt, 'CONFIGURATION') && appName === 'license') {
      setShowFunctionality(true);
      const authorizeRole = authorizeActionsRole(decodedTokenJwt, 'CONFIGURATION');
      const promoCodeRead = authorizeRole.authorize.some((x) => x === 'LICENSE_PROMOCODE_READ');
      if (promoCodeRead) {
        setIsDisabled(true);
        setValue(3);
      }
    }
    const appsAdminToken = localStorage.getItem('tokenAdminIbuho');
    if (!appsAdminToken) getTokenExternalSource();
    // Aqui debe cargar los parent feactures
    getOnlyParentFeactures()
    // lastRow 0 carga por paginacion, null carga todos los registros
    getServiceFeatures({ lastRow: null, numRows: NUM_REGISTERS_PAGING });
    getFeatureTypeList();
    getFeatureTypes();
  }, []);

  useEffect(() => {
    if (serviceFeaturePage === 0) {
      setPage(0);
    }
  }, [serviceFeaturePage]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChangePage = (event, newPage, typegrid, rowsPerPage) => {
    const numRows = rowsPerPage;
    let last;
    switch (typegrid) {
      case 'serviceFeatures':
        last = serviceFeaturesBatch.length;
        if (last < (newPage + 1) * numRows) {
          getServiceFeatures({ lastRow: last, numRows: NUM_REGISTERS_PAGING });
        }
        break;
      default:
        break;
    }
    setPage(newPage);
  };

  const handleChange = (value, newValue) => {
    setValue(newValue);
    setPage(0);
  };

  return (
    <>
      <Container component="main" maxWidth={isMd ? 'md' : 'lg'}>
        <Paper elevation={3} className={classes.paper}>
          <Typography color="primary" variant="h4" className={classes.title}>Configuración</Typography>
          { showFunctionality ? (
            <>
              <Tabs
                value={value}
                indicatorColor="secondary"
                textColor="secondary"
                onChange={handleChange}
                aria-label="disabled tabs example"
                centered
              >
                <Tab className={isMd ? classes.tabMd : classes.tabLg} disabled={isDisabled} label="Producto o servicio" {...a11yProps(0)} />
                <Tab className={isMd ? classes.tabMd : classes.tabLg} disabled={isDisabled} label="Tipo características" {...a11yProps(1)} />
                <Tab className={isMd ? classes.tabMd : classes.tabLg} disabled={isDisabled} label="Características del servicio" {...a11yProps(2)} />
                <Tab className={isMd ? classes.tabMd : classes.tabLg} label="Códigos promocionales" {...a11yProps(3)} />
              </Tabs>

              <Paper elevation={3} className={classes.paperTab}>
                <TabPanel value={value} index={0} hidden={isDisabled}>
                  {services.length
                    ? (
                      <GridServices
                        rows={services}
                        type="pending"
                        handleChangePage={handleChangePage}
                        page={page}
                      />
                    ) : <Loading />}
                </TabPanel>
                <TabPanel value={value} index={1} hidden={isDisabled}>
                  {featureTypesList.length
                    ? (
                      <GridFeatureType
                        rows={featureTypesList}
                        type="featureType"
                        handleChangePage={handleChangePage}
                        page={page}
                        setPage={setPage}
                      />
                    ) : <Loading />}
                </TabPanel>
                <TabPanel value={value} index={2} hidden={isDisabled}>
                  {serviceFeaturesBatch.length
                    ? (
                      <GridServiceFeature
                        rows={serviceFeaturesBatch}
                        totalRows={serviceFeaturesBatchRows}
                        setPage={setPage}
                        page={page}
                        type="serviceFeatures"
                        handleChangePage={handleChangePage}
                        isSearchServer={false}
                      />
                    ) : <Loading />}
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <PromoCode />
                </TabPanel>
              </Paper>
            </>
          ) : (
            <Box className={classes.accessDeniedContainer}>
              <Typography color="primary" variant="h4" className={classes.accessDenied}>
                No tienes los suficientes privilegios para realizar esta acción.
              </Typography>
            </Box>
          )
        }
        </Paper>
      </Container>
    </>
  );
};

const mapStateToProps = ({
  general, service, serviceFeature, featureType,
}) => ({
  services: service.services,
  loading: service.loading,
  serviceFeaturesBatch: serviceFeature.serviceFeaturesBatch,
  serviceFeaturesBatchRows: serviceFeature.serviceFeaturesBatchRows,
  featureTypes: general.featureTypes,
  featureTypesList: featureType.featureTypesList,
  serviceFeaturePage: serviceFeature.serviceFeaturePage,
});

const mapDispatchToProps = {
  getServices: getServicesAction,
  getServiceTypes: getServiceTypesAction,
  getServiceFeatures: getServiceFeaturesAction,
  getFeatureTypeList: getFeatureTypeAction,
  getFeatureTypes: getFeatureTypesAction,
  getTokenExternalSource: getTokenExternalSourceAction,
  getOnlyParentFeactures: getParentsServiceFeaturesAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Configuration);
