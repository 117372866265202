/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.report.mainReport,
    '& .MuiDialog-paperFullScreen': {
      ...theme.report.containerReport,
      width: '95%',
      height: '95%',
      borderRadius: '10px',
    },
  },
  titleReport: {
    ...theme.report.titleReport,
    fontWeight: 600,
  },
}));

const ReportView = (props) => {
  const classes = useStyles();
  const {
    titleReport, src,
  } = props;

  const { search } = window.location;
  const params = new URLSearchParams(search);
  const queryReportType = params.get('reportType');
  const queryDocumentType = params.get('documentType');
  const queryDocumentNumber = params.get('documentNumber');
  const queryClaimCreate = params.get('claimCreate');
  const queryClaimDelete = params.get('claimDelete');

  const setIframeReport = (type) => {
    if (src) {
      return src;
    }
    switch (type) {
      case 'GeneralConsumptionReport':
        return `https://kibana.gse.com.co/app/dashboards#/view/07314280-67f5-11ec-8f37-5117c9a1429e?embed=true&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-90d,to:now))&_a=(description:ReporteGeneralConsumoCountersQA,filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:fbdcea10-67ea-11ec-8f37-5117c9a1429e,key:customerType,negate:!f,params:(query:${queryDocumentType}),type:phrase),query:(match_phrase:(customerType:${queryDocumentType}))),('$state':(store:appState),meta:(alias:!n,disabled:!f,index:fbdcea10-67ea-11ec-8f37-5117c9a1429e,key:customerDocument,negate:!f,params:(query:'${queryDocumentNumber}'),type:phrase),query:(match_phrase:(customerDocument:'${queryDocumentNumber}')))),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:ReporteGeneralConsumoCountersQA,viewMode:view)&hide-filter-bar=true`;
      case 'KibanaConsumptionReport':
        return `https://kibana.gse.com.co/app/dashboards#/view/114be7a0-67ed-11ec-8f37-5117c9a1429e?embed=true&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-90d,to:now))&_a=(description:'Reporte%20de%20consumo%20counters',filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:fbdcea10-67ea-11ec-8f37-5117c9a1429e,key:customerType,negate:!f,params:(query:${queryDocumentType}),type:phrase),query:(match_phrase:(customerType:${queryDocumentType}))),('$state':(store:appState),meta:(alias:!n,disabled:!f,index:fbdcea10-67ea-11ec-8f37-5117c9a1429e,key:customerDocument,negate:!f,params:(query:'${queryDocumentNumber}'),type:phrase),query:(match_phrase:(customerDocument:'${queryDocumentNumber}'))),('$state':(store:appState),meta:(alias:!n,disabled:!f,index:fbdcea10-67ea-11ec-8f37-5117c9a1429e,key:claim,negate:!f,params:!(${queryClaimCreate},${queryClaimDelete}),type:phrases,value:'${queryClaimCreate},%20${queryClaimDelete}'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(claim:${queryClaimCreate})),(match_phrase:(claim:${queryClaimDelete}))))))),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:ReporteConsumoCountersQA,viewMode:view)&hide-filter-bar=true`;
      default:
        break;
    }
  };

  const setTitleReport = (type) => {
    switch (type) {
      case 'GeneralConsumptionReport':
        return 'Reporte general de consumo';
      case 'KibanaConsumptionReport':
        return 'Reporte de consumo';
      default:
        break;
    }
  };

  return (
    <>
      <Dialog
        fullScreen
        fullWidth
        maxWidth="lg"
        open
        className={classes.root}
      >
        <DialogTitle>
          <Grid item xs={12}>
            <Typography className={classes.titleReport}>
              {titleReport || setTitleReport(queryReportType)}
            </Typography>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <iframe src={setIframeReport(queryReportType)} height="100%" width="100%" />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ReportView;
