import axios from 'axios';
import {
  SERVICE_FEATURE_LOADING, GET_SERVICE_FEATURES, SET_PARENTS_FEATURES, CLEAR_SERVICE_FEATURES, RESET_PAGINATION_SERVICE_FEATURES,
} from '../types/serviceFeature.type';
import { API_LICENCIAMIENTO, NUM_REGISTERS_PAGING } from '../config/config';
import { defaultHeaders } from '../helpers/generalUtils';

/**
 * Charge parent feactures with specific endopint for these
 * @returns void 
 */
export const getParentsServiceFeaturesAction = () => async (dispatch) => {
  try {
    const parentFeatures = [];
    const config = defaultHeaders();
    let serviceFeature = [];
    Object.assign(config.headers, { lastRow: null, numRows: NUM_REGISTERS_PAGING });
    // http://localhost:61141/license/api/ServiceFeature/GetOnlyParentServices
    const response = await axios.get(`${API_LICENCIAMIENTO}/license/api/ServiceFeature/GetOnlyParentServices`, config);
    if (response.status === 200) {
      serviceFeature = response.data.result.serviceFeatureDetailsList;
    }
    if (serviceFeature.length > 0) {
      serviceFeature
        .forEach((item) => parentFeatures.push(
          {
            value: item.id,
            label: item.serviceFeatureCode,
            serviceTypeId: item.serviceTypeId,
          },
        ));
      dispatch({ type: SET_PARENTS_FEATURES, payload: parentFeatures });
    }

  } catch (error) {
    console.error(error);
    dispatch({ type: SERVICE_FEATURE_LOADING, payload: false });
  }
};

// eslint-disable-next-line max-len
export const getServiceFeaturesAction = (data, id, reloadAll = false) => async (dispatch, getState) => {
  try {
    dispatch({ type: SERVICE_FEATURE_LOADING, payload: true });
    if (reloadAll) dispatch({ type: CLEAR_SERVICE_FEATURES, payload: undefined });
    const config = defaultHeaders();
    Object.assign(config.headers, data);
    const response = await axios.get(`${API_LICENCIAMIENTO}/license/api/servicefeature/GetAllServiceFeatures`, config);
    if (response.status === 200) {
      const { serviceFeature: { serviceFeaturesBatch } } = getState();
      const serviceFeatureList = response.data.result.serviceFeatureList.records;
      // eslint-disable-next-line max-len
      if (serviceFeaturesBatch) Array.prototype.push.apply(serviceFeaturesBatch, serviceFeatureList);
      if (data.lastRow) {
        // carga con paginacion desde servidor
        // eslint-disable-next-line max-len
        const arrUpdatedAp = Object.assign([], serviceFeaturesBatch === null ? serviceFeatureList : serviceFeaturesBatch);
        // eslint-disable-next-line max-len
        dispatch({ type: GET_SERVICE_FEATURES, payload: arrUpdatedAp, rownums: response.data.result.serviceFeatureList.rows });
      } else {
        // carga masiva todos la data
        // eslint-disable-next-line max-len
        dispatch({ type: GET_SERVICE_FEATURES, payload: serviceFeatureList, rownums: response.data.result.serviceFeatureList.rows });
      }

      dispatch({ type: SERVICE_FEATURE_LOADING, payload: false });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: SERVICE_FEATURE_LOADING, payload: false });
  }
};

export const registerServiceFeatureAction = (data, enqueueSnackbar) => async (dispatch) => {
  try {
    dispatch({ type: SERVICE_FEATURE_LOADING, payload: true });
    const config = defaultHeaders();
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/servicefeature/RegisterServiceFeature`, data, config);
    if (response.status === 200) {
      dispatch({ type: SERVICE_FEATURE_LOADING, payload: false });
      dispatch({ type: RESET_PAGINATION_SERVICE_FEATURES, payload: undefined });
      await dispatch(getServiceFeaturesAction({ lastRow: 0, numRows: NUM_REGISTERS_PAGING }, 0, true));
      enqueueSnackbar('La característica del servicio se ha guardado exitosamente.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
      // If a new caracteristic is added, parent feactures should be changed
      dispatch(getParentsServiceFeaturesAction());
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 422) {
        enqueueSnackbar(error.response.data.statusMessage, {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar('Error de servidor', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    }
    console.error(`Error grabando característica de servicio: ${error.message}`);
    dispatch({ type: SERVICE_FEATURE_LOADING, payload: false });
  }
};

export const changeStateServiceFeatureAction = (data, enqueueSnackbar) => async (dispatch) => {
  try {
    dispatch({ type: SERVICE_FEATURE_LOADING, payload: true });
    const config = defaultHeaders();
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/servicefeature/ChangeStateServiceFeature`, data, config);
    if (response.status === 200) {
      dispatch({ type: SERVICE_FEATURE_LOADING, payload: false });
      await dispatch(getServiceFeaturesAction({ lastRow: 0, numRows: NUM_REGISTERS_PAGING }, data.Id, true));
      // If status change, parent services feactures should also be changed
      await dispatch(getParentsServiceFeaturesAction());
      const message = data.Status
        ? 'Se ha activado la característica del servicio exitosamente.'
        : 'Se ha inactivado la característica del servicio exitosamente.';
      enqueueSnackbar(message, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 422) {
        enqueueSnackbar(error.response.data.statusMessage, {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar('Error de servidor', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    }
    dispatch({ type: SERVICE_FEATURE_LOADING, payload: false });
    console.error(`Error modificando característica de servicio: ${error.message}`);
  }
};
