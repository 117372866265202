export const LOADING = 'PACKETS_LOADING';
export const GET_PAYMENTS = 'PACKETS_GET_PAYMENTS';
export const GET_PACKAGES = 'PACKETS_GET_PACKAGES';
export const GET_CHANNEL = 'GET_CHANNEL';
export const GET_CUSTOMER_COMBO = 'GET_CUSTOMER_COMBO';
export const GET_CUSTOMER_LICENSED_COMBO = 'GET_CUSTOMER_LICENSED_COMBO';
export const GET_CHANNEL_COMBO = 'GET_CHANNEL_COMBO';
export const GET_PACKAGES_TYPE = 'PACKETS_GET_PACKAGES_TYPE';
export const GET_PACKAGES_TYPE_COMBO = 'PACKETS_GET_PACKAGES_TYPE_COMBO';
export const GET_PROMO_CODES = 'PACKETS_GET_PROMO_CODES';
export const GET_PACKAGES_ADDITIONAL_DISCOUNT = 'GET_PACKAGES_ADDITIONAL_DISCOUNT';
export const GET_PACKAGES_ID = 'GET_PACKAGES_ID';
export const UPDATE_PENDING = 'UPDATE_PENDING';
export const UPDATE_APPROVED = 'UPDATE_APPROVED';
export const UPDATE_REJECTED = 'UPDATE_REJECTED';
export const SET_PROMO_CODE_SELECTED = 'SET_PROMO_CODE_SELECTED';
export const PACKET_SHOW_MESSAGE = 'PACKET_SHOW_MESSAGE';
export const PAYMENT_IS_SEARCH = 'PAYMENT_IS_SEARCH';
export const GET_SUPPORT_PAYMENT_FILE = 'GET_SUPPORT_PAYMENT_FILE';
export const GET_RUT_FILE = 'GET_RUT_FILE';
export const UPDATE_PACKAGE_FEATURES = 'PACKETS_UPDATE_PACKAGE_FEATURES';
export const SELECT_PACKAGE_FEATURES = 'PACKETS_SELECT_PACKAGE_FEATURES';
export const SEARCH_PACKAGE_FEATURES = 'PACKETS_SEARCH_PACKAGE_FEATURES';
export const SEARCH_PACKAGE = 'SEARCH_PACKAGE';
export const CUSTOMER_LINKED_PACKAGE = 'PACKETS_CUSTOMER_LINKED_PACKAGE';
export const LOADING_INFO_PACKAGE = 'PACKETS_LOADING_INFO_PACKAGE';
export const PROMOCODE_LINKED_PACKAGE = 'PACKETS_PROMOCODE_LINKED_PACKAGE';
export const CHANNEL_LINKED_PACKAGE = 'PACKETS_CHANNEL_LINKED_PACKAGE';
export const FEATURES_LINKED_PACKAGE = 'PACKETS_FEATURES_LINKED_PACKAGE';
export const PROMOCODE_USER_TYPE = 'PROMOCODE_USER_TYPE';
