/* eslint-disable no-prototype-builtins */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions, react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { trim } from 'lodash';
import { addLocalStorageEncript, getLocalStorageEncript } from '../../../helpers/storageUtils';
import { getPackagesAction, addToCartAction, getPriceByLicenseTypeAction, setShowCartAction } from '../../../actions/home.action';
import { addPricesLicenseAction, addQuantityLicenseAction, getPriceLicenseByQuantityAction } from '../../../actions/localStorage.action';
import {
  GET_PACKAGES_FROM_LOCAL,
} from '../../../types/home.type';
import { getServiceFeatureCodeBool } from '../../../helpers/cartUtils';
import Loading from '../Loading';

const ManagerExt = (props) => {
  const { idParam } = useParams();
  const {
    packages,
    appName,
    getPackages,
    addToCart,
    cart,
    getPriceByLicenseType,
    getPriceLicenseByQuantity,
    pricesLicense,
    quantityLicense,
    addPricesLicense,
    addQuantityLicense,
    setShowCart,
  } = props;

  const EMPLOYEE_TYPE = 'EMPLOYEE';
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingItem, setLoadingItem] = useState(false);
  const [cargadoCartExt, setCargadoCartExt] = useState(false);
  const [cartAddExt, setCartAddExt] = useState([]);

  setShowCart(false);
  /**
   * Obtiene ruta absoluta
   */
  const getAbsolutePath = () => {
    const loc = window.location;
    let pathName = loc.pathname.slice(0, -1);
    pathName = pathName.substring(0, pathName.lastIndexOf('/addCartItem/') + 1);
    return loc.href.substring(0, loc.href.length - ((loc.pathname + loc.search + loc.hash).length - pathName.length));
  };

  /**
   * Guarda en localstorage cart
   */
  const saveCartReducerToLocalStore = () => {
    addLocalStorageEncript(JSON.stringify(cart), 'cart');
  };

  /**
   *
   * @param {Identificador de busqueda} packageCode
   * @returns objeto package
   */
  const getCartPackage = (packageCode) => {
    let packageToAdd = packages?.base?.find(
      (pkg) => pkg.packageCode === trim(packageCode),
    );

    if (packageToAdd === undefined) {
      packageToAdd = packages?.complementary?.find(
        (pkg) => pkg.packageCode === trim(packageCode),
      );
    }

    if (packageToAdd === undefined) {
      packageToAdd = packages?.offers?.find(
        (pkg) => pkg.packageCode === trim(packageCode),
      );
    }

    if (packageToAdd === undefined) {
      packageToAdd = packages?.combo?.find(
        (pkg) => pkg.packageCode === trim(packageCode),
      );
    }
    return packageToAdd;
  };

  const loadCartCrosscutting = () => {
    cartAddExt.map((row) => {
      const packageToAdd = getCartPackage(row.packageCode);
      if (packageToAdd !== undefined) {
        let price = 0;
        const isEmployee = getServiceFeatureCodeBool(packageToAdd, 'employee');
        if (packageToAdd.packageType.packageTypeCode === 'BAS' && isEmployee) {
          price = getPriceLicenseByQuantity(EMPLOYEE_TYPE, row.number);
          packageToAdd.packagePrice = price;
          packageToAdd.packageUnitPrice = price;
          packageToAdd.packagePriceWithDiscount = price;
          packageToAdd.quantity = row.number;
          addToCart(packageToAdd, enqueueSnackbar);
        } else {
          const qxPacks = row.number;
          // eslint-disable-next-line no-plusplus
          for (let i = 1; i <= qxPacks; i++) {
            const showCart = !!(packageToAdd.packageUnitPrice > 0 && packageToAdd.packageType.packageTypeCode === 'BAS');
            addToCart(packageToAdd, enqueueSnackbar, showCart);
          }
        }
      } else {
        console.group('Error obteniendo por packageCode');
        console.info(`No se encontro el package code ${row.packageCode}, revise los codigos referenciados`);
        console.groupEnd();
      }

      // Redirige para compra directa
      window.parent.location.href = `${getAbsolutePath()}RegisterPurchaseExt`;
    });
  };

  useEffect(() => {
    if (packages?.hasOwnProperty('base')) {
      setLoadingItem(false);
    } else {
      setLoadingItem(true);
    }
    if (!cargadoCartExt) {
      // Save Package in Reducer
      const packageLocal = JSON.parse(getLocalStorageEncript('packages') || '[]');
      if (packageLocal.length === 0) {
        getPackages(appName);
      } else {
        // Save Package in Reducer
        dispatch({ type: GET_PACKAGES_FROM_LOCAL, payload: packageLocal });
      }
      setCargadoCartExt(true);
    }

    if (packages?.hasOwnProperty('base') && getLocalStorageEncript('packages') === undefined) {
      addLocalStorageEncript(JSON.stringify(packages), 'packages');
    }
  }, [packages]);

  /**
   * useEfect datos cart
   */
  useEffect(() => {
    if (cart.length > 0) {
      saveCartReducerToLocalStore();
    }
  }, [cart]);

  /**
   * Carga paquetes seleccionados en carrito
   */
  useEffect(() => {
    // eslint-disable-next-line no-prototype-builtins
    if (cartAddExt?.length && packages?.hasOwnProperty('base')) {
      loadCartCrosscutting();
    }
  }, [cartAddExt, packages]);

  /**
   * Carga precios de tipo de licencia
   */
  useEffect(() => {
    getPriceByLicenseType();
  }, [getPriceByLicenseType]);

  /**
   * Almacena en localStorage los datos de precios y listado de cantidades posibles para seleccion
   */
  useEffect(() => {
    pricesLicense && addPricesLicense(pricesLicense);
    quantityLicense && addQuantityLicense(quantityLicense);
  }, [pricesLicense]);

  /**
   * Adiciona paquete seleccionado en parametro de entrada idParam
   */
  useEffect(() => {
    let cartAddBefore = null;
    const stringCartAddedEncrypt = getLocalStorageEncript('cartAdded');
    if (stringCartAddedEncrypt !== undefined) { cartAddBefore = JSON.parse(stringCartAddedEncrypt); }
    const cartNew = {
      packageCode: idParam,
      appName,
      number: 1,
    };

    if (cartAddBefore === null) {
      cartAddBefore = [];
    }
    const indexFound = cartAddBefore.findIndex((item) => item.packageCode === idParam);
    if (indexFound >= 0) {
      cartAddBefore.splice(
        cartAddBefore.findIndex((item) => item.packageCode === idParam),
        1,
      );
    }
    cartAddBefore.push(cartNew);
    addLocalStorageEncript(JSON.stringify(cartAddBefore), 'cartAdded');
    setCartAddExt(cartAddBefore);
  }, [idParam]);

  if (loadingItem) return <Loading />;

  return <></>;
};

const mapStateToProps = ({ home, general }) => ({
  packages: home.packages,
  appName: general.appName,
  cart: home.cart,
  pricesLicense: home.pricesLicense,
  quantityLicense: home.quantityLicense,
});

const mapDispatchToProps = {
  getPackages: getPackagesAction,
  addToCart: addToCartAction,
  getPriceByLicenseType: getPriceByLicenseTypeAction,
  getPriceLicenseByQuantity: getPriceLicenseByQuantityAction,
  addPricesLicense: addPricesLicenseAction,
  addQuantityLicense: addQuantityLicenseAction,
  setShowCart: setShowCartAction,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManagerExt),
);
