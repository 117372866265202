import {
  LOADING,
  SAVE_PURCHASE,
  GET_CUSTOMER_INFORMATION,
  GET_STATUS_PURCHASEORDER,
  VALIDITY_CUSTOMER_EMAIL,
  CLEAR_VALIDITY_CUSTOMER_EMAIL,
  VALIDITY_EMAIL_CODE,
  GET_VALIDATION_CODE_ERROR,
  CLEAR_VALIDATION_CODE_ERROR,
  APPROVED_VALIDITY_EMAIL,
  PURCHASE_APPROVED_EMAIL,
  VALIDITY_CHANGE_CUSTOMER,
  CLEAR_VALIDITY_CHANGE_CUSTOMER,
  SET_REFERENCEUUID_PURCHASE_ORDER,
  PURCHASE_SHOW_MESSAGE,
  GET_ERRORS,
  CLEAR_ERRORS,
  LOADING_CHECK_PAYMENT,
  REGISTER_IDENTITY_VALIDATION,
} from '../types/purchaseLicense.type';

const INITIAL_STATE = {
  loading: false,
  loadingCheckPayment: false,
  getError: undefined,
  statusOrder: undefined,
  validityEmail: undefined,
  validityCode: undefined,
  getValidationCodeError: undefined,
  approvedValidityEmail: undefined,
  confirmEmail: undefined,
  validityChangeCustomer: undefined,
  referenceUuidPurchaseOrder: undefined,
  code: undefined,
  showMessageAlert: undefined,
  customerBillingInfo: {},
  customerServiceInfo: {},
  typeLoad: undefined,
  isValidationIdentitySuccess: undefined,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SAVE_PURCHASE:
      return {
        ...state,
        savePurchaseResponse: action.payload,
      };
    case GET_CUSTOMER_INFORMATION:
      return {
        ...state,
        customerBillingInfo: action.payload.customerBilling,
        customerServiceInfo: action.payload.customerService,
        typeLoad: action.typeLoad,
      };
    case GET_STATUS_PURCHASEORDER:
      return {
        ...state,
        statusOrder: action.payload,
      };
    case VALIDITY_CUSTOMER_EMAIL:
      return {
        ...state,
        validityEmail: action.payload,
      };
    case CLEAR_VALIDITY_CUSTOMER_EMAIL:
      return {
        ...state,
        validityEmail: undefined,
      };
    case VALIDITY_EMAIL_CODE:
      return {
        ...state,
        validityCode: action.payload,
      };
    case GET_VALIDATION_CODE_ERROR:
      return {
        ...state,
        getValidationCodeError: action.payload,
      };
    case CLEAR_VALIDATION_CODE_ERROR:
      return {
        ...state,
        getValidationCodeError: undefined,
      };
    case APPROVED_VALIDITY_EMAIL:
      return {
        ...state,
        approvedValidityEmail: action.payload,
      };
    case PURCHASE_APPROVED_EMAIL:
      return {
        ...state,
        confirmEmail: action.payload,
      };
    case VALIDITY_CHANGE_CUSTOMER:
      return {
        ...state,
        validityChangeCustomer: action.payload,
      };
    case CLEAR_VALIDITY_CHANGE_CUSTOMER:
      return {
        ...state,
        validityChangeCustomer: undefined,
      };
    case SET_REFERENCEUUID_PURCHASE_ORDER:
      return {
        ...state,
        referenceUuidPurchaseOrder: action.payload,
        code: action.code,
      };
    case PURCHASE_SHOW_MESSAGE:
      return {
        ...state,
        showMessageAlert: action.payload,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case LOADING_CHECK_PAYMENT:
      return {
        ...state,
        loadingCheckPayment: action.payload,
      };
    case GET_ERRORS:
      return {
        ...state,
        getError: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        getError: undefined,
      };
    case REGISTER_IDENTITY_VALIDATION:
      return {
        ...state,
        isValidationIdentitySuccess: action.payload,
      };
    default:
      return state;
  }
};
