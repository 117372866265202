/* eslint-disable react-hooks/exhaustive-deps  */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomInput from '../../../../components/form/CustomInput';
import CustomSelect from '../../../../components/form/CustomSelect';
import SwitchiOS from '../../../../components/form/SwitchiOS';
import { Rules } from '../../../../helpers/RHFRules';
import { isValidTokenAdmin } from '../../../../helpers/authUtils';
import { registerServiceAction } from '../../../../actions/service.action';
import { getUserDataByIdAction } from '../../../../actions/security.action';
import { getTokenExternalSourceAction } from '../../../../actions/general.action';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    borderRadius: 20,
  },
  titleAlert: {
    color: '#003582',
    fontWeight: 700,
    fontSize: 25,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  dialogTitleHead: {
    paddingTop: 20,
    paddingBottom: 5,
    marginTop: 3
  },
  dialogContent: {
    marginBottom: 20,
  },
  serviceForm: {
    flexDirection: 'row',
    padding: 1,
    marginBottom: 1,
  },
  divider: {
    marginBottom: 20,
  },
  heading: {
    fontSize: theme.typography.pxToRem(13),
    height: 12,
    flexBasis: '33.33%',
    flexShrink: 0,
    color: theme.palette.text.secondary,
  },
  paper: {
    backgroundColor: '#0000000D',
  },
  button: {
    marginTop: 10,
    color: '#fff',
    minWidth: 130,
    borderRadius: 10,
  },
  tamIcon: {
    transform: 'scale(0.85)',
  },
}));

const ModalService = (props) => {
  const classes = useStyles();
  const {
    isOpen, onClose, serviceTypes, item, isNew,
    registerService, loading, getUserDataById, dataUserCreate, dataUserModify,
    getTokenExternalSource,
  } = props;

  const defaultValues = {
    serviceTypeId: !isNew && item ? item.serviceTypeId : null,
    serviceName: !isNew && item ? item.serviceName : '',
    description: !isNew && item ? item.descriptionService : '',
    Status: isNew ? false : !(item && item.idstate === 1),
  };

  const { enqueueSnackbar } = useSnackbar();
  const [openViewMore, setOpenViewMore] = React.useState(false);
  const [stateActive, setStateActive] = React.useState(defaultValues.Status);
  const [userCreate, setUserCreate] = React.useState(null);
  const [userModify, setUserModify] = React.useState(null);

  const {
    handleSubmit, control, errors,
  } = useForm({ defaultValues });

  const handleSaveService = async (data) => {
    const dataSave = {
      ...data,
      Status: stateActive,
      Id: isNew ? 0 : item.id,
    };
    if (dataSave.serviceTypeId !== defaultValues.serviceTypeId
      || dataSave.serviceName !== defaultValues.serviceName
      || dataSave.description !== defaultValues.description
      || dataSave.Status !== defaultValues.Status) {
      await registerService(dataSave, enqueueSnackbar);
    }
    onClose();
  };

  useEffect(() => {
    if (item !== undefined) {
      const tokenAdmin = localStorage.getItem('tokenAdminIbuho');
      if (tokenAdmin && isValidTokenAdmin(tokenAdmin)) {
        getUserDataById(item.createdby, 'CREATE');
        getUserDataById(item.modifiedby, 'MODIFY');
      } else {
        getTokenExternalSource();
      }
    }
  }, []);

  useEffect(() => {
    if (dataUserCreate) {
      setUserCreate(dataUserCreate);
    } else {
      setUserCreate(null);
    }
  }, [dataUserCreate]);

  useEffect(() => {
    if (dataUserModify) {
      setUserModify(dataUserModify);
    } else {
      setUserModify(null);
    }
  }, [dataUserModify]);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isOpen}
        className={classes.root}
      >
        <DialogTitle className={classes.dialogTitleHead}>
          <Typography align="left" color="primary" variant="h6">
            {isNew ? 'Crear producto o servicio' : 'Editar producto o servicio'}
          </Typography>
          <IconButton aria-label="close" color="primary" className={classes.closeButton} onClick={onClose}>
            <CloseIcon className={classes.tamIcon} />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <form
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(handleSaveService)}
            className={classes.serviceForm}
          >
            <FormControl fullWidth>
              <CustomSelect
                label="Tipo de servicio*"
                name="serviceTypeId"
                control={control}
                options={serviceTypes}
                error={errors}
                className={{ marginBottom: 10, boxShadow: '2px 2px 4px #00000033' }}
                rule={Rules.required}
              />
              <CustomInput
                label="Nombre del servicio*"
                name="serviceName"
                control={control}
                error={errors}
                className={{ marginBottom: 10, boxShadow: '2px 2px 4px #00000033' }}
                rule={Rules.required}
                inputProps={{
                  maxLength: 100,
                }}
              />
              <CustomInput
                label="Descripción"
                name="description"
                control={control}
                error={errors}
                multiline
                rows={4}
                className={{ marginBottom: 10, boxShadow: '2px 2px 4px #00000033' }}
                inputProps={{
                  maxLength: 200,
                }}
              />
              <Grid component="label" container alignItems="center" spacing={2}>
                <Grid item>Activar</Grid>
                <Grid item>
                  <SwitchiOS
                    name="Status"
                    control={control}
                    // checked={defaultValues.Status}
                    defaultChecked={defaultValues.Status}
                    className={{ marginBottom: 10 }}
                    onChange={(e) => { setStateActive(e.target.checked); }}
                  />
                  {/* <CustomSwitch
                    label=""
                    name="Status"
                    control={control}
                    className={{ marginBottom: 10 }}
                    defaultValue={defaultValues.Status}
                    setStateActive={setStateActive}
                  /> */}
                </Grid>
              </Grid>
            </FormControl>
            {!isNew && !openViewMore
              && (
                <Button
                  edge="false"
                  size="small"
                  style={{ float: 'right' }}
                  color="primary"
                  onClick={() => { setOpenViewMore(true); }}
                >
                  Ver más
                  <ExpandMoreIcon color="primary" />
                </Button>
              )}
            {!isNew && (
              <>
                <p />
                <Collapse in={openViewMore}>
                  <Paper elevation={1} className={classes.paper}>
                    <Typography variant="caption">
                      <b>Creado por:</b>
                      {' '}
                      {userCreate ? (userCreate.names) : ''}
                      <br />
                      <b>Fecha y hora de creación:</b>
                      {' '}
                      {format(new Date(item.createdon), 'dd/MM/yyyy hh:mm:ss aaaa ')}
                      {' '}
                      <br />
                      <b>Modificado por:</b>
                      {' '}
                      {userModify ? (userModify.names) : ''}
                      {' '}
                      <br />
                      <b>Fecha y hora de modificación:</b>
                      {' '}
                      {!item.modifiedon.includes('0001')
                        ? format(new Date(item.modifiedon), 'dd/MM/yyyy hh:mm:ss aaaa ')
                        : ''}
                    </Typography>
                  </Paper>
                </Collapse>
              </>
            )}
            {openViewMore
              && (
                <Button
                  edge="false"
                  size="small"
                  style={{ float: 'right' }}
                  color="primary"
                  onClick={() => { setOpenViewMore(false); }}
                >
                  Ver menos
                  <ExpandLessIcon color="primary" />
                </Button>
              )}
            <Grid container justify="center">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ boxShadow: '0px 4px 3px #0000004D !important' }}
                className={classes.button}
              >
                {loading && <CircularProgress color="secondary" size={25} />}
                {isNew ? 'Crear' : 'Aceptar'}
              </Button>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ general, service, security }) => ({
  serviceTypes: general.serviceTypes,
  loading: service.loading,
  dataUserCreate: security.dataUserCreate,
  dataUserModify: security.dataUserModify,
});

const mapDispatchToProps = {
  registerService: registerServiceAction,
  getUserDataById: getUserDataByIdAction,
  getTokenExternalSource: getTokenExternalSourceAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalService);
