import {
  FEATURE_TYPE_LOADING, GET_FEATURE_TYPES_LIST,
} from '../types/featureType.type';

const INITIAL_STATE = {
  loading: false,
  featureTypesList: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_FEATURE_TYPES_LIST:
      return {
        ...state,
        featureTypesList: action.payload,
      };
    case FEATURE_TYPE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
