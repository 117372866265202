/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getPurchaseOrderParametersAction } from '../../actions/general.action';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 280,
    borderRadius: 16,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  paper: {
    padding: '5px',
    marginTop: '20px',
    opacity: '0.95',
    ...theme.miplan.bgColorCards,
    borderRadius: '20px',
  },
  appPaper: {
    padding: '5px',
    marginTop: '20px',
    opacity: '0.95',
    ...theme.miplan.appBgColorCards,
    borderRadius: '20px',
  },
  boxTitles: {
    marginLeft: 25,
  },
  title: {
    ...theme.miplan.title,
    fontWeight: 700,
  },
  caption: {
    color: '#818181',
    fontSize: '0.8125rem',
  },
  btnDirectPlan: {
    ...theme.miplan.btnDirectPlan,
    width: '150px',
    '& .MuiButton-label': {
      fontWeight: 700,
      fontSize: '16px',
    },
  },
  circularColor: {
    ...theme.miplan.circularColor,
  },
}));

const ExpiredPlan = (props) => {
  const { appName, getPurchaseOrderParameters, applicationInfo } = props;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPurchaseOrderParameters();
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  const getIconImg = (serviceName) => {
    try {
      switch (serviceName.toLowerCase()) {
        case serviceName.toLowerCase():
          return require(`../../assets/img/iconos/${serviceName.toLowerCase()}/ExpiredPlan.svg`);
        default:
          return require('../../assets/img/iconos/colfactura/ExpiredPlan.svg');
      }
    } catch (error) {
      return require('../../assets/img/iconos/colfactura/ExpiredPlan.svg');
    }
  };

  const redirectExternalUrl = () => {
    if (applicationInfo?.urlLandingPage) {
      window.location.href = applicationInfo.urlLandingPage;
    }
    setLoading(true);
  };

  const classes = useStyles();
  return (
    <>
      <Container component="main" maxWidth="lg">
        <Paper elevation={3} className={appName === 'license' ? classes.paper : classes.appPaper}>
          <Box className={classes.boxTitles}>
            <br />
            {
              appName !== 'license' && (
                <>
                  <Typography className={classes.title} variant="h4">
                    Mi plan
                  </Typography>
                  <br />
                </>
              )
            }
            {
              appName.toLowerCase() === 'firmaya' ? (
                <>
                  <br />
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={getIconImg(appName)} alt="ExpiredPlan" />
                  </div>
                  <br />
                  <br />
                  <Typography
                    variant="h4"
                    style={{
                      display: 'flex', justifyContent: 'center', fontWeight: 700, color: '#4F4F4F', fontSize: '22px',
                    }}
                  >
                    ¡Tu plan base ha vencido!
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="h4" style={{ display: 'flex', justifyContent: 'center', fontWeight: 700 }}>
                    ¡Tu plan base ha vencido!
                  </Typography>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={getIconImg(appName)} alt="ExpiredPlan" />
                  </div>
                </>
              )
            }
            <br />
            <br />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                className={classes.btnDirectPlan}
                onClick={redirectExternalUrl}
              >
                {loading ? <CircularProgress className={classes.circularColor} size={25} /> : 'Adquirir plan'}
              </Button>
            </div>
            <br />
            <br />
          </Box>
        </Paper>
      </Container>
    </>
  );
};

const mapStateToProps = (props) => ({
  applicationInfo: props.general.applicationInfo,
});

const mapDispatchToProps = {
  getPurchaseOrderParameters: getPurchaseOrderParametersAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpiredPlan);