/* eslint-disable max-len */
/* eslint-disable array-callback-return, react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Grid } from '@material-ui/core/';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleOutlineTwoToneIcon from '@material-ui/icons/AddCircleOutlineTwoTone';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CustomTableCollapse from '../../../components/form/Table/CustomTableCollapse';
import BagLicenseIcon from '../../../assets/img/iconos/license/BagsLicenseIcon.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 10,
    paddingBottom: 10,
    margin: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.5)',
    ...theme.miplan.rootCard,
    backgroundColor: '#0093D2',
  },
  paper: {
    padding: '5px',
    marginTop: '20px',
    opacity: '0.95',
  },
  cardHeader: {
    ...theme.miplan.cardHeader,
    fontSize: 16,
    padding: 15,
    display: 'initial',
    backgroundColor: '#0093D2',
    color: '#fff',
  },
  cardContent: {
    padding: '0px',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  licenseDetail: {
    width: '100%',
    backgroundColor: '#F5F5F5',
    '& .MuiListItem-root': {
      paddingTop: 8,
      paddingBottom: 0,
    },
  },
  inline: {
    display: 'inline',
  },
  menuActions: {
    backgroundColor: '#4DB4E0',
  },
  iconCard: {
    textAlign: 'center',
    borderRadius: 5,
    width: 30,
    height: 30,
    boxShadow: '0px 4px 7px #0000001F',
    ...theme.miplan.iconCard,
  },
  featureName: {
    ...theme.miplan.featureName,
  },
  featureValue: {
    ...theme.miplan.featureValue,
  },
  buttonCard: {
    ...theme.miplan.buttonCard,
    '&:hover': {
      ...theme.miplan.hoverButtonCard,
    },
  },
  iconActions: {
    width: 20,
    height: 20,
    boxShadow: '0px 4px 7px #0000001F',
    borderRadius: 5,
  },
  iconBox: {
    width: 30,
    height: 30,
    boxShadow: '0px 4px 7px #0000001F',
    borderRadius: 5,
  },
  iconNewLicense: {
    fontSize: 18,
    color: '#6AB31B',
    paddingRight: 2,
  },
  listItem: {
    '& .MuiListItem-root': {
      paddingTop: '8px',
      paddingBottom: 0,
    },
  },
  titleRowTable: {
    color: '#4288C3',
    fontWeight: 700,
    textAlign: 'left',
    fontSize: 14,
  },
  bodyRowTable: {
    textAlign: 'left',
  },
  titleServiceRowTable: {
    color: '#009BDD',
    fontWeight: 700,
    textAlign: 'left',
    fontSize: 14,
  },
  btnAddNewLicense: {
    border: '1px solid #E55200',
    backgroundColor: '#fff',
    color: '#E55200',
    marginBottom: '15px',
  },
  iconLicenseAction: {
    paddingLeft: '10px',
  },
  iconLicense: {
    color: '#009BDD',
    // '&:hover': {
    //   color: '#fff',
    //   fill: '#fff',
    // },
    // '&:hover': {
    //   svgcolor: '#fff',
    // },
    // '&.iconLicense path': {
    //   fill: '#fff',
    //   color: '#fff',
    // },
  },
  iconButtonLicense: {
    backgroundColor: '#fff',
    // '&:hover': {
    //   backgroundColor: '#2073B9',
    // },
  },
}));

const LicensesTableList = (props) => {
  const classes = useStyles();
  const {
    customerServicelicense, handleOpenModalViewLicense, handleOpenModalAddLicense, countRecordsList,
    handleChangePage, setPage, page, handleAddNewLicense,
  } = props;

  const [customerlicenseRowsTable, setCustomerlicenseRowsTable] = React.useState([]);

  const columns = [
    {
      id: 'customer',
      label: '',
      paddingLeft: '40px',
      isSortable: false,
      sortPropertyName: 'customer',
      position: 1,
    },
    {
      id: 'itemCollapse',
      label: '',
      paddingLeft: '40px',
      isSortable: false,
      sortPropertyName: 'itemCollapse',
      position: 2,
      className: '',
      alignRow: 'right',
      showIconCollapse: true,
    },
  ];

  const columnsCollapse = [
    {
      id: 'service',
      label: '',
      align: 'left',
      position: 1,
    },
    {
      id: 'itemBag',
      label: '',
      align: 'center',
      position: 2,
    },
  ];

  function createInfoCustomerElement(customer) {
    return (
      <Grid item xs container spacing={2}>
        <Grid item xs={12}>
          <Grid item xs>
            <div style={{ display: 'flex' }}>
              <div>
                {
                  customer.isNewCustomer ? (
                    <Tooltip title="Nuevo cliente creado" aria-label="add" placement="top">
                      <FiberManualRecordIcon className={classes.iconNewLicense} />
                    </Tooltip>
                  ) : (
                    <Typography style={{ paddingRight: '18px' }} />
                  )
                }
              </div>
              <div>
                <Typography gutterBottom variant="subtitle1" className={classes.titleRowTable}>
                  {customer.customerName}
                </Typography>
                <Typography variant="body2" className={classes.bodyRowTable}>
                  {`${customer.documentType}: ${customer.documentNumber}`}
                </Typography>
                <Typography variant="body2" className={classes.bodyRowTable}>
                  {`${customer.legalRepresentative}`}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function createInfoServicesPlansElement(itemService) {
    return (
      <Grid item xs container spacing={0}>
        <Grid item xs={12}>
          <Grid item xs>
            <div style={{ display: 'flex' }}>
              <div>
                {
                  itemService.isNewLicense ? (
                    <Tooltip title="Nueva licencia añadida" aria-label="add" placement="top">
                      <FiberManualRecordIcon className={classes.iconNewLicense} />
                    </Tooltip>
                  ) : (
                    <Typography style={{ paddingRight: '18px' }} />
                  )
                }
              </div>
              <div>
                <Typography gutterBottom variant="subtitle1" className={classes.titleServiceRowTable}>
                  {itemService.serviceName}
                </Typography>
                <Typography variant="body2" className={classes.bodyRowTable}>
                  {`Vigencia: ${format(new Date(itemService.effectiveDate), 'dd-MM-yyyy')} / ${format(new Date(itemService.expirationDate), 'dd-MM-yyyy')}`}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function createIconLicenseActionsElement(id, applyTransactionRanges) {
    return (
      <>
        <Box display="flex" flexDirection="row" justifyContent="right" alignItems="center">
          {
            applyTransactionRanges && (
              <Box className={classes.iconLicenseAction}>
                <IconButton
                  edge="end"
                  aria-label="Editar"
                  size="small"
                  onClick={() => handleOpenModalAddLicense(id)}
                  className={classes.iconButtonLicense}
                >
                  <Tooltip title="Editar licencia" aria-label="add" placement="top">
                    <EditIcon fontSize="small" className={classes.iconLicense} />
                  </Tooltip>
                </IconButton>
              </Box>
            )
          }
          <Box className={classes.iconLicenseAction}>
            <IconButton
              edge="end"
              aria-label="Editar"
              size="small"
              onClick={() => handleOpenModalViewLicense(id)}
              className={classes.iconButtonLicense}
            >
              <Tooltip title="Ver bolsas" aria-label="add" placement="top">
                <img src={BagLicenseIcon} alt="Ver bolsas" fill="#c2c2c" className={classes.iconLicense} />
              </Tooltip>
            </IconButton>
          </Box>
        </Box>
      </>
    );
  }

  function createNewLicenseCustomerElement(id) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="right" alignItems="center">
        <Button
          type="submit"
          variant="contained"
          className={classes.btnAddNewLicense}
          endIcon={<AddCircleOutlineTwoToneIcon />}
          size="small"
          onClick={() => {
            handleAddNewLicense(id);
          }}
        >
          Añadir licencia
        </Button>
      </Box>
    );
  }

  function mapCustomerServiceLicense(data) {
    const customerInfoRows = [];
    data.forEach((item) => {
      const serviceLicenseRows = [];
      item.validLicences.forEach((itemService) => {
        const serviceCollapseRow = {
          id: itemService.purchaseOrderLicenseId,
          service: createInfoServicesPlansElement(itemService),
          itemBag: createIconLicenseActionsElement(itemService.purchaseOrderLicenseId, itemService.applyTransactionRanges),
        };
        serviceLicenseRows.push(serviceCollapseRow);
      });
      const addNewLicenseServiceCollapseRow = {
        id: item.customerId,
        service: '',
        itemBag: createNewLicenseCustomerElement(item.customerId),
      };
      serviceLicenseRows.push(addNewLicenseServiceCollapseRow);
      const licenseRow = {
        id: item.customerId,
        customer: createInfoCustomerElement(item),
        rowsTableCollapse: serviceLicenseRows,
      };
      customerInfoRows.push(licenseRow);
    });
    setCustomerlicenseRowsTable(customerInfoRows);
  }

  useEffect(() => {
    if (customerServicelicense.length > 0) {
      mapCustomerServiceLicense(customerServicelicense);
    }
  }, [customerServicelicense]);

  return (
    <>
      {
        customerServicelicense.length > 0 && (
        <CustomTableCollapse
          data={customerlicenseRowsTable}
          columns={columns}
          columnsCollapse={columnsCollapse}
          showPagination
          hideHeader
          rowsPerPageOptions={[10]}
          countRecordsList={countRecordsList}
          handleChangePage={handleChangePage}
          setPage={setPage}
          page={page}
        />
        )
      }
    </>
  );
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(LicensesTableList);
