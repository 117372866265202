/* eslint-disable max-len */
import { addLocalStorageEncript, getLocalStorageEncript } from '../helpers/storageUtils';

export const addToCartLocal = (cartNew) => () => {
  const cart = JSON.parse(getLocalStorageEncript('cart') || '[]');
  const cartAux = cart;
  cartAux.push(cartNew);
};

export const deleteOfCartLocal = (packageCode, allItems) => (
) => {
  const cart = JSON.parse(getLocalStorageEncript('cart') || '[]');
  // eslint-disable-next-line no-unused-vars
  let cartUpdated;
  if (allItems) { cartUpdated = cart.filter((item) => item.packageCode !== packageCode); } else {
    cart.splice(
      cart.findIndex((item) => item.packageCode === packageCode),
      1,
    );
    cartUpdated = Object.assign([], cart);
  }
};

export const addPricesLicenseAction = (data) => (
) => {
  addLocalStorageEncript(JSON.stringify(data), 'pricesLicense');
};

export const getPriceLicenseByQuantityAction = (type, quantity) => (
) => {
  try {
    const pricesLicenseLocal = JSON.parse(getLocalStorageEncript('pricesLicense'));
    const pricesLicense = pricesLicenseLocal.filter((item) => item.type === type && item.quantity === Number(quantity));
    return pricesLicense.length > 0 ? pricesLicense[0] : null;
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export const addQuantityLicenseAction = (data) => (
) => {
  addLocalStorageEncript(JSON.stringify(data), 'quantityLicense');
};

export const getQuantityByTypeAction = (type) => (
) => {
  const quantity = JSON.parse(getLocalStorageEncript('quantityLicense') || '[]');
  const quantityType = quantity.filter((item) => item.type === type);
  return quantityType.sort((firstItem, secondItem) => firstItem.value - secondItem.label);
};
