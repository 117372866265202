/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
import {
  roundValuePrecision, roundDecimalValue,
} from './generalUtils';

import { DISCOUNT_TYPE } from '../constants/PurchaseConstants';

const getQuantity = (appName, cartItem) => {
  if (appName === 'colnomina' && cartItem.packageType.packageTypeCode === 'BAS') {
    return cartItem.quantity;
  }

  return 0;
};

export const getServiceFeatureCodeBool = (cartItem, type) => {
  try {
    if (cartItem.packageType.packageTypeCode === 'BAS') {
      if (type === 'employee') {
        const isEmployees = cartItem.itemsPackageFeatures.filter((item) => item.serviceFeature.serviceFeatureCode.includes('COLNOMINA_EMPLEADO')).length > 0;
        return isEmployees;
      }
      if (type === 'document') {
        const isDocuments = cartItem.itemsPackageFeatures.filter((item) => item.serviceFeature.serviceFeatureCode.includes('COLNOMINA_DOCUMENTO')).length > 0;
        return isDocuments;
      }
      if (type === 'person') {
        const isEmployees = cartItem.itemsPackageFeatures.filter((item) => item.serviceFeature.serviceFeatureCode.includes('BEMYSELF_FIRMA_DIGITAL')).length > 0;
        return isEmployees;
      }
      if (type === 'company') {
        const isEmployees = cartItem.itemsPackageFeatures.filter((item) => item.serviceFeature.serviceFeatureCode.includes('BEMYSELF_CERTIFICADODIGITALPE')).length > 0;
        return isEmployees;
      }
    }

    return false;
  } catch (error) {
    return false;
  }
};

export const CleanCart = (cart, promoCode, appName) => {
  const cartClean = {};
  cart.forEach((element) => {
    const quantityTemp = getQuantity(appName, element);
    const iva = element.taxIva > 0 ? element.taxIva / 100 : 0;
    if (cartClean.hasOwnProperty(element.packageId)) {
      const quantity = cartClean[element.packageId].number + 1;
      cartClean[element.packageId] = {
        ...cartClean[element.packageId],
        number: quantityTemp > 0 ? quantityTemp : quantity,
        taxiva: element.taxIva > 0
          ? (roundDecimalValue(((element.packageUnitPrice * quantity) * iva), 2))
          : 0,
        isEmployee: getServiceFeatureCodeBool(element, 'employee'),
      };
    } else {
      const taxIva = element.taxIva > 0 ? ((element.packageUnitPrice - element.discount) * element.taxIva) / 100 : 0;
      cartClean[element.packageId] = {
        id: element.packageId,
        name: element.packageName,
        number: quantityTemp > 0 ? quantityTemp : 1,
        base: element.packageUnitPrice,
        price: element.packagePriceWithDiscount,
        newBase: element.packageUnitPrice,
        discount: element.discount || 0,
        ivaValue: element.taxIva,
        taxiva: taxIva,
        total: quantityTemp > 0 ? element.packageUnitPrice : ((element.packageUnitPrice) + taxIva),
        isEmployee: getServiceFeatureCodeBool(element, 'employee'),
        quantityEmployees: element.quantity,
      };
    }
  });
  if (promoCode) {
    Object.values(cartClean).forEach((element) => {
      const item = promoCode.packagesInCart.find((p) => p.packageId === element.id);
      if (item) {
        element.discount = item ? item.discount : 0;
        const quantity = cartClean[element.id].number;
        // Precio de paquete sin descuento por cantidad en carrito
        const newBaseWithDiscount = roundDecimalValue((element.base * quantity) - element.discount, 2);
        const taxIvaNewBase = roundDecimalValue(((newBaseWithDiscount * element.ivaValue) / 100), 2);
        element.newBase = roundDecimalValue(((element.base * quantity) - element.discount), 2);
        element.taxiva = (item !== undefined && item.taxIva > 0)
          ? taxIvaNewBase
          : 0;
      }
    });
  }

  return Object.values(cartClean);
};

export const CalcPriceWithDiscountPackage = (originalPrice, discountValue, discountType) => {
  if (discountValue === 0) return originalPrice;
  let discount = 0;

  if (discountType === DISCOUNT_TYPE.Percentage) {
    discount = ((originalPrice / 100) * discountValue);
    return originalPrice - discount;
  } if (discountType === DISCOUNT_TYPE.Value) {
    discount = (originalPrice - discountValue);
    return originalPrice - discount;
  }
};

export const CalcDiscountPackage = (originalPrice, discountValue, discountType) => {
  if (discountValue === 0) return originalPrice;
  let discount = 0;

  if (discountType === DISCOUNT_TYPE.Percentage) {
    discount = roundValuePrecision(((originalPrice / 100) * discountValue), 2);
    return discount;
  } if (discountType === DISCOUNT_TYPE.Value) {
    return discountValue;
  }
};

export const formatter = new Intl.NumberFormat('es-CO', {
  style: 'currency',
  currency: 'COP',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});
