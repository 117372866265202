/* eslint-disable max-len */
/* eslint-disable no-prototype-builtins, react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import Popover from '@material-ui/core/Popover';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { trim } from 'lodash';

import { ReactComponent as AddShoppingCartIcon } from '../../../assets/img/iconos/license/AddToShoppingCartIcon.svg';
import {
  GET_PACKAGES_FROM_LOCAL,
} from '../../../types/home.type';

import { deleteOfCartAction, addToCartAction, getPackagesAction } from '../../../actions/home.action';
import { CleanCart, formatter, getServiceFeatureCodeBool } from '../../../helpers/cartUtils';
import { getLocalStorageEncript, addLocalStorageEncript } from '../../../helpers/storageUtils';
import {
  getPriceLicenseByQuantityAction,
} from '../../../actions/localStorage.action';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  card: {
    ...theme.home.cart.summaryCart,
    width: 400,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    justifyContent: 'space-between',
    minHeight: '210px',
  },
  noItems: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 24,
  },
  cardContent: {
    padding: '15px 15px 0',
  },
  cardHeader: {
    backgroundRepeat: 'no-repeat',
    height: '140px',
    padding: '11px 20px 0',
    color: 'white',
  },
  cardList: {
    padding: 15,
    fontSize: '16px',
    letterSpacing: '0px',
    lineheight: 1,
  },
  addToCartButton: {
    width: 37,
    minWidth: 0,
  },
  subTotalText: {
    ...theme.home.cart.subtotalText,
  },
  btnGoToCart: {
    ...theme.home.cart.btnGoToCart,
    '& .MuiButton-label': {
      fontWeight: 900,
      fontSize: '16px',
    },
  },
  btnRemoveFromCart: {
    ...theme.home.cart.btnRemoveFromCart,
    marginRight: '15px',
  },
  iconShoppingCart: {
    ...theme.home.cart.iconShoppingCart,
  },
}));

const getSubtotal = (cart) => ` ${formatter.format(
  cart.reduce(
    (previousValue, currentValue) => (
      ((currentValue.price * (currentValue.isEmployee ? 1 : currentValue.number)) - currentValue.discount) + previousValue
    ), 0,
  ),
)}`;

const CartMenuExt = (props) => {
  const {
    element, cart, addToCart, promoCode, deleteOfCart,
    appName, packages, getPackages, getPriceLicenseByQuantity,
  } = props;

  const EMPLOYEE_TYPE = 'EMPLOYEE';
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [isShownId, setIsShownId] = useState(0);
  const classes = useStyles();
  const [cartAddExt, setCartAddExt] = useState([]);
  const [cargadoCartExt, setCargadoCartExt] = useState(false);
  const localCart = getLocalStorageEncript('cart') ? JSON.parse(getLocalStorageEncript('cart')) : null;
  let cartClean = CleanCart(localCart?.length > 0 ? localCart : cart, promoCode, appName);

  useEffect(() => {
    cartClean = CleanCart(cart, promoCode, appName);
  }, [cart]);

  useEffect(() => {
    if (!cargadoCartExt) {
      let cartAddedStorage = null;
      const stringCartAddedEncrypt = getLocalStorageEncript('cartAdded');
      if (stringCartAddedEncrypt !== undefined) { cartAddedStorage = JSON.parse(stringCartAddedEncrypt); }
      setCartAddExt(cartAddedStorage);
      setCargadoCartExt(true);
      // Save Package in Reducer
      const packageLocal = JSON.parse(getLocalStorageEncript('packages') || '[]');
      if (packageLocal.length === 0) {
        getPackages(appName);
      } else {
        // Save Package in Reducer
        dispatch({ type: GET_PACKAGES_FROM_LOCAL, payload: packageLocal });
      }

      if (packages?.hasOwnProperty('base') && getLocalStorageEncript('packages') === undefined) {
        addLocalStorageEncript(JSON.stringify(packages), 'packages');
      }
    }
  }, [packages]);

  /**
   *
   * @param {Identificador de busqueda} packageCode
   * @returns objeto package
   */
  const getCartPackage = (packageCode) => {
    let packageToAdd = packages?.base?.find(
      (pkg) => pkg.packageCode === trim(packageCode),
    );

    if (packageToAdd === undefined) {
      packageToAdd = packages?.complementary?.find(
        (pkg) => pkg.packageCode === trim(packageCode),
      );
    }

    if (packageToAdd === undefined) {
      packageToAdd = packages?.offers?.find(
        (pkg) => pkg.packageCode === trim(packageCode),
      );
    }

    if (packageToAdd === undefined) {
      packageToAdd = packages?.combo?.find(
        (pkg) => pkg.packageCode === trim(packageCode),
      );
    }
    return packageToAdd;
  };

  const cargarCartCrosscutting = () => {
    cartAddExt.forEach((row) => {
      const packageToAdd = getCartPackage(row.packageCode);
      if (packageToAdd !== undefined) {
        let price = 0;
        const isEmployee = getServiceFeatureCodeBool(packageToAdd, 'employee');
        if (packageToAdd.packageType.packageTypeCode === 'BAS' && isEmployee) {
          price = getPriceLicenseByQuantity(EMPLOYEE_TYPE, row.number);
          packageToAdd.packagePrice = price;
          packageToAdd.packageUnitPrice = price;
          packageToAdd.packagePriceWithDiscount = price;
          packageToAdd.quantity = row.number;
        }
        addToCart(packageToAdd, enqueueSnackbar);
      } else {
        console.group('Error obteniendo por packageCode');
        console.info(`No se encontro el package code ${row.packageCode}, revise los codigos referenciados`);
        console.groupEnd();
      }
    });
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    cartAddExt?.length && cargarCartCrosscutting();
  }, [cartAddExt, packages]);

  const handleDelete = (item) => {
    deleteOfCart(item.id, true);
    // Eliminar del localStorage
    const stringCartEncrypt = getLocalStorageEncript('cart');
    if (stringCartEncrypt !== undefined) {
      const cartBefore = JSON.parse(stringCartEncrypt);
      const cartAux = cartBefore.find((x) => x.packageId === item.id);

      if (cartAux) {
        const stringCartAddedEncrypt = getLocalStorageEncript('cartAdded');
        if (stringCartAddedEncrypt !== undefined) {
          const cartAddBefore = JSON.parse(stringCartAddedEncrypt);
          const cartAddUpdateAux = cartAddBefore.filter((x) => x.packageCode !== cartAux.packageCode);
          addLocalStorageEncript(JSON.stringify(cartAddUpdateAux), 'cartAdded');
        }

        const cartUpdateAux = cartBefore.filter((x) => x.packageCode !== cartAux.packageCode);
        addLocalStorageEncript(JSON.stringify(cartUpdateAux), 'cart');
      }
    }
  };

  const getAbsolutePath = () => {
    const loc = window.location;
    let pathName = loc.pathname.slice(0, -1);
    pathName = pathName.substring(0, pathName.lastIndexOf('/') + 1);
    return loc.href.substring(0, loc.href.length - ((loc.pathname + loc.search + loc.hash).length - pathName.length));
  };

  const saveCartReducerToLocalStore = () => {
    addLocalStorageEncript(JSON.stringify(cart), 'cart');
  };

  const handleClick = () => {
    saveCartReducerToLocalStore();
    window.parent.location.href = `${getAbsolutePath()}RegisterPurchaseExt`;
  };

  const handleShowElement = (id) => {
    setIsShownId(id);
  };

  return (
    <Popover
      id="cartMenu-popover"
      open
      anchorEl={element}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
        margin: 0,
      }}
      PaperProps={{
        style: { borderRadius: 16 },
      }}
    >
      <Card className={classes.card}>
        {
          cart?.length
            ? (
              <>
                <CardContent className={classes.cardContent}>
                  <List disablePadding>
                    {cartClean?.map((item) => (
                      <ListItem
                        key={item.id}
                        divider
                        dense
                        disableGutters
                        onMouseEnter={() => handleShowElement(item.id)}
                        onMouseLeave={() => handleShowElement(0)}
                      >
                        <ListItemText
                          primary={item.name}
                          primaryTypographyProps={{ style: { fontSize: 11, fontWeight: 700 } }}
                        />
                        <ListItemSecondaryAction
                          onMouseEnter={() => handleShowElement(item.id)}
                          onMouseLeave={() => handleShowElement(0)}
                        >
                          {isShownId === item.id ? (
                            <Tooltip title="Eliminar">
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => handleDelete(item)}
                              >
                                <DeleteIcon
                                  color="secondary"
                                  className={classes.btnRemoveFromCart}
                                />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Typography style={{ fontSize: 11, paddingRight: '15px' }}>
                              {`${formatter.format(item.price)} x ${item.number}`}
                            </Typography>
                          )}
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                    <ListItem
                      key="cartMenu-popover-subtotal"
                      disableGutters
                    >
                      <ListItemText
                        primary="Subtotal"
                        primaryTypographyProps={{ style: { fontWeight: 900 } }}
                        className={classes.subTotalText}
                      />
                      <ListItemSecondaryAction>
                        <Typography
                          style={{ fontWeight: 900, paddingRight: '15px' }}
                          className={classes.subTotalText}
                        >
                          {getSubtotal(cartClean)}
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </CardContent>
                <CardActions style={{ justifyContent: 'center' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.btnGoToCart}
                    onClick={() => handleClick()}
                  >
                    Pagar
                  </Button>
                </CardActions>
              </>
            )
            : (
              <CardContent className={classes.noItems}>
                <Typography style={{ marginBottom: 10, fontWeight: 500 }}>
                  ¡Tu carrito está vacío!
                </Typography>
                <AddShoppingCartIcon className={classes.iconShoppingCart} />
              </CardContent>
            )
        }
      </Card>
    </Popover>
  );
};

const mapStateToProps = ({ home, general }) => ({
  promoCode: home.promoCode,
  cart: home.cart,
  packages: home.packages,
  appName: general.appName,
});

const mapDispatchToProps = {
  deleteOfCart: deleteOfCartAction,
  addToCart: addToCartAction,
  getPackages: getPackagesAction,
  getPriceLicenseByQuantity: getPriceLicenseByQuantityAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CartMenuExt);
