/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-param-reassign */
/* eslint-disable radix */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Box,
  Grid,
  Tooltip,
  IconButton,
  Divider,
  Typography,
  TextField,
} from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import newButton from '../../../assets/img/iconos/ibuhoo/newButton.svg';
import CustomInput from '../../form/CustomInput';
import { Rules } from '../../../helpers/RHFRules';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 10,
    paddingBottom: 10,
    margin: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.5)',
    ...theme.miplan.rootCard,
    backgroundColor: '#0093D2',
  },
  titleRange: {
    backgroundColor: '#009BDD',
    color: '#fff',
    textAlign: 'center',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    marginBottom: 5,
  },
  titlePrice: {
    backgroundColor: '#1D83B8',
    color: '#fff',
    textAlign: 'center',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    marginBottom: 5,
  },
}));

const SetRangeTransaction = (props) => {
  const classes = useStyles();
  const {
    setTransactionRanges, transactionRanges, isEditing, licenseInformation, handleChangeRangeClick,
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit, errors, control, setValue, watch, reset,
  } = useForm({
    mode: 'onChange',
  });

  const [shownDelete, setShownDelete] = useState(false);

  const watchRangeMin = watch('range-min');
  const watchRangeMax = watch('range-max');
  const watchRangeValue = watch('range-value');

  useEffect(() => {
    if (isEditing) {
      setTransactionRanges(licenseInformation.transactionRange);
    }
    // eslint-disable-next-line
  }, [licenseInformation.transactionRange]);

  const skipEnterPress = (e) => {
    if (e.which === 13 /* Enter */) {
      e.preventDefault();
    }
  };

  const onSubmit = () => {
    const rangesList = transactionRanges;

    if (parseInt(watchRangeMin) > parseInt(watchRangeMax)) {
      enqueueSnackbar('Rango mínimo no puede ser mayor al rango máximo.', {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
      reset();
      return;
    }

    if (rangesList.length > 0) {
      if (rangesList.filter((x) => (x.rangeMinimum <= parseInt(watchRangeMin) && x.rangeMaximum >= parseInt(watchRangeMin))).length > 0) {
        enqueueSnackbar('Rango mínimo ya se encuentra dentro de los valores parametrizados.', {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
        reset();
        return;
      }

      if (rangesList.filter((x) => (x.rangeMinimum <= parseInt(watchRangeMax) && x.rangeMaximum >= parseInt(watchRangeMax))).length > 0) {
        enqueueSnackbar('Rango máximo ya se encuentra dentro de los valores parametrizados.', {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
        reset();
        return;
      }
    }

    rangesList.push({
      id: rangesList?.length + 1,
      rangeMinimum: parseInt(watchRangeMin),
      rangeMaximum: parseInt(watchRangeMax),
      rangePrice: parseInt(watchRangeValue),
    });

    setTransactionRanges(rangesList);
    handleChangeRangeClick(rangesList);
    setValue('range-min', '');
    setValue('range-max', '');
    setValue('range-value', '');
    reset();
  };

  const handleAddRange = (e) => {
    if (e.target.value.length > 8) e.target.value = e.target.value.slice(0, 8);
  };

  const handleChangeRange = (e, id) => {
    const modifyRangesList = transactionRanges;
    let valueAuxMin = 0;
    // eslint-disable-next-line no-unused-vars
    let valueAuxMax = 0;

    const rangesList = transactionRanges.find((item) => item.id === id);

    modifyRangesList.forEach((item) => {
      switch (e.target.id) {
        case 'range-min-added':
          valueAuxMin = parseInt(e.target.value);
          break;
        case 'range-max-added':
          valueAuxMax = parseInt(e.target.value);
          break;
        default:
          break;
      }
      if (valueAuxMin > rangesList.rangeMaximum) {
        e.target.value = rangesList.rangeMinimum;
        enqueueSnackbar('Rango mínimo no puede ser mayor al rango máximo.', {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
        return;
      }
      if (item.id === id) {
        switch (e.target.id) {
          case 'range-min-added':
            item.rangeMinimum = parseInt(e.target.value);
            break;
          case 'range-max-added':
            item.rangeMaximum = parseInt(e.target.value);
            break;
          case 'range-value-added':
            item.rangePrice = parseInt(e.target.value);
            break;
          default:
            break;
        }
      }
    });
    setTransactionRanges(modifyRangesList);
  };

  const handleDelete = () => {
    setTransactionRanges([]);
    setValue('range-min', '');
    setValue('range-max', '');
    setValue('range-value', '');
    reset();
  };

  const handleShowElement = (status) => {
    setShownDelete(status);
  };

  return (
    <form
      className={classes.form}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      onKeyPress={(e) => skipEnterPress(e)}
    >
      <Grid item xs container spacing={1}>
        <Grid item xs={8} className={classes.titleRange}>
          <Typography>
            Rango de Transacciones
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.titlePrice}>
          <Typography>
            Valor transacción
          </Typography>
        </Grid>
        {
          transactionRanges?.map((rangeItem) => (
            <Box style={{ display: 'contents' }} key={rangeItem.id}>
              <Grid item xs={4} style={{ padding: '5px' }}>
                <TextField
                  id="range-min-added"
                  variant="outlined"
                  label={rangeItem.rangeMinimum}
                  size="small"
                  disabled={!isEditing}
                  onChange={(e) => handleChangeRange(e, rangeItem.id)}
                />
              </Grid>
              <Grid item xs={4} style={{ padding: '5px' }}>
                <TextField
                  id="range-max-added"
                  variant="outlined"
                  label={rangeItem.rangeMaximum}
                  size="small"
                  disabled={!isEditing}
                  onChange={(e) => handleChangeRange(e, rangeItem.id)}
                />
              </Grid>
              <Grid item xs={4} style={{ padding: '5px' }}>
                <TextField
                  id="range-value-added"
                  variant="outlined"
                  label={rangeItem.rangePrice}
                  size="small"
                  disabled={!isEditing}
                  onChange={(e) => handleChangeRange(e, rangeItem.id)}
                />
              </Grid>
            </Box>
          ))
        }
        <Grid
          item
          xs={12}
          onMouseLeave={() => handleShowElement(false)}
        >
          <Divider className={classes.divider} variant="fullWidth" />
        </Grid>
        <Grid
          item
          xs={4}
          onMouseEnter={() => handleShowElement(true)}
          onMouseLeave={() => handleShowElement(false)}
        >
          <CustomInput
            type="number"
            label="Min"
            name="range-min"
            control={control}
            rule={Rules.numerico}
            error={errors}
            required
            onKeyDown={(e) => handleAddRange(e)}
            styleFromHelperText={{ style: { marginRight: 10 } }}
            inputProps={{
              min: 1, max: 999999999, step: '1', style: { textAlign: 'left', width: '100%' },
            }}
          />
        </Grid>
        <Grid
          item
          xs={4}
          onMouseEnter={() => handleShowElement(true)}
          onMouseLeave={() => handleShowElement(false)}
        >
          <CustomInput
            type="number"
            label="Max"
            name="range-max"
            control={control}
            rule={Rules.numerico}
            error={errors}
            required
            onKeyDown={(e) => handleAddRange(e)}
            styleFromHelperText={{ style: { marginRight: 10 } }}
            inputProps={{
              min: 1, max: 999999999, step: '1', style: { textAlign: 'left', width: '100%' },
            }}
          />
        </Grid>
        <Grid
          item
          xs={shownDelete ? 3 : 4}
          onMouseEnter={() => handleShowElement(true)}
        >
          <CustomInput
            label="Valor (COP)"
            name="range-value"
            control={control}
            rule={Rules.numerico}
            error={errors}
            required
            styleFromHelperText={{ style: { marginRight: 10, width: '70%' } }}
            inputProps={{
              maxLength: 10,
            }}
            onMouseLeave={() => handleShowElement(false)}
          />
        </Grid>
        {
          shownDelete && (
            <Grid
              item
              xs={1}
              onMouseEnter={() => handleShowElement(true)}
              onMouseLeave={() => handleShowElement(false)}
            >
              <Tooltip title="Eliminar">
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDelete()}
                >
                  <DeleteIcon
                    color="secondary"
                    className={classes.btnRemoveFromCart}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          )
        }
        <Grid
          item
          xs={12}
          onMouseLeave={() => handleShowElement(false)}
        >
          <Divider className={classes.divider} variant="fullWidth" />
        </Grid>
        <Grid
          item
          xs={12}
          onMouseLeave={() => handleShowElement(false)}
        >
          <Tooltip title="Crear rango" aria-label="add-range">
            <IconButton
              edge="end"
              type="submit"
              size="small"
              style={{ float: 'right' }}
            >
              <img src={newButton} alt="newButton" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </form>
  );
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(SetRangeTransaction);
