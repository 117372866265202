import {
  SERVICE_LOADING, GET_SERVICES, GET_SERVICES_COMBO, GET_SERVICES_MANUAL_LICENSE_COMBO,
} from '../types/service.type';

const INITIAL_STATE = {
  loading: false,
  services: [],
  servicesCombo: [],
  serviceAddManualLicenseCombo: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SERVICES:
      return {
        ...state,
        services: action.payload,
      };
    case GET_SERVICES_COMBO:
      return {
        ...state,
        servicesCombo: action.payload,
      };
    case GET_SERVICES_MANUAL_LICENSE_COMBO:
      return {
        ...state,
        serviceAddManualLicenseCombo: action.payload,
      };
    case SERVICE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
