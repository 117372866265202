/* eslint-disable react-hooks/exhaustive-deps  */
import React, { useEffect } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  addToCartAction,
  getInfoPackageByEncryptedParameterAction,
  applyPromotionCodeAction,
} from '../../../actions/home.action';
import { getParmeterConfigAction } from '../../../actions/general.action';
import {
  CalcDiscountPackage,
} from '../../../helpers/cartUtils';
import { addLocalStorageEncript, getLocalStorageEncript, clearLocalStorageStore } from '../../../helpers/storageUtils';
import Loading from '../Loading';

const AddCartExt = (props) => {
  const { encryptedParameter } = useParams();
  const {
    loading,
    addToCart,
    getInfoPackageByEncryptedParameter,
    getPackageByEncryptedParameter,
    history,
    cart,
    applyPromotionCode,
    appName,
    getParmeterConfig,
    isErrorGetPackageByEncripted,
  } = props;

  const saveCartReducerToLocalStore = () => {
    addLocalStorageEncript(JSON.stringify(cart), 'cart');
    history.push('/registerPurchaseOrder');
  };

  useEffect(() => {
    clearLocalStorageStore();
    getInfoPackageByEncryptedParameter(encryptedParameter);
    getParmeterConfig(appName, 'ParameterApps');
  }, []);

  useEffect(() => {
    const storeLanding = getLocalStorageEncript('storeLanding');
    if (!storeLanding) {
      addLocalStorageEncript(true, 'storeLanding');
    }
  }, []);

  useEffect(() => {
    if (cart.length > 0) {
      saveCartReducerToLocalStore();
    }
  }, [cart]);

  useEffect(() => {
    if (Object.keys(getPackageByEncryptedParameter).length > 0) {
      if (getPackageByEncryptedParameter?.package.length > 0) {
        getPackageByEncryptedParameter.package.forEach((row) => {
          const qxPacks = row.quantity;
          // eslint-disable-next-line no-plusplus
          for (let i = 1; i <= qxPacks; i++) {
            // eslint-disable-next-line no-param-reassign
            row.discount = row.applyOffer
              ? CalcDiscountPackage(row.packagePrice, row.totalOffer, row.offerTypeCode)
              : 0;
            addToCart(row);
          }
        });
      }
      if (getPackageByEncryptedParameter.promotionCode) {
        const data = {
          PromotionCode: getPackageByEncryptedParameter.promotionCode,
        };
        applyPromotionCode(data, null, null, false);
      }
    }
  }, [getPackageByEncryptedParameter]);

  useEffect(() => {
    if (isErrorGetPackageByEncripted) {
      history.push('/messageValidationEmail');
    }
  }, [isErrorGetPackageByEncripted]);

  if ((loading)) {
    return <Loading />;
  }

  return (
    <></>
  );
};

const mapStateToProps = ({ general, home }) => ({
  appName: general.appName,
  loading: home.loading,
  getPackageByEncryptedParameter: home.getPackageByEncryptedParameter,
  isErrorGetPackageByEncripted: home.isErrorGetPackageByEncripted,
  cart: home.cart,
});

const mapDispatchToProps = {
  addToCart: addToCartAction,
  getInfoPackageByEncryptedParameter: getInfoPackageByEncryptedParameterAction,
  applyPromotionCode: applyPromotionCodeAction,
  getParmeterConfig: getParmeterConfigAction,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddCartExt),
);
