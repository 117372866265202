import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns';
import LicenseCard from './LicenseCard';
import PlanTable from './PlanTable';
import { capitalizeFirstLetter } from '../../helpers/generalUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 280,
    borderRadius: 16,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  paper: {
    padding: '5px',
    marginTop: '20px',
    opacity: '0.95',
    ...theme.miplan.bgColorCards,
    borderRadius: '20px',
  },
  appPaper: {
    padding: '5px',
    marginTop: '20px',
    opacity: '0.95',
    ...theme.miplan.appBgColorCards,
    borderRadius: '20px',
  },
  boxTitles: {
    marginLeft: 25,
  },
  title: {
    ...theme.miplan.title,
    fontWeight: 600,
    paddingTop: '15px',
  },
  subtitle: {
    ...theme.miplan.subtitle,
    fontWeight: 900,
  },
  caption: {
    color: '#818181',
    fontSize: '0.8125rem',
  },
  purchasedPlans: {
    ...theme.miplan.purchasedPlans,
    fontSize: 20,
  },
  plansTypography: {
    marginLeft: '57px',
    fontWeight: 700,
    ...theme.miplan.plansTypography,
  },
}));

const LicenseGroup = (props) => {
  const columns = [
    {
      id: 'packageName',
      label: 'Plan',
      paddingLeft: '40px',
      position: 1,
      align: 'center',
      alignRow: 'left',
    },
    {
      id: 'effectiveDate',
      label: 'Fecha de inicio de vigencia',
      paddingLeft: '40px',
      position: 2,
      align: 'center',
      alignRow: 'center',
    },
    {
      id: 'expirationDate',
      label: 'Fecha de vencimiento de vigencia',
      paddingLeft: '40px',
      position: 3,
      align: 'center',
      alignRow: 'center',
    },
  ];

  const classes = useStyles();
  const { data, appName } = props;

  const theme = useTheme();
  const isBetweenSm = useMediaQuery(theme.breakpoints.between('600', '800'));
  const isBetweenWidthCard = useMediaQuery(theme.breakpoints.between('0', '960'));
  const isBetweenWidthXs = useMediaQuery(theme.breakpoints.between('532', '600'));

  const packageBase = data.packagesActiveInfo.filter((item) => item.packageType === 'BAS');
  const packageCMP = data.packagesActiveInfo.filter((item) => item.packageType === 'CMP');

  return (
    <>
      <Container component="main" maxWidth="lg">
        <Paper elevation={3} className={appName === 'license' ? classes.paper : classes.appPaper}>
          <Box className={classes.boxTitles}>
            <Typography className={classes.title} variant="h4">
              {appName === 'license' ? capitalizeFirstLetter(data.serviceNameDescription.toLowerCase()) : 'Mi plan'}
            </Typography>
            {appName !== 'license' && (
              <Typography className={classes.subtitle} variant="subtitle2">{data.packageName}</Typography>
            )}
            {appName !== 'license' && (
            <Typography className={classes.caption} variant="caption">
              Fecha de fin vigencia:
              {format(new Date(data.expirationDate), 'dd/MM/yyyy') }
            </Typography>
            )}
          </Box>
          <Grid container style={{ padding: 20, marginLeft: isBetweenWidthCard ? '0px' : '56px', width: isBetweenWidthCard ? '100%' : '95%' }}>
            {data.packagesFeatures
              && data.packagesFeatures.map((feature, index) => (
                <Grid key={index} item xs={isBetweenWidthXs ? 6 : 12} sm={isBetweenSm ? 6 : 4} md={3} lg={3}>
                  <LicenseCard
                    key={index}
                    feature={feature}
                    serviceName={data.serviceName}
                  />
                </Grid>
              ))}
          </Grid>
          <Box className={classes.boxTitles}>
            {
              packageBase.length > 0 && (
                <>
                  <Typography className={classes.purchasedPlans}>Mis planes adquiridos</Typography>
                  <br />
                </>
              )
            }
            {
              packageBase.length > 0 && (
                <>
                  <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                    className={classes.plansTypography}
                  >
                    Planes base
                  </Typography>
                  <PlanTable
                    columns={columns}
                    data={packageBase}
                    appName={appName}
                  />
                </>
              )
            }
            {
              packageCMP.length > 0 && (
                <>
                  <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                    className={classes.plansTypography}
                  >
                    Paquetes complementarios
                  </Typography>
                  <PlanTable
                    columns={columns}
                    data={packageCMP}
                    appName={appName}
                  />
                </>
              )
            }
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default LicenseGroup;
