import {
  GET_SUMMARY_ORDER, SUMMARY_LOADING, UPLOAD_PAYMENT_SUPPORT, RESET_UPLOAD_PAYMENT_SUPPORT, UPLOAD_RUT, RESET_UPLOAD_RUT,
} from '../types/summaryOrder.type';

const INITIAL_STATE = {
  loading: false,
  summaryOrder: undefined,
  uploadedSupportFile: undefined,
  uploadedRutFile: undefined,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SUMMARY_ORDER:
      return {
        ...state,
        summaryOrder: action.payload,
      };
    case SUMMARY_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case UPLOAD_PAYMENT_SUPPORT:
      return {
        ...state,
        uploadedSupportFile: action.payload,
      };
    case RESET_UPLOAD_PAYMENT_SUPPORT:
      return {
        ...state,
        uploadedSupportFile: action.payload,
      };
    case UPLOAD_RUT:
      return {
        ...state,
        uploadedRutFile: action.payload,
      };
    case RESET_UPLOAD_RUT:
      return {
        ...state,
        uploadedRutFile: action.payload,
      };
    default:
      return state;
  }
};
