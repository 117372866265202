/* eslint-disable consistent-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-array-index-key */
/* eslint-disable radix, react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Rules } from '../../../../helpers/RHFRules';
import Loading from '../../../../components/crosscutting/Loading';
import Carousel from '../../../../components/application/Carousel';
import PackageSliderCard from '../../../../components/application/Package/PackageSliderCard';
import searchIcon from '../../../../assets/img/iconos/ibuhoo/searchIcon.svg';
import CustomSelect from '../../../../components/form/CustomSelect';
import {
  addFeatureToPackageAction,
  getFeatureToPackageAction,
  selectFeatureToPackageAction,
  getPackagesTypeAction,
  updateFeaturesToPackageAction,
  searchPackageAction,
} from '../../../../actions/packets.action';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '5px',
    marginTop: '20px',
    opacity: '0.95',
    borderRadius: 10,
  },
  boxTop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '-25px',
    backgroundColor: '#0000000D',
    padding: '27px',
    marginBottom: '12px',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
  },
  searchTextField: {
    boxShadow: '0px 3px 2px #00000026',
    width: '90%',
    borderRadius: '10px',
    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
    border: '1px solid #D5D5D5',
    opacity: 1,
    height: '40px',
  },
  title: {
    fontWeight: 600,
    paddingLeft: '15px',
  },
  searchIcon: {
    paddingLeft: '10px',
    paddingTop: '4px',
  },
  quantityField: {
    width: 70,
  },
  storageTypeField: {
    width: 70,
    marginLeft: 5,
    fontSize: 6,
  },
  titleDetail: {
    fontWeight: 'bold',
    ...theme.home.cart.titleDetail,
  },
  iconSelect: {
    color: '#009BDD',
  },
  customSelected: {
    width: '90px',
    paddingLeft: '23px',
  },
  carousel: {
    '& .slick-list': {
      paddingLeft: '25%',
    },
  },
  submit: {
    textAlign: 'center',
    padding: 10,
    marginTop: '42px',
  },
  btnSubmit: {
    marginLeft: 10,
    fontSize: '21px',
    width: '29%',
    borderRadius: 10,
    fontWeight: 100,
    '&:disabled': {
      opacity: 0.4,
      fontWeight: 100,
      backgroundColor: '#1D71B8',
      color: 'white',
    },
  },
  roundedTextField: {
    boxShadow: '0px 3px 2px #00000026',
    borderRadius: '10px',
    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
    border: '1px solid #D5D5D5',
    opacity: 1,
    height: '40px',
    backgroundColor: '#FFFFFF',
  },
  noRecordsFound: {
    marginTop: '50px',
    backgroundColor: '#E1EFF2',
    borderRadius: '10px',
  },
  labelNoRecordsFound: {
    padding: '7px',
    color: '#00A8E7',
    fontSize: 18,
    textAlign: 'center',
  },

}));

const ConfigurePackage = (props) => {
  const {
    loading,
    getFeatureToPackage,
    packagesFeatures,
    packagesType,
    updateFeaturesToPackage,
    searchPackage,
    packagesSearch,
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const arrayBGBase = theme.backOffice.configurations.package.packageConfiguration.arrayBGColorBase.split(',');
  const arrayBGCombo = theme.backOffice.configurations.package.packageConfiguration.arrayBGColorCombo.split(',');
  const arrayBGComplementary = theme.backOffice.configurations.package.packageConfiguration.arrayBGColorComplementary.split(',');

  const {
    errors, control, setValue,
  } = useForm({
    mode: 'onChange',
  });

  const { enqueueSnackbar } = useSnackbar();

  const [enteredFilter, setEnteredFilter] = useState('');
  const [packageTypeId, setPackageTypeId] = useState('1');
  const [auxPackageTypeId, setAuxPackageTypeId] = useState('');
  const [saveSettings, setSaveSettings] = React.useState(false);

  const setPackageType = (event) => {
    const { value } = event.target;
    if (event.target.name === 'PackageService') {
      setPackageTypeId(value.toString());
    }
    const newPackageType = {
      PackageType: value,
    };
    setAuxPackageTypeId(value.toString());
    getFeatureToPackage(newPackageType);
    return value;
  };

  const handleSearch = () => {
    const newPackageType = {
      PackageType: packageTypeId,
      PackageName: enteredFilter,
    };
    getFeatureToPackage(newPackageType);
  };

  const setPackage = () => {
    let packagesAux = [];
    const packagesTypes = [];
    packagesAux = packagesType.filter((doc) => doc.packageTypeId === 1
      || doc.packageTypeId === 2
      || doc.packageTypeId === 3);
    if (packagesAux.length > 0) {
      packagesAux.map((doc, index) => (
        packagesTypes.push({
          id: index,
          label: doc.packageTypeName,
          value: doc.packageTypeId,
        })
      ));
    }
    return packagesTypes;
  };

  const setSliderColor = (index, packageType) => {
    switch (packageType.toString()) {
      case '1':
        return arrayBGBase[index];
      case '2':
        return arrayBGComplementary[index];
      case '3':
        return arrayBGCombo[index];
      default:
        return arrayBGBase[index];
    }
  };

  const handleUpdateFeatures = (data) => {
    updateFeaturesToPackage(data, enqueueSnackbar);
  };

  const handleSet = (e) => {
    if (e.target.value != null) {
      searchPackage(e.target.value);
      if (packagesFeatures.length === 0) {
        packagesFeatures.pop();
      }
      setEnteredFilter(e.target.value);
    }
  };

  useEffect(() => {
    if (enteredFilter === '') {
      setAuxPackageTypeId(packageTypeId);
      const newPackageType = {
        PackageType: packageTypeId,
      };
      getFeatureToPackage(newPackageType);
    }
  }, [enteredFilter]);

  useEffect(() => {
    setValue('PackageService', auxPackageTypeId);
  }, [auxPackageTypeId, loading]);

  if (loading) return <Loading />;

  return (
    <>
      <Box className={classes.boxTop}>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex', alignItems: 'center', justifyContent: 'center',
          }}
        >
          <Grid item xs={5} lg={5} style={{ paddingLeft: '38px' }}>
            <CustomSelect
              style={{ width: '90px' }}
              name="PackageService"
              options={setPackage()}
              control={control}
              required
              rule={Rules.required}
              error={errors}
              styleFromHelperText={{ style: { marginRight: 10 } }}
              inputProps={{ onChange: (e) => setPackageType(e) }}
              classes={classes.roundedTextField}
            />
          </Grid>
          <Grid item xs={7} lg={7} style={{ paddingLeft: '40px' }}>
            <TextField
              className={classes.searchTextField}
              value={enteredFilter}
              onChange={(e) => handleSet(e)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if (packagesFeatures.length === 0) {
                    handleSearch();
                  } else {
                    searchPackage(enteredFilter);
                  }
                }
              }}
              // onChange={(e) => setEnteredFilter(e.target.value)}
              style={{ padding: '1px 0 5px', backgroundColor: '#FFFFFF' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} alt="searchIcon" className={classes.searchIcon} />
                  </InputAdornment>
                ),
                // onBlur: () => handleSearch(),
                disableUnderline: true,
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{
                style: { paddingTop: '8px' },
                maxLength: 100,
              }}
            />
          </Grid>
        </Grid>
      </Box>
      {
        packagesFeatures && packagesFeatures.length > 0 ? (
          <Box
            className={`${packagesFeatures && packagesFeatures.length === 1
              ? classes.carousel
              : ''}`}
          >
            <Carousel
              dots
              autoplay={false}
              arrows
              lazyLoad="ondemand"
              slidesToShow={packagesFeatures && packagesFeatures.length > 1 ? 2 : 1}
            >
              {packagesFeatures && packagesFeatures.map((itemPackage, index) => (
                <PackageSliderCard
                  setSaveSettings={setSaveSettings}
                  key={index}
                  itemPackage={itemPackage}
                  colorBgIndex={setSliderColor(index, packageTypeId)}
                  packageTypeId={packageTypeId}
                  totalPackages={packagesFeatures.length}
                />
              ))}
            </Carousel>
            <Box className={classes.submit}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="medium"
                disabled={!saveSettings}
                className={classes.btnSubmit}
                onClick={() => handleUpdateFeatures(packagesFeatures)}
              >
                Guardar Configuración
              </Button>
            </Box>
          </Box>
        ) : (
          <Box className={classes.noRecordsFound}>
            <Typography
              className={classes.labelNoRecordsFound}
            >
              {
              packagesSearch && packagesSearch.length === 0 ? (
                'No se encontraron registros.'
              ) : (
                'Presiona la tecla Enter para filtar tu busqueda.'
              )
              }
            </Typography>
          </Box>
        )
      }
    </>
  );
};

const mapStateToProps = ({ packets }) => ({
  packages: packets.packages,
  packagesRows: packets.packagesRows,
  packagesFeatures: packets.packagesFeatures,
  loading: packets.loading,
  packagesType: packets.packagesType,
  packagesSearch: packets.packagesSearch,
});

const mapDispatchToProps = {
  getFeatureToPackage: getFeatureToPackageAction,
  addFeatureToPackage: addFeatureToPackageAction,
  selectFeatureToPackage: selectFeatureToPackageAction,
  getPackagesType: getPackagesTypeAction,
  updateFeaturesToPackage: updateFeaturesToPackageAction,
  searchPackage: searchPackageAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurePackage);
