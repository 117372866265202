import {
  SECURITY_LOADING, SECURITY_GET_USERDATA_BY_ID, SECURITY_GET_USERCREATE_BY_ID, SECURITY_GET_USERMODIFY_BY_ID,
} from '../types/security.type';

const INITIAL_STATE = {
  loading: false,
  dataUserSecurity: undefined,
  dataUserCreate: undefined,
  dataUserModify: undefined,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SECURITY_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SECURITY_GET_USERDATA_BY_ID:
      return {
        ...state,
        dataUserSecurity: action.payload,
      };
    case SECURITY_GET_USERCREATE_BY_ID:
      return {
        ...state,
        dataUserCreate: action.payload,
      };
    case SECURITY_GET_USERMODIFY_BY_ID:
      return {
        ...state,
        dataUserModify: action.payload,
      };
    default:
      return state;
  }
};
