/* eslint-disable linebreak-style */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { Controller } from 'react-hook-form';

const useStyles = makeStyles(() => ({
  iconCustom: {
    '& .MuiIconButton-root': {
      color: '#009fe3',
    },
  },
  formControlCustom: {
    width: '100%',
  },
}));

const CustomBasicAutoComplete = (props) => {
  const {
    options, label, placeholder, className, disabled, registersSelected,
    setData, control, rule, name, error, handleChangeOption,
  } = props;

  const classes = useStyles();

  return (
    <Controller
      render={(propsComponente) => (
        <Autocomplete
          size="small"
          options={options}
          noOptionsText="No hay registros"
          getOptionLabel={(option) => option.text}
          // eslint-disable-next-line consistent-return
          getOptionSelected={(option, value) => {
            if (value === '') {
              return true;
            } if (value === option) {
              return true;
            }
          }}
          value={registersSelected?.text ? registersSelected : null}
          disableClearable
          fullWidth
          className={!disabled && classes.iconCustom}
          disabled={disabled}
          required
          onChange={(_, data) => {
            propsComponente.onChange(data);
            setData(data);
            handleChangeOption(data);
          }}
          renderInput={(params) => (
            <TextField
              className={className}
              {...params}
              variant="outlined"
              label={label}
              error={error}
              placeholder={placeholder}
              style={{ width: '100%' }}
              helperText={error ? 'Campo obligatorio' : ''}
            />
          )}
        />
      )}
      name={name}
      control={control}
      rules={rule}
    />
  );
};

export default CustomBasicAutoComplete;
