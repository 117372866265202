import React from 'react';
import { Controller } from 'react-hook-form';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import FormControl from '@material-ui/core/FormControl';
import deLocale from 'date-fns/locale/es';

const CustomDate = (props) => {
  const {
    label, name, id, control, rule, error, color, classNameKeyboardDate, onChangeHandler,
    size, variant, inputVariant, formatKeyboardDate, dateSelected, shouldDisableDate, defaultValue,
    disablePast,
  } = props;

  return (
    <FormControl>
      <Controller
        render={({ onChange, value }) => (
          <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              id={id}
              className={classNameKeyboardDate}
              variant={variant}
              inputVariant={inputVariant}
              format={formatKeyboardDate}
              label={label}
              color={color}
              onChange={onChangeHandler || ((e) => onChange(e))}
              value={dateSelected || value}
              size={size}
              invalidDateMessage="Fecha no valida"
              shouldDisableDate={shouldDisableDate}
              autoOk
              disablePast={disablePast}
            />
          </MuiPickersUtilsProvider>
        )}
        name={name}
        control={control}
        rules={rule}
        error={Boolean(error)}
        defaultValue={defaultValue}
      />
    </FormControl>
  );
};

export default CustomDate;
